export const USER_GROUPS = {
  TEST: 'test',
  ANONYMOUS: 'Anonymous',
  BUYER: 'buyer',
  ADMIN: 'admin',
  APPROVER: 'approver',
  GROUP_ORDER_BUYER: 'groupOrderBuyer',
}
export const CLIENT_TYPES = {
  REGULAR: 'regular',
  PUNCH_OUT: 'punchout',
  PUNCH_OUT_WITH_INTERNAL_CHECKOUT: 'sso',
}
