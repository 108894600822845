export const CONFIRMATION_PAGE_VALUES = {
  ptype: 'other',
  title: 'eway.ca - Order Confirmation',
}

export const ANALYTIC_TYPES = {
  pageView: 'PAGE_VIEW',
  addToCart: 'ADD_TO_CART',
  search: 'SEARCH',
}
