import PropTypes from 'prop-types'
import AlertNotification from 'shared/Alerts/AlertNotification'
import ToastNotificationsList from '../ToastNotificationsList'
/**
 * Name: AlertNotificationsList
 * Desc: Render Alert AlertNotificationsList
 * @param {array} alerts
 * @param {func} onCloseAlert
 *
 */
export function AlertNotificationsList({ alerts, onCloseAlert, id, ...rest }) {
  const alertNotifications = alerts.filter((alert) => !alert.toast)
  const toastNotifications = alerts.filter((alert) => alert.toast)
  return (
    <>
      <ToastNotificationsList
        toasts={toastNotifications}
        onCloseAlert={onCloseAlert}
        {...rest}
      />
      <div data-container-id={id}>
        {alertNotifications.map((alert) => (
          <AlertNotification
            key={alert.id}
            id={alert.id}
            type={alert.type}
            prefix={alert.prefix}
            text={alert.PrimaryMessage}
            index={alert.index}
            hasLink={alert.HasLink}
            linkText={alert.ButtonText}
            link={alert.ButtonLink}
            hasButton={alert.HasButton}
            buttonText={alert.ButtonText}
            maxWidth={alert.toast ? 'auto' : alert.maxWidth}
            iconPosition={alert.iconPosition}
            textDecoration={true}
            showCloseButton={alert.showCloseButton}
            color="secondary"
            secondaryMessages={alert.SecondaryMessages}
            closeAlertHandler={() => onCloseAlert(alert.id)}
          />
        ))}
      </div>
    </>
  )
}

export default AlertNotificationsList

// Props Validation
AlertNotificationsList.propTypes = {
  alerts: PropTypes.array,
  onCloseAlert: PropTypes.func,
  id: PropTypes.string,
}
