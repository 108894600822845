import { currentDateIsBetween } from './javascriptUtils'
import { getHostUrl } from 'services/utils/configUtils'
import { getUrl } from 'shared/EwayRouter'
import {
  SHOPPING_LIST_ROUTE,
  SHOPPING_CART_ROUTE,
  ORDER_SUMMARIES_ROUTE,
  QUICK_GROUP_ORDER_ROUTE,
} from 'shared/EwayRouter/EwayRouterConstants'

const HOST_URL = getHostUrl()

export const isLoggedIn = (buyer) => {
  const loggedIn = !!(buyer?.buyerId && Object.keys(buyer).length > 0)
  return loggedIn
}

// To display Messenger Center found on the homepage
export const canDisplayMessagingCenter = (settings) => {
  return settings?.displayEwayMessagingCenter
}
/**
 * Checks the user is suspended, all that logic should be done backend (normally)
 * @param {Object} details Expects the details object to be passed
 */

export const isSuspended = ({ suspendedDateFrom, suspendedDateUntil } = {}) => {
  return currentDateIsBetween(suspendedDateFrom, suspendedDateUntil)
}

/**
 * A function where nothing happens.
 */
export function noop() {}

/**
 * Checks if user is under minimum order amount
 * @param {Object} order - Expects the order object
 */
export const isUnderMinimumOrderAmount = ({
  minimumOrderAmount,
  subTotal: orderAmount,
}) => {
  return orderAmount < minimumOrderAmount
}

/**
 * Checks if the user has enabled recommendations
 * If in one of the settings
 *
 * "enableRecommendationProduct"
 * "enableRecentlyViewedFooter"
 *
 * Is true
 *
 * This methods returns true
 *
 * @param {Object} settings
 */
export const recommendationsIsEnabled = ({
  enableRecommendationProduct,
  enableRecentlyViewedFooter,
} = {}) => {
  return enableRecommendationProduct || enableRecentlyViewedFooter
}

export const getDefaultPageURL = ({ buyer = {} } = {}) => {
  const { firstPageType } = buyer

  const homePageURL = HOST_URL
  const shoppingListURL = getUrl(SHOPPING_LIST_ROUTE)
  const cartURL = getUrl(SHOPPING_CART_ROUTE)
  const myOrdersURL = `${getUrl(ORDER_SUMMARIES_ROUTE)}/1`
  const myApprovalURL = `${getUrl(ORDER_SUMMARIES_ROUTE)}/2`
  const quickGroupOrderWelcomeURL = getUrl(QUICK_GROUP_ORDER_ROUTE)
  const searchPageURL = `${HOST_URL}/Search/Search.aspx`

  switch (firstPageType) {
    case 2:
      return shoppingListURL
    case 3:
      return cartURL
    case 4:
      return myOrdersURL
    case 5:
      return myApprovalURL
    case 6:
      return quickGroupOrderWelcomeURL
    case 7:
      return homePageURL
    case 10:
      return searchPageURL
  }

  return homePageURL
}

export function redirectToDefaultPage(buyer) {
  const defaultPage = getDefaultPageURL({ buyer })
  window.location.assign(defaultPage)
}
