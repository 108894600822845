import TYPES from './SpecialDeliveryTooltipConstants'
export default {
  en: {
    [TYPES.DELIVERY_INSTALL]: `Delivery and installation charges are for Special Delivery furniture products only, identified by the {icon} icon, to the specified Shipping Address.`,
    [TYPES.DELIVERY_INSTALL_EXTRA]: `Customer care will contact you to determine the additional delivery and installation charge for your Special Delivery products, identified by the {icon} icon.`,
    [TYPES.STANDARD_DELIVERY]: `Delivery charges are for Special Delivery furniture products only, identified by the {icon} icon, to the specified Shipping Address.`,
    [TYPES.STANDARD_DELIVERY_EXTRA]: `Customer care will contact you to determine the additional delivery charge for your Special Delivery products, identified by the {icon} icon.`,
  },
  fr: {
    [TYPES.DELIVERY_INSTALL]: `Les frais de livraison et installation sont uniquement pour les produits d'ameublement en livraison spéciale, identifiés par l'icône {icon}, à l'adresse de livraison spécifiée.`,
    [TYPES.DELIVERY_INSTALL_EXTRA]: `Le Service à la clientèle vous contactera pour déterminer les frais de livraison et d'installation supplémentaires pour vos produits en livraison spéciale identifiés par l'icône {icon}.`,
    [TYPES.STANDARD_DELIVERY]: `Les frais de livraison sont uniquement pour les produits d'ameublement en livraison spéciale, identifiés par l'icône {icon}, à l'adresse de livraison spécifiée.`,
    [TYPES.STANDARD_DELIVERY_EXTRA]: `Le Service à la clientèle vous contactera pour déterminer les frais de livraison supplémentaires pour vos produits en livraison spéciale identifiés par l'icône {icon}.`,
  },
}
