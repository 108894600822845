import { getHostUrl } from 'services/utils/configUtils'

const HOST_URL = getHostUrl()
export const GROUP_ORDERS_ROUTE = `${HOST_URL}/api/meerkat/group-orders`
export const REPLIED = 1
export const NO_REPLIED = 0
export const SAME_ACCOUNT = 1
export const OTHER_ACCOUNT = 0
export const EMPTY_CART = 1
export const NO_EMPTY_CART = 0
export const DEFAULT_DATE_FORMAT = 'dd MMM yyyy'

export const PROCESS_ORDER_WARN_TYPES = {
  [`${REPLIED}_${SAME_ACCOUNT}_${EMPTY_CART}`]: 'repliedSameAccountEmptyCart',
  [`${REPLIED}_${SAME_ACCOUNT}_${NO_EMPTY_CART}`]:
    'repliedSameAccountNoEmptyCart',
  [`${REPLIED}_${OTHER_ACCOUNT}_${EMPTY_CART}`]: 'repliedOtherAccountEmptyCart',
  [`${REPLIED}_${OTHER_ACCOUNT}_${NO_EMPTY_CART}`]:
    'repliedOtherAccountNoEmptyCart',
  [`${NO_REPLIED}_${SAME_ACCOUNT}_${EMPTY_CART}`]:
    'noRepliedSameAccountEmptyCart',
  [`${NO_REPLIED}_${SAME_ACCOUNT}_${NO_EMPTY_CART}`]:
    'noRepliedSameAccountNoEmptyCart',
  [`${NO_REPLIED}_${OTHER_ACCOUNT}_${EMPTY_CART}`]:
    'noRepliedOtherAccountEmptyCart',
  [`${NO_REPLIED}_${OTHER_ACCOUNT}_${NO_EMPTY_CART}`]:
    'noRepliedOtherAccountNoEmptyCart',
}

export const GROUP_ORDER_STATUS = {
  OPEN: 'open',
  PROCESSED_TO_CART: 'processedToCart',
  SUBMITTED: 'submitted',
}

export const GROUP_ORDER_TYPE = {
  QUICK: 'quick',
  ADVANCED: 'advanced',
}

export const ERROR_MESSAGE = 'buyerId and groupOrderNumber are required'
