import { ENGLISH } from '@redux/constants'
import { getOldApiUrl } from 'services/utils/configUtils'
import { checkArgsEmpty, sendRequest } from '~/api/apiHelper'
const OLD_API_URL = getOldApiUrl()

/**
 * Name: getHomePageDefaultData
 * Desc: get home page data
 * @param {*} language
 */
const buildFetchHomePageDefaultData = async (args) => {
  const [language = ENGLISH] = args

  const url = `${OLD_API_URL}/api/navigation/getDefaultPage`
  const options = {
    headers: {
      'Accept-Language': `${language}-CA`,
    },
  }
  const response = await sendRequest(url, options)
  return response
}

/**
 * Name: getCardBudgetCalculatorData
 * Desc: get budget calculator data
 * @param {*} language
 */
export const getCardBudgetCalculatorData = async () => {
  const url = `${OLD_API_URL}/api/budgetCalculator/budgetCalculatorModel`
  const response = await sendRequest(url)
  return response
}

/**
 * Export method
 */
export const getHomePageDefaultData = checkArgsEmpty(
  buildFetchHomePageDefaultData
)
