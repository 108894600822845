import { Children, Fragment } from 'react'
import { getCookie } from 'services/cookieManager'
import { ENGLISH, FRENCH } from '~/redux/constants'
import { COOKIES } from '../constants'

export const ENGLISH_LOCALE_OLD_EWAY = 'en-CA'
const CA_FRENCH_LOCALE = 'fr-CA'
const US_ENGLISH_LOCALE = 'en-US'

// Add extra locale constant because TurnTO Review and Rating widget follow different language pattern
const TURN_TO_CA_FRENCH_LOCALE = 'fr_CA'
const TURN_TO_US_ENGLISH_LOCALE = 'en_CA'

export const isEnglish = (lang) => lang === ENGLISH

export const getChildrenByName = (children, name) =>
  Children.toArray(children).filter(
    (children) => children.type && children.type.name === name
  )

export const getOldEwayFullLocale = (lang) => {
  const languages = {
    [ENGLISH]: ENGLISH_LOCALE_OLD_EWAY,
    [FRENCH]: CA_FRENCH_LOCALE,
  }
  return languages[lang]
}

export const getFullLocale = (lang) => {
  const languages = {
    [ENGLISH]: US_ENGLISH_LOCALE,
    [FRENCH]: CA_FRENCH_LOCALE,
  }
  return languages[lang]
}

export const getTurnToFullLocale = (lang) => {
  const languages = {
    [ENGLISH]: TURN_TO_US_ENGLISH_LOCALE,
    [FRENCH]: TURN_TO_CA_FRENCH_LOCALE,
  }
  return languages[lang]
}

/**
 *
 * @param {*} fullLocale
 * Transforms a locale like 'en-CA' to just 'en'
 */
export const shortenLanguage = (fullLocale = '') => {
  return fullLocale.slice(0, -3)
}

export const getLanguageParam = (language) => {
  return `?Language=${getOldEwayFullLocale(language)}`
}

/**
 * Return the replaces string match with object keys and replace with value
 * @param {string} str
 * @param {object} obj
 * @returns {string} str
 *
 * Ex.  =>
 * getTextWithDynamicValue(
 * 'The product code is {{PRODUCT_CODE}}',
 * {
 *  '{{PRODUCT_CODE}}': 'H12345'
 * }
 * )
 *
 * Result : The product code is H12345
 *
 */

/**
 * @deprecated - use dynamicTranslation instead, favor unit tested function
 * @param {*} str
 * @param {*} obj
 * @returns a dynamically translated string
 */
export const getTextWithDynamicValue = (str, obj) => {
  const result = new RegExp(Object.keys(obj).join('|'), 'g')
  return str?.replace(result, function (data) {
    return obj[data]
  })
}

export const getTextWithDynamicComponent = (str, obj) => {
  const result = []
  const dynamicValues = str?.match(/{{.*?}}/g) || []

  if (!str || dynamicValues.length === 0) {
    return result
  }
  let data = str
  dynamicValues.forEach((key, index) => {
    if (str.search(key) > -1) {
      const splitedArr = data.split(key)
      data = splitedArr[1]

      if (splitedArr[0] !== '') {
        result.push(<Fragment key={index}>{splitedArr[0]}</Fragment>)
      }
      result.push(<Fragment key={`${key}-${index}`}>{obj[key]}</Fragment>)
    }
  })
  result.push(data)
  return result
}

/**
 * Server side language method that get the language from the NextJS context
 * @param {*} ctx
 */
export const getLanguageFromContext = (ctx) => {
  const languageCookie = ctx.req.cookies?.[COOKIES.LANGUAGE]
  const languageHeader = ctx.req.headers?.[COOKIES.LANGUAGE.toLowerCase()]
  return shortenLanguage(languageCookie || languageHeader || ENGLISH)
}

/**
 * Browser side mostly, returns current language from cookies
 * @param {*} ctx
 */
export const getLanguageFromCookies = () => {
  const locale = getCookie(COOKIES.LANGUAGE) || US_ENGLISH_LOCALE
  return shortenLanguage(locale)
}
