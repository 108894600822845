import isEmpty from 'lodash/isEmpty'
import { checkKeysEmpty } from 'services/utils'
import { checkBloomReachAvailable } from '~/services/thirdPartyAvailability'
import {
  getLanguageFromCookies,
  isEnglish,
} from '~/services/utils/languageUtils'
import {
  ANALYTIC_TYPES,
  CONFIRMATION_PAGE_VALUES,
} from './BloomReachAnalyticsConstants'
import defaultValues from './BloomReachAnalyticsPreLogin'

const analyticsCaches = []

let isEnabled = false
let analyticsFirstCallCurrentStatus = null

/**
 * Sends BloomReachTracking in an ajax fashion
 * https://help.bloomreach.com/display/BRINT/JavaScript+tracking+pixel+reference#tab-Virtual+Page+View+Code+Snippet
 * @param {*} data
 */
export async function sendBloomReachTracking(data) {
  const ready = await checkBloomReachAvailable()
  if (ready && !isEmpty(data)) {
    window.BrTrk.getTracker().updateBrData(data)
    window.BrTrk.getTracker().logPageView()
  }
}

export const sendBloomReachPageTracking = (data) => {
  if (!bloomReachAnalyticsStatus() || checkKeysEmpty(data)) {
    return
  }

  if (!isTrackingReady()) {
    cacheAnalytics(data, ANALYTIC_TYPES.pageView)
    return
  }

  sendBloomReachTracking(data)
}

export const sendBloomReachAddCartTracking = (data) => {
  if (!bloomReachAnalyticsStatus() || checkKeysEmpty(data)) {
    return
  }

  if (!isTrackingReady()) {
    cacheAnalytics(data, ANALYTIC_TYPES.addToCart)
    return
  }
  const { type, prod_id, sku } = data
  window.BrTrk.getTracker().logEvent('cart', type, {
    prod_id,
    sku,
  })
}

export const sendBloomReachSearchTracking = (searchData, analyticsData) => {
  if (!bloomReachAnalyticsStatus()) {
    return
  }

  if (checkKeysEmpty(searchData) || checkKeysEmpty(analyticsData)) {
    return
  }

  if (!isTrackingReady()) {
    cacheAnalytics({ searchData, analyticsData }, ANALYTIC_TYPES.search)
    return
  }

  window.BrTrk.getTracker().logEvent(
    'suggest',
    'submit',
    searchData,
    analyticsData,
    true
  )
}

/**
 * Set and get bloom reach analytics enable status
 * @param {boolean} status
 */
export const bloomReachAnalyticsStatus = (status = null) => {
  if (status === null) {
    return isEnabled
  }

  isEnabled = status
}

/**
 * Set and get bloom reach analytics called first time status
 * @param {boolean} status
 */
export const bloomReachAnalyticsFirstCallStatus = (status = null) => {
  if (status === null) {
    return analyticsFirstCallCurrentStatus
  }

  analyticsFirstCallCurrentStatus = status
}

export const isTrackingReady = () => {
  return !!window.BrTrk
}

function cacheAnalytics(analyticsValues, type) {
  if (isTrackingReady()) {
    return
  }
  analyticsCaches.push({
    analyticsValues,
    type,
  })
}

export function sendCachedAnalytics() {
  if (analyticsCaches.length === 0) {
    return
  }
  analyticsCaches.forEach((analyticsCache) => {
    const { analyticsValues, type } = analyticsCache
    switch (type) {
      case ANALYTIC_TYPES.pageView:
        return sendBloomReachPageTracking(analyticsValues)
      case ANALYTIC_TYPES.addToCart:
        return sendBloomReachAddCartTracking(analyticsValues)
      case ANALYTIC_TYPES.search:
        return sendBloomReachSearchTracking(
          analyticsValues.searchData,
          analyticsValues.analyticsData
        )
    }
  })
}

export function getConfirmationBloomReachAnalytics(order = {}) {
  const orderIsEmpty = isEmpty(order)
  if (orderIsEmpty) {
    return defaultValues
  }

  const dynamicValues = getDynamicValues(order, orderIsEmpty)
  return {
    ...defaultValues,
    ...dynamicValues,
    ...CONFIRMATION_PAGE_VALUES,
  }
}

function getDynamicValues(order, orderIsEmpty) {
  const { shipToClientId, ewayOrderNo, subTotal } = order
  const language = getLanguageFromCookies()
  const url = getWindowLocation()
  const ref = getDocumentReferrer()
  const basketItems = getBasketProducts(language, order.orderProducts)
  const basketValue = getBasketValue(subTotal, order)
  const orderNumber = getOrderNumber(ewayOrderNo)
  const domainKey = getDomainKey(language)
  const conversionValue = getConversionValue(orderIsEmpty)
  const userId = getUserId(shipToClientId)
  const dynamicValues = {
    ...url,
    ...ref,
    ...basketItems,
    ...basketValue,
    ...orderNumber,
    ...domainKey,
    lang: language,
    ...conversionValue,
    ...userId,
  }
  return dynamicValues
}

function getConversionValue(orderIsEmpty) {
  return { ...(!orderIsEmpty && { is_conversion: 1 }) }
}

function getDocumentReferrer() {
  return {
    ...(document.referrer && { ref: document.referrer }),
  }
}

function getWindowLocation() {
  return {
    ...(window.location.href && { url: window.location.href }),
  }
}

function getBasketValue(subTotal, order) {
  return {
    ...(subTotal && { basket_value: order.subTotal }),
  }
}

function getOrderNumber(ewayOrderNo) {
  return {
    ...(ewayOrderNo && { order_id: `EW${ewayOrderNo}` }),
  }
}

function getDomainKey(language) {
  const value = isEnglish(language) ? 'eway_ca' : 'eway_ca_fr'
  return {
    ...(language && { domain_key: value }),
  }
}

function getUserId(shipToClientId) {
  return {
    ...(shipToClientId && { user_id: shipToClientId.trim() }),
  }
}

const getBasketProducts = (language = '', productItems = []) => {
  const { length } = productItems
  const basketItemsValue = {
    ...(length && {
      basket: { items: productItems.map(formatProducts(language)) },
    }),
  }
  return basketItemsValue
}

const formatProducts = (language) => (product) => {
  const {
    productId,
    quantity,
    unitSellPrice,
    orderProductDetails = {},
  } = product
  const { descriptionEnrichedMedium = '' } = orderProductDetails[0] || {}
  return {
    prod_id: productId,
    sku: productId + language.toUpperCase(),
    name: descriptionEnrichedMedium,
    quantity,
    price: unitSellPrice,
  }
}

export function sendBloomReachPageAnalytics(data, searchAnalyticsData = {}) {
  const analyticsProperties =
    data?.Analytics?.Bloomreach?.AnalyticsProperties || {}

  if (bloomReachAnalyticsFirstCallStatus()) {
    return
  }

  bloomReachAnalyticsFirstCallStatus(true)

  if (searchAnalyticsData.q) {
    const { acct_id, domain_key, user_id } = analyticsProperties
    sendBloomReachSearchTracking(searchAnalyticsData, {
      acct_id,
      domain_key,
      user_id,
    })
  }

  sendBloomReachPageTracking(analyticsProperties)
}
