import { handleErrorWithLogging } from './apiHelper'

export async function doRequest(url, options) {
  let response = {}
  let resource
  try {
    response = await fetch(url, options)
    resource =
      response.status <= 400
        ? response.status === 204
          ? {}
          : await response.json()
        : handleErrorWithLogging(response, {}, options.method)
  } catch (error) {
    return handleErrorWithLogging(response, error, options.method)
  }
  return { resource, response, ok: response.ok }
}
