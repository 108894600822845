import SpecialDeliveryTooltip from '~/components/shared/SpecialDeliveryTooltip'
import { ENGLISH } from '~/redux/constants'
import { feeLabels, includedTypeLabels } from './feeOrderServiceLabels'
import { ORDER_FEE_TYPES } from './feeOrderServiceTypes'
import {
  deliveryAndInstallFeeIsUnknown,
  isExemptFromDeliveryAndInstallFees,
  userChooseDeliveryAndInstall,
} from './feeOrderServiceUtilities'

export const getFurnitureDeliveryAndInstallFees = (
  cartDetails = {},
  language = ENGLISH
) => {
  const name = feeLabels[ORDER_FEE_TYPES.DELIVERY_AND_INSTALL][language]
  if (isExemptFromDeliveryAndInstallFees(cartDetails)) {
    return {
      name,
      value: includedTypeLabels[language].included,
    }
  }
  const value = getFee(cartDetails, language)
  const hasSpecialDeliveryItem = cartDetails.cartSummary?.hasSpecialDeliveryItem

  if (hasSpecialDeliveryItem) {
    return {
      name: hasSpecialDeliveryItem ? name : '',
      value,
      message: (
        <SpecialDeliveryTooltip language={language} cartDetails={cartDetails} />
      ),
    }
  }
  return {}
}

function getFee(cartInfo, language) {
  if (!userChoosePreInstallOnSite(cartInfo)) {
    return 0
  }

  const deliveryTimeIsKnown = !deliveryAndInstallFeeIsUnknown(
    cartInfo.orderDeli
  )

  const showExtraLabel =
    !deliveryTimeIsKnown || someProductsNeedsAQuote(cartInfo.products)

  if (showExtraLabel) {
    return includedTypeLabels[language].extra
  }

  return (
    cartInfo.cartSummary.priceDetails.deliveryAndInstallFee ||
    includedTypeLabels[language].included
  )
}

function userChoosePreInstallOnSite(cartInfo) {
  return (
    cartInfo.orderDeli?.isDeliOptSet &&
    userChooseDeliveryAndInstall(cartInfo.orderDeli)
  )
}

function someProductsNeedsAQuote(products) {
  return products?.some((x) => x.needQuote)
}
