import PropTypes from 'prop-types'
import DynamicTranslation from 'shared/DynamicTranslation'
import IconTooltip from 'shared/IconTooltip'
import Icon from '~/components/shared/Icon'
import { ENGLISH } from '~/redux/constants'
import styles from './SpecialDeliveryTooltip.module.scss'
import { getTooltipText } from './SpecialDeliveryTooltipUtils'
import { removeStringBraces } from 'services/utils'
function SpecialDeliveryTooltip(props) {
  const { language, cartDetails } = props
  const tooltipText = getTooltipText(language, cartDetails)
  return (
    <IconTooltip
      icon="question"
      iconClassName={styles.containerStyling}
      tooltipClassName={styles.specialDeliveryStyling}
      id="specialDeliveryTooltip"
      data-color="iconColor"
      tooltipText={removeStringBraces(tooltipText)}
    >
      <DynamicTranslation
        text={tooltipText}
        replace="{icon}"
        with={<Icon variant="specialDelivery" className={styles.iconColor} />}
      />
    </IconTooltip>
  )
}

// Default Props
SpecialDeliveryTooltip.defaultProps = {
  language: ENGLISH,
  cartDetails: {
    orderDeli: {},
    cartInfo: {
      products: [],
    },
  },
}
// Props Validation
SpecialDeliveryTooltip.propTypes = {
  language: PropTypes.string,
  cartDetails: PropTypes.object,
}

export default SpecialDeliveryTooltip
