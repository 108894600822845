import { ENGLISH } from '~/redux/constants'
import { hasALabelAndFee } from '~/services/utils'
hasALabelAndFee
import {
  FEE_LINE_TYPE,
  PRODUCT_TOTAL,
  PROMO_DISCOUNT,
} from './feeOrderServiceConstants'
import { contractLabels, feeLabels } from './feeOrderServiceLabels'
import { getTarifFeeMessage } from './feeOrderServiceUtilities'

export const getProductTotal = (...params) => {
  const [cartInfo, lang, showContractProducts] = params
  const language = lang || ENGLISH
  const name = feeLabels[PRODUCT_TOTAL][language]
  const value = cartInfo.cartSummary?.priceDetails?.subTotal
  const message = getTarifFeeMessage(cartInfo, language)
  const contractFees = getContractProductFees(
    cartInfo?.cartSummary?.priceDetails,
    language
  )
  const productTotalFee = {
    name,
    value,
    ...(message && { message }),
    type: FEE_LINE_TYPE,
    isTitle: true,
  }

  const promoDiscount = {
    name: feeLabels[PROMO_DISCOUNT][language],
    value: cartInfo.cartSummary?.priceDetails?.promoDiscount,
    type: FEE_LINE_TYPE,
    isTitle: false,
  }
  const filterMethod = showContractProducts ? Boolean : hasALabelAndFee
  return [productTotalFee, promoDiscount, ...contractFees].filter(filterMethod)
}

export const getContractProductFees = (priceDetails, language) => {
  return [
    {
      name: contractLabels[language].contract,
      value: priceDetails?.mobTotal || 0,
      type: FEE_LINE_TYPE,
    },
    {
      name: contractLabels[language].nonContract,
      value: priceDetails?.nonMobTotal || 0,
      type: FEE_LINE_TYPE,
    },
  ].map((e) => ({ ...e, isTitle: false })) // set both to false
}
