import { ENGLISH } from '~/redux/constants'
import {
  TAX_LINE_TYPE,
  TAX_TOTAL_TYPE,
} from '../fees/order/feeOrderServiceConstants'
import { hasALabelAndFee } from '../utils'
import { TAXES, taxesLabels } from './taxesLabels'

export const geGstTaxFees = (cartInfo, language = ENGLISH) => {
  const name = taxesLabels[TAXES][language].gst
  const value = cartInfo.cartSummary?.priceDetails?.federalTaxTotal
  return { name, value, type: TAX_LINE_TYPE }
}
export const gePstTaxFees = (cartInfo, language = ENGLISH) => {
  const name = taxesLabels[TAXES][language].pst
  const value = cartInfo.cartSummary?.priceDetails?.provincialTaxTotal
  return { name, value, type: TAX_LINE_TYPE }
}
export const geHstTaxFees = (cartInfo, language = ENGLISH) => {
  const name = taxesLabels[TAXES][language].hst
  const value = cartInfo.cartSummary?.priceDetails?.hSTTaxTotal
  return { name, value, type: TAX_LINE_TYPE }
}

/**
 * Methods that gets all taxes with their types and prepares it
 * To be consumed by the orderSummary
 * @param {Object} cartInfo from the method called "getCartOrderData" and more
 * @param {String} language the language can be fr|en
 */
export const getAllTaxes = (cartInfo, language = ENGLISH) => {
  const taxes = [
    { ...geGstTaxFees(cartInfo, language) },
    { ...gePstTaxFees(cartInfo, language) },
    { ...geHstTaxFees(cartInfo, language) },
  ].filter(hasALabelAndFee)

  const total = getTaxesTotal(taxes, language)
  return [...total, ...taxes]
}

/**
 * Gets the "Total" label for the order summary of taxes
 * @param {Array} taxes the array of taxes
 * @param {String} language the language can be fr|en
 */
export const getTaxesTotal = (taxes, language) => {
  const total = taxes.reduce((acc, current) => {
    return acc + Math.round((current.value + Number.EPSILON) * 100) / 100
  }, 0)

  const taxesTotal = {
    value: total,
    name: taxesLabels[TAXES][language].total,
    type: TAX_TOTAL_TYPE,
    isTitle: true,
  }

  return taxesTotal.value ? [taxesTotal] : []
}

/**
 * Gets the applicable tax type order summary of taxes
 * @param {enum} HasClientTaxHarmonized
 * @param {number} FederalTaxTotal the value of GST
 * @param {number} ProvincialTaxTotal the value of PST
 * @param {number} HSTTaxTotal the value of HST
 */
export const getTaxAmount = (
  hasClientTaxHarmonized,
  federalTaxTotal,
  provincialTaxTotal,
  hSTTaxTotal
) => {
  // Tax type display computation
  // TODO: Should be moved to back end
  const showHSTTax =
    !!hasClientTaxHarmonized && !!(federalTaxTotal + provincialTaxTotal > 0)
  const showFederalTax = !hasClientTaxHarmonized && federalTaxTotal > 0
  const showProvincialTax = !hasClientTaxHarmonized && provincialTaxTotal > 0
  const taxes = {
    ...(showHSTTax && { hSTTaxTotal }),
    ...(showFederalTax && { federalTaxTotal }),
    ...(showProvincialTax && { provincialTaxTotal }),
  }

  return taxes
}
