import Cookies from 'js-cookie'
import omit from 'lodash/omit'
import { store } from '@redux/store'
import { isTest } from '~/config/env/environmentUtils'
import { setSessionValue } from '~/redux/actions'
import { COOKIES } from './constants'

/**
 * set cookie for given key and value
 * @param {*} key
 * @param {*} value
 */
export const setCookie = (key, value, optionsFromProps = {}) => {
  if (process.browser) {
    // Workaround for options SCE-5890
    const options = OMIT_SOME_OPTIONS_FOR_TESTS(optionsFromProps)
    Cookies.set(key, value, options)
  } else {
    // use redux store as a backend state manager
    return store?.dispatch(setSessionValue(key, value))
  }
}

/**
 * Return the cookie for given key
 * @param {*} key
 */
export const getCookie = (key) => {
  if (process.browser) {
    return Cookies.get(key) || null
  }
  const state = store?.getState()
  return state?.state?.session[key] || null
}

/**
 * Return all cookies
 * @returns {Object} - Returns an object containing all cookies
 */
export const getAllCookies = () => {
  if (process.browser) {
    return Cookies.get()
  }

  const state = store?.getState()

  return state?.state?.session || {}
}

/**
 * Remove cookie for given key and path
 * @param {*} key
 * @param {*} path
 */
export const removeCookie = (key, path = '/') => {
  if (!process.browser) {
    return //Need to check for server
  }
  Cookies.remove(key, { path })
}

function OMIT_SOME_OPTIONS_FOR_TESTS(options) {
  return isTest() ? omit(options, ['sameSite', 'secure']) : options
}

/**
 * Transforms a truthy value to a string
 * If I pass true, it will return "1"
 * @param {*} value
 * @return {string}
 */
export function booleanToString(value) {
  return Number(value).toString()
}

export function setApiAuthCookie(apiToken) {
  apiToken && setCookie(COOKIES.APIAUTH, apiToken, { sameSite: 'none' })
}
