import { checkArgsEmpty, sendRequest } from 'api/apiHelper'
import { getApiUrl } from 'services/utils/configUtils'
import { getOldEwayFullLocale } from '~/services/utils/languageUtils'
const API_URL = getApiUrl()

/**
 * Name: FetchCardOrderData
 * Desc: get product order for order tile
 * @param {*} language
 * @param {*} buyerId
 */
const buildFetchCardOrderData = async (args) => {
  const [language, buyerId] = args
  const url = `${API_URL}/buyers/${buyerId}/orders?daysBack=30&count=3&filters=EwayOrderNo,SubmittedDate,orderId`
  const result = apiRequest(language, url)
  return result
}

/**
 * Name:fetchCardOrderSearchData
 * Desc: get product order search result for order tile
 * @param {*} language
 * @param {*} buyerId
 */
const fetchCardOrderSearchData = async (args) => {
  const [language, buyerId, searchBy] = args
  const url = `${API_URL}/buyers/${buyerId}/searchOrders?searchText=${searchBy}&filters=EwayOrderNo,SubmittedDate,orderId`
  const result = apiRequest(language, url)
  return result
}

/**
 * Name: fetchCardOrderApprovalData
 * Desc: get approvals order list fot approval tile
 * @param {*} language
 * @param {*} buyerId
 */
const buildFetchCardOrderApprovalData = async (args) => {
  const [language, buyerId] = args
  const url = `${API_URL}/approvers/${buyerId}/orders?count=3&filters=EwayOrderNo,SubmittedDate,orderId`
  const result = apiRequest(language, url)
  return result
}

/**
 * Name: fetchCardOrderApprovalData
 * Desc: Get the order by EwayOrderNumber
 * @param {string} ewayOrderNumber
 */
const buildFetchGetOrderById = async (args) => {
  const [language, ewayOrderNumber] = args
  const locale = getOldEwayFullLocale(language)
  const strippedEwayOrderNumber = ewayOrderNumber.replace('EW', '')
  const url = `${API_URL}/orderbynumber/${strippedEwayOrderNumber}?includes=orderProducts{includes=orderProductDetails;filters=descriptionEnrichedMedium;cultureCode=${locale}}`
  const result = apiRequest('', url)
  return result
}
/**
 * Name: buildFetchGetOrderByGuid - REFACTOR: Use api/order to update the order
 * Desc: Fetches the order by a passed "guid"
 * @param {string} ewayOrderNumber
 */
const buildFetchGetOrderByGuid = async (args) => {
  const [orderId] = args
  const url = `${API_URL}/orders/${orderId}`
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  }
  return sendRequest(url, options)
}

export const getCardOrderData = checkArgsEmpty(buildFetchCardOrderData)
export const getOrderById = checkArgsEmpty(buildFetchGetOrderById)
export const getOrderByGuid = checkArgsEmpty(buildFetchGetOrderByGuid)
export const getCardOrderApprovalData = checkArgsEmpty(
  buildFetchCardOrderApprovalData
)
export const getSearchOrdersData = checkArgsEmpty(fetchCardOrderSearchData)

/**
 * Name: apiRequest
 * Desc: common API header request for order and approval tiles
 * @param {*} language
 * @param {*} url
 */
const apiRequest = async (language = '', url) => {
  const options = {
    ...(language && {
      headers: {
        'Accept-Language': `${language}-CA`,
      },
    }),
  }
  const response = await sendRequest(url, options)
  return response
}
