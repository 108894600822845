export const PRODUCT_COMPARISON = 'Product Comparison'

export default {
  events: 'event93',
  pageName: PRODUCT_COMPARISON,
  channel: PRODUCT_COMPARISON,
  prop3: PRODUCT_COMPARISON,
  prop4: PRODUCT_COMPARISON,
  prop5: PRODUCT_COMPARISON,
  prop6: PRODUCT_COMPARISON,
  prop12: PRODUCT_COMPARISON,
  prop26: PRODUCT_COMPARISON,
}

export const COMPARE_PRODUCTS_ADD_TO_CART = {
  prop3: '',
  prop4: '',
  prop5: '',
  prop6: '',
  prop12: '',
  prop26: '',
  eVar12: PRODUCT_COMPARISON,
}
