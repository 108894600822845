import { REMOVE_ITEM_MESSAGE } from '~/services/constants'
import isEmpty from 'lodash/isEmpty'
import { getCookie, removeCookie } from '~/services/cookieManager'
import { showNotification } from '..'
import {
  ALERT_TYPES,
  INSTALLATION_UNAVAILABLE,
  PRE_ASSEMBLY_NOT_AVAILABLE,
} from '../AlertNotificationUtils/AlertNotificationConstants'
import messages from './messages'
const AUTO_DISMISS = 4000

/**
 * Shows a message once, based on a cookie value then removes it
 * Message is shown on load once.
 * @param {*} language
 */
export function showShipToChangeAdditionalMessage(language) {
  if (getCookie(PRE_ASSEMBLY_NOT_AVAILABLE)) {
    showErrorMessageOnce(PRE_ASSEMBLY_NOT_AVAILABLE, language)
  }

  if (getCookie(INSTALLATION_UNAVAILABLE)) {
    showErrorMessageOnce(INSTALLATION_UNAVAILABLE, language)
  }
}

function showErrorMessageOnce(key, language) {
  const message = messages[language][key]
  showNotification.danger(message, { id: key })
  removeCookie(key)
}

/**
 * To be removed when "SCE-2398" is done
 * Reads cookies to know if item are deleted, and then deletes it so that on next reload
 * You don't see the message
 * @param {*} id
 */
export function getDeletedItemMessagesCookie() {
  const messages = JSON.parse(getCookie(REMOVE_ITEM_MESSAGE))
  if (messages?.length) {
    removeCookie(REMOVE_ITEM_MESSAGE, '/')
    return messages
  }
  return []
}

/**
 * Utility that shows deleted items after page reload
 * Should eventually be once shipTo change has no page reload
 */
export function showDeletedItemsAlerts() {
  const messages = getDeletedItemMessagesCookie()
  messages.forEach((message) => {
    showNotification.danger(message)
  })
}

export function addAlertMessagesType(alertMessage, index) {
  return {
    ...alertMessage,
    ...ALERT_TYPES[alertMessage.MessageStatus],
    index,
  }
}

/**
 * Updates the state of the AlertNotification Container,
 * replaces a notification that already exists
 * @param {function} setAlerts
 */
export function handleReplaceNotification(setAlerts) {
  return (message, options) => {
    setAlerts((alerts) => {
      const newAlerts = doReplaceOperation(alerts, options, message)
      return newAlerts.filter(Boolean)
    })
  }
}

/**
 * Updates the state of the AlertNotification Container,
 * adds a notification to the list of notifications
 * @param {function} setAlerts
 */
export function handleShowNotification(containerId, alertsLength, setAlerts) {
  return (message, options) => {
    const newAlert = containerId === options.containerId && {
      id: options.id ? `${options.id}` : alertsLength + 1,
      PrimaryMessage: message,
      HasButton: options.hasButton,
      SecondaryMessages: options.secondaryMessages,
      ButtonText: options.buttonText,
      LinkText: options.buttonText,
      type: options.type,
      showCloseButton: options.showCloseButton,
      maxWidth: options.maxWidth,
      iconPosition: options.iconPosition,
      prefix: icons[options.type],
      autoDismiss: options.autoDismiss || AUTO_DISMISS,
      toast: options.toast || false,
    }
    setAlerts((alerts) => {
      const alreadyExists = !isEmpty(alerts.find((a) => a.id === newAlert.id))
      // Make sure we properly replace the alert if the id already exists
      const newAlerts = alreadyExists
        ? doReplaceOperation(alerts, options, message)
        : [newAlert, ...alerts].filter(Boolean)
      return newAlerts
    })
  }
}

/**
 * Updates the state of the AlertNotification Container,
 * hide a notification to the list of notifications
 * @param {function} setAlerts
 */
export function handleHideNotification(_containerId, _alerts, setAlerts) {
  return (options) => {
    setAlerts((alerts) => {
      const newAlerts = alerts.filter((alert) => options.id !== alert.id)
      return newAlerts.filter(Boolean)
    })
  }
}

const icons = {
  warning: 'warning',
  danger: 'infoFilled',
  primary: 'infoFilled',
  success: 'check',
}

function doReplaceOperation(alerts, options, message) {
  return [
    ...alerts.map((alert) =>
      options.id === alert.id
        ? {
            ...alert,
            PrimaryMessage: message,
            type: options.type,
          }
        : alert
    ),
  ]
}
