export default {
  fr: {
    label: 'Écofrais',
    dynamicText: '* {link} applicables inclus :',
  },
  en: {
    label: 'Eco Fees',
    dynamicText: '* Includes Applicable {link}',
  },
}
