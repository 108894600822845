import { ENGLISH, SET_SESSION_VALUE, UPDATE_CART } from './constants'
const TOGGLE_LANGUAGE = 'TOGGLE_LANGUAGE'
const SWITCH_PWGS = 'SWITCH_PWGS'

// These are the main REST ressources
// To run the application a good understanding of:
// What is a buyer, a setting (AKA CCS), client and order are is required.
export const initialState = {
  session: {},
  buyer: {},
  settings: {},
  client: {},
  order: {},
  pwgsValue: '',
  language: ENGLISH,
  cartUpdateTimeStamp: 0,
}

const stateReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_LANGUAGE:
      return {
        ...state,
        session: {
          ...state.session,
          language: action.language,
        },
        language: action.language,
      }
    case SET_SESSION_VALUE:
      return {
        ...state,
        session: {
          ...state.session,
          [action.payload.key]: action.payload.value,
        },
      }
    case SWITCH_PWGS:
      return {
        ...state,
        pwgsValue: state.pwgsValue === '1' ? '3' : '1',
      }
    case UPDATE_CART:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      }
    default:
      return state
  }
}

export default stateReducer
