import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './Tooltip.module.scss'
import ReactTooltip from 'react-tooltip'
import Loader from '~/components/shared/Loader'

/**
 * Name: Tooltip
 * Desc: Wrapper around ReactTooltip
 * For documentation go see in https://bddeu.csb.app/
 * @param {object} children
 * @param {bool} isSmallView
 */

function Tooltip({
  children,
  className,
  stayOpenOnHover,
  isSmallView,
  isLoading = false,
  ...rest
}) {
  const tooltipHoverClass = stayOpenOnHover ? 'tooltipHoverClass' : ''
  const classes = classNames(styles.tooltip, className, tooltipHoverClass, {
    [styles.tooltipIe]: isSmallView,
  })
  return (
    <ReactTooltip className={classes} {...rest}>
      {isLoading ? <Loader /> : children}
    </ReactTooltip>
  )
}
// Default Props
Tooltip.defaultProps = {
  effect: 'solid',
  type: 'custom',
}

Tooltip.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isSmallView: PropTypes.bool,
  isLoading: PropTypes.bool,
  stayOpenOnHover: PropTypes.bool,
  rest: PropTypes.object,
}
export default Tooltip
