import { COOKIES } from '~/services/constants'

export const RETURN_TO_HOME_PAGE = 0
export const REDIRECT_TO_URL = 1
export const CLOSE_BROWSER = 2
export const SEND_EMPTY_CART = 3

export const LOGOUT_COOKIES = [
  COOKIES.APIAUTH,
  COOKIES.BUYER_ID,
  COOKIES.SESSION_ID,
  COOKIES.OLD_EWAY_SESSION,
  COOKIES.USER_INFO,
  COOKIES.TEMPORARY_BUYER_ID,
  COOKIES.ENABLE_DIFFERENT_ADDRESS,
  COOKIES.SET_DIFFERENT_ADDRESS_EMPTY,
]
