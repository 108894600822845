import {
  dynamicTranslation,
  hasReplaceKey,
  hasReplaceMap,
  splitElement,
} from 'components/shared/DynamicTranslation/DynamicTranslationUtils'
import PropTypes from 'prop-types'
import { Fragment } from 'react'

/**
 * Name: Dynamic Translation
 * Desc: Description
 * @param {string} text The actual text to be translated
 * @param {string} replace the key to replace
 * @param {object} with and what to replace it with
 */
export default function DynamicTranslation({
  text,
  replace: key,
  with: component,
  replaceMap,
}) {
  if (!text) {
    return text
  }
  const hasReplaceMapsKeys = hasReplaceMap(replaceMap)
  if (!hasReplaceMapsKeys && !hasReplaceKey(text, key)) {
    return text
  }
  const generatedComponent = hasReplaceMapsKeys
    ? dynamicTranslation(text, replaceMap)
    : splitElement(key, text, component)

  return generatedComponent?.map?.(toReactComponent)
}

// Default Props
DynamicTranslation.defaultProps = {
  replaceMap: {},
}

DynamicTranslation.propTypes = {
  component: PropTypes.node,
  key: PropTypes.string,
  text: PropTypes.string,
}

const toReactComponent = (element, index) => {
  return <Fragment key={index}>{element}</Fragment>
}
