import { isValidElement } from 'react'

/**
 * Function that does a dynamic translation of a string map
 * @param {string} text the text to be replaced - which contain the "{element}"" to be replaced
 * @param {object} replaceMap the replace map { element: 'value' } that should replace it
 * {
 *   '{link}': 'http://google.ca',
 *   '{there}': 'some-word',
 * }
 */
export function dynamicTranslation(text, replaceMap) {
  if (!text) {
    throw new Error(
      `Please provide a text for dynamic translation, ${text} was given`
    )
  }

  const entries = Object.entries(replaceMap).filter(filterAbsentValues(text))
  const component = entries.reduce((acc, entry) => {
    const [key, value] = entry
    return Array.isArray(acc)
      ? acc
          .map((item) =>
            typeof item === 'string' ? splitSection(key, item, value) : item
          )
          .reduce(
            (accItem, itemArr) =>
              Array.isArray(itemArr)
                ? [...accItem, ...itemArr]
                : [...accItem, itemArr],
            []
          )
      : splitSection(key, acc, value)
  }, text)

  return hasReactElements(component) ? component : component.join('')
}

export function dynamicTranslationChildrenChunks(replacedMapArray, replacers) {
  const itemChunks = [{ items: [] }]
  const { itemStartPlaceHolder, itemEndPlaceHolder, type } = replacers
  let index = 0
  replacedMapArray.forEach((item) => {
    if (item === itemStartPlaceHolder) {
      index++
      itemChunks[index] = { items: [], type }
      return
    }

    if (item === itemEndPlaceHolder) {
      index++
      itemChunks[index] = { items: [] }
      return
    }
    const { items = [] } = itemChunks[index]
    itemChunks[index].items = [...items, item]
  })

  return itemChunks
}

function filterAbsentValues(text) {
  return (e) => {
    const [key] = e
    return text.includes(key)
  }
}

function hasReactElements(component = []) {
  return Array.isArray(component)
    ? component?.some((part) => isValidElement(part))
    : [component]
}

function splitSection(key, text, component) {
  if (Array.isArray(text) && text.length) {
    const [newText, index] = findAndSplitNextElement(text, key)
    const mergedParts = insertComponentAtIndex(newText, index, component)
    return mergedParts
  }
  return splitElement(key, text, component)
}

function findAndSplitNextElement(text, key) {
  const index = text.findIndex((x) => x.includes?.(key))
  const firstPart = text.slice(0, index)
  const nextPart = text[index]?.split(key).filter(Boolean) || []
  const allParts = [...firstPart, ...nextPart]
  return [allParts, index + 1]
}

export function splitElement(key, text, insertedElement = '') {
  const elements = text?.split(key)
  const splittedLength = elements.length

  // Create an array that has space between each elements
  const lengthToGenerate = splittedLength + splittedLength - 1
  // generate an array that add the inserted text between each elements
  const finalArray = Array.from({ length: lengthToGenerate }).map((_e, i) => {
    const isEven = i % 2 === 0
    // If its even, we add the element
    if (isEven) {
      const indexToTake = Math.floor(i / 2)
      return elements[indexToTake]
    }
    // If its odd we add the insertedText
    return insertedElement
  })
  return finalArray
}

function insertComponentAtIndex(text, index, insertedElement) {
  if (!insertedElement) {
    return text
  }
  return [
    ...text.slice(0, index),
    insertedElement,
    ...text.slice(index, text.length),
  ]
}

export function hasReplaceKey(text, key) {
  return text?.includes?.(key)
}

export function hasReplaceMap(replaceMap) {
  return Object.keys(replaceMap).length
}
