import PropTypes from 'prop-types'
import styles from './Loader.module.scss'
import classNames from 'classnames'
import Box from '../Box'
/**
 * Loader component
 * IE11 compatible
 */
const Loader = ({ className, ...props }) => {
  const loaderClassNames = classNames(styles.outer, className)
  return (
    <Box className={loaderClassNames} {...props}>
      <div className={styles.inner}></div>
    </Box>
  )
}

Loader.propTypes = {
  className: PropTypes.string,
}

export default Loader
