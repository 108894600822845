import cx from 'classnames'
import { VALUES_CONSIDERED_SELF_CLOSING, BOX_PROP_TYPES } from './BoxConstants'
import { forwardRef } from 'react'
import { getDynamicStyles, omitBoxProps } from './BoxUtils'
/**
 * Name: Box
 * Desc: Atomic component that creates an abstract box
 */

const Box = forwardRef((props, ref) => {
  const propsWithoutStyles = omitBoxProps(props)
  const { className, as, children, ...rest } = propsWithoutStyles
  const { className: dynamicClassName, styles: dynamicStyles } =
    getDynamicStyles(props)
  const classes = cx(className, dynamicClassName)
  const Component = as
  const mergedProps = {
    ...(classes && { className: classes }),
    ...rest,
    ref,
  }

  const isSelfClosingElement = VALUES_CONSIDERED_SELF_CLOSING.some((v) =>
    as.includes(v)
  )
  if (isSelfClosingElement) {
    return (
      <>
        <Component {...mergedProps} />
        {dynamicStyles}
      </>
    )
  }

  return (
    <Component {...mergedProps}>
      {children}
      {dynamicStyles}
    </Component>
  )
})

Box.displayName = 'Box'

// Default Props
Box.defaultProps = {
  as: 'div',
}

Box.propTypes = BOX_PROP_TYPES

export default Box
