import {
  deliveryAndInstallFeeIsUnknown,
  deliveryFeeIsUnknown,
  userChooseDeliveryAndInstall,
  userChooseStandardDelivery,
} from '~/services/fees/order/feeOrderServiceUtilities'
import messages from './messages'
import TYPES from './SpecialDeliveryTooltipConstants'

export function getTooltipText(language, cartDetails) {
  const { orderDeli, products = [] } = cartDetails
  const type = getDeliveryType(orderDeli, products)
  const tooltipText = messages[language][type || TYPES.STANDARD_DELIVERY_EXTRA]
  return tooltipText
}

/**
 * Returns the delivery type "deliveryInstall, deliveryInstallExtra, standard or standardExtra"
 * This might become a service in itself, if need be to be extrated
 * @param {Object}
 * @param {Array} products - A list of products
 */
function getDeliveryType(deliveryDetails, products) {
  const needsQuote = someProductsNeedsAQuote(products)
  // Delivery and Install
  const deliveryAndInstall = userChooseDeliveryAndInstall(deliveryDetails)
  const feeNotKnownInstall = deliveryAndInstallFeeIsUnknown(deliveryDetails)
  const feeNotKnownOrNeedsQuoteInstall = feeNotKnownInstall || needsQuote
  const feeKnownOrNoQuoteInstall = !feeNotKnownOrNeedsQuoteInstall

  // Standard Delivery
  const standard = userChooseStandardDelivery(deliveryDetails)
  const feeNotKnownStandard = deliveryFeeIsUnknown(deliveryDetails)
  const feeKnownStandard = !feeNotKnownStandard

  if (deliveryAndInstall) {
    return feeKnownOrNoQuoteInstall
      ? TYPES.DELIVERY_INSTALL
      : TYPES.DELIVERY_INSTALL_EXTRA
  }

  if (standard) {
    return feeKnownStandard
      ? TYPES.STANDARD_DELIVERY
      : TYPES.STANDARD_DELIVERY_EXTRA
  }
}

function someProductsNeedsAQuote(products) {
  return products?.some((x) => x.needQuote)
}
