import { isEmptyArray } from 'services/utils'
import {
  ACTION_TYPE,
  CLEAR_CART_ANALYTICS_VALUES,
  ITEM_DELETED_ANALYTICS_VALUES,
  QTY_INCREASED_ANALYTICS_VALUES,
  QTY_REDUCED_ANALYTICS_VALUES,
} from 'shared/AdobeAnalytics/AdobeAnalyticsConstants'
import { generateAdobeAnalyticsEvents } from './AdobeAnalyticsUtils'

export const updateDeleteAdobeAnalyticData = (
  shoppingCartData = [],
  orderProductId,
  actionType,
  updatedQty
) => {
  let adobeAnalyticData = {}
  const productData =
    actionType === ACTION_TYPE.CLEAR_CART
      ? shoppingCartData
      : shoppingCartData.filter(function (product) {
          return product.OrderProductId === orderProductId
        })
  if (productData && !isEmptyArray(productData)) {
    if (actionType === ACTION_TYPE.UPDATE) {
      if (productData[0].Quantity < Number(updatedQty)) {
        adobeAnalyticData = QTY_INCREASED_ANALYTICS_VALUES
        adobeAnalyticData.products = generateProductForCartAdobeAnalytics(
          productData,
          ACTION_TYPE.ADD,
          updatedQty,
          productData[0].Quantity
        )
      } else if (productData[0].Quantity > Number(updatedQty)) {
        adobeAnalyticData = QTY_REDUCED_ANALYTICS_VALUES
        adobeAnalyticData.products = generateProductForCartAdobeAnalytics(
          productData,
          ACTION_TYPE.REDUCE,
          updatedQty,
          productData[0].Quantity
        )
      }
    } else if (actionType === ACTION_TYPE.DELETE) {
      adobeAnalyticData = ITEM_DELETED_ANALYTICS_VALUES
      adobeAnalyticData.products = generateProductForCartAdobeAnalytics(
        productData,
        ACTION_TYPE.DELETE
      )
    } else if (actionType === ACTION_TYPE.CLEAR_CART) {
      adobeAnalyticData = CLEAR_CART_ANALYTICS_VALUES
      adobeAnalyticData.products = generateProductForCartAdobeAnalytics(
        productData,
        ACTION_TYPE.CLEAR_CART
      )
    }
  }
  return adobeAnalyticData
}

const generateProductForCartAdobeAnalytics = (
  productData,
  actionType,
  updatedQty,
  lastSetQuantity = null
) => {
  let mergeProducts = ''
  if (productData && !isEmptyArray(productData)) {
    productData.map((data, index) => {
      const adobeAnalyticsProducts = {
        category: '',
        product: '',
        quantity: '',
        price: '',
        eventIncrementer: '',
      }
      adobeAnalyticsProducts.product = data.ProductCode
      adobeAnalyticsProducts.eventIncrementer = generateAdobeAnalyticsEvents(
        data.UnitSellPrice,
        updatedQty || data.Quantity,
        actionType,
        lastSetQuantity
      )
      mergeProducts += Object.keys(adobeAnalyticsProducts)
        .map(function (k) {
          return adobeAnalyticsProducts[k]
        })
        .join(';')
      if (productData.length !== index + 1) {
        mergeProducts += ','
      }
    })
    return mergeProducts
  }
}
