import { WELCOME_TO_EWAY_MODAL_SHOWN } from 'components/shared/WelcomeToEwayModal/WelcomeToEwayModalConstants'
import { getHostUrl } from 'services/utils/configUtils'
import { PAGE_SELECTION_SHOWN } from '~/components/shared/AccountSelection/AccountSelectionConstants'
import { SAVED_POSTAL_CODE } from '~/components/shared/Layout/Header/HeaderTop/HeaderTopConstants'
import { removeCookie } from '~/services/cookieManager'
import {
  CLOSE_BROWSER,
  LOGOUT_COOKIES,
  REDIRECT_TO_URL,
  RETURN_TO_HOME_PAGE,
  SEND_EMPTY_CART,
} from './LogoutConstants'

const HOST_URL = getHostUrl()

/**
 * Function that logout the user
 */
export function doLogout() {
  // Cookies about the buyer
  LOGOUT_COOKIES.forEach((name) => {
    removeCookie(name, '/')
  })

  clearSessionDependentData()
  clearAllSession()
}

export function clearSessionDependentData() {
  // Cookies about the modals
  sessionDependentCookies.forEach(({ name }) => removeCookie(name, '/'))
}

export function clearAllSession() {
  window.sessionStorage.clear()
}

export const sessionDependentCookies = [
  { name: WELCOME_TO_EWAY_MODAL_SHOWN, defaultValue: 'false' },
  { name: SAVED_POSTAL_CODE, defaultValue: '' },
  { name: PAGE_SELECTION_SHOWN, defaultValue: 'false' },
]

export function closeAction() {
  window.parent.open('', '_parent', '')
  window.parent.opener = self
  window.parent.close()
}

/**
 * Name: getLogoutUrl
 * Desc: render logout url
 * @param {string} language
 * @param {number} logoutAction
 * @param {string} logoffUrl : Whether to show logout link or not
 * Different value definition
 * 0 - home page
 * 1 - custom
 * 2-  close browser
 * 3-  send empty cart
 */

export const getLogoutUrl = (logoffAction, logoffUrl, language) => {
  switch (logoffAction) {
    //0 ⬇️
    case RETURN_TO_HOME_PAGE:
      return `${HOST_URL}/${language}`
    //1 ⬇️
    case REDIRECT_TO_URL:
      return logoffUrl
    //2 ⬇️
    case CLOSE_BROWSER:
      return `${HOST_URL}/Logoff.aspx`
    //3 ⬇️
    case SEND_EMPTY_CART:
      return punchoutCartUrl()
    default:
      return '/'
  }
}

export function punchoutCartUrl(
  orderId = '00000000-0000-0000-0000-000000000000'
) {
  return `${HOST_URL}/PunchOut/PunchOutCartResponse.aspx?OrderId=${orderId}`
}
