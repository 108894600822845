/**
 * How to know if your running server side or client side in nextjs
 * process.browser is now deprecated, favor usage of window !== undefined
 * https://stackoverflow.com/a/59562136/1366834
 * @returns {Boolean}
 */
export function isBrowser() {
  return typeof window !== 'undefined'
}

export function isServer() {
  return typeof !isBrowser()
}
