import { isDev, isProduction } from '~/config/env/environmentUtils'
/**
 * Created for debugging purposes
 * @param {Error} e - expects an error
 */
export const logErrorToConsole = (e) => {
  // eslint-disable-next-line no-console
  console.error(e)
  return new Error(e)
  // Ideally would also be sent to the log provider
}

/**
 * Log message in dev
 * @param {String} e - Error message to display
 */
export const logErrorToConsoleOnlyInDev = (e) => {
  if (isDev()) {
    // eslint-disable-next-line no-console
    console.error(e)
    // Ideally would also be sent to the log provider
  }
}

export const logErrorInTestAndDev = (e) => {
  if (!isProduction()) {
    // eslint-disable-next-line no-console
    console.error(e)
    // Ideally would also be sent to the log provider
  }
}
