import classNames from 'classnames'
import PropTypes from 'prop-types'
import buttonStyles from 'shared/Button/Button.module.scss'
import Icon from 'shared/Icon'
import styles from './EwayLink.module.scss'

/**
 * Name: EwayLink
 * Desc: Render anchor tag
 * @param {string} text
 * @param {string} href
 * @param {string} icon
 * @param {string} variant
 * @param {string} size
 * @param {string} color
 * @param {bool} readOnly
 * @param {bool} textDecoration
 * @param {string} fontWeight
 * @param {func} onClick
 * @param {string} target
 * @param {string} dataTest,
 * @param {string} fontFamily
 */
const EwayLink = (props) => {
  const {
    onClick,
    text,
    children,
    icon,
    size,
    color,
    readOnly,
    fontFamily,
    textDecoration,
    fontWeight,
    target,
    styling,
    className,
    whiteSpace = '',
    variant,
    dataTest,
    cursor,
    onMouseEnter,
    center = false,
    ...rest
  } = props

  const events = {
    ...(onClick && { onClick }),
    ...(onMouseEnter && { onMouseEnter }),
  }

  const linkClasses = classNames(className, {
    [styles[size]]: size,
    [styles.cursorPointer]: cursor === 'pointer',
    [styles.noWrap]: whiteSpace.toLowerCase() === 'nowrap',
    [styles.pointerEvent]: readOnly,
    [styles.fontFamilyMedium]: fontFamily === 'md',
    [styles[color]]: color,
    [styles.center]: center,
    [styles.textDecoration]: textDecoration,
    [styles.strong]: fontWeight,
    [`${buttonStyles.btn} ${buttonStyles[variant]}`]: isButtonStyling(styling),
  })

  return (
    <a
      target={target}
      data-test={dataTest}
      className={linkClasses}
      {...events}
      {...rest}
    >
      {text || children}
      {icon && <Icon variant={icon} />}
    </a>
  )
}

//Default Props
EwayLink.defaultProps = {
  size: 'md',
  variant: 'primary',
  readOnly: false,
}

// Props Validations
EwayLink.propTypes = {
  // Used to set the
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  icon: PropTypes.string,
  cursor: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func,
  variant: PropTypes.string,
  /** Allows you to show the link as a button \
   * **By default the value is "null"** \
   *
   * Only value can be
   * - button
   */
  styling: PropTypes.string,
  target: PropTypes.string,
  // Makes the link underlined, needs to be renamed to "underline"
  textDecoration: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  // Ads the ability to add data-test attributes for E2E testing
  dataTest: PropTypes.string,
  /** Size controls the text size of different types of links \
   * **By default the value is "mdText"** \
   *
   * Other values can be
   * - xs
   * - sm
   * - md
   * - lg
   * - xlg
   */
  size: PropTypes.string,
  color: PropTypes.string,
  readOnly: PropTypes.bool,
  whiteSpace: PropTypes.string,
  fontFamily: PropTypes.string,
  // Sets the font-weight to bold
  fontWeight: PropTypes.string,
  className: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  children: PropTypes.node,
  onMouseEnter: PropTypes.func,
  center: PropTypes.bool,
}

export default EwayLink

function isButtonStyling(styling) {
  const BUTTON = 'button'
  return styling === BUTTON
}
