const PAGE_NAME = 'Shopping List Summary'

export default {
  pageName: PAGE_NAME,
  eVar36: 'Other Finding Method',
  prop3: 'Shopping',
  prop4: 'Top Navigation Menu',
  prop5: 'Top Navigation Menu',
  prop6: 'Top Navigation Menu',
}

export const SHOPPING_LISTS_ADD_TO_CART_VALUES = {
  eVar3: 'Shopping List',
  events: 'scAdd, scOpen, event35, event36',
  eVar12: PAGE_NAME,
  pev2: 'Cart Addition',
  prop3: '',
  prop4: '',
  prop5: '',
  prop6: '',
}
