import SpecialDeliveryTooltip from '~/components/shared/SpecialDeliveryTooltip'
import { ENGLISH } from '~/redux/constants'
import { feeLabels, includedTypeLabels } from './feeOrderServiceLabels'
import { ORDER_FEE_TYPES } from './feeOrderServiceTypes'
import {
  deliveryFeeIsUnknown,
  isExemptFromDeliveryFee,
  userChooseStandardDelivery,
} from './feeOrderServiceUtilities'

/**
 * In API they're called *"Transact"* fees \
 * And in the view it's called *"Furniture Delivery Fees"* \
 * -- Watch out they're different from *"Furniture And Installation Fees"* -- \
 * Transact fees are the one applied to "chairs, tables what not" \
 * In old eway, check out products with the "Blue Trucks"
 */
export const getFurnitureDeliveryFees = (
  cartDetails = {},
  language = ENGLISH
) => {
  const { orderDeli = {} } = cartDetails
  const { shippingPostalCode: postalCode } = orderDeli

  const standardDelivery =
    userSelectedDeliveryOption(orderDeli) &&
    userChooseStandardDelivery(orderDeli)

  const name = getFurnitureLabel(postalCode, language)
  if (!standardDelivery || !cartDetails.cartSummary?.hasSpecialDeliveryItem) {
    return { name, value: 0 }
  }
  const value = getFurnitureFee(cartDetails, language)
  const isExempt = !isExemptFromDeliveryFee(cartDetails)
  const hasSpecialDelivery = hasSpecialDeliveryItems(cartDetails)
  const isShownTooltip = isExempt && hasSpecialDelivery
  return {
    name,
    value,
    message: isShownTooltip ? (
      <SpecialDeliveryTooltip language={language} cartDetails={cartDetails} />
    ) : (
      ''
    ),
  }
}

const getFurnitureLabel = (postalCode, language) => {
  const { long, short } = getFeeLabelsByLanguage(language)
  // If the postal is not defined return short version
  const value = postalCode ? long : short
  return value.replace('{postalCode}', postalCode)
}

export function getFurnitureFee(cartInfo, language) {
  const feeTotal = cartInfo.cartSummary?.priceDetails.transactionalFee
  const fee =
    getIsIncludedFeeLabel(feeTotal, cartInfo, language) ||
    getIsExtraFeeLabel(cartInfo, language, feeTotal) ||
    feeTotal
  return fee || 0
}

const getIsExtraFeeLabel = ({ orderDeli = {} }, language) => {
  const feeLabels = includedTypeLabels[language]
  // Has extra fees because we DON'T know the delivery time
  if (
    userSelectedDeliveryOption(orderDeli) &&
    userChooseStandardDelivery(orderDeli) &&
    deliveryFeeIsUnknown(orderDeli)
  ) {
    return feeLabels.extra
  }
  return undefined
}

const getIsIncludedFeeLabel = (fee, cartInfo, language) => {
  const { included } = includedTypeLabels[language]

  const hasNoFee = fee === 0
  const hasDeliveryTime = !deliveryFeeIsUnknown(cartInfo.orderDeli)

  // Has "Included" we DO know the delivery time
  return hasNoFee && hasDeliveryTime ? included : undefined
}

const userSelectedDeliveryOption = (deliveryDetails) => {
  return deliveryDetails?.isDeliOptSet === true
}

function getFeeLabelsByLanguage(language) {
  return feeLabels[ORDER_FEE_TYPES.TRANSAC][language]
}

function hasSpecialDeliveryItems(cartDetails) {
  return cartDetails.cartSummary.hasSpecialDeliveryItem
}
