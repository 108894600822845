import DynamicTranslation from 'components/shared/DynamicTranslation/DynamicTranslation'
import FeeLink from 'components/shared/OrderSummary/OrderSummaryDetail/OrderSummaryLine/FeeLink/index.js'
import PropTypes from 'prop-types'
import { Fragment } from 'react'
import FeeMessage from 'shared/OrderSummary/OrderSummaryDetail/OrderSummaryLine/FeeMessage'
import { ENGLISH } from '~/redux/constants'
import messages from './messages'

export default function TariffFeeMessage({ language, short }) {
  const { label, dynamicText } = messages[language]

  const linkComponent = (
    <FeeLink
      href="/en/Shopping/DeliveryHandling/GetDeliveryHandlingFaq"
      language={language}
    >
      {label}
    </FeeLink>
  )

  const component = short ? (
    linkComponent
  ) : (
    <DynamicTranslation
      text={dynamicText}
      replace="{link}"
      with={linkComponent}
    />
  )

  return (
    <FeeMessage
      language={language}
      text={component}
      as={short ? Fragment : undefined}
    />
  )
}

// PropTypes
TariffFeeMessage.propTypes = {
  language: PropTypes.string,
  short: PropTypes.bool,
}

// Default Props
TariffFeeMessage.defaultProps = {
  language: ENGLISH,
}
