import { ENGLISH } from '@redux/constants'
import { isLoggedIn } from 'shared/Utils/userUtils'
import {
  generateAdobeAnalyticsProductsFromOrder,
  getConfirmationDynamicValue,
  getConfirmShippingBillingDynamicValue,
} from '~/components/shared/AdobeAnalytics/AdobeAnalyticsUtils'
import { camelAndCapitalize } from '~/services/utils'
import { getOldEwayFullLocale } from '~/services/utils/languageUtils'
import * as CONSTANTS from '../AdobeAnalyticsConstants'
import { globalAnalyticsSettings } from '../values/adobeAnalyticsGlobalSettings'
import {
  getClientType,
  getCustomEventsFromPageType,
  getQueryAndPageNumber,
  getUserGroup,
} from './AdobeAnalyticsValuesGeneratorUtils'
import {
  BLOOMREACH,
  ENDECA,
  KEYWORD_PATH,
} from './Search/AdobeAnalyticsSearchConstants'
import { handleBrowseProductAnalytics } from './Search/AdobeAnalyticsSearchUtils'

export const generateAdobeAnalyticsValues = async (props) => {
  const dynamicValues = getDynamicValues(props, DEFAULT_VALUES)
  const pageSpecificValues = await getPageSpecificValues(props)
  const mergedEvents = mergeEvents(pageSpecificValues.events)

  const values = {
    ...globalAnalyticsSettings(),
    ...dynamicValues,
    ...pageSpecificValues,
    events: mergedEvents,
  }
  return values || {}
}
/**
 * Represents the default values for Adobe Analytics
 * REMOVE AT YOUR OWN RISK
 */
export const DEFAULT_VALUES = {
  // Event4 is the "PageView event". Event4 should be on EVERY single page.
  // By default, Adobe Analytics has it own PageView event, which only can be used in their own reports.
  // Event4 was created to build our own Staples customized report
  // It should be called on EVERY page and is user by <AdobeAnalytics /> in <Layout />
  events: CONSTANTS.EVENT4,
  //  Site Type - describes the technology type, could be MVC, WF, React
  eVar66: 'React',
  //  Site Type - describes the technology type, could be MVC, WF, React
  prop28: 'React',
}

export function getDynamicValues(props = {}, defaultValues) {
  const { buyer = {}, client = {}, language: languageFromHeaders = '' } = props
  const server = require('os').hostname()
  const locale = getOldEwayFullLocale(
    languageFromHeaders || buyer?.language || ENGLISH
  )
  const userGroup = getUserGroup(props)
  const clientType = getClientType(props)

  const dynamicValues = {
    // The current machine name
    server,
    // language
    eVar35: locale,
    // language
    prop19: locale,
    // buyer ID
    eVar18: buyer?.buyerId,
    // Correspond to the current shipToAccount
    eVar37: client?.clientNo,
    // Corresponds to the master account number, the principal parent number of the current account.
    prop21: client?.principalClientNo,
    // The main account number
    eVar38: client?.principalClientNo,
    // user group
    prop27: userGroup,
    // user group
    eVar59: userGroup,
    // client type
    eVar70: clientType,
  }

  return {
    ...dynamicValues,
    ...defaultValues,
  }
}

const getPageSpecificValues = async (props = {}) => {
  const { ctx = {} } = props
  const pathName = getAnalyticsValuesPathName(ctx)
  const pageName = pathName.replace('/', '')
  const camelCasedTitle = camelAndCapitalize(pageName)
  const pageValues = await importPageValues(camelCasedTitle)
  const dynamicPageValues = getDynamicPageValues(props)
  const events = getCustomEventsFromPageType(ctx, {
    ...pageValues,
    ...dynamicPageValues,
  })
  const values = {
    ...pageValues,
    ...dynamicPageValues,
    events,
  }
  return values
}

export function mergeEvents(events = []) {
  const eventsArray = [DEFAULT_VALUES.events, ...events]
  return removeDuplicates(eventsArray).filter(Boolean).join()
}

const mapping = {
  '/': 'home',
  '/shopping-cart': 'cart',
  '/returns': 'returns',
  '/contact-us': 'contactUs',
  '/contact-form': 'contactForm',
  '/compare-products': 'compareProducts',
  '/shopping-lists': 'shoppingLists',
  '/templates-summary': 'templatesSummary',
  '/order-detail-template': 'orderDetailTemplate',
  '/flyers': 'flyers',
  '/rebates-offers': 'rebatesOffers',
  '/recall-information': 'recallInformation',
  '/confirmation': 'recallInformation',
  '/special-orders': 'specialOrders',
  '/ink-and-toner': 'inkAndToner',
  '/onboarding/steps': 'customerOnboarding',
  '/find-your-order': 'findYourOrder',
}
export function getAnalyticsValuesPathName(props = []) {
  const { pathname = '' } = props
  const route = mapping[pathname]
  return route || 'default'
}

async function importPageValues(camelCasedTitle) {
  return (await import(`../values/adobeAnalytics${camelCasedTitle}`)).default
}

export function getDynamicPageValues(props = {}) {
  const { ctx = {}, buyer = {}, order } = props
  const { asPath: pathWithParams } = ctx
  const path = removeDynaTraceQueryParam(pathWithParams)
  const { pageNumber, query } = getQueryAndPageNumber(ctx)
  const pathLowerCase = path.toLowerCase()
  const loggedIn = isLoggedIn(buyer)
  if (path === '' || path === '/') {
    return loggedIn ? CONSTANTS.HOME_PAGE_POST_LOGIN_VALUES : {}
  }

  if (path.includes('/shopping-cart')) {
    return {
      products: generateAdobeAnalyticsProductsFromOrder(order),
    }
  }

  if (path.includes('/browse-products')) {
    const isKeywordSearch = path.includes(KEYWORD_PATH)
    // evar67 returns the search engine type
    const eVar67 = isKeywordSearch ? BLOOMREACH : ENDECA
    const values = handleBrowseProductAnalytics(
      pathLowerCase,
      pageNumber,
      ctx.req,
      loggedIn,
      query
    )
    return {
      ...values,
      eVar67,
    }
  }
  if (path.includes('/product-detail')) {
    return {
      prop3: 'Product Detail Page',
    }
  }

  if (path.includes('/contact-us')) {
    if (path.includes('?from=Footer')) {
      return CONSTANTS.CONTACT_US_FOOTER
    }
    if (path.includes('?from=Header')) {
      return CONSTANTS.CONTACT_US_MENU
    }
  }

  if (path.includes('/contact-form?context=HD')) {
    return CONSTANTS.CONTACT_FORM_HELP_DESK
  }

  if (path.includes('/orders/summaries/')) {
    if (path.includes('/orders/summaries/1')) {
      return CONSTANTS.SUBMITTED_ORDER
    } else if (path.includes('/orders/summaries/2')) {
      return CONSTANTS.ORDER_IN_APPROVAL
    }
    return CONSTANTS.APPROVED_ORDER
  }

  if (path.includes('/confirmation')) {
    const { type } = ctx?.query
    return getConfirmationDynamicValue(type)
  }

  if (path.includes('/landing-pages')) {
    const { id } = ctx?.query
    return {
      pageName: id,
      ...CONSTANTS.LANDING_PAGES,
    }
  }
  if (path.includes('/confirm-user-address-information')) {
    const { id } = ctx?.query
    return getConfirmShippingBillingDynamicValue(id)
  }

  // In worst of cases return empty object ⬇️
  return {}
}

/**
 * Removes unwanted values from path string
 * @param {*} path
 */
function removeDynaTraceQueryParam(path = '') {
  return path.replace('', '')
}

export function removeDuplicates(array) {
  return [...new Set(array)]
}
