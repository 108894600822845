import classNames from 'classnames'
import PropTypes from 'prop-types'
import { isReactFragment } from 'shared/Utils/reactUtils'
import styles from './FeeMessage.module.scss'

/**
 * Used to display <EcoFeeMessage /> and <TariffFeeMessage />
 * @param {*} param
 */
export default function FeeMessage({
  text,
  children,
  className,
  as: Component,
}) {
  const feeClasses = classNames(styles.feeMessage, {
    [className]: className,
  })
  const props = {
    ...(!isReactFragment(Component) && {
      'data-test': 'fee-message',
      className: feeClasses,
    }),
  }

  return <Component {...props}>{children || text}</Component>
}
// Default Props
FeeMessage.defaultProps = {
  as: 'div',
}

// PropTypes
FeeMessage.propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.symbol]),
  className: PropTypes.string,
  text: PropTypes.node,
  children: PropTypes.node,
}
