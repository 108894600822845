import { resolve } from 'styled-jsx/css'
import omit from 'lodash/omit'
import pick from 'lodash/pick'
import allColors from 'assets/scss/colors.module.scss'
import { BOX_STYLES } from './BoxConstants'

export const getDynamicStyles = ({
  align: alignItems,
  as,
  bg,
  border,
  borderBottom,
  borderRight,
  right,
  top,
  opacity,
  borderColor: borderClr,
  borderStyle,
  borderWidth,
  color: foreColor,
  cursor,
  direction,
  display,
  flex,
  flexGrow,
  flexWrap,
  fontFamily,
  fontSize,
  fontWeight,
  h: heightShortHand,
  height,
  justify,
  letterSpacing,
  lineHeight,
  listStyle,
  m,
  maxH,
  maxW,
  mb,
  minH,
  minW,
  ml,
  mr,
  mt,
  p,
  pb,
  pl,
  position,
  pr,
  pt,
  rounded,
  textAlign,
  textTransform,
  w,
  zIndex,
  visibility,
}) => {
  const bgColor = getColor(bg)
  const color = getColor(foreColor)
  const borderColor = getColor(borderClr)
  const padding = getPadding({ p, pt, pr, pb, pl })
  const margin = getMargin({ m, mt, mr, mb, ml })
  const h = height || heightShortHand
  return resolve`
      ${as} {
        ${justify ? `justify-content: ${justify};` : ''}
        ${flex ? `flex: ${flex};` : ''}
        ${direction ? `flex-direction: ${direction};` : ''}
        ${flexWrap ? `flex-wrap: ${flexWrap};` : ''}
        ${flexGrow ? `flex-grow: ${flexGrow};` : ''}
        ${alignItems ? `align-items: ${alignItems};` : ''}
        ${display ? `display: ${display};` : ''}
        ${position ? `position: ${position};` : ''}
        ${cursor ? `cursor: ${cursor};` : ''}
        ${fontFamily ? `font-family: ${fontFamily};` : ''}
        ${fontSize ? `font-size: ${fontSize};` : ''}
        ${fontWeight ? `font-weight: ${fontWeight};` : ''}
        ${listStyle ? `list-style: ${listStyle};` : ''}
        ${letterSpacing ? `letter-spacing: ${letterSpacing};` : ''}
        ${margin}
        ${padding}
        ${color ? `color: ${color};` : ''}
        ${textAlign ? `text-align: ${textAlign};` : ''}
        ${bgColor ? `background-color:${bgColor};` : ''}
        ${rounded ? `border-radius:${rounded};` : ''}
        ${border ? `border:${border};` : ''}
        ${borderWidth ? `border:${borderWidth};` : ''}
        ${borderColor ? `border-color:${borderColor};` : ''}
        ${borderStyle ? `border-style:${borderStyle};` : ''}
        ${borderBottom ? `border-bottom:${borderBottom};` : ''}
        ${borderRight ? `border-right:${borderRight};` : ''}
        ${right ? `right:${right};` : ''}
        ${top ? `top:${top};` : ''}
        ${opacity ? `opacity:${opacity};` : ''}
        ${w ? `width:${w};` : ''}
        ${h ? `height:${h};` : ''}
        ${maxW ? `max-width:${maxW};` : ''}
        ${maxH ? `max-height:${maxH};` : ''}
        ${minH ? `min-height:${minH};` : ''}
        ${minW ? `min-width:${minW};` : ''}
        ${textTransform ? `text-transform:${textTransform};` : ''}
        ${lineHeight ? `line-height:${lineHeight};` : ''}
        ${zIndex ? `z-index:${zIndex};` : ''}
        ${visibility ? `visibility: ${visibility};` : ''}
      }
        `
}

const getPadding = ({ p, pt, pr, pb, pl }) => {
  return ` ${p ? `padding:${p};` : ''}
        ${pt ? `padding-top: ${pt};` : ''}
        ${pr ? `padding-right: ${pr};` : ''}
        ${pb ? `padding-bottom: ${pb};` : ''}
        ${pl ? `padding-left: ${pl};` : ''}`
}

const getMargin = ({ m, mt, mr, mb, ml }) => {
  return ` ${m ? `margin:${m};` : ''}
          ${mt ? `margin-top: ${mt};` : ''}
          ${mr ? `margin-right: ${mr};` : ''}
          ${mb ? `margin-bottom: ${mb};` : ''}
          ${ml ? `margin-left: ${ml};` : ''}`
}

// Those style props are extracted from the DOM

export const getColor = (value) => {
  const isHexadecimal = value?.startsWith('#')
  return isHexadecimal ? value : colors[value]
}

const colors = {
  red: '#cc0000',
  blue: '#2a78c6',
  white: '#ffffff',
  teal: '#01787a',
  black: '#444444',
  grey: allColors.inputBorder,
  whisper: '#e7e7e7',
  ...allColors,
}

export function omitBoxProps(props) {
  return omit(props, BOX_STYLES)
}

export function splitBoxProps(props) {
  const boxProps = pick(props, BOX_STYLES)
  const rest = omit(props, BOX_STYLES)
  return { rest, boxProps }
}
