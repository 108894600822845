export const PRODUCT_ENVIRONMENTAL_FEE = 1
export const PRODUCT_HANDLING_FEE = 2 // confusion between HANDLING_FEE and DELIVERY_INSTALLATION
export const PRODUCT_DELIVERY_INSTALLATION = 2 // confusion to clarify
export const PRODUCT_TRANSACTION_FEE = 3
export const PRODUCT_ASSEMBLY_FEE = 4
export const PRODUCT_DELIVERY_INSTALLATION_FEE = 5
export const PRODUCT_TARIF_FEE = 9

export const PRODUCT_FEE_TYPES = {
  ENVIRONMENTAL_FEE: PRODUCT_ENVIRONMENTAL_FEE,
  HANDLING_FEE: PRODUCT_HANDLING_FEE,
  DELIVERY_INSTALLATION: PRODUCT_DELIVERY_INSTALLATION,
  TRANSACTION_FEE: PRODUCT_TRANSACTION_FEE,
  ASSEMBLY_FEE: PRODUCT_ASSEMBLY_FEE,
  DELIVERY_INSTALLATION_FEE: PRODUCT_DELIVERY_INSTALLATION_FEE,
  TARIF_FEE: PRODUCT_TARIF_FEE,
}
