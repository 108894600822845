import PropTypes from 'prop-types'
/*eslint import/namespace: ['error', { allowComputed: true }]*/
import * as svgVariants from '~/components/shared/Icon/Svg/Variants/index'
/**
 *Name: Svg
 *Desc: Svg component
 * @param {string} height
 * @param {string} width
 * @param {string} size
 * @param {object} variantIcon
 * @param {string} color
 * @param {string} title
 * @param {string} className
 * @param {bool} focusable
 */

const Svg = ({
  size,
  fill = 'currentColor',
  title,
  variant,
  height = '16px',
  width = '16px',
  focusable = false,
  primaryTitle = '',
  secondaryTitle = '',
  ...restFromProps
}) => {
  const component = svgVariants[variant](primaryTitle, secondaryTitle)
  if (!component) {
    return null
  }
  const { type: Type = 'svg', props: propsFromVariant } = component
  const {
    width: svgWidth,
    height: svgHeight,
    ...fromVariant
  } = propsFromVariant
  return component ? (
    <Type
      {...fromVariant}
      {...restFromProps}
      focusable={focusable}
      height={size || svgHeight || height}
      width={size || svgWidth || width}
      viewBox={component.props.viewBox}
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      fill={fill}
    >
      {title ? <title>{title}</title> : null}
      {component.props.children}
    </Type>
  ) : null
}

Svg.defaultProps = {
  color: '#666666',
}

// Props Validation
Svg.propTypes = {
  language: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  variant: PropTypes.string,
  fill: PropTypes.string,
  color: PropTypes.string,
  focusable: PropTypes.bool,
  title: PropTypes.string,
  className: PropTypes.string,
  primaryTitle: PropTypes.string,
  secondaryTitle: PropTypes.string,
}
export default Svg
