import { getApiUrl } from 'services/utils/configUtils'
import { sendRequest } from '../apiHelper'
const API_URL = getApiUrl()

export function fetchClientInformation(currentShipToAccountId, options = {}) {
  if (!currentShipToAccountId) {
    return {}
  }
  const clientUrl = `${API_URL}/clients/${currentShipToAccountId}`
  const clientPromise = sendRequest(clientUrl, options)
  return clientPromise
}

export const getClientInfo = async (parentClientNo = '') => {
  const url = `${API_URL}/clients/byNo/${parentClientNo}`
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  }
  const result = await sendRequest(url, options)
  return result
}
