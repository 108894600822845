import {
  CUSTOM_PROMPT_TYPE,
  filterPromptValue,
} from 'services/utils/promptUtils'
import { showNotification } from 'shared/Alerts'
import { DANGER } from 'shared/Alerts/AlertNotificationUtils/AlertNotificationConstants'
import { smoothScrollTop } from 'shared/Alerts/AlertNotificationUtils/AlertNotificationUIUtils'
import { HAS_CUSTOM_PROMPT_ERRORS } from '~/components/shared/CheckoutButton/constant'
import { saveCustomPrompt } from '~/services/shoppingCartService'
import { removeLocalStorage, setLocalStorage } from '~/services/storageManager'
import { wrapWithCurlyBrace } from '~/services/utils'
import { CUSTOM_PROMPT_ERROR_NOTIFICATION_ID } from './CustomPromptConstant'
import messages from './messages'

export const customPromptAlertNotification = (language) => {
  showNotification(messages[language].formAlertNotificationMsg, {
    type: DANGER,
    id: CUSTOM_PROMPT_ERROR_NOTIFICATION_ID,
  })
  smoothScrollTop()
}
export const onSaveCustomPrompt = async (
  promptId,
  value,
  sequence,
  language,
  orderProductId,
  orderId,
  setFormErrors,
  formServerErrors
) => {
  const customPrompt = filterPromptValue(value)
  const orderProductIdWithCurly = wrapWithCurlyBrace(orderProductId)
  const response = await saveCustomPrompt(
    language,
    orderProductIdWithCurly,
    CUSTOM_PROMPT_TYPE.PRODUCT,
    orderId,
    promptId,
    customPrompt,
    sequence
  )

  const { MessageError = [] } = response || {}
  const messages = MessageError
  const fieldName = getCustomPromptFieldName({
    promptId,
    sequence,
    orderProductId,
  })

  if (messages.length) {
    setFormErrors({
      formServerErrors: {
        ...formServerErrors,
        [fieldName]: messages[0]?.PrimaryMessage,
      },
    })
    return
  }
  if (formServerErrors?.[fieldName]) {
    delete formServerErrors[fieldName]
  }
  setFormErrors?.({
    formServerErrors,
  })
}

export const getCustomPromptFieldName = ({
  promptId,
  sequence,
  orderProductId,
}) => {
  const fieldName = `custom-prompt-${promptId}-${sequence}-${orderProductId}`
  return fieldName
}

export const apiCallToSaveCustomPrompt = (
  event,
  customPromptNo,
  sequence,
  language,
  orderProductId,
  orderId,
  setFormErrors,
  formServerErrors
) => {
  event.preventDefault()
  const {
    target: { value },
  } = event
  onSaveCustomPrompt(
    customPromptNo,
    value,
    sequence,
    language,
    orderProductId,
    orderId,
    setFormErrors,
    formServerErrors
  )
}

export function getPromptLength(item) {
  const hasPrefix = !!item.Prefix
  const prefixLength = hasPrefix ? item.Prefix.toString().length : 0
  const promptLength = item.PromptLenght || item.PromptLength || 0

  return prefixLength > 0 ? promptLength - prefixLength : promptLength
}

export function removeCustomPromptErrorsOnLoad() {
  removeLocalStorage(HAS_CUSTOM_PROMPT_ERRORS)
}
export function highlightCustomPromptErrorsOnLoad() {
  setLocalStorage(HAS_CUSTOM_PROMPT_ERRORS, true)
}
