import { isGroupOrderFromBuyer } from 'services/utils'

export const DEFAULT_ORDER_PREFIX = 'EW'
export const GROUP_ORDER_PREFIX = 'GR'

export const formatOrderNumber = ({ order, buyer }) => {
  const { prefix, suffix } = getOrderPrefixAndSuffix(order, buyer)

  return {
    prefix,
    suffix,
    orderNumber: `${prefix}${suffix}`,
  }
}

function getOrderPrefixAndSuffix(order, buyer) {
  const { ewayOrderNo } = order
  const { groupOrderNumber } = buyer
  const isGrpOrder = isGroupOrderFromBuyer(buyer)

  const prefix = isGrpOrder ? GROUP_ORDER_PREFIX : DEFAULT_ORDER_PREFIX
  const suffix = isGrpOrder ? groupOrderNumber : ewayOrderNo
  return { prefix, suffix }
}

export const getOrderNumberFull = (order, buyer) => {
  const { prefix, suffix } = getOrderPrefixAndSuffix(order, buyer)
  return `${prefix}${suffix}`
}
