export default {
  en: {
    budgetTileHeadingLabel: 'Budget Calculator',
    budgetLabel: 'Budget',
    budgetTimePeriodLabel: 'Time Period',
    budgetAvailableLabel: 'Available',
    noOrderLabel:
      "You don't have any recent orders to display. Only orders submitted within the last 30 days will appear here",
    noApprovalOrderLabel:
      'You are all caught up! You have no orders to approve at this time.',
    viewOrderLabel: 'View all',
    youHaveLabel: 'You have',
    orderLabel: 'order submitted within the last 30 days.',
    moreOrderLabel: 'orders submitted within the last 30 days.',
    waitingApprovalLabel: 'order waiting for approval.',
    moreWaitingApprovalsLabel: 'orders waiting for approval.',
    orderTileHeadingLabel: 'Orders',
    orderNumberLabel: 'Order Number',
    orderDateLabel: 'Order Date',
    approvalsTileHeadingLabel: 'Approvals',
    messagesTileHeadingLabel: 'Messages',
    listsTileHeadingLabel: 'Lists',
    createListLabel: 'Create a list',
    viewAllLabel: 'View all',
    itemLabel: 'item in the list',
    moreItemsLabel: 'items in the list',
    noMsgLabel: 'You do not have any active messages to display.',
    toLabel: 'to',
    helpMessage:
      'Need help on special issues related to your account? Planning a new project such as returning to the office or work from home? Interested in our Personal Purchase Program (PPP)? {link} to email your Sales Representative directly.',
    clickHereText: 'Click here',
    noDataFoundMsg: 'There are no matches found.',
    searchCountMsg: 'match found.',
    searchMultipleCountMsg: 'matches found.',
    findYourOrderDetails:
      "Can't find your order?  Try using the {FIND_YOUR_ORDER} page to look up your order.",
    findYourOrder: 'Find your order',
    searchPlaceholder: 'Find by order number',
    search: 'Search',
  },
  fr: {
    budgetTileHeadingLabel: 'Calculateur de budget',
    budgetLabel: 'Budget',
    budgetTimePeriodLabel: 'Période',
    budgetAvailableLabel: 'Disponible',
    noOrderLabel:
      "Vous n'avez pas de commandes récentes à afficher. Seules les commandes soumises au cours des 30 derniers jours apparaîtront ici",
    noApprovalOrderLabel:
      "Vous êtes à jour! Vous n'avez pas de commandes à approuver pour le moment.",
    viewOrderLabel: 'Voir tout',
    youHaveLabel: 'Vous avez',
    orderLabel: ' commande soumise au cours des 30 derniers jours',
    moreOrderLabel: 'commandes soumises au cours des 30 derniers jours ',
    waitingApprovalLabel: "commande en attente d'approbation.",
    moreWaitingApprovalsLabel: "commandes en attente d'approbation.",
    orderTileHeadingLabel: 'Commandes',
    orderNumberLabel: 'Numéro de commande',
    orderDateLabel: 'Date de la commande',
    approvalsTileHeadingLabel: 'Approbations',
    messagesTileHeadingLabel: 'Messages',
    listsTileHeadingLabel: 'Listes',
    createListLabel: 'Créer une liste',
    viewAllLabel: 'Voir tout',
    itemLabel: 'article dans cette liste',
    moreItemsLabel: 'articles dans cette liste',
    noMsgLabel: "Vous n'avez pas de messages actifs à afficher",
    toLabel: 'à',
    helpMessage:
      "Besoin d'aide avec des problèmes particuliers liés à votre compte ? Vous planifiez un nouveau projet comme le retour au bureau ou le travail à domicile ? Intéressé par notre programme d'achat personnel (PPP) ?  {link} pour envoyer un courriel à votre représentant des ventes directement.",
    clickHereText: 'Cliquez ici',
    noDataFoundMsg: " Il n'y a aucune correspondance trouvée.",
    searchCountMsg: 'correspondance trouvée.',
    searchMultipleCountMsg: 'correspondances trouvées.',
    findYourOrderDetails:
      "Vous ne trouvez pas votre commande ? Essayez d'utiliser la page {FIND_YOUR_ORDER} pour rechercher votre commande.",
    findYourOrder: 'Trouvez votre commande',
    searchPlaceholder: 'Rechercher par numéro de commande',
    search: 'Chercher',
  },
}
