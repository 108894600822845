import { ENGLISH, FRENCH } from '@redux/constants'
import colors from 'colors/safe'
import uniqueId from 'lodash/uniqueId'
import { doLogout } from '~/components/shared/Layout/Header/Logout/LogoutUtils'
import { isDev, isTest } from '~/config/env/environmentUtils'
import { getIsUsingMockServer, MOCK_TESTS_WARNING } from '~/lib/mockServer'
import { API_ERROR, SEND_REQUEST_DEFAULTS } from './apiHelperConstants'
import getConfig from 'next/config'
import { doRequest } from './apiHelperDoRequest'
import { getOptions } from './apiHelperUtils'
// in .env SHOW_FULL_API_ERRORS ⬇️
const { serverRuntimeConfig = {} } = getConfig()
const { showFullApiErrors: showFullApiErrorsEnabled } = serverRuntimeConfig

/**
 * @param {string} resourceUrl - Url to send the request to
 * @param {object} optionArgs - fetch options to pass in
 * @param {object} sendRequestOptions - can disable the cache and also disables adding the auth token
 */
export const sendRequest = async (
  resourceUrl,
  optionArgs = {},
  sendRequestOptions = SEND_REQUEST_DEFAULTS
) => {
  const { isAddToken, disableCache } = sendRequestOptions
  const { retry = {}, ...options } = optionArgs
  const { as: retryAs = '', addPreviousMethodToUrl } = retry
  const shouldRetry = !!retryAs
  // Shows error when calling from test cases ⬇️
  MOCK_TESTS_WARNING(resourceUrl)

  const mergedOptions = getOptions(options, disableCache, isAddToken)
  const { resource, response, ok } = await doRequest(resourceUrl, mergedOptions)
  if (!ok && shouldRetry) {
    const { finalResourceUrl, retryOptions } = handleRetry(
      mergedOptions,
      retryAs,
      addPreviousMethodToUrl,
      resourceUrl
    )
    const { resource, response } = await doRequest(
      finalResourceUrl,
      retryOptions
    )
    addStatus(resource, response)
    return resource
  }
  addStatus(resource, response)
  return resource || {}
}

function handleRetry(options, retryAs, addPreviousMethodToUrl, resourceUrl) {
  const retryOptions = {
    ...options,
    method: retryAs.toUpperCase(),
  }

  const previousMethod = options.method || 'GET'
  const finalResourceUrl = addPreviousMethodToUrl
    ? `${resourceUrl}/${previousMethod.toLowerCase()}`
    : resourceUrl
  return { finalResourceUrl, retryOptions }
}

/**
 * Eslint was disabled because we need to log this specific
 * Information in the "node" layer at this point in time.
 * if getBuyer or getOrder doesn't work for some reason, the error will be logged
 */
export const handleErrorWithLogging = async (
  response = {},
  error = {},
  method = 'GET'
) => {
  logError(response, error, method)

  const isUnauthorized = response.status === 401
  if (isUnauthorized && process.browser && !isDev()) {
    removeAuthCookies()
  }

  let jsonResponse = {}
  // Try to get json response
  try {
    jsonResponse = await response.json?.()
  } catch (error) {
    // Silence is golden
  }

  return {
    ...jsonResponse,
    status: response.status,
    isUnauthorized,
    ...API_ERROR,
  }
}

const argumentsEmpty = (values) =>
  values.filter((element) => element).length === 0

const removeAuthCookies = () => {
  doLogout()
}

/**
 * Eslint was disabled because we need to log this specific
 * Information in the "node" layer at this point in time.
 * if request doesn't work for some reason, the error will be logged
 */
async function logError(response = {}, error = {}, method = 'GET') {
  if (isTest() && getIsUsingMockServer()) {
    // Don't log errors if your using a mock server
    return
  }
  const date = colors.green(`${new Date().toLocaleString(FRENCH)} `)
  const errorWord = colors.bgRed(`ERROR:`)
  const errorMessage = response.status || error.message
  const errorForWhichUrl = response.url ? `for ${response.url}` : ''
  const url = response
    ? colors.red(`${errorMessage} ${errorForWhichUrl}`)
    : error

  // eslint-disable-next-line no-console
  console.log(
    `
${date} ${errorWord} ${method} ${url} ${response?.statusText || ''}
`
  )
  await showFullApiErrors(response)
}

async function showFullApiErrors(response) {
  if (response.status > 400 && showFullApiErrorsEnabled) {
    const type = response.headers.get('content-type')
    let method = ''
    if (type.includes('text/html') || type.includes('text/plain')) {
      method = 'text'
    } else if (type.includes('json')) {
      method = 'json'
    }
    const result = await response[method]?.()
    if (result) {
      // eslint-disable-next-line no-console
      console.log(result)
    }
  }
}

function addStatus(resource, response) {
  if (resource?.status) {
    //Some of the APIs return messages in status object (like Monkey - The Credit Card Service)
    resource.messages = resource?.status
  }
  if (resource) {
    resource.status = response.status
    resource.isSuccess = response.status === 200
    resource.isUnauthorized = response.status === 401
  }
}

/**
 * Check args empty needs to be hoisted, or else it crashes.
 * Find out why.a
 * @param {*} fn
 */
export function checkArgsEmpty(fn) {
  return function (...args) {
    const flattenedArgs = flatten(args)
    if (argumentsEmpty(flattenedArgs)) {
      if (isDev()) {
        // eslint-disable-next-line no-console
        console.log(colors.red(`Empty args for function: ${fn.name}`))
      }
      return API_ERROR
    }
    return fn(flattenedArgs)
  }
}

function flatten(arr) {
  return arr.reduce(function (flat, toFlatten) {
    return flat.concat(
      Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten
    )
  }, [])
}

export function withUniqueId(list) {
  return list.map((e) => ({ id: uniqueId(), ...e }))
}

export function isSuccess(status) {
  return status === 200
}

/**
 * Name: apiRequest
 * Desc: common API header request for order and approval tiles
 * @param {*} language
 * @param {*} url
 */
export const apiRequest = async (language, url) => {
  const options = {
    headers: {
      'Accept-Language': `${language}-CA`,
    },
  }
  const response = (await sendRequest(url, options)) || {}
  return response
}

export function generateHeadersWithLanguage(language = ENGLISH) {
  return {
    'Content-Type': 'application/json',
    'Accept-Language': `${language}-CA`,
  }
}

export const CONTENT_TYPE_JSON = {
  'Content-Type': 'application/json',
}
