import PropTypes from 'prop-types'
import Icon from 'shared/Icon'
import Tooltip from 'shared/Tooltip'
import { handleTooltipShowHide } from 'services/utils'

/**
 * Name: IconTooltip
 * Desc: Displays an icon with a tooltip above it
 * @param {string} text
 * @param {string} color
 * @param {string} strong
 * @param {bool} lineThrough
 * @param {string} variant
 */
function IconTooltip({
  children,
  iconClassName,
  id,
  tooltipClassName,
  icon,
  tooltipText,
}) {
  return (
    <>
      <Icon
        data-tip
        data-for={id}
        variant={icon}
        onFocus={handleTooltipShowHide}
        onBlur={handleTooltipShowHide}
        className={iconClassName}
        data-test={id}
        aria-describedby={id}
        aria-label={tooltipText}
        tabIndex="0"
      >
        <Tooltip data-for={id} id={id} place="top" className={tooltipClassName}>
          {children}
        </Tooltip>
      </Icon>
    </>
  )
}

// PropTypes
IconTooltip.propTypes = {
  children: PropTypes.node,
  iconClassName: PropTypes.string,
  tooltipClassName: PropTypes.string,
  id: PropTypes.string,
  icon: PropTypes.string,
  tooltipText: PropTypes.string,
}

export default IconTooltip
