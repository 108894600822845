export const DELIVERY_TYPE_ZONE = 1
export const DELIVERY_TYPE_FIFTY_GREEN = 0
export const PRODUCT_TOTAL = 'productTotal'
export const FEE_TOTAL = 'feeTotal'
export const CONTRACT_PRODUCT_TOTAL = 'CONTRACT_PRODUCT_TOTAL'
export const NON_CONTRACT_PRODUCT_TOTAL = 'NON_CONTRACT_PRODUCT_TOTAL'
export const PROMO_DISCOUNT = 'promoDiscount'
export const FEE_LINE_TYPE = 'fee'
export const TAX_LINE_TYPE = 'tax'
export const TAX_TOTAL_TYPE = 'taxTotal'
export const FEE_LINE_TOTAL = 'orderTotal'
