import messages from './messages'
import { FREE_GIVE_AWAY } from 'shared/ProductCard/ProductCardConstants'
import { I_WANT_INSTALL_ON_SITE } from '~/components/shared/DeliveryServiceOptions/DeliveryServiceOptionsConstants'
import { ENGLISH } from '~/redux/constants'
import { PRODUCT_FEE_TYPES } from '~/services/fees/products/feeProductTypes'
const { ASSEMBLY_FEE } = PRODUCT_FEE_TYPES

export const getAssemblyFeeFromOrder = (
  language = ENGLISH,
  item,
  isDeliOptSet,
  deliServOptType,
  isPreAsmbAvailable
) => {
  const {
    QualifierType,
    IsAssemblySet,
    IsAssembly,
    OrderProductFees: {
      Assembly: { FeeAmount, FeeTotal } = {
        FeeAmount: 0,
        FeeTotal: 0,
      },
    } = {
      Assembly: {},
    },
  } = item

  let assemblyFee = []
  const optionSet = isDeliOptSet === true
  const zoneCanPreAssemble = isPreAsmbAvailable === true
  const isFreeGiveAway = QualifierType === FREE_GIVE_AWAY
  const assemblySet = IsAssemblySet === true
  const hasAssemblyType = !!IsAssembly
  const userChoseInstallOnSite = deliServOptType === I_WANT_INSTALL_ON_SITE
  if (optionSet) {
    if (
      (zoneCanPreAssemble && !isFreeGiveAway && assemblySet) ||
      (userChoseInstallOnSite && hasAssemblyType)
    ) {
      assemblyFee = [
        {
          FeeTypeText: '',
          FeeAmount,
          link: '',
          FeeType: ASSEMBLY_FEE,
          FeeTotal: userChoseInstallOnSite
            ? ''
            : FeeTotal || messages[language].includedLabel,
          isExemptFromAssemblyFees: userChoseInstallOnSite || FeeAmount === 0,
          deliServOptType,
        },
      ]
    }
  }

  return assemblyFee
}
