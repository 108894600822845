export const PAGE_NAME = 'pageName'
export const CALLING_LOCATION = 'callingLocation'

export const CATEGORY = 'category'

export const BANNER_CID = 'CID'
export const BANNER_ICID = 'ICID'
export const INTERNAL_CAMPAIGNS = 'Internal Campaigns'
export const EXTERNAL_CAMPAIGNS = 'External Campaigns'
export const SPECIAL_ORDER = 'specialOrder'
// Page View Event
export const EVENT4 = 'event4'
export const ADD_TO_CART_EVENTS = 'scAdd,scOpen,event35,event36'
const IN_APPROVAL = 'In Approval'

export const PAGE_TYPE = {
  CLASS: 'Class',
  DEPARTMENT: 'Department',
  CATEGORY: 'Category',
}
export const SEARCH_CALLING_LOCATION = {
  BANNERS: 'banners',
  SEARCH_BAR: 'search-bar',
  NAV_BAR: 'nav-bar',
  PROMO_BANNER: 'promo-banner',
  BREADCRUMB: 'breadcrumb',
  BROWSE: 'browse',
}

export const ADD_TO_CART_HOME_PAGE = {
  RECOMMENDATION: {
    eVar3: 'HomePage Recommendation',
    eVar12: 'Recommendations - HomePage',
  },
  RECENT_VIEWED: {
    eVar3: 'HomePage Recommendation',
    eVar12: 'Recently Viewed - HomePage',
  },
  QUICK_VIEW_RECOMMENDATION: {
    eVar3: 'HomePage Recommendation',
    eVar12: 'Recommendations - Quick View',
  },
  QUICK_VIEW_RECENT_VIEWED: {
    eVar3: 'HomePage Recommendation',
    eVar12: 'Recently Viewed - HomePage - Quick View',
  },
}
export const ADD_TO_CART_PDP_PAGE = {
  PRODUCT_DETAIL: {
    eVar12: 'Product Detail',
  },
  RECOMMENDATION: {
    eVar12: 'Recommendations - Product Detail',
    eVar3: 'Product Recommendation',
  },
  RECENT_VIEWED: {
    eVar12: 'Recently Viewed - Product Detail',
    eVar3: 'Product Recommendation',
  },
  ACCESSORIES: {
    eVar12: 'Accessories - Product Detail',
    eVar3: 'PDP',
  },
  RELATED_ITEMS: {
    eVar12: 'Related Items - Product Detail',
    eVar3: 'PDP',
  },
  ALTERNATE_ORDERING_FORMAT: {
    eVar12: 'Alternate Ordering Format - Product Detail',
  },
  QUICK_VIEW_RECOMMENDATION: {
    eVar12: 'Recommendations - PDP - Quick View',
    eVar3: 'Product Recommendation',
  },
  QUICK_VIEW_RECENT_VIEWED: {
    eVar12: 'Recently Viewed – PDP - Quick View',
  },
}

export const ADD_TO_CART_SHOPPING_CART_PAGE = {
  SHOPPING_CART: {
    eVar12: 'Quick add',
    eVar3: 'Quick add',
    eVar36: 'Other Finding Method',
  },
  RECOMMENDATION: {
    eVar12: 'Recommendations - Shopping Cart',
    eVar3: 'Shopping Cart Recommendations',
    eVar36: 'Other Finding Method',
  },
  RECENT_VIEWED: {
    eVar12: 'Recommendations - Recently Viewed',
    eVar3: 'Shopping Cart Recommendations',
    eVar36: 'Other Finding Method',
  },
  QUICK_VIEW_RECOMMENDATION: {
    eVar12: 'Recommendation - Shopping List Details - Quick View',
    eVar3: 'Shopping List Recommendation',
  },
  QUICK_VIEW_RECENT_VIEWED: {
    eVar12: 'Recently Viewed - Shopping List Details - Quick View',
    eVar3: 'Shopping List',
  },
}

export const ADD_TO_CART_SHOPPING_LIST_PAGE = {
  SHOPPING_LIST: {
    eVar12: 'Quick add',
    eVar3: 'Quick add',
    eVar36: 'Other Finding Method',
  },
  RECOMMENDATION: {
    eVar12: 'Recommendations - Shopping List',
    eVar3: 'Shopping List Recommendations',
    eVar36: 'Other Finding Method',
  },
  RECENT_VIEWED: {
    eVar12: 'Recommendations - Recently Viewed',
    eVar3: 'Shopping Cart Recommendations',
    eVar36: 'Other Finding Method',
  },
  QUICK_VIEW_RECOMMENDATION: {
    eVar12: 'Recommendation - Shopping List Details - Quick View',
    eVar3: 'Shopping List Recommendation',
  },
  QUICK_VIEW_RECENT_VIEWED: {
    eVar12: 'Recently Viewed - Shopping List Details - Quick View',
    eVar3: 'Shopping List',
  },
}
export const ADD_TO_CART_SUBMITTED_ORDER_DETAIL = {
  QUICK_VIEW: {
    eVar12: 'Previously Submitted Order',
    eVar3: 'Previously Submitted Order',
  },
}

export const ADD_TO_CART_SEARCH_RECOMMENDATION = {
  BROWSE_RESULTS: {
    eVar12: 'Recommendations - Browse Results',
    eVar3: 'Browse Recommendation',
  },
  SEARCH_RESULTS: {
    eVar12: 'Recommendations - Search Results',
    eVar3: 'Search Recommendation',
  },
  SHOPPINGLIST: {
    eVar12: 'Recommendations - Shopping List',
  },
  PROMOTION_BANNER: {
    eVar12: 'Recommendations - Promotion Landing Page',
  },
  QUICK_VIEW_BROWSE_RESULTS: {
    eVar12: 'Recommendations - Browse Results - Quick View',
    eVar3: 'Browse Recommendation',
  },
  QUICK_VIEW_SEARCH_RESULTS: {
    eVar12: 'Recommendations - Search Results - Quick View',
    eVar3: 'Search Recommendation',
  },
  QUICK_VIEW_SHOPPINGLIST: {
    eVar3: 'Shopping List Recommendation',
    eVar12: 'Recommendation - Shopping List Details - Quick View',
  },
  QUICK_VIEW_PROMOTION_BANNER: {
    eVar3: 'Product Recommendation',
    eVar12: 'Recommendations - Promotion Landing Page - Quick View',
  },
  HYBRID_SEARCH: {
    eVar3: 'Browse Recommendation',
    eVar12: 'Recommendations - Browse Results',
  },
  QUICK_VIEW_HYBRID_SEARCH: {
    eVar3: 'Browse Recommendation',
    eVar12: 'Recommendations - Browse Results - Quick View',
  },
}
/* Need to chagne that value after confirmation or inputs*/
export const ADD_TO_CART_SEARCH_RECENT_VIEWED = {
  BROWSE_RESULTS: {
    eVar12: 'Recently Viewed - Browse Results',
    eVar3: 'Browse Recommendation',
  },
  SEARCH_RESULTS: {
    eVar12: 'Recently Viewed - Search Results',
    eVar3: 'Search Recommendation',
  },
  SHOPPINGLIST: {
    eVar12: 'Recently Viewed - Shopping List',
  },
  PROMOTION_BANNER: {
    eVar12: 'Recently Viewed - Promotion Landing Page',
  },
  QUICK_VIEW_BROWSE_RESULTS: {
    eVar12: 'Recently Viewed - Browse Results - Quick View',
    eVar3: 'Browse Recommendation',
  },
  QUICK_VIEW_SEARCH_RESULTS: {
    eVar12: 'Recently Viewed - Search Results - Quick View',
    eVar3: 'Search Recommendation',
  },
  QUICK_VIEW_SHOPPINGLIST: {
    eVar3: 'Shopping List',
    eVar12: 'Recently Viewed - Shopping List Details - Quick View',
  },
  QUICK_VIEW_PROMOTION_BANNER: {
    eVar12: 'Recently Viewed – PDP - Quick View',
  },
}
export const ADD_TO_CART_SEARCH_RESULT = {
  BOX_FINDER: {
    eVar12: 'Box Finder',
    eVar3: 'Search',
  },

  BROWSE_RESULTS: {
    eVar12: 'Browse Results',
    eVar3: 'Browse',
  },
  SEARCH_RESULTS: {
    eVar12: 'Search Results',
    eVar3: 'Search',
  },
  SHOPPINGLIST: {
    eVar12: 'Shopping List Details',
    eVar3: 'Shopping List',
  },
  PROMOTION_BANNER: {
    eVar12: 'Promotion Landing Page',
    eVar3: 'Promotion Banner',
  },
  INK_AND_TONER: {
    eVar3: 'Ink and Toner Finder Tool',
    eVar12: 'Search Results',
  },
  INK_AND_TONER_FINDER: {
    eVar3: 'Ink and Toner Finder Button.',
    eVar12: 'Ink and Toner Finder Tool',
  },
  QUICK_VIEW_BOX_FINDER: {
    eVar12: 'Box Finder – Quick View',
    eVar3: 'Search',
  },

  QUICK_VIEW_BROWSE_RESULTS: {
    eVar12: 'Browse Results - Quick View',
    eVar3: 'Browse',
  },
  QUICK_VIEW_SEARCH_RESULTS: {
    eVar12: 'Search Results - Quick View',
    eVar3: 'Search',
  },
  QUICK_VIEW_SHOPPINGLIST: {
    eVar12: 'Shopping List Details - Quick View',
    eVar3: 'Shopping List',
  },
  QUICK_VIEW_PROMOTION_BANNER: {
    eVar12: 'Promotion Landing Page - Quick View',
    eVar3: 'Promotion Banner',
  },
  QUICK_VIEW_INK_AND_TONER: {
    eVar3: 'Ink and Toner Finder Tool',
    eVar12: 'Search Results - Quick View ',
  },
  QUICK_VIEW_INK_AND_TONER_FINDER: {
    eVar3: 'Ink and Toner Finder Button.',
    eVar12: 'Ink & Toner Finder - Quick View',
  },
  HYBRID_SEARCH: {
    eVar3: 'Browse',
    eVar12: 'Browse Results',
  },
  QUICK_VIEW_HYBRID_SEARCH: {
    eVar3: 'Browse',
    eVar12: 'Browse Results - Quick View',
  },
}
export const ADD_TO_CART_SEARCH_ADD_SELECTED = {
  BROWSE_RESULTS: {
    eVar12: 'Browse Results - Add Selected',
    eVar3: 'Browse',
  },
  SEARCH_RESULTS: {
    eVar12: 'Search Results - Add Selected',
    eVar3: 'Search',
  },
  SHOPPINGLIST: {
    eVar12: 'Shopping List Details - Add Selected',
    eVar3: 'Shopping List',
  },
  PROMOTION_BANNER: {
    eVar12: 'Promotion Landing Page – Add Selected',
    eVar3: 'Promotion Banner',
  },
  QUICK_VIEW_HYBRID_SEARCH: {
    eVar3: 'Browse',
    eVar12: 'Browse Results - Add Selected',
  },
}
export const PDP_EVAR3_VALUE = {
  HOME: {
    eVar3: 'HomePage',
  },
  HOME_RECOMMENDATION: {
    eVar3: 'HomePage Recommendation',
  },
  PDP: {
    eVar3: 'Product',
  },
  PDP_RECOMMENDATION: {
    eVar3: 'Product Recommendation',
  },
  CATEGORY: {
    eVar3: 'Browse',
  },
  CATEGORY_RECOMMENDATION: {
    eVar3: 'Browse Recommendation',
  },
  SEARCH: {
    eVar3: 'Search',
  },
  SEARCH_RECOMMENDATION: {
    eVar3: 'Search Recommendation',
  },
  SHOPPING_LIST: {
    eVar3: 'Shopping List',
  },
  PROMOTION_BANNER: {
    eVar3: 'Promotion Banner',
  },
  SHOPPING_CART_RECOMMENDATION: {
    eVar3: 'Shopping Cart Recommendation',
  },
}

export const ITEM_DELETED_ANALYTICS_VALUES = {
  events: 'scRemove,event67,event68',
  eVar12: 'Removal: Delete Selected - Shopping Cart',
}
export const CLEAR_CART_ANALYTICS_VALUES = {
  events: 'scRemove,event67,event68',
  eVar12: 'Removal: Clear Cart - Shopping Cart',
}
export const QTY_REDUCED_ANALYTICS_VALUES = {
  events: 'scRemove,event67,event68',
  eVar12: 'Removal: Reduce Quantity - Shopping Cart',
  eVar3: 'Shopping Cart',
}
export const QTY_INCREASED_ANALYTICS_VALUES = {
  events: ADD_TO_CART_EVENTS,
  eVar12: 'Shopping Cart',
  eVar36: 'Other Finding Method',
  eVar3: 'Shopping Cart',
}
export const ACTION_TYPE = {
  UPDATE: 'update',
  DELETE: 'delete',
  ADD: 'add',
  CLEAR_CART: 'clear_cart',
  REDUCE: 'reduce',
  REMOVE: 'remove',
  REJECT: 'reject',
  APPROVE: 'approve',
}

export const REVIEW_AND_CONFIRM_ANALYTICS_VALUES = {
  pageName: 'Checkout: Review & Pay',
  channel: 'Checkout',
  events: `event8`,
  prop3: 'Checkout',
  prop4: 'Checkout: Review & Pay',
  prop5: 'Checkout: Review & Pay',
  prop6: 'Checkout: Review & Pay',
  prop12: 'Checkout: Review & Pay',
  prop26: 'no value',
}

export const DELIVERY_DETAILS_ANALYTICS_VALUES = {
  pageName: 'Checkout: Delivery Details',
  channel: 'Checkout',
  events: `event7`,
  prop3: 'Checkout',
  prop4: 'Checkout: Delivery Details',
  prop5: 'Checkout: Delivery Details',
  prop6: 'Checkout: Delivery Details',
  prop12: 'Checkout: Delivery Details',
  prop26: 'no value',
}

export const ADOBE_ANALYTICS_CONST = {
  QUICK_VIEW: 'quickView',
}

export const CONTACT_US_FOOTER = {
  prop4: 'Footer',
  prop5: 'Footer',
  prop6: 'Footer',
}

export const HOME_PAGE_POST_LOGIN_VALUES = {
  channel: 'Post-Login Homepage',
  pageName: 'Post-Login Homepage',
  eVar3: 'Post-Login Homepage',
  prop3: 'Post-Login Homepage',
  prop4: 'Post-Login Homepage',
  prop5: 'Post-Login Homepage',
  prop6: 'Post-Login Homepage',
  prop12: 'Post-Login Homepage',
}

export const CONTACT_US_MENU = {
  prop4: 'Menu',
  prop5: 'Menu',
  prop6: 'Menu',
}
export const CONTACT_FORM_HELP_DESK = {
  pageName: 'Contact us - Tech Supports',
  prop3: 'Contact us - Tech Supports',
}

export const SUBMITTED_ORDER = {
  pageName: 'Submitted Order Summary',
  eVar2: 'Submitted Order Summary',
  prop3: 'Orders',
  prop4: 'Top Navigation Menu',
  prop5: 'Submitted Order Summary',
  prop6: 'Top Navigation Menu',
  prop12: 'Submitted Order Summary',
  eVar14: 'Non-Browse',
  eVar15: 'Non-Browse',
  prop26: 'Submitted Order Summary',
  eVar36: 'Other Finding Method',
}
export const APPROVED_ORDER = {
  pageName: 'Recently Approved Summary',

  prop3: 'Orders',
  prop4: 'Top Navigation Menu',
  prop5: 'Approved Order Summary',
  prop6: 'Top Navigation Menu',
  prop12: 'Recently Approved Summary',
  eVar14: 'Non-Browse',
  eVar15: 'Non-Browse',
  eVar36: 'Other Finding Method',
}

export const FLYERS_DETAILS = {
  prop3: 'Online Flyer',
  eVar3: 'Online Flyer',
  prop4: 'Online Individual Flyer Page',
  prop5: 'Online Individual Flyer Page',
  prop6: 'Online Individual Flyer Page',
  prop12: 'Online Flyer',
  eVar14: 'Non-Browse',
  eVar15: 'Non-Browse',
  eVar36: 'Other Finding Method',
}

export const LANDING_PAGES = {
  eVar2: 'Special Order Form',
  prop3: 'Landing Page',
  eVar3: 'Not_assigned',
  prop4: 'Landing Page',
  prop5: 'Landing Page',
  prop6: 'Landing Page',
  prop12: 'Landing Page',
  eVar14: 'Non-Browse ',
  eVar15: 'Non-Browse ',
  prop26: 'Recall Page',
  eVar36: 'Other finding method',
}

export const CONFIRM_SPECIAL_ORDERS = {
  pageName: 'Special Order Confirmation',
  eVar2: 'Special Order Confirmation',
  prop3: 'Orders ',
  eVar3: 'Not_assigned ',
  prop4: 'Special Order Confirmation',
  prop5: 'Special Order Confirmation',
  prop6: 'Special Order Confirmation',
  prop12: 'Special Order Confirmation',
  eVar14: 'Non-Browse ',
  eVar15: 'Non-Browse ',
  prop26: 'Special Oder Confirmation',
  eVar36: 'Other Finding Method ',
}

export const INK_AND_TONER_VALUES = {
  eVar2: 'Ink, Ribbon & Toner',
  eVar3: 'Ink and Toner Finder Tool',
  prop5: 'Ink, Ribbon & Toner',
  prop26: 'Ink, Ribbon & Toner',
  eVar36: 'Ink and Toner Finder Tool',
}

export const ORDER_IN_APPROVAL = {
  pageName: IN_APPROVAL,
  prop3: 'Orders',
  prop4: IN_APPROVAL,
  prop5: IN_APPROVAL,
  prop6: IN_APPROVAL,
  prop12: IN_APPROVAL,
  eVar14: 'Non-Browse',
  eVar15: 'Non-Browse',
  eVar36: 'Other Finding Method',
}
