// DO NOT CHANGE TO IMPORT STATEMENT UNTIL NODEJS 14 >
const { DATA_DOG } = require('./logProvidersConfig')
/**
 * Gets as a boolean object which log provider app is using. Also used server side, keep require statement until we upgrade to nodejs version 14 >
 * @param {String} logProviderName - The log provider name, can be 'datadog'
 * @returns {Object}
 */
function getLogProviderByName(logProviderName = DATA_DOG) {
  const isDataDog = logProviderName === DATA_DOG
  return { isDataDog }
}
module.exports = {
  getLogProviderByName,
}
