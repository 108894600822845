import { generatePdpUrl } from '~/services/utils'
import { FREE_GIVE_AWAY } from './ProductCardConstants'
import { addCallingLocationToHref as addAdobeCallingLocationToHref } from '~/components/shared/AdobeAnalytics/AdobeAnalyticsUtils'

export function getBulkBuyShow(tieredPricing, isFlyer, showBulkBuyFromProps) {
  // If props are forcing it to be hidden, it should hide it
  if (!showBulkBuyFromProps) {
    return false
  }
  // Show it if not grid view, has tiered pricing, and not a flyer item
  const showBulkBuy = !!tieredPricing && !isFlyer

  return showBulkBuy
}

export const getProductPrices = ({
  combinedBasePrice = 0,
  combinedSellPrice = 0,
  containsPromotion,
  saving,
  isFlyer,
}) => {
  const price = combinedBasePrice
  const hasPromoAndIsNotFlyer = containsPromotion && !isFlyer
  const finalCombinedPrice = combinedBasePrice - combinedSellPrice
  const savingAmount = hasPromoAndIsNotFlyer ? saving : finalCombinedPrice
  const productPrice = Number((price - savingAmount).toFixed(2))
  return { productPrice, saving: savingAmount, price }
}

export function productIsFree(product) {
  return product.QualifierType === FREE_GIVE_AWAY
}

export function getProductUrl({
  productCode,
  queryString,
  enableInstrumentation,
  richRelevanceUrl,
}) {
  const productUrl = generatePdpUrl(productCode, queryString)
  const isProductLinkExternal = enableInstrumentation && !!richRelevanceUrl
  const productLinkUrl = isProductLinkExternal ? richRelevanceUrl : productUrl

  return productLinkUrl
}

export function generateProductUrlWithAdobeAnalytics({
  pageName,
  callingLocation,
  productCode,
  richRelevanceUrl,
  enableInstrumentation,
}) {
  let queryString = ''
  if (pageName && callingLocation?.pdpEvar3) {
    queryString = addAdobeCallingLocationToHref(
      pageName,
      callingLocation.pdpEvar3,
      true
    )
  }

  const productLinkUrl = getProductUrl({
    productCode,
    queryString,
    enableInstrumentation,
    richRelevanceUrl,
  })

  return productLinkUrl
}
