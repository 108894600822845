import { purifyHtmlString } from '~/services/utils'
import {
  ALERT_TYPES,
  MESSAGE_STATUS,
  MESSAGE_TYPE,
  REMOVE_ITEM_CART_MESSAGE,
} from './AlertNotificationConstants'

export const byErrorMessages = (message) => {
  return message.MessageStatus === MESSAGE_STATUS.ERROR
}

export const bySuccessMessages = (message) => {
  return message.MessageStatus === MESSAGE_STATUS.SUCCESS
}

export const byWarningMessages = (message) => {
  return message.MessageStatus === MESSAGE_STATUS.WARNING
}

export const byPDPMessage = (message) =>
  message.MessageType === MESSAGE_TYPE.OTHER &&
  message.MessageStatus === MESSAGE_STATUS.WARNING

// True if message is an approval type of message
export const byApprovalMessage = (x) => x.MessageType === MESSAGE_TYPE.APPROVAL
export const byPromotionMessage = (x) =>
  x.MessageType === MESSAGE_TYPE.PROMOTIONAL

/**
 * Method that checks messages contain error message
 * @param {Object} message
 */
export const byRemovedErrorMessages = (message) => {
  return (
    message.MessageStatus === MESSAGE_STATUS.ERROR &&
    message.MessageType === MESSAGE_TYPE.OTHER &&
    message.PrimaryMessage.includes(REMOVE_ITEM_CART_MESSAGE) //Checks that message contains removed message
  )
}

export const formatApprovalMessages = (MessagesCenter) => {
  return MessagesCenter.filter(byApprovalMessage).map((x) => {
    return {
      mainMessage: x.PrimaryMessage,
      secondaryMessages: x.SecondaryMessages.map((m) =>
        purifyHtmlString(m.SecondaryMessage)
      ),
    }
  })
}
export const formatPromotionalMessages = (MessagesCenter) => {
  return MessagesCenter.filter(byPromotionMessage).map((x) => {
    return {
      mainMessage: x.PrimaryMessage,
      secondaryMessages: x.SecondaryMessages.map((m) =>
        purifyHtmlString(m.SecondaryMessage)
      ),
    }
  })
}

export const formatRestrictedMessages = (MessagesCenter) => {
  return MessagesCenter.filter(byWarningMessages).map((x) => {
    return {
      mainMessage: x.PrimaryMessage,
    }
  })
}
export const formatPDPWarningMessages = (MessagesCenter) => {
  return MessagesCenter.filter(byPDPMessage).map((x) => {
    return {
      mainMessage: x.PrimaryMessage,
      secondaryMessages: x.SecondaryMessages.map((m) =>
        purifyHtmlString(m.SecondaryMessage)
      ),
    }
  })
}
export const formatServerMessages = (MessagesCenter = [], options = {}) => {
  return MessagesCenter.filter(bySuccessOrErrorMessages).map((x) => {
    if (x.SecondaryMessages) {
      return {
        message: purifyHtmlString(x.PrimaryMessage, null, options),
        secondaryMessages: x.SecondaryMessages.map((m) =>
          purifyHtmlString(m.SecondaryMessage)
        ),
        type: ALERT_TYPES[x.MessageStatus].type,
        messageType: x.MessageType,
      }
    }
    return {
      message: purifyHtmlString(x.PrimaryMessage),
      type: ALERT_TYPES[x.MessageStatus].type,
    }
  })
}

export const filterAllMessage = (messagesCenter = [], options) => {
  return formatServerMessages(messagesCenter, options).filter(Boolean)
}

/**
 * If message is error or success
 * @param {Object} message
 */
export const bySuccessOrErrorMessages = (message) =>
  byErrorMessages(message) || bySuccessMessages(message)

export const formatWithPrimaryMessageAndType = (x) => ({
  message: x.PrimaryMessage,
  type: ALERT_TYPES[x.MessageStatus].type,
})
