import { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Collapse } from 'react-collapse'

const CollapseWrapper = ({ children, ...rest }) => {
  return process.browser ? <Collapse {...rest}>{children}</Collapse> : children
}

// PropTypes validation
CollapseWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.oneOf([Fragment]),
  ]),
}

export default CollapseWrapper
