import { ENGLISH } from '~/redux/constants'
import { PRODUCT_FEE_TYPES } from '~/services/fees/products/feeProductTypes'
import { getProductAdditionalFees } from './feeProductsAdditionalFees'
import { getAssemblyFeeFromOrder } from './feeProductsAssemblyFeeFromOrder'
const {
  ENVIRONMENTAL_FEE,
  ASSEMBLY_FEE,
  DELIVERY_INSTALLATION_FEE,
  TARIF_FEE,
  TRANSACTION_FEE,
} = PRODUCT_FEE_TYPES

export const getAdditionalFees = (
  language = ENGLISH,
  item = {},
  isDeliOptSet,
  deliServOptType,
  isPreAsmbAvailable
) => {
  const { OrderProductFees = [] } = item
  let feeToDisplay = Object.values(OrderProductFees)
  if (item.IsPriceTariffFeeCombined) {
    feeToDisplay = feeToDisplay.filter((fee) => fee.FeeType !== TARIF_FEE)
  }
  if (item.IsPriceReallyWeeFeeCombined) {
    feeToDisplay = feeToDisplay.filter(
      (fee) => fee.FeeType !== ENVIRONMENTAL_FEE
    )
  }

  const assemblyFee = getAssemblyFeeFromOrder(
    language,
    item,
    isDeliOptSet,
    deliServOptType,
    isPreAsmbAvailable
  )
  const additionalFees = getProductAdditionalFees(
    [
      ...feeToDisplay.filter(
        (f) =>
          f.FeeType !== TRANSACTION_FEE &&
          f.FeeType !== DELIVERY_INSTALLATION_FEE &&
          f.FeeType !== ASSEMBLY_FEE
      ),
      ...assemblyFee,
    ],
    language,
    item.ProductCode,
    deliServOptType
  )
  return additionalFees
}
