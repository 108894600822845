import EwayRouter from 'shared/EwayRouter'
import { FREE_GIVE_AWAY, PROMOTION_QUALIFIER } from '../ProductCardConstants'
/**
 * Returns the selected value for product
 * @param {array} selectedProducts
 * @param {string} productCode
 * @returns bool
 */
export const getCheckedValue = (selectedProducts = [], productCode) =>
  !!selectedProducts &&
  !!selectedProducts.filter(({ ProductCode }) => ProductCode === productCode)
    .length

/**
 * Returns the promo info
 * @param {object} promoInfo
 * @param {number} qualifierType
 * @returns bool || string
 */
export const checkPromoInfo = (promoInfo = {}, qualifierType) => {
  const isPromotion = qualifierType === PROMOTION_QUALIFIER
  const isGiveAwayProduct = qualifierType === FREE_GIVE_AWAY
  return (isPromotion && promoInfo.ContainPromo) || isGiveAwayProduct
}

export function redirectToProductLink(productLinkUrl) {
  window.location.assign(EwayRouter.getUrl(productLinkUrl))
}
