import {
  apiRequest,
  checkArgsEmpty,
  CONTENT_TYPE_JSON,
  sendRequest,
  generateHeadersWithLanguage,
} from '~/api/apiHelper'
import { ENGLISH } from '~/redux/constants'
import { getApiUrl, getOldApiUrl } from '../utils/configUtils'
import { formatProductDetail } from '~/api/productOperations'

const OLD_API_URL = getOldApiUrl()
const API_URL = getApiUrl()

export async function getCartDetails(args) {
  const [lang] = args
  const language = lang || ENGLISH
  const url = `${OLD_API_URL}/api/cart/cartDetails`
  const { CartInfo: { Products = [], ...restCartInfo } = {}, ...rest } =
    await apiRequest(language, url)
  const productsList = formatProductDetail(Products, language)
  return { ...rest, CartInfo: { Products: productsList, ...restCartInfo } }
}

async function buildGetAlternateProductData(language) {
  const url = `${OLD_API_URL}/api/orders/getAlternateProducts`
  const headers = generateHeadersWithLanguage(language)
  const options = {
    headers,
  }
  const response = await sendRequest(url, options)

  // Doesn't return an object yet
  // REFACTOR API: API returns [] should return object- we modify response to adapt UI needs
  const { values, entries, fromEntries } = Object

  // split the products
  const products = values(response).filter((e) => typeof e === 'object')
  // get the api props - contains isSuccess, isAuthorized... etc
  const apiProps = entries(response).filter(
    ([, value]) => typeof value !== 'object'
  )

  return {
    products: formatProductDetail(products, language), // the products list
    ...fromEntries(apiProps), // contains isSuccess, authorized data etc
  }
}
export const getAlternateProductData = buildGetAlternateProductData

const buildUpdateProductPrice = async (args) => {
  const [productId, price] = args
  const url = `${API_URL}/customerOnboarding/products/${productId}/update-price/${price}`
  const options = {
    method: 'POST',
    headers: CONTENT_TYPE_JSON,
  }
  const result = await sendRequest(url, options)
  return result
}

export const updateLinePrice = checkArgsEmpty(buildUpdateProductPrice)
