import { adobeAnalyticsPlugins } from 'components/shared/AdobeAnalytics/AdobeAnalyticsPlugins'
import {
  BOX_FINDER_PAGE_VALUES,
  INK_AND_TONER_FINDER_VALUES,
} from 'components/shared/AdobeAnalytics/AdobeAnalyticsValuesGenerator/Search/AdobeAnalyticsSearchConstants'
import omit from 'lodash/omit'
import { useEffect } from 'react'
import {
  addQueryString,
  checkKeysEmpty,
  containsHostUrl,
  fromEntries,
  handleAsyncError,
  isEmptyArray,
} from 'services/utils'
import { getAnalyticsAccountFromEnv } from 'services/utils/configUtils'
import {
  ACTION_TYPE,
  CALLING_LOCATION,
  CATEGORY,
  CONFIRM_SPECIAL_ORDERS,
  EXTERNAL_CAMPAIGNS,
  FLYERS_DETAILS,
  INK_AND_TONER_VALUES,
  INTERNAL_CAMPAIGNS,
  PAGE_NAME,
  PAGE_TYPE,
  SEARCH_CALLING_LOCATION,
  SPECIAL_ORDER,
} from 'shared/AdobeAnalytics/AdobeAnalyticsConstants'
import { PAGE_NAMES } from 'shared/Utils/constants'
import {
  ICON_COPY,
  MOVE_TO_CART,
} from '~/components/containers/OrderDetailTemplate/OrderDetailTemplateConstant'
import {
  COPY_TO_CART_VALUES,
  MOVE_TO_CART_ADD_VALUES,
  MOVE_TO_CART_REMOVE_VALUES,
} from '~/components/shared/AdobeAnalytics/values/adobeAnalyticsOrderDetailTemplate'
import { SHOPPING_LISTS_ADD_TO_CART_VALUES } from '~/components/shared/AdobeAnalytics/values/adobeAnalyticsShoppingLists'
import { isDev, isProduction, isTest } from '~/config/env/environmentUtils'
import { getLocalStorage } from '~/services/storageManager'
import { isHostedInOldEway } from '../EwayRouter'
import {
  getCidCampaign,
  getIcidCampaign,
} from './AdobeAnalyticsQueryParamUtils'
import { removeDuplicates } from './AdobeAnalyticsValuesGenerator'
import {
  getProp4,
  removeFalsy,
} from './AdobeAnalyticsValuesGenerator/Search/AdobeAnalyticsSearchUtils'
import { COMPARE_PRODUCTS_ADD_TO_CART } from './values/adobeAnalyticsCompareProducts'
import { globalAnalyticsSettings } from './values/adobeAnalyticsGlobalSettings'
import returnConfirmAnalyticsValues from './values/adobeAnalyticsReturnConfirmation'
import { getOldEwayFullLocale } from 'services/utils/languageUtils'
import { ENGLISH } from '~/redux/constants'

const url = require('url')

const WAITING_MILLISECONDS = 4000 // Time to wait to check if tracking is ready in milliseconds
const WAITING_SECONDS = WAITING_MILLISECONDS / 1000 // Time to wait in seconds
const EVENTS = 'events'
const SHOPPING_LIST_PRODUCT_KEY = 'ProductId'
const TEMPLATE_DETAIL_PRODUCT_KEY = 'ProductCode'
/**
 * Allows you to show some of the adobe analytics warnings
 */
const DEBUG_ANALYTICS = false

/**
 * adobe status is the variable that enables the sendTrackingInformation method
 * is also used in _app.js to disable the analytics service globally in the app.
 */
let adobeStatus

let analyticsFirstCallCurrentStatus = null

const TEMPLATE_ANALYTICS_STATUS = {
  [ICON_COPY]: false,
  [MOVE_TO_CART]: false,
}

/**
 * Expects a bool, if set to true, you will be able to send AdobeAnalytics calls
 * If set to false, "sendTrackingInformation" will not function.
 * @param {bool} status - The boolean that enables or disable the AdobeAnalytics component
 */
export const setAdobeInstanceStatus = (status = true) => {
  adobeStatus = status
}

const getAdobeAnalyticsStatus = () => {
  return adobeStatus
}
/**
 * Configures the analytics
 * @param {Object} ssrRenderedValues different analytics that come from server
 * @returns The account name
 */
export const configureAnalytics = (ssrRenderedValues = {}) => {
  const { account: accountValue } = ssrRenderedValues
  const account = accountValue || getAnalyticsAccountFromEnv()
  const instance = createAnalyticsInstance(account)
  if (instance) {
    const formattedValues = formatAnalyticsVariables(ssrRenderedValues)
    addPluginsToAnalyticsInstance(instance)
    updateAnalyticsValuesWith(formattedValues)
    addAdobeAnalyticsPreviousPageNameValue(instance, ssrRenderedValues)
  }
  return account
}

const addAdobeAnalyticsPreviousPageNameValue = (values = {}) => {
  const instance = getAnalyticsInstance()
  if (instance) {
    instance.prop26 = instance.getPreviousValue(values.pageName, 'gpv_pn', '')
  }
}

const addPluginsToAnalyticsInstance = (instance) => {
  if (globalAnalyticsSettings().usePlugins) {
    process.browser ? addDoPlugins(instance) : adobeAnalyticsPlugins(instance)
  }
  if (instance) {
    instance.getPreviousValue = new Function(
      'v',
      'c',
      'el',
      '' +
        "var s=this,t=new Date,i,j,r='';t.setTime(t.getTime()+1800000);if(el" +
        "){if(s.events){i=s.split(el,',');j=s.split(s.events,',');for(x in i" +
        '){for(y in j){if(i[x]==j[y]){if(s.c_r(c)) r=s.c_r(c);v?s.c_w(c,v,t)' +
        ":s.c_w(c,'no value',t);return r}}}}}else{if(s.c_r(c)) r=s.c_r(c);v?" +
        "s.c_w(c,v,t):s.c_w(c,'no value',t);return r}"
    )
  }
}

/**
 * https://docs.adobe.com/content/help/en/analytics/implementation/javascript-implementation/appmeasurement-js/appmeasure-mjs.html
 * Throughout your code, call the global s_gi() function to retrieve an existing AppMeasurement instance.
 */

export const hasAnalyticsInstance = (account) => {
  return !!getAnalyticsInstance(account)
}

/**
 * Set and get adobe analytics called first time status
 * @param {boolean} status
 */
export const adobeAnalyticsFirstCallStatus = (status = null) => {
  if (status === null) {
    return analyticsFirstCallCurrentStatus
  }
  analyticsFirstCallCurrentStatus = status
}

/**
 * Check adobe analytics enabled status from ccs setting
 * @param {boolean} isLoggedIn
 * @param {object} settings
 */
export const isAdobeAnalyticsEnabled = (isLoggedIn, settings) => {
  const { enableWebAnalyticsInstrumentation } = settings
  return isLoggedIn ? enableWebAnalyticsInstrumentation : true
}

/**
 * Function updates all of the Analytics value singleton (in browser)
 * Go see Analytics.spec.js to understand usage
 * Be careful NOT to spread values in this following fashion:
 *
 *  browserInstance = {
 *     ...getAnalyticsInstance(),
 *     ...values
 *  }
 *
 *  This would delete the existing singleton instance in the browser by creating a new object, yet, crushing the singleton that already exists in browser.
 *  The consequence being that your analytics object will only be partially sent.
 */
export const updateAnalyticsValuesWith = (values = {}) => {
  const analyticValues = formatAnalyticsVariables(values)
  const browserInstance = getAnalyticsInstance()
  if (browserInstance) {
    Object.entries(analyticValues).forEach((analyticVariable) => {
      const [key, value] = analyticVariable

      if (key === EVENTS) {
        const eventNames = [browserInstance[EVENTS], value]
          .filter(Boolean)
          .join()
        const eventNamesArr = eventNames.split(',')
        browserInstance[EVENTS] = removeDuplicates(eventNamesArr)
          .filter(Boolean)
          .join()
      } else {
        browserInstance[key] = value
      }
    })
  }
  return browserInstance
}

export const isAdobeAnalyticsTrackingReady = () => {
  return !!('s_gi' in window && window.s_gi)
}

let cacheInterVal = null
const analyticsCaches = []
let cacheStartTime = 0

/**
 * Use to cache analytics values before it will be ready
 * and send cached analytics when it will be ready
 * @param {object} analyticsValues
 */
function cacheAnalytics(analyticsValues) {
  analyticsCaches.push(analyticsValues)

  if (cacheInterVal !== null) {
    return
  }

  // Start waiting for the tracking to be ready
  cacheStartTime = new Date().getTime()
  cacheInterVal = setInterval(() => {
    const greaterThanWaitingSeconds =
      new Date().getTime() - cacheStartTime > WAITING_MILLISECONDS
    if (greaterThanWaitingSeconds) {
      analyticsCaches.splice(0, analyticsCaches.length)
      clearInterval(cacheInterVal)
    }

    if (!isAdobeAnalyticsTrackingReady()) {
      return
    }
    clearInterval(cacheInterVal)
    analyticsCaches.forEach((analyticsCache) => {
      updateAndSendTrackingWith(analyticsCache)
    })
    analyticsCaches.splice(0, analyticsCaches.length)
  }, 100)
}

export const updateAndSendTrackingWith = async (values = {}) => {
  if (!isAdobeAnalyticsTrackingReady()) {
    cacheAnalytics(values)
    return
  }
  await checkAndConfigureAnalytics(values)
  updateAnalyticsValuesWith(values)
  sendTrackingInformation()
}
export const updateAndSendTrackingWithSimple = async (values = {}) => {
  updateAnalyticsValuesWith(values)
  sendTrackingInformation()
}

export const updateAndSendTrackingWithAddToCart = async (values = {}) => {
  const instanceClone = getCloneBrowserInstance()
  resetAnalyticsVarsAndEvents()
  updateAnalyticsValuesWith(values)
  sendTrackingInformation()
  resetAnalyticsVarsAndEvents()
  restoreBrowserInstanceWithOldValue(instanceClone)
}

const getCloneBrowserInstance = () => {
  const browserInstance = getAnalyticsInstance()
  const newInstance = {}
  if (browserInstance) {
    const props = getAnalyticsProps()
    for (const key in browserInstance) {
      // TODO: Find out why we're cloning the instance.
      // eslint-disable-next-line no-prototype-builtins
      if (browserInstance.hasOwnProperty(key)) {
        const result = props.findIndex((prop) => {
          return key.startsWith(prop)
        }, key)
        if (result !== -1) {
          newInstance[key] = browserInstance[key]
        }
      }
    }
  }
  return newInstance
}

const restoreBrowserInstanceWithOldValue = (instanceClone) => {
  const browserInstance = getAnalyticsInstance()
  if (browserInstance) {
    const props = getAnalyticsProps()
    for (const key in instanceClone) {
      // TODO: Find out why this method seems duplicated and similar to "getCloneBrowserInstance"
      // eslint-disable-next-line no-prototype-builtins
      if (instanceClone.hasOwnProperty(key)) {
        const result = props.findIndex((prop) => {
          return key.startsWith(prop)
        }, key)
        if (result !== -1) {
          browserInstance[key] = instanceClone[key]
        }
      }
    }
  }
}

/**
 * Function that checks if the analytics is ready
 */
export const checkTracking = () => {
  if (isTest()) {
    return Promise.resolve(true)
  }
  return new Promise((resolve, reject) => {
    const startTime = new Date().getTime()
    const interval = setInterval(() => {
      const greaterThanWaitingSeconds =
        new Date().getTime() - startTime > WAITING_MILLISECONDS

      if (greaterThanWaitingSeconds) {
        clearInterval(interval)
        return reject(`
            Could not create instance of Adobe Analytics in less than ${WAITING_SECONDS} seconds, did you forget to include the App Measurement Script?
            Read more here: https://docs.adobe.com/content/help/en/analytics/implementation/javascript-implementation/appmeasure-mjs-pagecode.html
        `)
      }
      isAdobeAnalyticsTrackingReady() &&
        resolve(true) &&
        clearInterval(interval)
    }, 100)
  })
}

export const checkTrackingIsReady = handleAsyncError(checkTracking)

export const createAnalyticsInstance = (account) => {
  if (isDev() && !account) {
    throw new Error('Cannot enable Adobe Analytics Tracking, no account passed')
  }
  return window?.s_gi?.(account)
}

export const getAnalyticsInstance = () => {
  const isEnabled = getAdobeAnalyticsStatus()
  // This means the instance was disabled via settings
  if (DEBUG_ANALYTICS && !isEnabled && isDev()) {
    // eslint-disable-next-line no-console
    console.warn(
      `Adobe Analytics was disabled by setting the adobeStatus to false, cannot send tracking`
    )
    return
  }
  const account = getAnalyticsAccountFromEnv()
  return getSafely(() => window?.s_gi?.(account))
}

const getSafely = (callback) => {
  try {
    return callback()
  } catch (_) {
    if (DEBUG_ANALYTICS && isDev()) {
      throw new Error(
        `Adobe Analytics tracking instance does not exist. cannot call Adobe Tracking, make sure <AdobeAnalytics /> is included in the page, with the proper script. Go see <AdobeAnalytics /> for more details`
      )
    }
  }
}
export const getNewEventValues = (events, oldValue, newValue) => {
  return { events: events?.replace(oldValue, newValue) }
}

export const replaceEventValueInBrowser = (oldValue, newValue) => {
  const browserInstance = getAnalyticsInstance()
  if (browserInstance) {
    const { events } = browserInstance
    const newEvents = events.replace(oldValue, newValue)
    browserInstance[EVENTS] = newEvents
    return browserInstance
  }
}
/**
 *
 * Sets all the evars to undefined
 * https://docs.adobe.com/content/help/en/analytics/implementation/javascript-implementation/function-clearvars.html
 * Please note that on old Analytics this method doesn't work (.ie H.26 version)
 *
 */
export const resetAnalyticsVarsAndEvents = () => {
  const instance = getAnalyticsInstance()
  if (instance) {
    handleAddingClearVarsOnOlderVersion(instance) // Ads clear vars for older scripts.
    return instance.clearVars()
  }
}

/**
 * Method that checks analytics is ready and configures them
 * @param {*} values
 */
async function checkAndConfigureAnalytics(values = {}) {
  const analyticsIsReady = await checkTrackingIsReady()
  if (analyticsIsReady) {
    configureAnalytics(values)
  }
}

/**
 *
 * Method now only formats evars, to eVars, more to come.
 */
export function formatAnalyticsVariables(values = {}) {
  const formattedEntries = Object.entries(values).map((entry) => {
    const [key, value] = entry
    if (key.toLowerCase().includes('evar')) {
      return formatEvar(key, value)
    }
    return entry
  })
  return Object.fromEntries(formattedEntries)
}

/**
 *  Sends the tracking pixel to adobe analytics
 *  https://docs.adobe.com/content/help/en/analytics/implementation/javascript-implementation/function-t.html#section_F75C7BD4A8954CD5BE066C6B88A4A01C
 *
 * Sends out the the tracking pixel via our Adobe Analytics implementation,
 * can be inspected in networks tabs, filter it with "b/ss"
 */
export const sendTrackingInformation = () => {
  const instance = getAnalyticsInstance()
  if (instance) {
    instance.t()
  }
}

export async function checkAdobeAnalyticsInstanceAvailable() {
  const STOP_AFTER_10_SECONDS = 10000
  let interval
  let timeout

  return new Promise((resolve) => {
    interval = setInterval(() => {
      const instance = getAnalyticsInstance()
      if (instance?.t) {
        resolve(true)
        clearInterval(interval)
        clearTimeout(timeout)
      }
    }, 500)

    timeout = setTimeout(() => {
      // eslint-disable-next-line no-console
      console.warn(
        'Could not instantiate Adobe analytics after more than 10 seconds, check configurations'
      )
      clearInterval(interval)
      resolve(false)
    }, STOP_AFTER_10_SECONDS)
  })
}

function addDoPlugins() {
  const instance = getAnalyticsInstance()
  if (instance) {
    instance.doPlugins = adobeAnalyticsPlugins
    // Force doPlugins to be ran in test .env for now
    if (isTest()) {
      adobeAnalyticsPlugins(instance)
    }
  }
}

function formatEvar(key, value) {
  const number = key.replace(/[^0-9\\.]/g, '')
  return [`eVar${number}`, value]
}

export function getAdobeAnalyticsSentValues(
  allowedKeys = ['evar', 'prop', 'pagename', 'channel', 'event', 'campaign']
) {
  const analyticsInstance = getAnalyticsInstance()
  if (analyticsInstance) {
    const entries = Object.entries(analyticsInstance)
      .map((entry) => [entry[0].toLowerCase(), entry[1]])
      .filter((entry) => {
        const [key] = entry
        return allowedKeys.some((allowedKey) => key.includes(allowedKey))
      })
    return fromEntries(entries)
  }
}

const getNavBarItemType = (arrValues) => {
  let pageType = ''
  if (arrValues.length > 2) {
    pageType = PAGE_TYPE.CLASS
  } else if (arrValues.length > 1) {
    pageType = PAGE_TYPE.DEPARTMENT
  } else {
    pageType = PAGE_TYPE.CATEGORY
  }
  return pageType
}

export const addCallingLocationToHref = (
  pageName,
  callingLocation,
  isQuestionMark = false
) =>
  isQuestionMark
    ? `?${PAGE_NAME}=${pageName}&${CALLING_LOCATION}=${callingLocation}`
    : `${PAGE_NAME}=${pageName}&${CALLING_LOCATION}=${callingLocation}`

export const addCategoryQueryString = (
  url,
  arrValues,
  isBrowseCallingLocation = false
) => {
  if (!url || !containsHostUrl(url)) {
    return url
  }
  // Ads the calling location to the href
  let locationQueryStr
  if (isBrowseCallingLocation) {
    locationQueryStr = addCallingLocationToHref(
      PAGE_NAMES.HOME,
      SEARCH_CALLING_LOCATION.BROWSE
    )
  } else {
    locationQueryStr = addCallingLocationToHref(
      PAGE_NAMES.HOME,
      SEARCH_CALLING_LOCATION.NAV_BAR
    )
  }

  const pageType = getNavBarItemType(arrValues)
  const catQueryStr = `${CATEGORY}=${[pageType, ...arrValues].join(':')}`
  const queryStr = [locationQueryStr, catQueryStr].join('&')
  const newUrl = addQueryString(url, queryStr)
  return newUrl
}

export function encodeValues(values = []) {
  return values.map((item) => encodeURIComponent(item))
}

export const updateBreadCrumbsForAdobeAnalytics = (breadcrumbs = []) => {
  breadcrumbs?.forEach((breadcrumb) => {
    breadcrumb.link = addQueryString(
      breadcrumb.link,
      `${CALLING_LOCATION}=${SEARCH_CALLING_LOCATION.BREADCRUMB}`
    )
  })

  return breadcrumbs
}

/**
 * Method that ads ClearVars on the Adobe Analytics Instance,
 * If necessary
 * @param {Object} instance the adobe analytics instance
 */
function handleAddingClearVarsOnOlderVersion(instance) {
  if (clearVarsDoesNotExistOn(instance)) {
    addClearVars(instance)
  }
}

/**
 * Checks that clearVars exists on the object
 * @param {Object} instance the adobe analytics instance
 */
function clearVarsDoesNotExistOn(instance) {
  return typeof instance.clearVars !== 'function'
}

/**
 * Methods inspired from the actual AdobeAnalytics recent script found in
 * "static/scripts/analytics-script"
 */
function addClearVars(instance) {
  instance.clearVars = () => clearVarsMimic(instance)
}

/**
 * Polyfill for clearVars
 * @param {Object} instance the adobe analytics instance
 */
function clearVarsMimic(instance) {
  const propsToClear = getAnalyticsProps()
  for (const key in instance) {
    const result = propsToClear.findIndex((prop) => {
      return key.startsWith(prop)
    }, key)
    if (result !== -1) {
      delete instance[key]
    }
  }
}

const getAnalyticsProps = () => [
  'prop',
  'eVar',
  'hier',
  'list',
  'channel',
  'events',
  'eventList',
  'products',
  'productList',
  'purchaseID',
  'transactionID',
  'state',
  'zip',
  'campaign',
]

/**
 *
 * @param {*} data - Expects and object with data.AnalyticsProperties = {}
 * @param {*} defaultValue - Some object to add additional analytics values
 */
export const sendAdobeAnalyticValues = async (data, defaultValue = {}) => {
  const analyticsProperties = data?.AnalyticsProperties || {}
  if (checkKeysEmpty(analyticsProperties)) {
    return
  }
  const isReady = await checkTrackingIsReady()
  if (isReady) {
    resetAnalyticsVarsAndEvents()
    configureAnalytics()
    updateAndSendTrackingWith({ ...analyticsProperties, ...defaultValue })
  }
}

export const generateAdobeAnalyticsEvents = (
  revenue,
  units,
  actionType,
  lastSetUnits
) => {
  let eventString = ''
  if (revenue) {
    if (actionType === ACTION_TYPE.REJECT) {
      eventString += `event55 = ${revenue}`
    } else if (checkEvents(actionType)) {
      eventString += `event67 = ${revenue}`
    } else {
      eventString += `event35 = ${revenue}`
    }
  }
  if (eventString !== '') {
    eventString += `|`
  }
  if (units) {
    const updatedUnits =
      lastSetUnits && [ACTION_TYPE.ADD, ACTION_TYPE.REDUCE].includes(actionType)
        ? Math.abs(Number(lastSetUnits) - Number(units))
        : units

    if (actionType === ACTION_TYPE.REJECT) {
      eventString += `event54 = ${updatedUnits}`
    } else if (checkEvents(actionType)) {
      eventString += `event68 = ${updatedUnits}`
    } else {
      eventString += `event36 = ${updatedUnits}`
    }
  }
  return eventString
}

const checkEvents = (actionType) => {
  return [
    ACTION_TYPE.DELETE,
    ACTION_TYPE.REMOVE,
    ACTION_TYPE.CLEAR_CART,
    ACTION_TYPE.REDUCE,
  ].includes(actionType)
}

/**
 * Set and get adobe analytics called first time status
 * @returns boolean
 */
export const fistCallAdobeAnalytics = (
  activeStepId,
  updateStatus,
  status = null
) => {
  if (status === null) {
    return updateStatus[activeStepId]
  }
  updateStatus[activeStepId] = status
}

const locations = {
  SearchByKeyword: 'SEARCH',
  GetShoppingListDetails: 'Shopping List',
  Category: 'Browse',
}

const getLocationName = (url) => {
  const baseUrl = (url && url.split('?')[0]) || ''
  const keys = Object.keys(locations)
  const pageName = baseUrl.split('/').find((item) => {
    const index = keys.indexOf(item)
    return index !== -1 && keys[index]
  })
  return pageName && locations[pageName]
}

export const getCallingLocation = (adobeAnalytics) => {
  // eslint-disable-next-line no-unused-vars
  const { events = '', ...data } = adobeAnalytics || {}
  const storageURL = getLocalStorage('browseProductUrl')
  const locationName = getLocationName(storageURL)
  return {
    ...data,
    eVar3: locationName,
    ...COMPARE_PRODUCTS_ADD_TO_CART,
  }
}

// Shopping Lits add-to-cart Adobe Analytics is calling from here.
export const shoppingListsAdobeAnalytics = (
  adobeAnalytic,
  productData = []
) => {
  const values = {
    ...adobeAnalytic,
    ...generateProductAndEventsForAnalytics(
      productData?.BasketInfo?.BasketProducts,
      ACTION_TYPE.ADD,
      SHOPPING_LIST_PRODUCT_KEY
    ),
    ...SHOPPING_LISTS_ADD_TO_CART_VALUES,
  }
  callShoppingListsAnalytics(values)
}

const callShoppingListsAnalytics = (values = {}) => {
  const data = {
    AnalyticsProperties: {
      ...values,
    },
  }
  sendAdobeAnalyticValues(data)
}

// Call Adobe Analytics for copy-to-cart and move-to-cart for Order Detail Template page
export const orderDetailAdobeAnalytics = (
  templateData,
  adobeAnalytics,
  location = ''
) => {
  if (location === ICON_COPY) {
    sendAdobeAnalyticsValues(
      adobeAnalytics,
      templateData,
      ACTION_TYPE.ADD,
      COPY_TO_CART_VALUES,
      ICON_COPY
    )
  }
  if (location === MOVE_TO_CART) {
    sendAdobeAnalyticsValues(
      adobeAnalytics,
      templateData,
      ACTION_TYPE.ADD,
      MOVE_TO_CART_ADD_VALUES
    )
    /**
     * Here two Analytics are calling in the same function. So we need to set delay for update \
     * the Events and vars in Analytics
     */
    setTimeout(() => {
      sendAdobeAnalyticsValues(
        adobeAnalytics,
        templateData,
        ACTION_TYPE.REMOVE,
        MOVE_TO_CART_REMOVE_VALUES,
        MOVE_TO_CART
      )
    }, 1000)
  }
}

const sendAdobeAnalyticsValues = (
  adobeAnalytics,
  templateData,
  actionType,
  value,
  callStatus = ''
) => {
  const values = {
    ...adobeAnalytics,
    ...generateProductAndEventsForAnalytics(
      templateData,
      actionType,
      TEMPLATE_DETAIL_PRODUCT_KEY
    ),
    ...value,
  }
  callCopyAndMoveToCartAnalytics(values, callStatus)
}

const callCopyAndMoveToCartAnalytics = (values, activeEvent = '') => {
  const data = {
    AnalyticsProperties: values,
  }
  if (fistCallAdobeAnalytics(activeEvent, TEMPLATE_ANALYTICS_STATUS)) {
    return
  }
  fistCallAdobeAnalytics(activeEvent, TEMPLATE_ANALYTICS_STATUS, true)
  sendAdobeAnalyticValues(data)
}

const generateProductAndEventsForAnalytics = (
  productData,
  actionType,
  productKey
) => {
  const adobeAnalyticData = {}
  if (productData && !isEmptyArray(productData)) {
    if (actionType === ACTION_TYPE.ADD) {
      adobeAnalyticData.products = generateProductForAdobeAnalytics(
        productData,
        ACTION_TYPE.ADD,
        productKey
      )
    }
    if (actionType === ACTION_TYPE.REMOVE) {
      adobeAnalyticData.products = generateProductForAdobeAnalytics(
        productData,
        ACTION_TYPE.REMOVE,
        productKey
      )
    }
  }
  return adobeAnalyticData
}

export const generateProductForAdobeAnalytics = (
  productData,
  actionType,
  productKey,
  updatedQty = null,
  lastSetQuantity = null
) => {
  let mergeProducts = ''
  productData.map((data, index) => {
    const adobeAnalyticsProducts = {
      category: '',
      product: '',
      quantity: '',
      price: '',
      eventIncrement: '',
    }
    adobeAnalyticsProducts.product = data[productKey]
    adobeAnalyticsProducts.eventIncrement = generateAdobeAnalyticsEvents(
      data.UnitSellPrice,
      updatedQty || data.Quantity,
      actionType,
      lastSetQuantity
    )
    mergeProducts += Object.keys(adobeAnalyticsProducts)
      .map(function (k) {
        return adobeAnalyticsProducts[k]
      })
      .join(';')
    if (productData.length !== index + 1) {
      mergeProducts += ','
    }
  })
  return mergeProducts
}

// Rebates and Offers Adobe Analytics is calling from here.
export const rebatesAndOffersAdobeAnalytics = (adobeAnalytics) => {
  const data = {
    AnalyticsProperties: {
      ...adobeAnalytics,
      eVar3: 'Rebates',
    },
  }
  sendAdobeAnalyticValues(data)
}
export const generateAnalyticsProductCodeList = (productCodes = []) => {
  return productCodes.map((code) => `;${code}`).join(',')
}

export function generateAdobeAnalyticsProductsFromOrder(order = {}) {
  const orderProductsCodeList = order.orderProducts?.map((p) => p.productId)
  const products = generateAnalyticsProductCodeList(orderProductsCodeList)
  return products
}

export function getParamFromUrl(url, param) {
  const urlParams = new URLSearchParams(url)
  const result = urlParams.get(param) || urlParams.get(param.toUpperCase())
  return result || ''
}

export function safeShow(_, word, page) {
  return page ? `${word}${page}` : `${word}`
}

export function getFullUrl(req = {}) {
  if (process.browser) {
    return window.location.href
  }
  const absoluteUrlFromHeaders = req?.headers?.absoluteuri
  return isHostedInOldEway()
    ? absoluteUrlFromHeaders
    : url.format({
        protocol: req?.protocol,
        host: req?.headers?.host,
        pathname: req?.originalUrl,
      })
}

export function handleSearchBrowseAdobeAnalytics({
  productCount,
  pageNumber,
  analytics = {},
  inkAndTonerSearch,
  isBoxRequest,
  isInkAndTonerFinder,
  query = {},
  buyer = {},
  language = ENGLISH,
}) {
  const { callingLocation, q, category = '' } = query

  let searchTrackingValues = {}
  let analyticsValue = analytics
  if (isBoxRequest) {
    searchTrackingValues = BOX_FINDER_PAGE_VALUES
  } else if (inkAndTonerSearch) {
    searchTrackingValues = INK_AND_TONER_VALUES
  } else if (isInkAndTonerFinder) {
    searchTrackingValues = INK_AND_TONER_FINDER_VALUES
    if (!analyticsValue) {
      analyticsValue = {
        prop3: 'Search Result',
        eVar18: buyer?.buyerId,
        prop19: getOldEwayFullLocale(language),
        eVar35: getOldEwayFullLocale(language),
      }
    }
  } else if (callingLocation === 'browse') {
    const splitCat = category.split(':')[1] || ''
    const selectedCategory = splitCat
    searchTrackingValues = {
      ...getBrowseResultHybridEvents(q, selectedCategory),
    }
  }
  const values = {
    ...analyticsValue,
    prop4: getProp4(pageNumber) || null,
    prop2: productCount || null,
    ...searchTrackingValues,
  }
  // Omit the Technology Type
  const cleanedValues = omit(values, 'prop28')

  // Spread the values
  updateAnalyticsValuesWith({ ...cleanedValues })
  // Send the Adobe Analytics.
  sendTrackingInformation()
}

/**
 * Custom Hook that ads debug methods for AdobeAnalytics
 * Do not move to /hooks folder, since it's only used for Analytics.
 */
export function useAnalyticsDebugFunctions() {
  useEffect(() => {
    if (!isProduction() && process.browser) {
      // Make some methods accessible via debug
      window.showAnalyticsSentValues = getAdobeAnalyticsSentValues
      window.showAnalyticsSentValuesJSON = (filter) =>
        JSON.stringify(getAdobeAnalyticsSentValues(filter), true, 2)
      window.sendTrackingInformation = sendTrackingInformation
    }
  }, [])
}

export function handleFlyerDetailAdobeAnalytics(title = '') {
  updateAnalyticsValuesWith({
    pageName: title,
    ...FLYERS_DETAILS,
  })
  sendTrackingInformation()
}

export function getCampaignAdobeVars() {
  // if cid is truthy means there's an external campaign
  // if Icid is truthy means there's an internal campaign
  const { icidValue, cidValue, hasExternalCampaign } = getAdobeCampaignValues()
  const eVar0 = hasExternalCampaign ? cidValue : ''
  const eVar4 = icidValue || ''
  return { eVar4, eVar0 }
}

export function getAdobeCampaignValues() {
  const cidValue = getCidCampaign()
  const icidValue = getIcidCampaign()
  const hasInternalCampaign = !!icidValue
  const hasExternalCampaign = !!cidValue
  return { icidValue, cidValue, hasInternalCampaign, hasExternalCampaign }
}

export function handleAdobeAddToCartCampaignValues() {
  const result = getAdobeCampaignValues()
  const value = getSearchCallingLocation(result)
  return value
}

function getSearchCallingLocation({
  hasInternalCampaign,
  hasExternalCampaign,
}) {
  if (hasInternalCampaign) {
    return INTERNAL_CAMPAIGNS
  } else if (hasExternalCampaign) {
    return EXTERNAL_CAMPAIGNS
  }
  return ''
}

export function removeAdobeAnalyticsEvent(event) {
  const instance = getAnalyticsInstance()
  if (instance && instance?.events) {
    instance.events = instance.events
      .split(',')
      .filter((e) => e !== event)
      .join(',')
  }
}

export function addAdobeAnalyticsEvent(event) {
  const instance = getAnalyticsInstance()
  const newEvents = [event, ...instance.events.split(',')].join(',')
  instance.events = newEvents
}

export function handleBrowseProductAdobeAnalyticsPageTracking() {
  const instance = getAnalyticsInstance()
  if (instance && !instance.events.includes('event4')) {
    addAdobeAnalyticsEvent('event4')
  }
}

export function getConfirmationDynamicValue(type) {
  if (type === SPECIAL_ORDER) {
    return CONFIRM_SPECIAL_ORDERS
  }
  return returnConfirmAnalyticsValues
}
export function handleAdobeProductFindingMethod() {
  const { eVar4, eVar0 } = getCampaignAdobeVars()
  const values = removeFalsy({ eVar4, campaign: eVar0, eVar0 })
  updateAnalyticsValuesWith(values)
}

export function setAdobeAnalyticsEventsEmpty() {
  const instance = getAnalyticsInstance()
  if (instance) {
    instance.events = ''
    instance.prop26 = ''
  }
}

function getBrowseResultHybridEvents(searchCategory, selectedCategory) {
  return {
    pageName: `${searchCategory} Browse Results`,
    channel: 'Browse Results',
    events: '',
    prop1: '',
    eVar1: 'Non-search',
    eVar2: 'Post-Login Homepage',
    prop3: 'Browse Results',
    eVar3: 'Browse',
    eVar4: `Non-Internal Campaign`,
    eVar14: selectedCategory,
    prop5: searchCategory,
    prop6: searchCategory,
    prop7: 'Category',
    prop8: searchCategory,
    eVar15: searchCategory,
    eVar36: 'Other Finding Method',
  }
}

export function getConfirmShippingBillingDynamicValue(id) {
  return {
    pageName: `${id}: Vendor First Step Page`,
    eVar1: 'Non-search',
    eVar2: 'Special Order Form',
    prop3: 'Vendor First Step',
    eVar3: 'Not_assigned',
    prop4: `${id}: Vendor First Step Page`,
    prop5: `${id}: Vendor First Step Page`,
    prop6: `${id}: Vendor First Step Page`,
    prop12: `${id}: Vendor First Step Page`,
    eVar14: 'Non-Browse',
    eVar15: 'Non-Browse',
    eVar36: 'Other Finding Method',
  }
}

export function confirmUserAddressAdobeAnalytics(id, isSuccess) {
  const trackingData = {
    ...getConfirmShippingBillingDynamicValue(id),
    pageName: `${id}: ${isSuccess ? 'success' : 'unsuccessful'}`,
  }
  updateAnalyticsValuesWith({
    ...trackingData,
  })
  sendTrackingInformation()
}
