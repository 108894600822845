// Created in order to prevent navigation utils issues
//  https://github.com/jsdom/jsdom/issues/2112
export function windowReload() {
  window.location.reload()
}

export function windowHref(href) {
  if (!href) {
    return
  }
  window.location.href = href
}
