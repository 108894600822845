export default {
  eVar1: 'Non-Search',
  eVar3: 'Not_assigned',
  eVar14: 'Non-Browse',
  eVar15: 'Non-Browse',
  eVar36: 'Other Finding Method',
  eVar71: 'Order Return Form',
  prop3: 'Orders',
  prop4: 'Top Navigation Menu',
  prop5: 'Order',
  prop6: 'Return Form',
  events: 'event90',
  pageName: 'Request for Return Authorization',
  channel: 'Returns',
}
