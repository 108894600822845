import { isDev } from '~/config/env/environmentUtils'
import {
  BANNER_CID,
  BANNER_ICID,
  CALLING_LOCATION,
  SEARCH_CALLING_LOCATION,
} from 'shared/AdobeAnalytics/AdobeAnalyticsConstants'
import { getParamFromUrl } from './AdobeAnalyticsUtils'

export const getQueryVariable = (variable = '') => {
  const query = window?.location?.search?.substring(1)
  const param = variable?.toLowerCase()
  const vars = query?.split('&') || []
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=')
    if (pair[0] && pair[0].toLowerCase() === param) {
      return pair[1] || ''
    }
  }
  return ''
}

export function getIcidCampaign() {
  const value = getQueryVariable(BANNER_ICID)
  // Using double because we're not getting the expected result in one decoding
  // e.g icid=Test%253AValue.
  return doubleDecode(value)
}

export function getCallingLocationFromUrl(url) {
  const callingLocation = getParamFromUrl(url, CALLING_LOCATION)
  return getCallingLocationNew(callingLocation)
}

export function getCidCampaign() {
  const value = getQueryVariable(BANNER_CID)
  // Using double because we're not getting the expected result in one decoding
  // e.g icid=Test%253AValue.
  return doubleDecode(value)
}

export function getIcidCampaignFromUrl(url) {
  return getParamFromUrlWithDoubleDecode(url, 'icid')
}

export function getCategories(url) {
  const decodedUrl = doubleDecode(url)
  return getCategoryFromUrl(decodedUrl)?.split(':') || []
}

export function getCategoryFromUrl(url) {
  return getParamFromUrl(url, 'category')
}

export function getCidCampaignFromUrl(url) {
  return getParamFromUrlWithDoubleDecode(url, 'cid')
}

export const getParamFromUrlWithDoubleDecode = (
  encodedUrl = '',
  param = ''
) => {
  const url = doubleDecode(encodedUrl)
  const index = url.indexOf('?')
  const finalUrl = Array.from(url).slice(index, url.length).join('')

  const urlLowerCase = finalUrl.toLowerCase()
  const paramLowerCase = param.toLowerCase()

  const paramIsInUrl = urlLowerCase.includes(paramLowerCase)
  return paramIsInUrl ? doubleDecode(getParamFromUrl(finalUrl, param)) : ''
}

export function doubleDecode(value) {
  return decodeURIComponent(decodeURIComponent(value))
}

/**
 * Expect the calling location to be passed
 * Get you the calling location for Adobe Analytics
 * @param {string} from - A callingLocation retrieved from the url
 */
export const getCallingLocationNew = (from) => {
  const calledFromNavBar = from === SEARCH_CALLING_LOCATION.NAV_BAR
  const calledFromSearchBar = from === SEARCH_CALLING_LOCATION.SEARCH_BAR
  const calledFromPromotion = from === SEARCH_CALLING_LOCATION.PROMO_BANNER
  const calledFromBreadCrumbs = from === SEARCH_CALLING_LOCATION.BREADCRUMB
  const calledFromBanners = from === SEARCH_CALLING_LOCATION.BANNERS
  return {
    calledFromPromotion,
    calledFromNavBar,
    calledFromSearchBar,
    calledFromBreadCrumbs,
    calledFromBanners,
  }
}

export const getQueryFromFullUrl = (url) => {
  const decodedUrl = decodeURIComponent(url)
  try {
    const addr = new URL(decodedUrl)
    return addr.searchParams.get('q')
  } catch (error) {
    if (isDev()) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
    return ''
  }
}
