export default {
  en: {
    recentlyViewedItemsLabel: 'Recently Viewed Items',
    expertSuggestionLabel: 'Our experts suggest',
    productTotal: 'Product Total',
    feeTotal: 'Fee Total',
    assemblyfee: 'Assembly fee',
    enviromentalFees: 'Enviromental Handling Fees',
    tariffFees: 'Tariff Fees',
    handlingFees: 'Handling Fees',
    furnitureDelivery: 'Furniture Delivery (to ',
    taxes: 'Taxes',
    hst: 'HST',
    gst: 'GST',
    freeText: 'FREE',
    totalBeforeDiscount: 'Total Before Discount',
    discount: 'Discount',
  },
  fr: {
    recentlyViewedItemsLabel: 'Articles récemment consultés',
    expertSuggestionLabel: 'Nos experts suggèrent',
    productTotal: 'Total des produits',
    feeTotal: 'Total des frais',
    assemblyfee: "Frais d'assemblage",
    enviromentalFees: 'Frais de gestion environnementale',
    tariffFees: 'Frais de Douane',
    handlingFees: 'Frais de manutention',
    furnitureDelivery: 'Livraison de meubles (à ',
    taxes: 'Taxes',
    hst: 'TVH',
    gst: 'TPS',
    catalogue: 'Catalogue',
    freeText: 'LIBRE',
    totalBeforeDiscount: 'Total avant remise',
    discount: 'Remise',
  },
}
