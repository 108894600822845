import PropTypes from 'prop-types'
import AlertNotification from 'shared/Alerts/AlertNotification'
import classNames from 'classnames'
import styles from './ToastNotificationsList.module.scss'
import { useRef, useEffect } from 'react'
import Box from 'shared/Box'

/**
 * Name: ToastNotificationsList
 * Desc: Render Alert AlertNotificationsList
 * @param {array} toasts
 * @param {func} onCloseAlert
 */
export function ToastNotificationsList({
  toasts,
  onCloseAlert,
  position = 'fixed',
}) {
  const placement = 'topCenter'
  const classes = classNames(styles.toast, styles[placement])

  return (
    <Box className={classes} position={position}>
      {toasts.map((toast) => (
        <ToastAlertWrapper
          key={toast.id}
          onCloseAlert={onCloseAlert}
          toastId={toast.id}
          toastAutoDismiss={toast.autoDismiss}
        >
          <AlertNotification
            key={toast.id}
            id={toast.id}
            type={toast.type}
            prefix={toast.prefix}
            text={toast.PrimaryMessage}
            index={toast.index}
            hasLink={toast.HasLink}
            linkText={toast.ButtonText}
            link={toast.ButtonLink}
            hasButton={toast.HasButton}
            buttonText={toast.ButtonText}
            maxWidth={toast.toast ? 'auto' : toast.maxWidth}
            iconPosition={toast.iconPosition}
            textDecoration={true}
            showCloseButton={toast.showCloseButton}
            color="secondary"
            secondaryMessages={toast.SecondaryMessages}
            closeAlertHandler={() => onCloseAlert(toast.id)}
          />
        </ToastAlertWrapper>
      ))}
    </Box>
  )
}

export default ToastNotificationsList

// Props Validation
ToastNotificationsList.propTypes = {
  toasts: PropTypes.array,
  onCloseAlert: PropTypes.func,
  position: PropTypes.string,
}

const ToastAlertWrapper = ({
  children,
  onCloseAlert,
  toastId,
  toastAutoDismiss,
}) => {
  const toastTimer = useRef(null)
  useEffect(() => {
    !toastTimer.current &&
      (toastTimer.current = setTimeout(() => {
        onCloseAlert(toastId)
        clearTimeout(toastTimer.current)
      }, toastAutoDismiss))

    // Clear timer in case of notification closed from outside( By close button )
    return () => {
      toastTimer.current && clearTimeout(toastTimer.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return children
}

ToastAlertWrapper.propTypes = {
  children: PropTypes.node,
  onCloseAlert: PropTypes.func,
  toastId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  toastAutoDismiss: PropTypes.number,
}
