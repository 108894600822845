export const RETRY_PARAMS = {
  addPreviousMethodToUrl: true,
  as: 'POST',
}

export const API_ERROR = {
  hasError: true,
  isSuccess: false,
}

export const SEND_REQUEST_DEFAULTS = { isAddToken: true, disableCache: true }

export const SUCCESS_FALSE = {
  isSuccess: false,
}
