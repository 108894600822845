import {
  clearCartRequest,
  getCatalogueList,
  getCompareProductsData,
  orderMoveIntoTemplateRequest,
  removeProductFromCart,
  saveCustomPromptForProduct,
  saveLineNoteForProduct,
  updateQuantityInCart,
} from 'api/shoppingCartOperations'
import messages from 'components/containers/ShoppingCart/messages'
import cartUtilityMessages from 'components/containers/ShoppingCart/ShoppingCartUtilityPanel/messages'
import { updateUserAccountAndReload } from 'services/accountService'
import { budgetCalculatorDateIsInvalid } from 'services/budgetServices'
import {
  addProductId,
  checkKeysEmpty,
  customDateFormat,
  isEmptyArray,
} from 'services/utils'
import { getHostUrl } from 'services/utils/configUtils'
import { ACTION_TYPE } from 'shared/AdobeAnalytics/AdobeAnalyticsConstants'
import {
  bloomReachAnalyticsFirstCallStatus,
  sendBloomReachPageTracking,
} from 'shared/BloomReachAnalytics/BloomReachAnalyticsUtils'
import { FREE_GIVE_AWAY } from 'shared/ProductCard/ProductCardConstants'
import {
  AVAILABLE,
  MONTHLY_BUDGET,
  TIME_PERIOD,
} from '~/components/containers/ShoppingCart/ShoppingCartConstants'
import {
  adobeAnalyticsFirstCallStatus,
  resetAnalyticsVarsAndEvents,
  updateAndSendTrackingWith,
} from '~/components/shared/AdobeAnalytics/AdobeAnalyticsUtils'
import { getCartOrderSummary } from 'shared/OrderSummary/OrderSummaryDetail/OrderSummaryDetailsUtils'
import { ENGLISH } from '~/redux/constants'
import { getCartOrderData } from '~/services/cartService'
import { updateDeleteAdobeAnalyticData } from '../../components/shared/AdobeAnalytics/AdobeAnalyticsUtilsShoppingCart'
import { getAdditionalFees } from '../fees/products'
import compareProductMessages from '~/components/containers/CompareProducts/messages'

export const HOST_URL = getHostUrl()
const DEFAULT_SHIP_TO_SELECTION_VALUE = 2

/**
 * Name: fetchBudgetData
 * Desc: Fetch budget data for shopping cart page
 * @param {*} data
 * @param {*} language
 * @param {*} showCustomDates
 */
export const getBudgetData = (data, language, showCustomDates) => {
  const translations = cartUtilityMessages[language]
  const result = [
    {
      text: data.timePeriodMessage,
      value: data.totalBudget,
      strong: '',
      label: MONTHLY_BUDGET,
    },
    {
      text: translations.available,
      value: data.availableBudget,
      strong: 'strong',
      label: AVAILABLE,
    },
    {
      text: translations.timePeriod,
      value: `${customDateFormat(data.beginDate)} ${
        translations.toLabel
      } ${customDateFormat(data.endDate)}`,
      strong: '',
      label: TIME_PERIOD,
    },
  ]

  if (budgetCalculatorDateIsInvalid(data || {})) {
    result.length = 0
  }
  if (!data?.showBudgetCalculator) {
    result.length = 0
  }
  if (!showCustomDates || !data?.showTimePeriodDates) {
    result.splice(
      result.findIndex((item) => item.label === TIME_PERIOD),
      1
    )
  }
  return result
}

/**
 * Name: getShoppingCartData
 * Desc: fetch shopping Cart Data
 * @param {string} language
 * @param {object} settings
 */

export async function getShoppingCartData({
  language,
  enableContractProductTotalBreakdown = false,
  shippingPostalCode,
}) {
  const cartOrderData = await getCartOrderData(
    language,
    enableContractProductTotalBreakdown,
    shippingPostalCode
  )
  sendBloomReachPageAnalytics(cartOrderData)
  return { ...cartOrderData }
}

function sendBloomReachPageAnalytics(data = {}) {
  const analyticsProperties =
    data.bloomreachAnalytics?.AnalyticsProperties || {}

  if (bloomReachAnalyticsFirstCallStatus()) {
    return
  }

  bloomReachAnalyticsFirstCallStatus(true)

  sendBloomReachPageTracking(analyticsProperties)
}

/**
 * Name: getCatalogueProductList
 * Desc: fetch Catalogue Product List
 * @param {*} language
 *
 */

export async function getCatalogueProductList(buyerId, clientNo, language) {
  {
    const catalogueList = await getCatalogueList(buyerId, clientNo, language)
    return catalogueList
  }
}

/**
 * Name: orderMoveIntoTemplate
 * Desc: Order move into template
 * @param {*} language
 */
export async function orderMoveIntoTemplate(language, ewayOrderNo) {
  {
    const result = await orderMoveIntoTemplateAction(language, ewayOrderNo)
    return result
  }
}

/**
 * Name: clearCart
 * Desc: clear cart
 * @param {number} buyerId
 * @param {string} sessionId
 * @param {string} language
 */
export async function clearCart(buyerId, sessionId, language) {
  {
    const result = await clearCartRequest(buyerId, sessionId, language)
    return result
  }
}
/**
 * Name: orderMoveIntoTemplate
 * Desc: Move orders into Template
 * @param {*} language
 * @param {*} ewayOrderNo
 */
export async function orderMoveIntoTemplateAction(language, ewayOrderNo) {
  const result = await orderMoveIntoTemplateRequest(language, ewayOrderNo)
  return result
}

/**
 * Name: getConfirmDialogText
 * Desc: Fetch Dialog confirmation text
 * @param {*} language
 */
export const getConfirmDialogText = (language) => {
  const confirmDialogText = {
    headingText: messages[language].shoppingCart,
    descriptionShort: messages[language].dialogDescriptionShort,
    descriptionLong: messages[language].dialogDescriptionLong,
    confirmationBtnText: messages[language].dialogProceedBtnText,
    cancelText: messages[language].dialogCancelBtnText,
    closeText: messages[language].dialogCloseLinkText,
  }

  return confirmDialogText
}
/**
 * Name: removeProduct
 * Desc: Remove Product from cart
 * @param {*} language
 * @param {*} orderProductId
 * @param {*} adobeAnalyticData
 */
export async function removeProduct(
  language,
  orderProductId,
  settings,
  adobeAnalyticData,
  shippingPostalCode
) {
  const cartProductListData = await removeProductFromCart(
    language,
    orderProductId
  )
  const { CartInfo = {}, Messages = [], ApprovalAction } = cartProductListData

  const orderSummary = getCartOrderSummary({
    language,
    cartDetails: cartProductListData,
    approvalAction: ApprovalAction,
    showContractProductTotal: settings.enableContractProductTotalBreakdown,
    shippingPostalCode,
  })
  sendAdobeAnalyticValuesForClearCart(adobeAnalyticData, false)
  return {
    cartProductList: CartInfo?.Products || [],
    orderSummary,
    messages: Messages,
  }
}

/**
 * Name: updateQuantity
 * Desc: Update Product quantity in cart
 * @param {*} language
 * @param {*} orderProductId
 * @param {*} quantity
 * @param {object} adobeAnalyticData
 */
export async function updateQuantity(
  language,
  orderProductId,
  quantity,
  settings,
  adobeAnalyticData,
  shippingPostalCode,
  showLineNoteFlag
) {
  const cartProductListData = await updateQuantityInCart(
    language,
    orderProductId,
    quantity
  )

  const { CartInfo = {}, Messages = [], ApprovalAction } = cartProductListData

  const orderSummary = getCartOrderSummary({
    language,
    cartDetails: cartProductListData,
    approvalAction: ApprovalAction,
    showContractProductTotal: settings.enableContractProductTotalBreakdown,
    shippingPostalCode,
  })

  if (adobeAnalyticData && cartProductListData.Status === 200) {
    sendAdobeAnalyticValuesForClearCart(adobeAnalyticData, false)
  }
  return {
    cartProductList: formateCartList(CartInfo?.Products, showLineNoteFlag),
    orderSummary,
    messages: Messages,
  }
}
function formateCartList(cartProductList = [], showLineNoteFlag) {
  return cartProductList.map((product = {}) => {
    const isNote =
      !!showLineNoteFlag && product.QualifierType !== FREE_GIVE_AWAY
    return { ...product, isNote }
  })
}
/**
 * Name: saveLineNote
 * Desc: Save line note
 * @param {*} language
 * @param {*} orderProductId
 * @param {*} lineNote
 */
export async function saveLineNote(language, orderProductId, lineNote) {
  const response = await saveLineNoteForProduct(
    language,
    orderProductId,
    lineNote
  )
  return response && response.StatusCode === 200
}

/** clearCartAction
 * @param {string} language
 * @param {number} buyerId
 * @param {string} sessionId
 * @param {number} defaultShipToNumber
 * @param {number} defaultShipToSelection
 */
export const clearCartAction = async (
  buyerId,
  sessionId,
  language,
  defaultShipToNumber,
  defaultShipToSelection,
  clientNo,
  shoppingCartProductData
) => {
  const adobeAnalyticData = updateDeleteAdobeAnalyticData(
    shoppingCartProductData,
    '',
    ACTION_TYPE.CLEAR_CART
  )

  sendAdobeAnalyticValuesForClearCart(adobeAnalyticData, false)

  const result = await clearCart(buyerId, sessionId, language)
  if (
    isEmptyArray(result?.CartInfo?.Products) &&
    defaultShipToSelection === DEFAULT_SHIP_TO_SELECTION_VALUE
  ) {
    updateUserAccountAndReload(defaultShipToNumber, clientNo, [])
  }
  return result
}

export const getCartData = async ({
  language = ENGLISH,
  isDeliOptSet,
  deliServOptType,
  isPreAsmbAvailable,
  enableContractProductTotalBreakdown,
  shippingPostalCode,
  showLineNoteFlag,
  updateRecentListOnly = false, //update recent list view only
} = {}) => {
  let shoppingCartData
  if (!updateRecentListOnly) {
    shoppingCartData = await getShoppingCartData({
      language,
      enableContractProductTotalBreakdown,
      shippingPostalCode,
    })
  }
  let shoppingCartFinalDataResult
  if (shoppingCartData) {
    const {
      cartProductList,
      orderSummary,
      orderDeliveryInfo,
      customPromptsInfo,
      cartSummary,
    } = shoppingCartData || {}

    const formattedCartProductList = formatCartProductList({
      cartProductList,
      language,
      isDeliOptSet,
      deliServOptType,
      isPreAsmbAvailable,
      showLineNoteFlag,
    })
    shoppingCartFinalDataResult = {
      shoppingCartProductData: [...formattedCartProductList],
      shoppingCartOrderSummary: orderSummary,
      shoppingCartOrderDetail: orderDeliveryInfo,
      customPromptData: customPromptsInfo,
      deliveryInfo: cartSummary,
    }
  } else {
    shoppingCartFinalDataResult = {}
  }
  const data = {
    ...shoppingCartData,
    dispatchedData: { ...shoppingCartFinalDataResult },
  }
  return data
}

export function formatCartProductList({
  cartProductList = [],
  language = ENGLISH,
  isDeliOptSet,
  deliServOptType,
  isPreAsmbAvailable,
  showLineNoteFlag,
}) {
  return cartProductList
    .map((product = {}) => {
      const additionalFees = getAdditionalFees(
        language,
        product,
        isDeliOptSet,
        deliServOptType,
        isPreAsmbAvailable
      )
      const isNote =
        !!showLineNoteFlag && product.QualifierType !== FREE_GIVE_AWAY
      return { ...product, additionalFees, isNote }
    })
    .map(addProductId)
}

/**
 * Name: ValidateAndSaveDetailCustomPrompt
 * Desc: validate and save custom prompt
 * @param {string} language
 * @param {string} orderProductId
 * @param {string} pageType
 * @param {string} orderId
 * @param {number} promptId
 * @param {string} promptValue
 * @param {number}  sequence
 */
export async function saveCustomPrompt(
  language,
  orderProductId,
  pageType,
  orderId,
  promptId,
  promptValue,
  sequence
) {
  const response = await saveCustomPromptForProduct(
    language,
    orderProductId,
    pageType,
    orderId,
    promptId,
    promptValue,
    sequence
  )
  return response
}
export const onCustomPromptValidate = ({ values, dependencies }) => {
  const newErrors = {}
  const { fields, language, messages } = dependencies

  Object.keys(values).forEach((fieldName) => {
    if (fields[fieldName]?.mandatory && !values[fieldName]) {
      newErrors[fieldName] = messages[language].requiredUserIdMsg
    }
  })

  return newErrors
}

const sendAdobeAnalyticValuesForClearCart = (
  analyticsProperties,
  isOnload = false,
  defaultValue = {},
  firstCallStatus = true
) => {
  if (isOnload) {
    if (adobeAnalyticsFirstCallStatus()) {
      return
    }
    adobeAnalyticsFirstCallStatus(firstCallStatus)
  }
  resetAnalyticsVarsAndEvents()
  !checkKeysEmpty(analyticsProperties) &&
    updateAndSendTrackingWith({ ...analyticsProperties, ...defaultValue })
}
/**
 * Name: getCompareProductsData
 * Desc: get Compare Products
 * @param {*} language
 * @param {array} compareProductIds
 */
export async function getCompareProduct(language, compareProductIds) {
  const compareProductIdsObject = { body: compareProductIds }

  try {
    const result = await getCompareProductsData(
      language,
      compareProductIdsObject
    )
    const { status = 200 } = result
    const isSuccess = status === 200
    const finalResult = isSuccess
      ? formatDataForCompareProduct(language, result)
      : result
    return finalResult
  } catch (e) {
    return { isSuccess: false, status: 400, message: e }
  }
}

function formatDataForCompareProduct(language, result) {
  const finalCompareProducts = result.filter((product) => {
    const eProps = JSON.parse(product?.EProps)?.Tabs.filter(
      (item) => item.Name === compareProductMessages[language].specifications
    )
    return {
      ...(product.eProps = eProps[0]?.Sections[0]?.Attributes),
    }
  })
  return finalCompareProducts
}
