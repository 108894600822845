import * as api from 'api'
import {
  getCardOrderApprovalData,
  getCardOrderData,
  getSearchOrdersData,
} from 'api/orderOperations'
import messages from 'components/containers/Home/MembershipCardPanel/messages'
import { customDateFormat } from 'services/utils'
import { API_ERROR } from '~/api/apiHelperConstants'
import { withAuth } from '~/api/apiHelperUtils'
import { getUrl } from '~/components/shared/EwayRouter'
import {
  APPROVAL_ORDERS_ROUTE,
  SUBMITTED_ORDERS_ROUTE,
} from '~/components/shared/EwayRouter/EwayRouterConstants'

/**
 * Method that build an order object
 * @param {*} isCustomerOnboarding
 * @param {*} headers
 * @param {*} query
 */

const DEFAULT_ORDER_PREFIX = 'EW'
const promoProductType = 2 //product type which have promotional offer

export async function buildOrder(headers) {
  const { buyerId, sessionId } = headers
  if (!buyerId || !sessionId) {
    return { isUnauthorized: true, ...API_ERROR, status: 401 }
  }
  const order = (await (buyerId && api.getOrder(buyerId, sessionId))) || {}
  const isGroupOrderActive = await isGroupOrder(order?.orderId)
  const { groupOrderNumber = null } = isGroupOrderActive
    ? await getGroupOrderNo(order?.orderId)
    : {}
  const builtOrder = {
    ...order,
    isGroupOrder: isGroupOrderActive,
    groupOrderNumber,
  }

  return builtOrder || {}
}

export const buildOrderWithAuth = withAuth(buildOrder)

export async function isGroupOrder(orderId) {
  return !!orderId && (await api.getGroupOrder(orderId)).length > 0
}
export async function getGroupOrderNo(orderId) {
  return !!orderId && (await api.getGroupOrderNo(orderId))
}

export function filterPromoProducts(products = []) {
  const productList = []
  products.forEach((product = {}) => {
    return (
      product.promoProductQualifierType !== promoProductType &&
      productList.push(product)
    )
  })
  return productList
}

export function filterAlternateProducts(products = []) {
  const productList = []
  products.forEach((p = {}) => {
    return p.isCurrentProduct && productList.push(p)
  })
  return productList
}

/**
 * Name: formatCardOrderData
 * Desc: convert submittedDate into YYYY-MM-DD date format. Add prefix EW to every order of ewayOrderNo
 * @param {object} cardOrderData
 */
const formatCardOrderData = (cardOrderData) => {
  const result = []
  if (cardOrderData && cardOrderData.length && Array.isArray(cardOrderData)) {
    for (let item, i = 0; (item = cardOrderData[i++]); ) {
      result.push([
        customDateFormat(item.submittedDate),
        `${DEFAULT_ORDER_PREFIX}${item.ewayOrderNo}`,
        item.orderId,
      ])
    }
  }
  return result
}

export function getSlicedProductItems(order, displayLimit) {
  const orderProducts =
    filterAlternateProducts(filterPromoProducts(order?.orderProducts)).sort(
      (a, b) => (a.lineNumber < b.lineNumber ? 1 : -1)
    ) || []

  const productItems = orderProducts.slice(0, displayLimit)
  return productItems || []
}

export function getAndApplyPromoDiscount(order = {}) {
  return order.promoDiscount
    ? order.total - order.promoDiscount
    : order.total || 0
}

/**
 * Name: CardOrderData
 * Desc: Fetch approval order list
 * @param {*} language
 * @param {*} buyerId
 * @param {*} showMenuOrder
 * @param {*} showMenuOrderSubmitted
 */

export async function cardOrderData(
  language,
  buyerId,
  showMenuOrder,
  showMenuOrderSubmitted
) {
  if (showMenuOrder && showMenuOrderSubmitted) {
    const orderData = await fetchCardOrderData(language, buyerId)
    const orders = orderData ? formatCardOrderData(orderData.orders) : []
    const submittedOrderListUrl = getUrl(SUBMITTED_ORDERS_ROUTE)
    const data = {
      language,
      heading: messages[language].orderTileHeadingLabel,
      noOrderLabel: messages[language].noOrderLabel,
      viewOrderLabel: messages[language].viewAllLabel,
      youHaveLabel: messages[language].youHaveLabel,
      orderLabel: messages[language].orderLabel,
      moreOrderLabel: messages[language].moreOrderLabel,
      viewOrderUrl: submittedOrderListUrl,
      orderTileHeadingLabel: messages[language].orderTileHeadingLabel,
      orderHeaderRow: [
        messages[language].orderNumberLabel,
        messages[language].orderDateLabel,
      ],
      orderData: orders,
      orderCount: orderData?.total,
      buyerId,
    }
    return data
  }

  return {}
}

/**
 * Name: cardOrderApprovalData
 * Desc: fetch order list
 * @param {*} language
 * @param {*} buyerId
 * @param {*} showMenuOrder
 */

export async function cardOrderApprovalData(language, buyerId, showMenuOrder) {
  if (showMenuOrder) {
    const approvalData = await fetchCardOrderApprovalData(language, buyerId)
    approvalData.orders = approvalData
      ? formatCardOrderData(approvalData.orders)
      : []
    const approvalOrderListUrl = getUrl(APPROVAL_ORDERS_ROUTE)
    const data = {
      language,
      heading: messages[language].approvalsTileHeadingLabel,
      noApprovalOrderLabel: messages[language].noApprovalOrderLabel,
      viewOrderLabel: messages[language].viewOrderLabel,
      waitingApprovalLabel: messages[language].waitingApprovalLabel,
      moreWaitingApprovalsLabel: messages[language].moreWaitingApprovalsLabel,
      youHaveLabel: messages[language].youHaveLabel,
      viewOrderUrl: approvalOrderListUrl,
      orderHeaderRow: [
        messages[language].orderNumberLabel,
        messages[language].orderDateLabel,
      ],
      orderData: approvalData?.orders,
      orderCount: approvalData?.total,
    }
    return data
  }
}

/**
 * Name: fetchCardOrderData
 * Desc: Fetch data for orders tile on home page
 * @param {*} language
 * @param {*} buyerId
 */
export async function fetchCardOrderData(language, buyerId) {
  const cardOrderData = await getCardOrderData(language, buyerId)
  return cardOrderData
}

/**
 * Name: fetchCardOrderApprovalData
 * Desc: Fetch data for approval tile on home page
 * @param {*} language
 * @param {*} buyerId
 */
export async function fetchCardOrderApprovalData(language, buyerId) {
  const cardOrderApprovalData = await getCardOrderApprovalData(
    language,
    buyerId
  )
  return cardOrderApprovalData
}

/**
 * Name: getSearchOrder
 * Desc: Fetch order search  list
 * @param {*} language
 * @param {*} buyerId
 * @param {string} searchBy
 */

export async function getSearchOrder(language, buyerId, searchBy) {
  const orderData = await getSearchOrdersData(language, buyerId, searchBy)
  const orders = orderData ? formatCardOrderData(orderData.orders) : []
  const data = {
    orderData: orders,
    orderCount: orderData?.total,
  }
  return data
}
