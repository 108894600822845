import { getBillingOptionName } from '~/components/shared/PaymentOptions/PaymentOptionsList/PaymentOptionsListUtils'
import {
  isGroupOrderFromBuyer,
  renameKeys,
  removeAndTrimEmptyValues,
  removeUndefinedValues,
} from '../../services/utils/utils'
import compose from 'lodash/fp/compose'
import { isLoggedIn } from '~/components/shared/Utils/userUtils'

export const formatBuyer = (buyer = {}) => {
  const formattedBuyer = compose(
    formatBuyerKeys,
    removeAndTrimEmptyValues,
    removeUndefinedValues
  )(buyer)
  const loggedIn = isLoggedIn(formattedBuyer)
  const finalBuyer = {
    ...formattedBuyer,
    ...(loggedIn && { isLoggedIn: loggedIn }),
  }
  return finalBuyer
}

function formatBuyerKeys(buyer = {}) {
  const keysToRename = {
    cultureCode: 'language',
    emailAddress: 'email',
    IsWaitingForCOActivation: 'isCustomerOnboarding',
  }
  const renamedBuyer = renameKeys(buyer, keysToRename)
  const transformedBuyer = {
    ...renamedBuyer,
    // Transforms the billing option type as a readable string
    preferedBillingOptionName: getBillingOptionName(
      buyer.preferedBillingOptionType
    ),
    fullName: getFullName(buyer),
    isInGroupOrder: isGroupOrderFromBuyer(buyer),
    language: renamedBuyer.language && renamedBuyer.language.substr(0, 2),
  }
  return transformedBuyer
}

export function getFullName({ firstName = '', lastName = '' } = {}) {
  return [firstName, lastName].filter(Boolean).join(' ')
}
