import noop from 'lodash/noop'

export const DANGER = 'danger'
export const WARNING = 'warning'
export const SUCCESS = 'success'
export const PRIMARY = 'primary'

// Icons
export const ALERT_DANGER_ICON = 'infoFilled'
export const ALERT_WARNING_ICON = 'warning'
export const ALERT_SUCCESS_ICON = 'check'
export const ALERT_PRIMARY_ICON = 'info'

export const ALERT_TYPES = {
  1: {
    type: DANGER,
    prefix: ALERT_DANGER_ICON,
  },
  2: {
    type: WARNING,
    prefix: ALERT_WARNING_ICON,
  },
  3: {
    type: SUCCESS,
    prefix: ALERT_SUCCESS_ICON,
  },
  4: {
    type: PRIMARY,
    prefix: ALERT_PRIMARY_ICON,
  },
  10: {
    type: PRIMARY,
    prefix: ALERT_PRIMARY_ICON,
  },
}

/*
 * Based on old eway values
 * Message.MessageStatus ... ⬇️
 *
 * Error = 1,
 * Warning = 2,
 * Success = 3,
 * Information = 4,
 * Default = 10
 */
export const MESSAGE_STATUS = {
  ERROR: 1,
  WARNING: 2,
  SUCCESS: 3,
}
/*
 * Based on old eway code
 * Message.MessageType .. ⬇️
 *
 * CannotOrderTotal = 0
 * CannotOrderCategory = 1
 * ToBeApproved = 2
 * PromotionApplied = 3
 * Other = 4 - To document
 * Invitee = 5
 */
export const MESSAGE_TYPE = {
  OTHER: 4, // Not sure what this means yet
  APPROVAL: 2,
  PROMOTIONAL: 3,
  CAN_NOT_ORDER_CATEGORY: 1,
  ADD_TO_CART: 6,
}

// Used to identify if message is error (weird and to remove)
export const REMOVE_ITEM_CART_MESSAGE = 'removed from cart'

// Constants for notifications emitters
export const GLOBAL_CONTAINER_ID = 'global-container'
export const QUICK_VIEW_CONTAINER_ID = 'quick-view-container'
export const CATALOG_CONTAINER_ID = 'catalog-container'
export const PRE_CHECKOUT_CONTAINER_ID = 'precheckout-container'
export const ACCOUNT_SELECTION_MODAL_CONTAINER_ID =
  'account-selection-modal-container'
export const CHECKOUT_CONTAINER_ID = 'checkout-container'

export const QUICK_VIEW_MESSAGE_ID = 'quick-view-add-to-cart-success'
export const CATALOG_MESSAGE_ID = 'catalog-add-to-cart-success'

export const SHOW_NOTIFICATION = 'showNotification'
export const REPLACE_NOTIFICATION = 'replaceNotification'
export const HIDE_NOTIFICATION = 'hideNotification'
export const HIDE_NOTIFICATION_ALL = 'hideNotificationAll'
export const PRE_ASSEMBLY_NOT_AVAILABLE = 'preAssemblyNotAvailable'
export const INSTALLATION_UNAVAILABLE = 'installationUnavailable'
export const PRINT_VIEW = 'printView'
export const APPROVAL_PAGE_MESSAGE_ID = 'approval-page-add-to-cart-success'
export const USER_ADDRESS_CONTAINER_ID = 'user-address-container'

/**
 * Default options for notifications
 */
export const defaultOptions = {
  type: PRIMARY,
  maxWidth: 'lg',
  isAutoScrollTop: false,
  textDecoration: true,
  hasLink: false,
  showCloseButton: true,
  hasButton: false,
  prefix: 'sucess',
  closeAlertHandler: noop,
  fallbackToGlobalContainer: false,
  secondaryMessages: [],
  containerId: GLOBAL_CONTAINER_ID,
}

export const SHOW_HIDE_OPTION_AVAILABLE = 1 // Means Restricted show/hide option is available
