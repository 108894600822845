import { isDev } from '~/config/env/environmentUtils'
const BUYER_ID = 'buyerId'
const SESSION_ID = 'sessionId'
const ORDER_ID = 'orderId'
export const QUERY_STRING_POSTAL_CODE = 'postalCode'
export const REQUIRED_KEYS = [BUYER_ID, SESSION_ID, ORDER_ID]
export const DEFAULT_OPTIONS = {
  path: '/',
  expires: undefined,
  httpOnly: false,
  // in dev version don't include same site
  sameSite: isDev() ? '' : 'none',
}
