import { ENGLISH } from '~/redux/constants'
import { getAllTaxes } from '../../taxesService/taxesService'
import { camelCaseKeys, hasALabelAndFee } from '../../utils'
import {
  DELIVERY_TYPE_FIFTY_GREEN,
  DELIVERY_TYPE_ZONE,
  FEE_LINE_TOTAL,
  FEE_LINE_TYPE,
  FEE_TOTAL,
} from './feeOrderServiceConstants'
import { getFurnitureDeliveryFees } from './feeOrderServiceFurnitureAndDelivery'
import { getFurnitureDeliveryAndInstallFees } from './feeOrderServiceFurnitureDeliveryAndInstall'
import {
  deliveryTypeLabels,
  feeLabels,
  includedTypeLabels,
  orderTotalLabel,
} from './feeOrderServiceLabels'
import { getProductTotal } from './feeOrderServiceProductTotal'
import { ORDER_FEE_TYPES } from './feeOrderServiceTypes'

export function getFeesSummary(info, language, showContractProductTotal) {
  const cartInfo = camelCaseKeys(info)
  return [
    ...getProductTotal(cartInfo, language, showContractProductTotal),
    ...getAllFees(cartInfo, language),
    ...getAllTaxes(cartInfo, language),
  ].filter(Boolean) // return only truthy values
}

export const getAllFees = (cartDetails, language = ENGLISH) => {
  const fees = [
    getEnvironmentalHandlingFees(cartDetails, language),
    getTariffFees(cartDetails, language),
    getProductHandlingFees(cartDetails, language),
    getSmallOrderOrDeliveryAreaFee(cartDetails, language),
    getFreightFees(cartDetails, language),
    getAssemblyFees(cartDetails, language),
    getFurnitureDeliveryAndInstallFees(cartDetails, language),
    getFurnitureDeliveryFees(cartDetails, language),
  ]

  // get fee total from all fees
  const feeTotal = fees.reduce((acc, current) => {
    const feeValue = isNaN(current.value) ? 0 : current.value
    return acc + feeValue
  }, 0)

  // create total fee line
  const feeTotalLine = {
    name: feeLabels[FEE_TOTAL][language],
    value: feeTotal,
    isTitle: true,
  }

  const allFees = [feeTotalLine, ...fees] // add as first line
  return allFees.filter(hasALabelAndFee)
}

export const getAssemblyFees = (cartInfo, language = ENGLISH) => {
  const name = feeLabels[ORDER_FEE_TYPES.ASSEMBLY][language]
  const value = cartInfo.cartSummary?.isExemptFromAssemblyFees
    ? includedTypeLabels[language].included
    : cartInfo.cartSummary?.priceDetails?.assemblyFee || 0
  return { name, value, type: FEE_LINE_TYPE }
}

export const getProductHandlingFees = (cartInfo, language = ENGLISH) => {
  const name = feeLabels[ORDER_FEE_TYPES.PRODUCT_HANDLING][language]
  const value = cartInfo.cartSummary?.priceDetails?.handlingFee || 0
  return { name, value, type: FEE_LINE_TYPE }
}

export const getTransacFees = (cartInfo, language = ENGLISH) => {
  const name = feeLabels[ORDER_FEE_TYPES.TRANSAC][language]
  const value = cartInfo.cartSummary?.priceDetails?.TransactionalFee || 0
  return { name, value, type: FEE_LINE_TYPE }
}

export const getEnvironmentalHandlingFees = (cartInfo, language = ENGLISH) => {
  const name = feeLabels[ORDER_FEE_TYPES.ENVIRONMENTAL][language]
  const value = cartInfo.cartSummary?.priceDetails?.environmentalFee || 0
  return { name, value, type: FEE_LINE_TYPE }
}

export const getSmallOrderOrDeliveryAreaFee = (
  cartInfo,
  language = ENGLISH
) => {
  const name =
    cartInfo.orderDeli?.deliveryZone === DELIVERY_TYPE_ZONE
      ? deliveryTypeLabels[language][DELIVERY_TYPE_ZONE]
      : deliveryTypeLabels[language][DELIVERY_TYPE_FIFTY_GREEN]
  const value = cartInfo?.cartSummary?.priceDetails?.smallOrderFee || 0
  return { name, value, type: FEE_LINE_TYPE }
}

export const getFreightFees = (cartInfo, language = ENGLISH) => {
  const name = feeLabels[ORDER_FEE_TYPES.FREIGHT_FEE][language]
  const value = cartInfo.cartSummary?.priceDetails?.freightFee || 0
  return { name, value, type: FEE_LINE_TYPE }
}

export const getTariffFees = (cartInfo, language = ENGLISH) => {
  const name = feeLabels[ORDER_FEE_TYPES.TARIFF][language]
  const value = cartInfo.cartSummary?.priceDetails?.tariffFee || 0
  return { name, value, type: FEE_LINE_TYPE }
}

export const getOrderTotal = (cartInfo, language = ENGLISH) => {
  const value = cartInfo.cartSummary?.priceDetails?.total || 0
  const name = orderTotalLabel[language]
  return { name, value, type: FEE_LINE_TOTAL }
}

// below methods ⬇️ was exported in separate file, because too big.
export {
  getFurnitureDeliveryFees,
  getFurnitureDeliveryAndInstallFees,
  getProductTotal,
}
