import { getHostUrl } from 'services/utils/configUtils'
import { getLanguageParam, isEnglish } from '~/services/utils/languageUtils'
import { ENGLISH, FRENCH } from '~/redux/constants'
import EwayRouter from 'shared/EwayRouter'
import {
  GROUP_ORDERS,
  CHECKOUT_ROUTE,
  MAPPING,
} from 'shared/EwayRouter/EwayRouterConstants'
const HOST_URL = getHostUrl()

/**
 *  Expects to receive an old eway url like:
 * "https://dev.staplesams.com/Release/en/Orders/Submitted/Returns"
 *  and return the value "en"
 * @param {string} url
 */
export function getLanguageFromUrl(url) {
  if (process.browser && !url) {
    return getLanguage(window.location.href)
  }
  return getLanguage(url)
}

function getLanguage(value) {
  const [language = ''] = getLanguageMatchFromUrl(value)
  return language.replace(/\//g, '')
}

export function getLanguageMatchFromUrl(url = '') {
  return url.match(/(\/en\/?|\/fr\/?)/g) || ''
}

export function linkFromRelativeUrl(relativeUrl, language) {
  return `${HOST_URL}${relativeUrl}${getLanguageParam(language)}`
}

/**
 * Utility that check page is PDP
 * @param {*} location - Expects the browsers window.location
 */
export function isPdpPage(href) {
  if (!href.length) {
    return false
  }
  // PDP routes
  const routes = ['/product-detail/product', '/Shopping/Product/']
  return routes.some((route) => href.includes(route))
}

export function getOppositeLanguageUrl(url) {
  const value = getLanguageMatchFromUrl(url).toString()
  const language = getLanguageFromUrl(url)
  const params = isEnglish(language) ? [ENGLISH, FRENCH] : [FRENCH, ENGLISH]
  const oppositeValue = value.replace(...params)
  return url.replace(value, oppositeValue)
}

/**
 * Wraps it with host url only
 * @param {string} url
 */
export const wrapWithHostUrl = (url) => {
  return `${HOST_URL}${url}`
}
export function throwErrorIf(condition, message) {
  if (condition) {
    throw new Error(message)
  }
}

export function isCheckout() {
  return isPageBasedOnMapping(MAPPING[CHECKOUT_ROUTE])
}

export function isPageBasedOnMapping(values) {
  const currentUrl = window.location.href.toLowerCase()
  return values.some((value) => currentUrl.includes(value))
}

export function getGroupOrderHomeUrl() {
  return EwayRouter.getUrl(GROUP_ORDERS)
}
