import {
  apiRequest,
  checkArgsEmpty,
  generateHeadersWithLanguage,
  sendRequest,
} from 'api/apiHelper'
import {
  getApiUrl,
  getKheopsAppIdFromEnv,
  getOldApiUrl,
} from 'services/utils/configUtils'
import { camelCaseKeys } from '~/services/utils'
import { formatProductDetail } from 'api/productOperations'

const OLD_API_URL = getOldApiUrl()
const API_URL = getApiUrl()
const KHEOPS_APPLICATION_ID = getKheopsAppIdFromEnv()

/**
 * Name: FetchCatalogueList
 * Desc: get Catalogue List
 * @param {*} language
 *
 */
const buildFetchCatalogueList = async (args) => {
  const [buyerId, clientNo, language] = args
  const url = `${OLD_API_URL}/api/catalogues/${buyerId}/${clientNo}`
  const result = await apiRequest(language, url)
  return formatProductDetail(result, language)
}

/**
 * Name: buildMoveOrderIntoTemplate
 * Desc: Order move into template
 * @param {*} language
 *
 */
const buildMoveOrderIntoTemplate = async (args) => {
  const [language, ewayOrderNo] = args
  const url = `${API_URL}/orders/${ewayOrderNo}/template`
  const options = {
    method: 'POST',
    headers: generateHeadersWithLanguage(language),
  }
  const result = sendRequest(url, options)
  return result
}

/**
 * Name: removeAndFetchProducts
 * Desc: remove product from cart
 * @param {*} language
 * @param {*} orderProductId
 */
const removeAndFetchProducts = async (args) => {
  const [language, orderProductId] = args
  const url = `${OLD_API_URL}/api/cart/removeProduct/${orderProductId}`
  const options = {
    method: 'GET',
    headers: generateHeadersWithLanguage(language),
  }
  return await mapCartInfo(url, options, language)
}

/**
 * Name: updateAndFetchProducts
 * Desc: update product quantity in cart
 * @param {*} language
 * @param {*} orderProductId
 * @param {*} quantity
 */
const updateAndFetchProducts = async (args) => {
  const [language, orderProductId, quantity] = args
  const url = `${OLD_API_URL}/api/cart/updateProduct/${orderProductId}`
  const options = {
    method: 'POST',
    body: JSON.stringify({ Quantity: quantity }),
    headers: generateHeadersWithLanguage(language),
  }
  return await mapCartInfo(url, options, language)
}

/**
 * Name: saveLineNoteProduct
 * Desc: save LineNote for Product
 * @param {*} language
 * @param {*} orderProductId
 * @param {*} lineNote
 */
const saveLineNoteProduct = async (args) => {
  const [language, orderProductId, lineNote] = args
  const url = `${OLD_API_URL}/api/cart/saveLineNote/${orderProductId}`
  const options = {
    method: 'POST',
    body: JSON.stringify({ LineNote: lineNote }),
    headers: generateHeadersWithLanguage(language),
  }
  const result = sendRequest(url, options)
  return result
}

/**
 * Name: clearCartRequest
 * Desc: clear cart request for shopping-cart page
 * @param {*} buyerId
 * @param {*} sessionId
 * @param {*} language
 */
const clearCartOrder = async (args) => {
  const [buyerId, sessionId, language] = args
  const url = `${OLD_API_URL}/api/cart/clearOrder`
  const options = {
    method: 'POST',
    headers: {
      EwayApplicationId: KHEOPS_APPLICATION_ID,
      EwayBuyerId: buyerId,
      EwaySessionId: sessionId,
      'Content-Type': 'application/json',
      'Accept-Language': `${language}-CA`,
    },
  }
  const result = sendRequest(url, options)
  return result
}

const validateForCustomPrompts = async (args) => {
  const [language, orderId = null] = args
  const queryWithOrderId = orderId ? `?orderId=${orderId}` : ''
  const url = `${OLD_API_URL}/api/orders/validateCustomPrompts${queryWithOrderId}`
  const options = {
    method: 'POST',
    headers: generateHeadersWithLanguage(language),
  }
  const result = camelCaseKeys(await sendRequest(url, options))
  const status = result.hasError ? 400 : 200
  return { ...result, status }
}
/**
 * Name:   saveCustomPromptProduct
 * Desc: validate and save custom prompt
 * @param {string} language
 * @param {string} productId
 * @param {string} pageType
 * @param {string} orderId
 * @param {number} promptId
 * @param {string} promptValue
 * @param {number}  sequence
 */
const saveCustomPromptProduct = async (args) => {
  const [
    language,
    orderProductId,
    pageType,
    orderId,
    promptId,
    promptValue,
    sequence,
  ] = args

  const url = `${OLD_API_URL}/api/orders/ValidateAndSaveDetailCustomPrompt`
  const options = {
    method: 'POST',
    body: JSON.stringify({
      orderProductId,
      pageType,
      orderId,
      promptId,
      promptValue,
      sequence,
    }),
    headers: generateHeadersWithLanguage(language),
  }
  const result = sendRequest(url, options)
  return result
}
const removeRestrictedProductsFromCart = async (args) => {
  const [language] = args
  const url = `${OLD_API_URL}/api/products/RemoveRestrictedProducts`
  const options = {
    method: 'GET',
    headers: generateHeadersWithLanguage(language),
  }
  const result = sendRequest(url, options)
  return result
}

const getCompareProducts = async (args) => {
  const [language, compareProductIds] = args
  const url = `${OLD_API_URL}/api/products/compareProducts`
  const options = {
    headers: generateHeadersWithLanguage(language),
    method: 'POST',
    body: JSON.stringify(compareProductIds.body),
  }
  const result = await sendRequest(url, options)
  return formatProductDetail(result, language)
}

const mapCartInfo = async (url, options, language) => {
  const {
    CartInfo: { Products = [], ...restCartInfo },
    ...rest
  } = await sendRequest(url, options)
  const productsList = formatProductDetail(Products, language)
  return { ...rest, CartInfo: { Products: productsList, ...restCartInfo } }
}

export const getCatalogueList = checkArgsEmpty(buildFetchCatalogueList)
export const orderMoveIntoTemplateRequest = checkArgsEmpty(
  buildMoveOrderIntoTemplate
)
export const removeProductFromCart = checkArgsEmpty(removeAndFetchProducts)
export const updateQuantityInCart = checkArgsEmpty(updateAndFetchProducts)
export const saveLineNoteForProduct = checkArgsEmpty(saveLineNoteProduct)
export const clearCartRequest = checkArgsEmpty(clearCartOrder)
export const saveCustomPromptForProduct = checkArgsEmpty(
  saveCustomPromptProduct
)
export const validateCustomPrompts = checkArgsEmpty(validateForCustomPrompts)
export const removeRestrictedProduct = checkArgsEmpty(
  removeRestrictedProductsFromCart
)
export const getCompareProductsData = checkArgsEmpty(getCompareProducts)
