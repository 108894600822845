export const DEFAULT_ADD_TO_CART_QUANTITY = 1
export const INPUT_GROUP = 'inputGroup'
export const PRODUCT_MINIMUM_QUANTITY = 0

export const CALLING_LOCATION_MAPPING = {
  isCategoryPage: true,
  isKeyWordPage: false,
  isShoppingListPage: false,
  isPromotionPage: true,
}
