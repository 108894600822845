import add from 'date-fns/add'
import toLower from 'lodash/toLower'
import queryString from 'query-string'
import parse from 'url-parse'
import { isEwayGuid, isValidPostalCode } from '~/services/utils/regexUtils'
import {
  QUERY_STRING_POSTAL_CODE,
  REQUIRED_KEYS as REQUIRED,
} from './autoLoginServiceConstants'
const REQUIRED_KEYS = [...REQUIRED, QUERY_STRING_POSTAL_CODE]
const REQUIRED_KEYS_LOWER_CASED = REQUIRED_KEYS.map(toLower)

/**
 * Function that filters out everything and only return buyerId, sessionId, and orderId
 * As an object
 * @param {Object} values - Should contain a buyerId, sessionId, and orderId
 * @param {string} values.buyerId - the buyerId of the user, should be guid if not, is ignored
 * @param {string} values.sessionId - the sessionId of the user, should be guid if not, is ignored
 * @param {string} values.orderId - the orderId of the user, should be guid if not, is ignored
 * @returns A filter object with proper casing of { buyerId, sessionId, orderId } if they exist in the payload
 */
export function getAutoLoginValues(values = {}) {
  const valuesFormatted = getAutoLoginEntries(values)
  const result = Object.fromEntries(valuesFormatted)
  return result
}

export function getAutoLoginEntries(values) {
  return Object.entries(values)
    .filter(includesRequiredKeys) // filter only the required keys
    .filter(entriesAreGuidOrPostalCode) // filter only the required keys
    .map(([key, value]) => {
      const correctCase = REQUIRED_KEYS.find(
        (e) => e.toLowerCase() === key.toLowerCase()
      )
      return [correctCase, value]
    })
}

export function includesRequiredKeys([key]) {
  return REQUIRED_KEYS_LOWER_CASED.includes(key.toLowerCase())
}

export const entriesAreGuidOrPostalCode = (e) => {
  const [, value] = e
  const isValid = !!isEwayGuid(value) || isValidPostalCode(value)
  return isValid
}

export function generateDaysFromNow(days) {
  if (!days) {
    return new Date()
  }
  return add(new Date(), { days })
}

export function removeAutoLoginParams(url) {
  const { query, origin, pathname } = parse(url)
  const items = queryString.parse(query)
  const extractedEntries = Object.entries(items).filter((e) => {
    const [key] = e
    return !REQUIRED_KEYS_LOWER_CASED.includes(key.toLowerCase())
  })
  const newBuiltEntries = Object.fromEntries(extractedEntries)
  const newParams = queryString.stringify(newBuiltEntries)
  const result = `${origin}${pathname}${newParams ? `?${newParams}` : ''}`
  return result
}
