import { forwardRef } from 'react'
import Button from 'shared/Button'
import { isProduction } from '~/config/env/environmentUtils'
import styles from './CloseButton.module.scss'

const CloseButton = forwardRef(({ ...rest }, ref) => {
  return (
    <Button
      icon="close"
      variant="link"
      className={styles.closeButton}
      {...rest}
      ref={ref}
    />
  )
})

if (!isProduction()) {
  CloseButton.displayName = 'CloseButton'
}

export default CloseButton
