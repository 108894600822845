import {
  ACCOUNT_CREDIT_CARD,
  ADD_CHECKOUT_CREDIT_CARD,
  ADD_PROFILE_CREDIT_CARD,
  ON_ACCOUNT,
} from './PaymentOptionItemApiConstants'

export const NONE = 'none'
export const ACCOUNT_CREDIT = 'accountCredit'
export const PLACE_ON_ACCOUNT = 'placeOnAccount'
export const PROFILE = 'profile'
export const USE_PROVIDED = 'useProvided'
export const USE_PREVIOUSLY_ENTERED = 'creditCardPreviouslyEntered'

export const PAYMENT_VARIANT_PROPS = {
  [PLACE_ON_ACCOUNT]: {
    id: PLACE_ON_ACCOUNT,
    'data-test': 'on-account',
    value: ON_ACCOUNT, // REFACTOR: can probably be deleted
  },
  [USE_PREVIOUSLY_ENTERED]: {
    id: USE_PREVIOUSLY_ENTERED,
    'data-test': 'previously-entered-credit-card',
    // has no value, because only shown on UI
  },
  [PROFILE]: {
    id: PROFILE,
    'data-test': 'profile-credit-card',
    value: ADD_PROFILE_CREDIT_CARD, // REFACTOR: can probably be deleted
  },
  [USE_PROVIDED]: {
    id: USE_PROVIDED,
    'data-test': 'credit-card-on-order',
    value: ADD_CHECKOUT_CREDIT_CARD, // REFACTOR: can probably be deleted
  },
  [ACCOUNT_CREDIT]: {
    id: ACCOUNT_CREDIT,
    'data-test': 'account-credit-card',
    value: ACCOUNT_CREDIT_CARD, // REFACTOR: can probably be deleted
  },
}

export const PAYMENT_OPTIONS = Object.values(PAYMENT_VARIANT_PROPS)
