import { datadogRum } from '@datadog/browser-rum'
import isEmpty from 'lodash/isEmpty'
import getConfig from 'next/config'
import { getLogProviderByName } from '~/config/env/logProviders/logProviderIsomorphicUtils'
import { SOME_LOG_PROVIDER } from '~/config/env/logProviders/logProvidersConfig'
import { logErrorToConsoleOnlyInDev } from '../logger'
const { logProviderName = SOME_LOG_PROVIDER } = getConfig().publicRuntimeConfig

export function logError(
  error,
  isLoggedIn = false,
  enableErrorReporting = true
) {
  if (!isLoggedIn || (isLoggedIn && enableErrorReporting)) {
    const { isDataDog } = getLogProviderByName(logProviderName)
    // TODO:
    // Condition here not good, should be opposite
    // If object is not error, we should log it in dev
    if (!isEmpty(error) && error instanceof Error) {
      logErrorToConsoleOnlyInDev(
        'Tried to pass either empty error, or object that is not an error: ',
        error
      )
    }
    if (isDataDog) {
      datadogRum?.addError?.(error)
    } else {
      // send to another log provider
    }
  }
}
