import { ENGLISH, FRENCH } from '@redux/constants'
import DOMPurify from 'dompurify'
import attempt from 'lodash/attempt'
import isError from 'lodash/isError'
import mapKeys from 'lodash/mapKeys'
import pickBy from 'lodash/pickBy'
import ReactTooltip from 'react-tooltip'
import { getHostUrl } from './configUtils'
import { getFullLocale } from './languageUtils'
import { isValidEmailAddress } from './regexUtils'

const HOST_URL = getHostUrl()

export const isString = (value) =>
  typeof value === 'string' || value instanceof String

// Object.fromEntries polyfill
// https://github.com/feross/fromentries/blob/master/index.js
export const fromEntries = (iterable) => {
  return [...iterable].reduce(
    (obj, { 0: key, 1: val }) => Object.assign(obj, { [key]: val }),
    {}
  )
}

export const keysToLoweCase = (obj) => {
  return mapKeys(obj, (v, k) => k.toLowerCase())
}

export const removeAndTrimEmptyValues = (obj) => {
  return Object.keys(obj || {})
    .filter((k) => obj[k] !== null && obj[k] !== undefined) // Remove undef. and null.
    .filter((k) => obj[k] !== '') // Remove empty strings
    .filter((k) => obj[k].length !== 0) // Remove empty strings
    .reduce(
      (newObj, k) =>
        typeof obj[k] === 'object'
          ? { ...newObj, ...{ [k]: removeAndTrimEmptyValues(obj[k]) } } // Recurse.
          : {
              ...newObj,
              ...{ [k]: isString(obj[k]) ? obj[k].trim() : obj[k] },
            }, // Copy value.
      {}
    )
}

export const formatToCanadianPhoneArray = (number) =>
  number?.match(/^(\d{3})(\d{3})(\d{4})$/) || []

export const removeUseLessCountValue = (obj) => {
  return Object.keys(obj || {})
    .filter((k) => k !== 'count') // Remove count
    .reduce(
      (newObj, k) =>
        typeof obj[k] === 'object'
          ? { ...newObj, ...{ [k]: removeUseLessCountValue(obj[k]) } } // Recurse.
          : {
              ...newObj,
              ...{ [k]: isString(obj[k]) ? obj[k].trim() : obj[k] },
            }, // Copy value.
      {}
    )
}

const ENCODED_CHARACTERS = [
  { key: '&amp;', value: '&' },
  { key: '&lt;', value: '<' },
  { key: '&gt;', value: '>' },
]
export const purifyHtmlString = (
  item,
  options = {},
  optionalSpanProps = {}
) => {
  if (typeof item !== 'string') {
    return item
  }
  ENCODED_CHARACTERS.forEach(({ key, value }) => {
    item = item.replace(new RegExp(key, 'g'), value)
  })

  const sanitizer = DOMPurify.sanitize || ((string) => string)
  const pureString = (
    <span
      dangerouslySetInnerHTML={{ __html: sanitizer(item, options) }}
      {...optionalSpanProps}
    />
  )
  return pureString
}

/**
 *
 * @param obj Expects an object you want to rename the keys from.
 * @param keys Expects an object you want to rename the keys to.
 */
// The optimal way to do it would be to invert the sorting algorithm,
// map on the keys (since there are naturally less)
export const renameKeys = (obj, keys) => {
  // Bail Early
  if (!Object.entries(keys).length === 0 && obj.constructor === Object) {
    return obj
  }

  return fromEntries(
    Object.entries(obj).map((line) =>
      Object.keys(keys).includes(line[0]) // If there's a match remplace that key.
        ? [keys[line[0]], line[1]]
        : line
    )
  )
}

export const wrapWithCurlyBrace = (value) => {
  if (value === undefined || !value.length) {
    return ''
  }
  return hasCurlyBrace(value) ? value : withCurlyBrace(value)
}

export const wrapWithCurlyBraceAndTrim = (value) => {
  return wrapWithCurlyBrace(value).replace(/ /g, '')
}

const hasCurlyBrace = (value) => {
  return value.includes('{') && value.includes('}')
}

const withCurlyBrace = (value) => {
  return `{${value}}`.replace('{{', '{').replace('}}', '}')
}

export const removeUndefinedValues = (obj) =>
  pickBy(obj, (v) => v !== undefined)

const pipe =
  (f1, ...fns) =>
  (...args) =>
    fns.reduce((res, fn) => fn(res), f1.apply(null, args))

const capitalizeFirstLetter = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1)

/**
 * Name: toCamelCase
 * Desc: convert string to camel case
 * @param {*} str
 */
export const toCamelCase = (str = '') =>
  str
    .replace(/\s(.)/g, function ($1) {
      return $1.toUpperCase()
    })
    .replace(/\s/g, '')
    .replace(/^(.)/, function ($1) {
      return $1.toLowerCase()
    })

export const camelAndCapitalize = pipe(toCamelCase, capitalizeFirstLetter)

/**
 *
 * @param {string} value Prevents having "undefined" values, mainly used for css module
 */

export function priceFormat(priceVal = 0, language = 'en', decimals = 2) {
  let formattedPrice = ''
  if (isNumeric(priceVal)) {
    const locale = getFullLocale(language)
    const currency = currenciesByLanguage[language]
    formattedPrice = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
      maximumFractionDigits: decimals,
    })
      .format(priceVal)
      .replace('CA', '') // on windows, it ads the CA need to report to node.
  }
  return formattedPrice
}

const currenciesByLanguage = {
  en: 'USD',
  fr: 'CAD',
}

export function isNumeric(num) {
  return !isNaN(num)
}

export function customDateFormat(dateString, locale = 'fr-ca') {
  let newDate = ''
  if (dateString) {
    const date = new Date(dateString)
    newDate =
      !isNaN(date.getTime()) && new Intl.DateTimeFormat(locale).format(date)
  }
  return newDate
}

export function checkKeysEmpty(obj = {}) {
  if (!obj || Object.keys(obj).length === 0) {
    return true
  }
  return false
}

export function getLanguageSuffix(lang) {
  const languages = {
    [ENGLISH]: 'en-CA',
    [FRENCH]: 'fr-CA',
  }
  return languages[lang]
}

export const isEmptyArray = (array) => {
  return Array.isArray(array) && !array.length
}

export const SHOW_LOGO_DEFAULT_VALUE = 1 //default value of showLogo
export function canShowLogo(settings) {
  return settings?.showLogo === undefined
    ? SHOW_LOGO_DEFAULT_VALUE
    : settings.showLogo
}
export function isGroupOrderFromBuyer(buyer) {
  return buyer.inviteeState > 0
}

export function queryStringToObject(queryString) {
  if (!queryString) {
    return {}
  }
  return JSON.parse(
    '{"' +
      queryString
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"') +
      '"}'
  )
}

export function objectToQueryString(obj) {
  const str = []
  for (const p in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(p)) {
      str.push(p + '=' + obj[p])
    }
  }
  return str.join('&')
}

const ZERO_STRING = '0'
export function getItemUrl(language, hostUrl, item) {
  item.Url = !item.Url ? (item.Id !== ZERO_STRING ? item.Id : '#') : item.Url

  return !isNaN(item.Url)
    ? `${hostUrl}/${language}/Shopping/Search/Category/${
        item.Url
      }?categorydescription=${item.Name.replace(' ', '+')}&filter=0`
    : item.Url.replace('~', hostUrl)
}

const ESCAPE_KEY = 27
export const isEdgeBrowser = () => {
  return /Edge\/\d./i.test(navigator.userAgent)
}
/**
 * Used to prevent user typing letters in the input field
 * When user types letter "a" - it returns an empty string
 * @param {string} str - Expect to receive a string
 * @returns A string without digits
 */
export function filterNumbers(str = '') {
  const finalString = !isString(str) ? str.toString() : str
  return finalString.replace(/\D/g, '')
}

export function generatePdpUrl(productId, queryString) {
  let pdpUrl = `/product-detail/product/${productId}`
  if (queryString) {
    pdpUrl += `${queryString}`
  }
  return pdpUrl
}

export const containsHostUrl = (url) => {
  return url?.includes(HOST_URL)
}

export const isQueryStringExists = (url) => url && url.indexOf('?') !== -1

export const addQueryString = (url, query) => {
  if (!url || !containsHostUrl(url) || url.includes(query)) {
    return url
  }
  return appendQueryString(url, query)
}

export const hasALabelAndFee = (x) => x.name && x.value
/**
 * Higher order function that handles an error
 * Inspired by WesBos https://wesbos.github.io/Async-Await-Talk/#59
 * @param {*} fn
 * @param {*} logType
 */
export const handleAsyncError =
  (fn, logType = 'warn') =>
  (...params) =>
    // eslint-disable-next-line no-console
    fn(...params).catch((err) => console[logType](err))

export function appendQueryString(url, query) {
  const separator = isQueryStringExists(url) ? '&' : '?'
  return `${url}${separator}${query}`
}

/**
 * Recursively transforms an object and children object to camel case keys
 * https://stackoverflow.com/questions/12931828/convert-returned-json-object-properties-to-lower-first-camelcase
 * replaces the camel case package
 * https://www.npmjs.com/package/camelcase-keys
 * @param {object} o expects an object
 */
export function camelCaseKeys(o) {
  let origKey, newKey, value
  const newO = {}
  if (o instanceof Array) {
    return o.map(function (value) {
      if (typeof value === 'object') {
        value = camelCaseKeys(value)
      }
      return value
    })
  }
  for (origKey in o) {
    // eslint-disable-next-line no-prototype-builtins
    if (o.hasOwnProperty(origKey)) {
      newKey = (
        origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey
      ).toString()
      value = o[origKey]
      if (
        value instanceof Array ||
        (value !== null && value?.constructor === Object)
      ) {
        value = camelCaseKeys(value)
      }
      newO[newKey] = value
    }
  }

  return newO
}

/**
 * Return n number array
 * @returns Array
 */
export const generateArrayByLength = (length) => {
  return Array.from(Array(length).keys())
}

/**
 * Name: hasValidJsonString
 * To check response is json string or not
 * @param {string} jsonString
 */
export const hasValidJsonString = (jsonString) => {
  try {
    const jsonObj = JSON.parse(jsonString)
    return jsonObj && typeof jsonObj === 'object'
  } catch (error) {
    return false
  }
}
/**
 * Check multiple email address with any separator
 * @param {string} emailAddress
 * @param {string} splitter
 */
export const isValidMultipleEmailAddress = (emailAddress, splitter) => {
  const emailsAddress = emailAddress.replace(/;\s*$/, '')
  const emails = emailsAddress.split(splitter)
  const filterEmails = getValidEmails(emails)
  return filterEmails?.length === emails?.length
}
const getValidEmails = (emails) => {
  return emails.filter((email) => {
    return isValidEmailAddress(email)
  })
}

export function isJSON(str) {
  return !isError(attempt(JSON.parse, str))
}

export function safeParse(value) {
  return !isJSON(value) ? value : JSON.parse(value)
}

export const maybe = (condition, callback) => (condition ? callback : null)

export function isEscPress(e) {
  return e.keyCode === ESCAPE_KEY
}

export const removeStringBraces = (str) => {
  return str.replace(/{icon}/g, '')
}

const FOCUS = 'focus'
export const handleTooltipShowHide = (e) => {
  return e.type === FOCUS
    ? ReactTooltip.show(e.target)
    : ReactTooltip.hide(e.target)
}

/**
 *
 * @param {number} stopAfter - Time, in ms .ie 1000, that it takes to stop the polling function to look for something.
 * @param {Object.<string, number>} valueToLookFor - The actual value that the polling function should look for. Generally it'll be a "window.something" value.
 * @param {string} message - The error message shown when the value is not found after the "stopAfter" has elapsed.
 * @param {number} [pollSpeed=500] The speed at which it should poll the value defaults to 500ms.
 */
export function pollValueExists(
  valueToLookFor,
  stopAfter,
  message,
  pollSpeed = 500
) {
  if (!stopAfter || valueToLookFor) {
    return false
  }
  let interval
  let timeout
  const STOP_AFTER = stopAfter
  return new Promise((resolve) => {
    interval = setInterval(() => {
      if (valueToLookFor) {
        resolve(true)
        clearInterval(interval)
        clearTimeout(timeout)
      }
    }, pollSpeed)

    timeout = setTimeout(() => {
      // eslint-disable-next-line no-console
      message && console.warn(message)
      clearInterval(interval)
      resolve(false)
    }, STOP_AFTER)
  })
}

//https://stackoverflow.com/questions/14636536/how-to-check-if-a-variable-is-an-integer-in-javascript
export const isInteger = (value) => {
  const x = parseFloat(value)
  return !isNaN(value) && (x | 0) === x
}

export const removeWhiteSpace = (value) => {
  return value.replace(/\s/g, '')
}

export function includesProductDetail(url) {
  return url?.includes('/product-detail')
}

const REPLACE = 'replace'
export const prepareData = (val, fieldName) => {
  return [
    {
      op: REPLACE,
      path: fieldName,
      value: val,
    },
  ]
}

export const byTruthyValues = ([, value]) => !!value

export function filterAndMapTruthyValues(mergedMap = {}) {
  return Object.entries(mergedMap)
    .filter(byTruthyValues) /* return truthy values.*/
    .map(([key]) => key)
}

export function addProductId(p = {}) {
  return { ...p, ...(p.OrderProductId && { id: p.OrderProductId }) }
}

export function openInNewTab(url = '', { focus = false } = {}) {
  if (url) {
    const win = window.open(url, '_blank')
    if (win && focus) {
      win?.focus?.()
    }
  }
}
