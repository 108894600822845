import isEmpty from 'lodash/isEmpty'
import getConfig from 'next/config'
import { getCookie } from 'services/cookieManager'
import { isProduction } from '~/config/env/environmentUtils'
import { ENGLISH } from '~/redux/constants'
const HOST_URL_KEY = 'HOSTURL'
const { publicRuntimeConfig: configs = {} } = getConfig() || {}
import colors from 'colors/safe'

export const getHostUrl = (url = '') => {
  const hostUrlStripped = removeTrailingSlash(url)
  const { hostUrl: hostUrlEnv } = configs
  const hostUrl = getCookie(HOST_URL_KEY) || hostUrlStripped || hostUrlEnv
  if (!hostUrl) {
    throwConfigError(
      `Did you perhaps forget to configure the host env variable ?`
    )
  }
  return hostUrl
}

export const getAnalyticsAccountFromEnv = () => {
  const { analyticsAccount } = getConfig().publicRuntimeConfig
  if (!analyticsAccount) {
    throwConfigError(
      'Did you forget to set your analytics account name? in "analyticsGlobalSettings.js", please do so, or else nothing will be tracked! should be like: "staplestest" for dev, or staging environnement'
    )
  }
  return analyticsAccount
}

export const getKheopsAppIdFromEnv = () => {
  const { kheopsAppIdSecret } = configs
  if (!kheopsAppIdSecret) {
    throwConfigError(
      'Did you forget to set the Kheops application id for dev? The value should be 103 to point towards our "Savana"cluster'
    )
  }
  return kheopsAppIdSecret
}

export const getLiveHelpUrl = () => {
  const { liveHelpUrl } = configs
  return liveHelpUrl
}
/**
 *
 * Corresponds to Amon, the newest api layer url created the oldest api that exists in the eway ecosystem
 * Example usage
 * ```js
 * const API_URL = getApiUrl()
 * ```
 * @returns string
 */
export const getApiUrl = () => {
  const { apiUrl } = configs
  if (!apiUrl) {
    throwConfigError(
      `Did you perhaps forget to configure the API_URL variable? Create an .env variable that contains the API_URL in the .env file at the root of the project`
    )
  }
  return apiUrl
}

/**
 * Corresponds to Kheops the oldest api that exists on the eway ecosystem
 * Example usage
 * ```js
 * const OLD_API_URL = getOldApiUrl()
 * ```
 * @returns string
 */
export const getOldApiUrl = () => {
  const { oldApiUrl } = configs
  if (!oldApiUrl) {
    throwConfigError(
      `Did you perhaps forget to configure the OLD_API_URL variable? It should points towards the host where Kheops is`
    )
  }
  return oldApiUrl
}

export const getMailServiceUrl = () => {
  const { mailServiceUrl } = configs
  if (!mailServiceUrl) {
    throwConfigError(
      `Did you perhaps forget to configure the MAIL_SERVICE_URL variable? It should points towards the host where Kheops is`
    )
  }
  return mailServiceUrl
}

export const canShowPostalCodeSignIn = () => {
  const { postalCodeSignIn } = configs
  if (!postalCodeSignIn) {
    throwConfigError(
      `Did you perhaps forget to configure the POSTAL_CODE_SIGN_IN variable? please do so, or the "Postal code signIn" feature will be unavailable`
    )
  }
  return !!(postalCodeSignIn && parseInt(postalCodeSignIn) > 0)
}

export const catalogueGloballyEnabled = () => {
  const { enableNeedCatalogueButton } = configs
  if (!enableNeedCatalogueButton) {
    throwConfigError(
      `Did you perhaps forget to configure the ENABLE_NEED_A_CATALOGUE_BUTTON variable? please do so, or the "Enable Catalogue button" feature will be unavailable`
    )
  }
  return !!(
    enableNeedCatalogueButton && parseInt(enableNeedCatalogueButton) > 0
  )
}
export const getCdnUrl = () => {
  const { cdnUrl } = configs
  if (!cdnUrl) {
    throwConfigError(
      'Did you perhaps forget to configure the CDN_URL variable? Or else you might not get images in dev'
    )
  }
  return cdnUrl
}

export const getHelpCentreUrl = () => {
  const { helpCentreUrl } = configs
  if (!helpCentreUrl) {
    throwConfigError(
      'Did you perhaps forget to configure the HELP_CENTRE_URL variable? Or else you might not get images in dev'
    )
  }
  return helpCentreUrl
}

export const getKountApiUrl = () => {
  const { kountApiUrl } = configs
  if (!kountApiUrl) {
    throwConfigError(
      `Did you perhaps forget to configure the KOUNT_API_URL variable? It prevents fraudulent orders, to fix: run npm run create-env`
    )
  }
  return kountApiUrl
}

export const getUseCdn = () => {
  const { useCdnDownload } = configs
  if (!useCdnDownload) {
    throwConfigError(
      'Did you perhaps forget to configure the USE_CDN_DOWNLOAD variable? if true, you get images from a CDN, if not fallbacks to old eway images'
    )
  }
  return checkIfTrue(useCdnDownload)
}

export const getTurnToSiteKey = (language = ENGLISH) => {
  const { turnToSiteKeyEn, turnToSiteKeyFr } = configs
  if (!turnToSiteKeyEn || !turnToSiteKeyFr) {
    throwConfigError(
      `Did you perhaps forget to configure the turnToSiteKeyEn, turnToSiteKeyFr variable? Create an .env variable that contains the TURN_TO_SITE_KEY_EN,TURN_TO_SITE_KEY_FR in the .env file at the root of the project`
    )
  }
  return language === ENGLISH ? turnToSiteKeyEn : turnToSiteKeyFr
}

export const getSpexWidgetAuthKeyAndProfileId = () => {
  const { spexWidgetAuthKey, spexWidgetProfileId } = configs
  if (!spexWidgetAuthKey || !spexWidgetProfileId) {
    throwConfigError(
      `Did you perhaps forget to configure the spexWidgetAuthKey, spexWidgetProfileId variable? Create an .env variable that contains the SPEX_WIDGET_AUTH_KEY,SPEX_WIDGET_PROFILE_ID in the .env file at the root of the project`
    )
  }
  return { authKey: spexWidgetAuthKey, profileId: spexWidgetProfileId }
}

export const getAddressValidationVars = () => {
  const { addressValidation = {} } = getConfig().serverRuntimeConfig
  const { url, key, id, secret, tokenUrl } = addressValidation
  const addressValidationVarsEmpty = isEmpty(addressValidation)
  const someAreEmpty =
    Object.values(addressValidation).some((v) => isEmpty(v)) ||
    addressValidationVarsEmpty

  if (!isProduction()) {
    if (someAreEmpty) {
      throwConfigError(
        `Did you perhaps forget to configure the

          ADDRESS_VALIDATION_URL,
          ADDRESS_VALIDATION_KEY,
          ADDRESS_VALIDATION_ID,
          ADDRESS_VALIDATION_SECRET
          ADDRESS_VALIDATION_TOKEN_URL

          variables? Create an .env variable that contains the those values in the .env file at the root of the project`
      )
    }
  } else {
    if (someAreEmpty) {
      // eslint-disable-next-line no-console
      console.warn(`Cannot use Address Validation Service, see configuration`)
    }
  }
  return { url, key, id, secret, tokenUrl }
}

export function getAddressValidationIsDisabled() {
  const { addressValidationDisable } = getConfig().publicRuntimeConfig
  return checkIfTrue(addressValidationDisable)
}

export function getUseProductionSegment() {
  const { useProductionSegmentIntegration } = getConfig().publicRuntimeConfig
  return checkIfTrue(useProductionSegmentIntegration)
}

/**
 * Corresponds to acacia the  api that exists on the eway ecosystem
 * Example usage
 * ```js
 * const ACACIA_API_URL = getAcaciaApiUrl()
 * ```
 * @returns string
 */
export const getAcaciaApiUrl = () => {
  const { acaciaApiUrl } = configs
  if (!acaciaApiUrl) {
    throwConfigError(
      `Did you perhaps forget to configure the ACACIA_API_URL variable? It should points towards the host where Acacia is`
    )
  }
  return acaciaApiUrl
}

/**
 * Corresponds to remora the  api that exists on the eway ecosystem
 * Example usage
 * ```js
 * const REMORA_API_URL = getRemoraApiUrl()
 * ```
 * @returns string
 */
export const getRemoraApiUrl = () => {
  const { remoraApiUrl } = configs
  if (!remoraApiUrl) {
    throwConfigError(
      `Did you perhaps forget to configure the REMORA_API_URL variable? It should points towards the host where Remora is`
    )
  }
  return remoraApiUrl
}

export const getInkAndTonerFeatureFlag = () => {
  const { enableNewInkAndToner = false } = configs
  return checkIfTrue(enableNewInkAndToner)
}

export const getPunchoutServiceFlag = () => {
  const { enableNewPunchoutService = false } = configs
  return checkIfTrue(enableNewPunchoutService)
}

const toFixIt = `${colors.green(
  '\n 🐛 To fix the issue run npm run create-env \n Then run npm run dev again. All should work again.'
)}`

function checkIfTrue(value) {
  if (value === '1' || value === 'true' || value === true) {
    return true
  }
  return false
}

function withFix(message) {
  return `${message}
  ${toFixIt}`
}

function throwConfigError(message = '') {
  // If quokka is running the file don't show the error
  if (process.env.WALLABY_ENV === 'true') {
    return
  }
  throw new Error(withFix(message))
}

function removeTrailingSlash(url) {
  return url.replace(/\/$/, '')
}

export const getNewPromotionsFlag = () => {
  const { enableNewPromotions = 0 } = configs
  return checkIfTrue(enableNewPromotions)
}

export const getPeacockApiUrl = () => {
  const { peacockApiUrl } = configs
  if (!peacockApiUrl) {
    throwConfigError(
      `Did you perhaps forget to configure the PEACOCK_API_URL variable? Create an .env variable that contains the PEACOCK_API_URL in the .env file at the root of the project`
    )
  }
  return peacockApiUrl
}
