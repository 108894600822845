import { getCookie } from 'services/cookieManager'
import { COOKIES } from '~/services/constants'
export function withAuth(fn) {
  return (...args) => {
    const isUnauthorized = [...args].pop()
    return isUnauthorized ? {} : fn(...args)
  }
}

export function getOptions(options, disableCache, isAddToken) {
  let mergedOptions = options
  if (disableCache) {
    mergedOptions = addDisableCachingHeaders(mergedOptions)
  }

  if (isAddToken) {
    mergedOptions = addAuthToken(mergedOptions)
  }
  return mergedOptions
}

const addDisableCachingHeaders = (options = {}) => ({
  ...options,
  headers: {
    ...options.headers,
    pragma: 'no-cache',
    'cache-control': 'no-cache',
  },
})

export const addAuthToken = (options = {}) => {
  const apiToken = getCookie(COOKIES.APIAUTH)
    ? { APIAUTH: getCookie(COOKIES.APIAUTH) }
    : {}

  return {
    ...options,
    headers: {
      ...options.headers,
      ...apiToken,
    },
  }
}
