import PropTypes from 'prop-types'
import { Component } from 'react'
import { initializeStore } from '@redux/store'
import { COOKIES } from 'services/constants'
import { getAllCookies, setCookie } from '~/services/cookieManager'
import { getAllHeaders } from '~/services/headersManager'
import { isBrowser } from '~/services/utils/runtimeUtils'
import { formatCookiesFromContext } from './reduxStoreUtils'
import { isProduction } from '~/config/env/environmentUtils'

const isServer = typeof window === 'undefined'
const __NEXT_REDUX_STORE__ = '__NEXT_REDUX_STORE__'

// https://reactjs.org/docs/higher-order-components.html
export default function withReduxStore(App) {
  const ReduxWrapper = class extends Component {
    // Start by rendering the HOC on the server
    static async getInitialProps(appContext) {
      // Get from headers, then fallback to cookies
      const cookies = isBrowser()
        ? getAllCookies()
        : appContext.ctx.req?.cookies
      const headers = getAllHeaders(appContext.ctx.req)
      const formattedCookies = formatCookiesFromContext(cookies)

      const session = {
        language: headers.language || formattedCookies.language,
        buyerId: headers.buyerId || formattedCookies.buyerId,
        sessionId: headers.sessionId || formattedCookies.sessionId,
        APIAUTH: headers.tokenHeader || formattedCookies.tokenHeader,
      }

      // Get or Create the store with `undefined` as initialState
      // This allows you to set a custom default initialState/
      const reduxStore = getOrCreateStore(session)
      // Set the cookie in store after creating it or else API won't be settled
      session.APIAUTH && setCookie(COOKIES.APIAUTH, session.APIAUTH)

      // Provide the store to getInitialProps of pages
      appContext.ctx.reduxStore = reduxStore

      // Then, call initialize method in EwayApp
      const pageAppProps = App.getInitialProps
        ? await App.getInitialProps(appContext)
        : {}

      // merge redux initial state with app props.
      const appProps = {
        ...pageAppProps,
        initialReduxState: reduxStore.getState().state,
      }

      return appProps
    }

    // Rendered in the server, then the browser (constructor called in both)
    constructor(props) {
      super(props)
      const { session } = props.initialReduxState || {}
      this.reduxStore = getOrCreateStore(session)
    }

    // Rendered in the browser
    render() {
      return <App {...this.props} reduxStore={this.reduxStore} />
    }
  }

  // props validation
  ReduxWrapper.propTypes = {
    initialReduxState: PropTypes.object,
  }

  if (!isProduction()) {
    ReduxWrapper.displayName = 'ReduxWrapper'
  }

  return ReduxWrapper
}

function getOrCreateStore(initialState) {
  // Always make a new store if server, otherwise state is shared between requests
  if (isServer) {
    return initializeStore(initialState)
  }

  // Create store if unavailable on the client and set it on the window object
  if (!window[__NEXT_REDUX_STORE__]) {
    window[__NEXT_REDUX_STORE__] = initializeStore(initialState)
  }
  return window[__NEXT_REDUX_STORE__]
}
