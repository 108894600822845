import omit from 'lodash/omit'
import PropTypes from 'prop-types'
export const BOX_PROP_TYPES = {
  as: PropTypes.string,
  align: PropTypes.string,
  bg: PropTypes.string,
  bb: PropTypes.string,
  border: PropTypes.string,
  borderBottom: PropTypes.string,
  borderRight: PropTypes.string,
  borderColor: PropTypes.string,
  borderStyle: PropTypes.string,
  borderWidth: PropTypes.string,
  right: PropTypes.string,
  top: PropTypes.string,
  opacity: PropTypes.string,
  children: PropTypes.node,
  color: PropTypes.string,
  cursor: PropTypes.string,
  direction: PropTypes.string,
  display: PropTypes.string,
  flex: PropTypes.string,
  flexGrow: PropTypes.string,
  flexWrap: PropTypes.string,
  fontFamily: PropTypes.string,
  fontSize: PropTypes.string,
  fontWeight: PropTypes.string,
  h: PropTypes.string,
  height: PropTypes.string,
  justify: PropTypes.string,
  letterSpacing: PropTypes.string,
  lineHeight: PropTypes.string,
  listStyle: PropTypes.string,
  m: PropTypes.string,
  maxH: PropTypes.string,
  maxW: PropTypes.string,
  mb: PropTypes.string,
  minH: PropTypes.string,
  minW: PropTypes.string,
  ml: PropTypes.string,
  mr: PropTypes.string,
  mt: PropTypes.string,
  p: PropTypes.string,
  pb: PropTypes.string,
  pl: PropTypes.string,
  position: PropTypes.string,
  pr: PropTypes.string,
  pt: PropTypes.string,
  rounded: PropTypes.string,
  textAlign: PropTypes.string,
  textTransform: PropTypes.string,
  w: PropTypes.string,
  zIndex: PropTypes.string,
  visibility: PropTypes.string,
}
export const VALUES_CONSIDERED_SELF_CLOSING = ['hr', 'input']
export const BOX_STYLES = Object.keys(omit(BOX_PROP_TYPES, 'as', 'children'))
