export default {
  en: {
    pleaseSelectLabel: 'Please select',
    selectPlaceHolder: 'Please Select',
    formAlertNotificationMsg: `There are mandatory custom fields below with missing or invalid entries. Please review all error messages below and correct your data in order to proceed with your order.`,
    customPromptLabel: 'Custom Prompt',
  },
  fr: {
    pleaseSelectLabel: 'Veuillez sélectionner',
    selectPlaceHolder: 'Veuillez sélectionner',
    formAlertNotificationMsg: `Il y a ci-dessous des champs personnalisés avec des entrées manquantes ou invalides. Veuillez réviser tous les messages d'erreur ci-dessous et corriger vos données, afin de poursuivre votre commande.`,
    customPromptLabel: 'Champ personnalisé',
  },
}
