import PropTypes from 'prop-types'
import { linkFromRelativeUrl } from 'shared/EwayRouter/EwayRouterUtils'
import EwayLink from '~/components/shared/EwayLink'
export default function FeeLink({ language, href: relativeUrl, children }) {
  const url = linkFromRelativeUrl(relativeUrl, language)
  return (
    <EwayLink target="_blank" href={url} textDecoration>
      {children}
    </EwayLink>
  )
}

FeeLink.propTypes = {
  children: PropTypes.node,
  language: PropTypes.string,
  href: PropTypes.string,
}
