import PropTypes from 'prop-types'
import { createContext, useContext } from 'react'
const CookiesContext = createContext({})
const { Provider, Consumer } = CookiesContext

/**
 * This context should be small, and stay small,
 * Please do not add any business logic that "shows" or hides "things" in components
 */
export function CookiesContextProvider({ children, cookies }) {
  return <Provider value={cookies}>{children}</Provider>
}

CookiesContextProvider.propTypes = {
  children: PropTypes.node,
  cookies: PropTypes.object,
}

/**
 * Returns cookies rendered server side, but also on the browser
 * @returns server side rendered cookies, but also those rendered on the browser
 */
export function useCookies() {
  return useContext(CookiesContext)
}

export { Consumer as CookiesContextConsumer }
export default CookiesContext
