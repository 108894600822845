// Following configuration only applies to production servers
// For story  https://jiraent.staples.com/browse/SCE-5479

// If you want to disable logging for a specific server in production add its name in below array
// use const disableAppLogging = ['CQCANJ1WVPEWW01']
// That would disable logging for server 01 in production.
const DISABLE_APP_LOGGING_FOR = []
exports.DISABLE_APP_LOGGING_FOR = DISABLE_APP_LOGGING_FOR

// Sets the providers for the server by name in production we want.
/**
 * Leave space for new providers to be added
 */
const SOME_LOG_PROVIDER = ''
const DATA_DOG = 'datadog'

// Provider to use by default unless specified
const DEFAULT_PROVIDER = DATA_DOG
exports.DEFAULT_PROVIDER = DEFAULT_PROVIDER

const SERVER_CONFIG = {
  [DATA_DOG]: [
    'CQCLON1WVPEWW01',
    'CQCLON1WVPEWW02',
    'CQCLON1WVPEWW03',
    'CQCLON1WVPEWW04',
    'CQCLON1WVPEWW05',
    'CQCLON1WVPEWW06',
    'CQCLON1WVPEWW07',
    'CQCLON1WVPEWW08',
    'CQCLON1WVPEWW09',
    'CQCLON1WVPEWW10',
    'CQCLON1WVPEWW99',
  ],
  [SOME_LOG_PROVIDER]: [],
}
exports.SERVER_CONFIG = SERVER_CONFIG
exports.SOME_LOG_PROVIDER = SOME_LOG_PROVIDER
exports.DATA_DOG = DATA_DOG
