import cookie, { serialize } from 'cookie'
import isEmpty from 'lodash/isEmpty'
import { getApiUrl, getHostUrl, getOldApiUrl } from 'services/utils/configUtils'
import {
  ENGLISH_LOCALE_OLD_EWAY,
  getOldEwayFullLocale,
} from 'services/utils/languageUtils'
import { ENGLISH } from '~/redux/constants'
import { COOKIES } from '~/services/constants'
import { getAllHeaders } from '~/services/headersManager'
import { logErrorToConsole } from '~/services/logger'
import { camelCaseKeys, renameKeys } from '~/services/utils'
import { authError, ONE_DAY } from './loginConstants'

const HOST_URL = getHostUrl()

const OLD_API_URL = getOldApiUrl()
const API_AUTH_URL = getApiUrl()
  .toLowerCase()
  .substring(0, getApiUrl().lastIndexOf('/'))

/**
 * Name: getIpAddressFromRequest
 * Desc: extract out the client ip address from request
 * @param {object} req
 */

/**
 * When validate credentials is successful,
 * This methods get called to get the APIAUTH
 * @param {string} username
 * @param {string} encryptedPassword
 */

export async function getApiAuth(buyerId, sessionId) {
  if (!buyerId) {
    return handleError(`"getApiAuth": Missing buyerId when getting apiauth`)
  }
  if (!sessionId) {
    return handleError(`"getApiAuth": Missing sessionId when getting apiauth`)
  }

  const response = await fetch(`${API_AUTH_URL}/session`, {
    headers: {
      EwayBuyerId: buyerId,
      EwaySessionId: sessionId,
      appid: process.env.CERBERUS_APP_ID_SECRET,
    },
  })
  if (response.ok) {
    const apiauth = await response.text()
    return Promise.resolve({ isSuccess: response.ok, apiauth })
  }
  const error = new Error('Could not fetch APIAUTH.')
  return Promise.reject({
    isSuccess: false,
    apiauth: '',
    error,
  })
}

function handleError(message) {
  const error = logErrorToConsole(message)
  return formatError(error)
}

function formatError(error) {
  return {
    error: {
      error: error.message,
      stack: error.stack,
    },
  }
}

export function createResponse({ message, status, stack = {} }) {
  return {
    from: 'NextJs API',
    statusCode: status,
    message,
    ...(!isEmpty(stack) && { stack }),
  }
}

export function isJsonString(str) {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

export function safeParse(req) {
  return isJsonString(req.body) ? JSON.parse(req.body) : req.body
}

/**
 * When validate credentials is successful,
 * This methods get called to return the set cookie headers.
 * @param {Object} payload - The payload in order to authenticate the user
 *@param {string} locale - The locale
 * The payload can be 2 things
 * 1. An object with a
 *
 * ```js
 * const object = { username, password }
 * ```
 * FYI: The password needs be to encrypted with the encryption service.
 *
 * 2. An object with a structure of
 * ```js
 * const object = { buyerId, sessionId, orderId}```
 *
 * In order to validate the users is authorized to access the website.
 *
 *

 */
export async function authenticate(payload, locale) {
  const headers = {
    'Content-Type': 'application/json',
    ...(locale && { 'Accept-Language': `${locale}` }),
    EwayApplicationId: process.env.KHEOPS_APP_ID_SECRET || 100,
  }

  const response = await fetch(`${OLD_API_URL}/api/buyer/authenticate`, {
    method: 'POST',
    headers,
    body: JSON.stringify(payload),
  })

  const { status } = response
  const result = await response.json()
  const data = camelCaseKeys(result)
  const { buyerId, sessionId, redirectUrl, messages } = data
  const hasErrorMessage = messages?.length
  const isSuccess = response.ok && !hasErrorMessage
  const isAdminLogin = !!data.adminUserId

  if (isSuccess) {
    return Promise.resolve({
      ...result,
      buyerId,
      sessionId,
      redirectUrl,
      success: true,
      isAdminLogin,
    })
  }
  const finalStatus = hasErrorMessage ? 400 : status
  const error = new Error(authError)

  return Promise.reject({
    ...result,
    status: finalStatus,
    message: messages?.[0].primaryMessage || error,
    success: false,
    isAdminLogin,
  })
}

/**
 * Fetches the WebForm cookies such as .ASPXAUTH to login the user on WebForm pages.
 * @param {Object} payload -  Expects and object that has the buyerId, orderId, and sessionId
 * ```js
 * const object  = { buyerId, sessionId, orderId }
 * ```
 */
export async function fetchWebFormCookies(
  payload,
  locale = ENGLISH_LOCALE_OLD_EWAY
) {
  const response = await fetch(
    `${HOST_URL}/${ENGLISH}/api/buyer/loginpunchout`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': `${locale}`,
        EwayApplicationId: process.env.KHEOPS_APP_ID_SECRET || 100,
      },
      body: JSON.stringify(payload),
    }
  )
  if (response.ok) {
    const data = response.headers.raw()['set-cookie']
    return Promise.resolve({
      isSuccess: response.ok,
      data: formatCookies(data),
      error: {},
    })
  }
  return Promise.reject({
    isSuccess: false,
    error: new Error('Could not fetch WebFormCookies'),
    data: [],
  })
}

/**
 * Formats and parses cookies that need to be set.
 * @param {array} rawCookies - The raw cookies that come from a nodejs server side request
 */
function formatCookies(rawCookies) {
  const formattedCookies = rawCookies
    .map((c) => cookie.parse(c))
    .map((cookie) => {
      const renamedValue = renameKeys(cookie, KEYS_TO_RENAME)
      return renamedValue
    })
    .map((c) => {
      return c.expires ? { ...c, expires: new Date(c.expires) } : c
    })
    .map((e) => {
      const [first, ...rest] = Object.entries(e)
      const [name, value] = first
      const formattedObject = Object.fromEntries([
        ['name', name],
        ['value', value],
        ...rest,
      ])
      return formattedObject
    })
  return formattedCookies
}
const KEYS_TO_RENAME = { SameSite: 'sameSite', Path: 'path' }

export function getLocale(req) {
  return req.headers['accept-language'] || ENGLISH
}

export function getLoginData(req) {
  const ipAddress = getIpAddressFromRequest(req)
  const { language } = getAllHeaders(req)
  const locale = getOldEwayFullLocale(language)
  return { ipAddress, locale, language }
}

export function generateCookies(apiauth, data = {}, options = {}) {
  const { addSecureHeaders = false } = options
  const buyerId = data.BuyerId
  const sessionId = data.SessionId
  const adminId = data.AdminUserId
  const reactCookie = data.Cookies?.find(
    (c) => c.m_name === 'EnableReactPages'
  )?.['m_value']

  const cookiesMap = [
    [COOKIES.APIAUTH, apiauth],
    [COOKIES.ADMIN_ID, adminId],
    [COOKIES.BUYER_ID, buyerId],
    [COOKIES.SESSION_ID, sessionId],
    [COOKIES.ENABLE_REACT_PAGES, reactCookie],
  ]
  const cookies = cookiesMap
    .map((cookie) => {
      const [name, value] = cookie
      if (!value) {
        return
      }
      return serialize(name, value, {
        maxAge: ONE_DAY,
        path: '/',
        ...(addSecureHeaders && { sameSite: 'none', secure: true }),
      })
    })
    .filter(Boolean)
  return cookies
}

export function getIpAddressFromRequest(req) {
  let ipAddress
  // The request may be forwarded from local web server.
  const forwardedIpsStr = req.headers
    ? req.headers['HTTP_X_FORWARDED_FOR'] ||
      req.headers['REMOTE_ADDR'] ||
      req.headers['x-forwarded-for'] ||
      req.headers['X-Forwarded-For']
    : null

  if (forwardedIpsStr) {
    // 'x-forwarded-for' header may return multiple IP addresses in
    // the format: "client IP, proxy 1 IP, proxy 2 IP" so take the
    // the first one
    const forwardedIps = forwardedIpsStr.split(',')
    ipAddress = forwardedIps[0]
  }
  if (!ipAddress) {
    // If request was not forwarded
    ipAddress = req.connection && req.connection.remoteAddress
    ipAddress = ipAddress?.substring(
      ipAddress?.lastIndexOf(':') + 1,
      ipAddress?.length
    )
  }
  return ipAddress || ''
}
