import EcoAndTariffFeeMessage from 'shared/OrderSummary/OrderSummaryDetail/OrderSummaryLine/EcoAndTariffFeeMessage'
import EcoFeeMessage from 'shared/OrderSummary/OrderSummaryDetail/OrderSummaryLine/EcoFeeMessage'
import TariffFeeMessage from 'shared/OrderSummary/OrderSummaryDetail/OrderSummaryLine/TarifFeeMessage/TariffFeeMessage'
const TARIFF_FEE_TYPE = 'tariff'
const ECO_AND_TARIFF_FEE_TYPE = 'eco-and-tariff'
const ECO_FEE_TYPE = 'eco'

/**
 *
 * @param {String} type the type of fee to be returned
 * @param {Object} cartInfo the   cart info that comes from  "getCartOrderData"
 */
export const getFeeAmountByType = (type, cartInfo) => {
  const initialFeeValue = 0
  const feeValue = cartInfo.products?.reduce((acc, currentProduct) => {
    // get the amount
    const feeAmount = currentProduct?.orderProductFees?.[type]?.feeAmount
    // get the quantity
    const productQuantity = currentProduct?.quantity
    // get multiply it
    const feeCalculatedAmount = feeAmount * productQuantity
    const finalFeeAmount = feeCalculatedAmount || 0
    // add it, and accumulate it for each product
    return acc + finalFeeAmount
  }, initialFeeValue)
  return feeValue || 0
}

/**
 * Filters the products with a specific condition
 * @param {*} cartInfo the original data
 * @param {*} condition the actual condition by which we filter the data
 */
export const getProductsWhere = (cartInfo, condition) => {
  const products = cartInfo.products?.filter(condition)
  const filteredCartInfo = {
    ...cartInfo,
    products,
  }
  return filteredCartInfo
}

export const userChooseStandardDelivery = (deliveryDetails) => {
  const DELIVER_ONLY = 1 // user choose deliver only
  return deliveryDetails.deliServOptType === DELIVER_ONLY
}

export const userChooseDeliveryAndInstall = (deliveryDetails) => {
  const DELIVERY_AND_INSTALL = 2 // user choose delivery and install in ui
  return deliveryDetails.deliServOptType === DELIVERY_AND_INSTALL
}

export const userChoosePickUp = (deliveryDetails) => {
  const DELIVERY_PICKUP = 3 // user choose pick-up in ui
  return deliveryDetails.deliServOptType === DELIVERY_PICKUP
}

/**
 * The fee can be unknown "mainly"  of the distance
 * amonst many other factors
 * @param {*} deliveryDetails
 */
export const deliveryFeeIsUnknown = (deliveryDetails) => {
  return deliveryDetails?.isDeliUnknown === true
}

/**
 * The fee can be unknown "mainly" of the distance
 * amonst many other factors
 * @param {*} deliveryDetails
 */
export const deliveryAndInstallFeeIsUnknown = (deliveryDetails) => {
  return deliveryDetails?.isDeliAndInstUnKnown === true
}

export function getTarifFeeMessage(cartInfo, language) {
  const noticeMessages = {
    [TARIFF_FEE_TYPE]: <TariffFeeMessage language={language} />,
    [ECO_FEE_TYPE]: <EcoFeeMessage language={language} />,
    [ECO_AND_TARIFF_FEE_TYPE]: <EcoAndTariffFeeMessage language={language} />,
  }
  return noticeMessages[getMessageType(cartInfo)] || ''
}

export const getMessageType = (cartInfo) => {
  return cartInfo.cartSummary?.hasReallyTariffFeeCombinedPrice &&
    cartInfo.cartSummary.hasReallyWeeFeeCombinedPrice
    ? ECO_AND_TARIFF_FEE_TYPE
    : cartInfo.cartSummary?.hasReallyTariffFeeCombinedPrice
    ? TARIFF_FEE_TYPE
    : cartInfo.cartSummary?.hasReallyWeeFeeCombinedPrice
    ? ECO_FEE_TYPE
    : ''
}

export function isExemptFromDeliveryAndInstallFees(cartDetails) {
  return cartDetails.cartSummary?.isExemptFromDeliveryAndInstallFees
}

export function isExemptFromDeliveryFee(cartDetails) {
  return cartDetails.cartSummary?.isExemptFromDeliveryFees
}
