import {
  removeRestrictedProduct,
  validateCustomPrompts,
} from 'api/shoppingCartOperations'
import { getCookie, removeCookie, setCookie } from 'services/cookieManager'
import { getLocalStorage } from 'services/storageManager'
import { getTextWithDynamicValue } from 'services/utils/languageUtils.js'
import { MESSAGE_STATUS } from 'shared/Alerts/AlertNotificationUtils/AlertNotificationConstants'
import { showRestrictedMessage } from 'shared/Alerts/AlertNotificationUtils/AlertNotificationUIUtils'
import {
  redirectToCheckout,
  redirectToShoppingCart,
} from '~/components/shared/EwayRouter'
import {
  highlightCustomPromptErrorsOnLoad,
  removeCustomPromptErrorsOnLoad,
  customPromptAlertNotification,
} from '../ProductCard/ProductCardBody/ProductContent/CustomPrompt/CustomPromptUtil'
import {
  HAS_CUSTOM_PROMPT_ERRORS,
  PRODUCT_REMOVED,
  RESTRICTED_MESSAGES,
  RESTRICTED_PRODUCT,
} from './constant'
import messages from './messages'

export const validateCustomPromptFields = async (language) => {
  const result = await validateCustomPrompts(language)
  const { hasError = false } = result

  if (hasError) {
    highlightCustomPromptErrorsOnLoad()
    redirectToShoppingCart(language)
    customPromptAlertNotification(language)
    return result
  }
  removeCustomPromptErrorsOnLoad()
  redirectToCheckout(language)
  return result
}
export const removeRestrictedProducts = async (language) => {
  const response = await removeRestrictedProduct(language)
  const isRestricted = response?.Messages.filter((restricted) => {
    if (restricted.Id === PRODUCT_REMOVED) {
      return restricted
    }
  })
  if (isRestricted) {
    setCookie(RESTRICTED_PRODUCT, true)
    setCookie(RESTRICTED_MESSAGES, JSON.stringify(isRestricted))
  }
  return response
}

export const getCustomPromptError = () => {
  return getLocalStorage(HAS_CUSTOM_PROMPT_ERRORS)
}
export const getRestrictedMessage = (language) => {
  if (getCookie(RESTRICTED_PRODUCT)) {
    const restrictedMessage = JSON.parse(getCookie(RESTRICTED_MESSAGES))
    let Items = []
    restrictedMessage.map((restrictedItem) => {
      Items = [restrictedItem.PrimaryMessage, ...Items]
    })
    let itemCode = ''
    const itemCodeObj = {
      '{{ITEM_CODE}}': formatProductCode(Items),
    }
    itemCode =
      Items.length > 0
        ? getTextWithDynamicValue(
            messages[language].restrictedMessage,
            itemCodeObj
          )
        : ''
    const Messages = [
      {
        PrimaryMessage: itemCode,
        MessageStatus: MESSAGE_STATUS.WARNING,
      },
    ]
    if (Items.length > 0) {
      showRestrictedMessage(Messages)
    }
    removeCookie(RESTRICTED_PRODUCT)
    removeCookie(RESTRICTED_MESSAGES)
  }
}
const formatProductCode = (Items) => {
  let productCode = ''
  if (Items.length > 0) {
    productCode = Items.toString().replace(/,\s*$/, '')
    return productCode
  }
  return productCode
}
