export default {
  en: {
    compareProductHeading: 'Product Comparison',
    compareProductHeadingFirstList: ['Product', 'Manufacturer', 'Rating'],
    compareProductHeadingLastList: ['Availability', 'Delivery'],
    reviewText: 'Review',
    backButton: 'Back to product list',
    close: 'Close',
    specifications: 'Specifications',
  },
  fr: {
    compareProductHeading: 'Comparer les sélections',
    compareProductHeadingFirstList: ['Produit', 'Manufacturier', 'Évaluation'],
    compareProductHeadingLastList: ['Disponibilité', 'Livraison'],
    reviewText: 'Commentaires',
    backButton: 'Retour à la liste des produits',
    close: 'Fermer',
    specifications: 'Spécifications',
  },
}
