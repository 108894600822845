import { keysToLoweCase } from '~/services/utils/utils'
import { PAGE_NAMES } from './constants'
import { EDIT_MODE } from 'components/containers/SubmittedOrderDetails/SubmittedOrderDetailsConstants'

export const queryParamsToProps = (query) => {
  if (!query) {
    return {}
  }
  const lowerCaseKeys = keysToLoweCase(query)
  const orderType = getUrlOrderType(lowerCaseKeys)
  return {
    numbers: lowerCaseKeys['ewayorderno']?.split(','),
    type: orderType,
  }
}

const getUrlOrderType = (keys) => {
  const queryType = keys['type']?.toLowerCase()
  const types = {
    specialorder: 'special',
    return: 'return',
  }
  return types[queryType]
}

export function isShoppingCartPage(pageName) {
  return pageName === PAGE_NAMES.SHOPPING_CART
}
export function isCheckoutPage(pageName) {
  return pageName === PAGE_NAMES.CHECKOUT
}

export const isSubmittedOrderModifyPage = ({ pageName, query }) => {
  const { mode } = query
  const isModifyPage =
    pageName === PAGE_NAMES.SUBMITTED_ORDER_DETAILS && mode === EDIT_MODE
  return isModifyPage
}
