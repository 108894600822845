import { EMPTY_GUID } from '~/services/guid/guidService'

export default {
  acct_id: '5292', // required for tracking to work
  domain_key: 'eway_ca', //  required for tracking to work
  ptype: 'homepage', // required for tracking to work
  type: 'pageview', // required for tracking to work
  version: '0.2', // corresponds to the version used on old eway
  merchant: 'eway_ca',
  pix_request_domain: 'p.brsrvr.com',
  user_id: EMPTY_GUID,
  title: 'home page',
  aq: '',
  basket: '',
  basket_value: '',
  cat: '',
  cat_id: '',
  etype: 'submit',
  is_conversion: '',
  lang: 'en',
  prod_id: '',
  prod_name: '',
  q: '',
}
