export const COOKIES = {
  BUYER_ID: '_EWayBuyerId',
  SESSION_ID: '_EWaySessionId',
  ADMIN_ID: '_EWayAdminId',
  APIAUTH: 'APIAUTH',
  ENABLE_REACT_PAGES: 'EnableReactPages',
  LANGUAGE: '_EWayCurrentLanguage',
  SAVED_USER_ID: 'SaveUserId',
  LAST_USER_ID: 'LastUserId',
  USER_INFO: 'UserInfo',
  SHOW_HIDE_RESTRICTIONS: 'ShowHideRestrictions',
  SHOW_RESTRICTION: '1',
  HIDE_RESTRICTION: '0',
  OLD_EWAY_SESSION: '.ASPXAUTH',
  TEMPORARY_BUYER_ID: 'TemporaryBuyerId',
  AUTO_LOGIN_INITIATED: 'autoLoginStarted',
  ENABLE_DIFFERENT_ADDRESS: 'enableDifferentAddress',
  SET_DIFFERENT_ADDRESS_EMPTY: 'setDifferentAddressEmpty',
}

export const LOCAL_STORAGES = {
  ACCOUNT_SELECTION_MODAL: 'accountSelectionModal',
}

export const REMOVE_ITEM_MESSAGE = 'removeItemMessage'
export const BEST_MATCH_VALUE = 2

export const DAYS_30 = 30
export const DAYS_60 = 60
export const DAYS_90 = 90
export const DAYS_180 = 180
export const All_ORDERS = -1

export const POSTAL_CODE_MAX_LENGTH = 7
