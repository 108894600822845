import { initialize } from '@redux/initialize'
import { CookiesContextProvider } from 'context/CookiesContext/CookiesContext'
import withReduxStore from 'lib/with-redux-store'
import omit from 'lodash/omit'
import App from 'next/app'
import getConfig from 'next/config'
import Head from 'next/head'
import { Provider } from 'react-redux'
import EwayRouter from 'shared/EwayRouter'
import { setAdobeInstanceStatus } from '~/components/shared/AdobeAnalytics/AdobeAnalyticsUtils'
import { sessionDependentCookies } from '~/components/shared/Layout/Header/Logout/LogoutUtils'
import {
  setAutoLoginCookies,
  shouldDoLoginByIds,
} from '~/redux/autoLoginService'
import { getAllCookies } from '~/services/cookieManager'
import { logErrorToConsole } from '~/services/logger'
import { logError } from '~/services/telemetaryData/loggerService'
import { isBrowser } from '~/services/utils/runtimeUtils'
import 'assets/scss/global.scss'
const configs = getConfig().publicRuntimeConfig

class Eway extends App {
  static async getInitialProps({ Component, ctx = {} }) {
    // Checks if the app is hosted on vintage eway
    // Checks if vintage eway is acting as a reverse proxy
    const isRunningBehindVintageEway = checkIsRunningInVintage(ctx)
    EwayRouter.setIsRunningInVintageEway(isRunningBehindVintageEway)

    // Do the automatic login (if any values found in url)
    const doLoginByIds = !isBrowser() && shouldDoLoginByIds(ctx.query)
    try {
      doLoginByIds && (await setAutoLoginCookies(ctx))
    } catch (error) {
      logErrorToConsole(error)
    }

    // Then initial the application
    const apiData = await initialize(ctx)
    const {
      settings = {},
      buyer = {},
      tokenHeader,
      hostUrl,
      language,
    } = apiData
    const { isLoggedIn } = buyer
    const { enableErrorReporting, isPunchOutEnable = false } = settings
    // Sets the apps language based on vintage eway values
    EwayRouter.setRouterLanguage(language)

    const logErrors = {
      enableErrorReporting: !!enableErrorReporting,
    }
    let pageProps = {}
    try {
      if (Component.getInitialProps) {
        const props = {
          ...ctx,
          apiData,
        }
        pageProps = await Component.getInitialProps(props)
      }
      const cookiesToOmitForPunchoutUser = sessionDependentCookies.map(
        ({ name }) => name
      )
      const cookies = isBrowser() ? getAllCookies() : ctx.req.cookies
      const requestCookies = isPunchOutEnable
        ? omit({ ...cookies }, cookiesToOmitForPunchoutUser)
        : cookies

      const mergedPageProps = {
        pageProps: {
          ...pageProps,
          cookies: requestCookies,
        },
        apiToken: tokenHeader,
        hostUrl,
        ...logErrors,
      }

      return mergedPageProps
    } catch (error) {
      // Capture errors that happen during a page's getInitialProps.
      // This will work on both client and server sides.
      logError(error, isLoggedIn, enableErrorReporting)

      return {
        hasError: true,
        ...logErrors,
      }
    }
  }

  componentDidMount() {
    // When the component is mounted front-end set the status for the Adobe Analytics Component
    const { settings = {} } = this.props.pageProps || {}
    setAdobeInstanceStatus(settings.enableWebAnalyticsInstrumentation)
  }

  componentDidCatch(error) {
    const { pageProps = {} } = this.props
    const { settings = {} } = pageProps
    const { buyer = {} } = pageProps
    const { isLoggedIn = false } = buyer
    const { enableErrorReporting = true } = settings
    logError(error, isLoggedIn, enableErrorReporting)
  }

  render() {
    const { Component, pageProps, reduxStore } = this.props

    return (
      <>
        <Head>
          <title>Eway</title>
        </Head>
        <CookiesContextProvider cookies={pageProps?.cookies}>
          <Provider store={reduxStore}>
            <Component {...pageProps} />
          </Provider>
        </CookiesContextProvider>
      </>
    )
  }
}

export default withReduxStore(Eway)

function checkIsRunningInVintage(ctx) {
  const useVintageUrls = !!configs?.useVintageUrls
  const hasHostUrl = !!ctx.req?.headers?.hosturl
  const isRunningBehindVintageEway = hasHostUrl || useVintageUrls
  return isRunningBehindVintageEway
}
