export default {
  en: {
    subTotal: 'Sub total :',
    total: 'Total :',
    noneLabel: 'None',
    ecoFeesLabel: 'Environmental Handling Fee',
    deliveryFeesLabel: 'Delivery Fee',
    transacFeesLabel: 'Transac',
    assemblyFeesLabel: 'Assembly Fee',
    deliveryAndInstallFeesLabel: 'Delivery and Install Fee',
    productHandlingFeesLabel: 'Product Handling',
    remoteLocationFeesLabel: 'Remote Location Fee',
    deliveryAreaSurchargeFeesLabel: 'Delivery Area Surcharge',
    tariffFeesLabel: 'Tariff Fees',
    forText: 'for',
    includedLabel: 'INCLUDED',
  },
  fr: {
    subTotal: 'Sous total :',
    total: 'Total :',
    noneLabel: 'None',
    ecoFeesLabel: 'Frais de gestion environnementale',
    deliveryFeesLabel: 'Frais de livraison',
    transacFeesLabel: 'Transac',
    assemblyFeesLabel: "Frais d'assemblage",
    deliveryAndInstallFeesLabel: "Frais de livraison et d'assemblage",
    productHandlingFeesLabel: 'Manutention',
    remoteLocationFeesLabel: 'Frais de région éloignée',
    deliveryAreaSurchargeFeesLabel: 'Surcharge de zone de livraison',
    tariffFeesLabel: 'Frais de douane',
    forText: 'pour',
    includedLabel: 'INCLUS',
  },
}
