import { getAnalyticsInstance } from '~/components/shared/AdobeAnalytics/AdobeAnalyticsUtils'
import { isTrackingReady } from '~/components/shared/BloomReachAnalytics/BloomReachAnalyticsUtils'
import { isTest } from '~/config/env/environmentUtils'

// TODO: All functions look similar, to refactor.
export async function checkChatServiceAvailable() {
  const STOP_AFTER_10_SECONDS = 10000
  let interval
  let timeout
  if (isTest()) {
    return Promise.resolve(true)
  }

  return new Promise((resolve) => {
    interval = setInterval(() => {
      if (window.insideFrontInterface) {
        resolve(true)
        clearInterval(interval)
        clearTimeout(timeout)
      }
    }, 500)

    timeout = setTimeout(() => {
      // eslint-disable-next-line no-console
      console.warn(
        'Could not instantiate Live Help chat button after more than 10 seconds, usually means the liveHelp url is not included, check configurations'
      )
      clearInterval(interval)
      resolve(false)
    }, STOP_AFTER_10_SECONDS)
  })
}

export async function checkBloomReachAvailable() {
  if (isTest()) {
    return Promise.resolve(true)
  }
  const STOP_AFTER_WAITING_SECONDS = 2000
  let interval
  let timeout

  return new Promise((resolve) => {
    interval = setInterval(() => {
      if (isTrackingReady()) {
        clearInterval(interval)
        clearTimeout(timeout)
        resolve(true)
      }
    }, 200)

    timeout = setTimeout(() => {
      // eslint-disable-next-line no-console
      console.warn(
        `Could not instantiate Bloomreach usually means the bloomreach <script> tag isn't present.`
      )
      clearInterval(interval)
      resolve(false)
    }, STOP_AFTER_WAITING_SECONDS)
  })
}

export async function checkAdobeAnalyticsInstanceAvailable() {
  if (isTest()) {
    return Promise.resolve(true)
  }
  const STOP_AFTER_10_SECONDS = 10000
  let interval
  let timeout

  return new Promise((resolve) => {
    interval = setInterval(() => {
      const instance = getAnalyticsInstance()
      if (instance?.t) {
        resolve(true)
        clearInterval(interval)
        clearTimeout(timeout)
      }
    }, 500)

    timeout = setTimeout(() => {
      // eslint-disable-next-line no-console
      console.warn(
        'Could not instantiate Adobe analytics after more than 10 seconds, check configurations'
      )
      clearInterval(interval)
      resolve(false)
    }, STOP_AFTER_10_SECONDS)
  })
}
