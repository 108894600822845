export default {
  en: {
    orderSummaryTitle: 'Order Summary',
    orderBy: 'Ordered by',
    orderDate: 'Order Date',
    scheduleDate: 'Scheduled Date',
    costCenter: 'Cost Center',
    accNo: 'Acct #',
    shippingAddress: 'Shipping Address',
    modTotal: 'MOB Product Total',
    nonModTotal: 'Non-MOB Product Total',
    furnitureDelivery: 'Furniture Delivery & Installation',
    environmentalFees: 'Environmental Handling Fees',
    handlingFee: 'Product Handling',
    assembly: 'Assembly',
    discount: 'Discount',
    gst: 'GST',
    pst: 'PST',
    hst: 'HST',
    total: 'Order total',
    paymentMethod: 'Payment Method',
    productTotal: 'Product total',
    poNumber: 'PO Number',
    deliveryInstruction: 'Delivery Instructions',
    taxesTotal: 'Taxes total',
    billingOptionAccountCreditCard: 'Account Credit Card',
    billingOptionCheckoutCreditCard: 'Checkout Credit Card',
    billingOptionOnAccount: 'On Account',
    billingOptionProfileCreditCard: 'Profile Credit Card',
    billingOptionNone: '',
    totalPriceToolTip:
      'You can view more detailed Accounting & Payment Information for this order by clicking on the invoice number(s) listed in the Order Details, to obtain a PDF copy of the invoice(s), when available.',
  },
  fr: {
    orderSummaryTitle: 'Sommaire de la commande',
    orderBy: 'Commandé par',
    orderDate: 'Date de la commande',
    scheduleDate: 'Livraison planifiée',
    costCenter: 'Centre de coût',
    accNo: 'Compte #',
    shippingAddress: 'Adresse de livraison',
    modTotal: 'BRM Total des produits',
    nonModTotal: 'Total des produits non BRM',
    furnitureDelivery: `Installation et livraison d'ameublement`,
    environmentalFees: 'Frais de gestion environnementale',
    handlingFee: 'Frais de manutention',
    assembly: 'Assemblage',
    discount: 'Remise',
    gst: 'TPS',
    pst: 'TVP',
    hst: 'TVH',
    total: 'Total de la commande',
    paymentMethod: 'Mode de paiement',
    productTotal: 'Total des produits',
    poNumber: 'Numéro du bon de commande',
    deliveryInstruction: 'Instructions de livraison',
    taxesTotal: 'Total des taxes',
    billingOptionAccountCreditCard: 'Carte de crédit de compte',
    billingOptionCheckoutCreditCard: "Carte de crédit d'envoi",
    billingOptionOnAccount: 'Sur mon compte',
    billingOptionProfileCreditCard: 'Carte de crédit du profil',
    billingOptionNone: '',
    totalPriceToolTip:
      "Visionnez davantage d'informations détaillées de comptabilité et de paiement de cette commande en cliquant sur le(s) numéro(s) de facture(s) indiqués dans le détail de la commande, pour obtenir une copie de la(les) facture(s) en format PDF, si disponible.",
  },
}
