import {
  DELIVERY_TYPE_FIFTY_GREEN,
  DELIVERY_TYPE_ZONE,
  FEE_TOTAL,
  PRODUCT_TOTAL,
  PROMO_DISCOUNT,
} from './feeOrderServiceConstants'
import { ORDER_FEE_TYPES } from './feeOrderServiceTypes'

export const productTotal = {
  en: 'Product Total',
  fr: 'Total des produits',
}

export const orderTotalLabel = {
  en: 'Order total',
  fr: 'Total de la commande',
}

export const contractLabels = {
  en: {
    contract: 'Contract product total',
    nonContract: 'Non-contract product total',
  },

  fr: {
    contract: 'Total des produits sous contrat',
    nonContract: 'Total des produits non contractuels',
  },
}

export const feeLabels = {
  [FEE_TOTAL]: {
    en: 'Fee total',
    fr: 'Total des frais',
  },
  [PRODUCT_TOTAL]: {
    en: 'Product total',
    fr: 'Total des produits',
  },
  [ORDER_FEE_TYPES.ASSEMBLY]: {
    en: 'Assembly',
    fr: 'Assemblage',
  },
  [ORDER_FEE_TYPES.ENVIRONMENTAL]: {
    en: 'Environmental handling fees',
    fr: 'Frais de gestion environnementale',
  },
  [ORDER_FEE_TYPES.TARIFF]: {
    en: 'Tariff fees',
    fr: 'Frais de douane',
  },
  [ORDER_FEE_TYPES.PRODUCT_HANDLING]: {
    en: 'Product handling',
    fr: 'Frais de manutention',
  },
  [ORDER_FEE_TYPES.DELIVERY_AND_INSTALL]: {
    en: 'Furniture delivery & installation',
    fr: 'Livraison & installation de meuble',
  },
  [ORDER_FEE_TYPES.FREIGHT_FEE]: {
    en: 'Area surcharge',
    fr: 'Surcharge de zone',
  },
  [ORDER_FEE_TYPES.TRANSAC]: {
    en: {
      long: 'Furniture delivery (To {postalCode})',
      short: 'Furniture delivery',
    },
    fr: {
      long: 'Livraison de meuble (À {postalCode})',
      short: 'Livraison de meuble',
    },
  },
  [PROMO_DISCOUNT]: {
    en: 'Discount',
    fr: 'Remise',
  },
}

export const deliveryTypeLabels = {
  fr: {
    [DELIVERY_TYPE_ZONE]: 'Surcharge de zone de livraison',
    [DELIVERY_TYPE_FIFTY_GREEN]: 'Frais de petite commande (50 Vert)',
  },
  en: {
    [DELIVERY_TYPE_ZONE]: 'Delivery area surcharge',
    [DELIVERY_TYPE_FIFTY_GREEN]: 'Small order fee (50 Green)',
  },
}

export const includedTypeLabels = {
  fr: {
    included: 'Inclus', // means it's free
    extra: 'Supplément',
  },
  en: {
    included: 'Included', // means it's free
    extra: 'Extra',
  },
}
