import { SEARCH_TYPES } from '~/components/containers/BrowseProducts/BrowseProductsConstants'
import { OLD_API_URL } from '.'

export function getSearchUrl({
  kws,
  pageNumber,
  optionsFilter = SEARCH_TYPES.DEFAULT_FILTER,
  sortBy,
  guestLocation,
}) {
  let url = `${OLD_API_URL}/api/search/${kws}/${pageNumber}/${optionsFilter}`
  if (sortBy) {
    url += `/${sortBy}`
  }
  if (guestLocation) {
    url += `/${guestLocation}`
  }
  return url
}

/**
 * Handles the delivery message shown
 * @param {bool} isBackOrdered - is the product back ordered
 * @param {bool} showAlphaCommentLine2 - show the alpha comment line 2
 * @param {string} alphaCommentLine2 - Spot reserved in Alpha (ERP) for the delivery message
 * @param {string} serviceDeliveryMessage - The delivery message from the API
 */
export function getDeliveryMessage({
  isBackOrdered,
  showAlphaCommentLine2,
  alphaCommentLine2,
  serviceDeliveryMessage,
}) {
  const alphaMessage = showAlphaCommentLine2 ? alphaCommentLine2 : ''
  const productMessage = isBackOrdered ? alphaMessage : serviceDeliveryMessage
  const deliveryMessage =
    isBackOrdered !== undefined ? productMessage : alphaMessage
  return deliveryMessage
}
