import { getApiUrl } from 'services/utils/configUtils'
import { sendRequest } from '../apiHelper'
const API_URL = getApiUrl()
export function fetchPunchOut(punchOutModuleId, options) {
  const getPunchOutUrl = (moduleId) => {
    return moduleId ? `${API_URL}/generic/punchOutModule/${moduleId}` : null
  }
  const punchoutSettingsUrl = getPunchOutUrl(punchOutModuleId)
  const punchoutSettingsPromise = punchoutSettingsUrl
    ? sendRequest(punchoutSettingsUrl, options)
    : []
  return punchoutSettingsPromise
}
