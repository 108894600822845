const PAGE_NAME = 'Template Details Page'
const ORDER_TEMPLATE = 'Order Template'
const EMPTY_EVENT = {
  eVar1: '',
  prop3: '',
  prop4: '',
  prop5: '',
  prop6: '',
  prop12: '',
  eVar14: '',
  eVar15: '',
  eVar36: '',
}
const COMMON_EVENTS = {
  pev2: 'Cart Addition',
  ...EMPTY_EVENT,
}

export default {
  pageName: PAGE_NAME,
  eVar1: 'non-search',
  prop3: 'Templates',
  prop4: 'Top Navigation Menu',
  prop5: 'orders',
  prop6: 'Templates',
  prop12: 'Templates',
  eVar14: 'Non-Browse',
  eVar15: 'Non-Browse',
  eVar36: 'Other Finding Method',
}

export const COPY_TO_CART_VALUES = {
  events: 'scAdd,scOpen,event35,event36',
  eVar3: ORDER_TEMPLATE,
  eVar12: 'Order Template Copy to Cart',
  ...COMMON_EVENTS,
}

export const MOVE_TO_CART_REMOVE_VALUES = {
  events: 'scRemove,event67,event68',
  eVar3: ORDER_TEMPLATE,
  ...EMPTY_EVENT,
}

export const MOVE_TO_CART_ADD_VALUES = {
  events: 'scAdd,scOpen,event35,event36',
  eVar3: ORDER_TEMPLATE,
  eVar12: 'Order Template move to Cart',
  ...COMMON_EVENTS,
}
