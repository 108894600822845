export const TAXES = 'Taxes'
export const taxesLabels = {
  [TAXES]: {
    en: {
      gst: 'GST', // federal
      pst: 'PST', // provincial
      hst: 'HST', // harmonized
      total: 'Taxes total',
    },
    fr: {
      gst: 'TPS', // federal
      pst: 'TVP', // provincial
      hst: 'TVH', // harmonized
      total: 'Total des taxes',
    },
  },
}
