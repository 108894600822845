export const LIST_VIEW_EXTRA_LARGE = 'listViewXl'
export const LIST_VIEW_LARGE = 'listViewLg'
/**
 * LIST_VIEW = is the default value for the view id no values are passed in <BrowseProducts />
 */
export const LIST_VIEW = 'listView'
export const GRID_VIEW = 'gridView'
export const GRID_VIEW_SM = 'gridViewSm'
export const TABLE_VIEW = 'tableView'

export const ZERO = 0
export const LIST_PRICE_OR_NONE = 1
export const MARKET_OR_LIST_PRICE = 2
export const LIST_PRICE_ONLY = 3
export const NO_BACK_ORDERED = 0

export const FREE_GIVE_AWAY = 2 // Free give away product
export const PROMOTION_QUALIFIER = 1 // Having promotion
export const QUANTITY_MAX_LENGTH = 4
export const MIN_PRODUCT_QTY = 1

export const LINE_NOTE_MAX_LENGTH = 120 //Line note
export const DISPLAY_MAX_LENGTH_LINE_NOTE_TEXT = 255 //Line note

export const TOOLTIP_UPDATE_TIME = 500
export const ZERO_NUMBER = 0
export const IS_RATING_EDIT = false
export const RATING = 'Rating'

export const LIST_VIEW_OFFSET = 70
export const GRID_VIEW_OFFSET = 30
