import productListMessages from '~/services/fees/products/feeProductLabels'
import { ENGLISH } from '~/redux/constants'
import { getHostUrl } from '~/services/utils/configUtils'
import messages from 'components/containers/ProductDetail/ProductDetailPrimarySection/ProductDetailContainer/ProductDetailSectionTop/messages'

export const HOST_URL = getHostUrl()
/**
 * Name: getProductAdditionalFees
 * Desc: return additional fees object
 * TODO: // To refactor, similar logic can be found for getFeeToDisplay
 */

export const getProductAdditionalFees = (
  feetoDisplay,
  language = ENGLISH,
  productCode,
  deliServOptType
) => {
  if (!feetoDisplay) {
    return []
  }
  const feeTypeData = [
    { text: 'noneLabel' },
    { text: 'ecoFeesLabel', url: `${HOST_URL}/Search/FeeFaqPage.aspx` },
    {
      text: 'productHandlingFeesLabel',
      url: `${HOST_URL}/${language}/Shopping/DeliveryHandling/GetDeliveryHandlingFaq?Language=${language}-CA`,
    },
    { text: 'transacFeesLabel', url: '' },
    { text: 'assemblyFeesLabel', url: '' },
    { text: 'deliveryAndInstallFeesLabel', url: '' },
    { text: 'deliveryFeesLabel', url: '' },
    { text: 'remoteLocationFeesLabel', url: '' },
    { text: 'deliveryAreaSurchargeFeesLabel', url: '' },
    {
      text: 'tariffFeesLabel',
      url: `${HOST_URL}/${language}/Shopping/DeliveryHandling/GetDeliveryHandlingFaq?Language=${language}-CA`,
    },
  ]

  const additionalFeeDisplayObj = []
  const productHandlingFee = []
  feetoDisplay.forEach((data) => {
    if (feeTypeData[data.FeeType]) {
      const obj = {}
      const feeTypeInNo = feeTypeData[data.FeeType].text || ''
      obj.FeeTypeText = `${productListMessages[language][feeTypeInNo]}
      ${productListMessages[language].forText} ${productCode}`
      obj.FeeAmount = data.FeeAmount
      obj.link = feeTypeData[data.FeeType].url
      obj.FeeType = data.FeeType
      obj.FeeTotal = data.FeeTotal
      obj.isExemptFromAssemblyFees = data?.isExemptFromAssemblyFees || false
      obj.deliServOptType = deliServOptType

      if (data.FeeType === 2) {
        productHandlingFee.push(obj)
      } else {
        additionalFeeDisplayObj.push(obj)
      }
    }
  })
  return [...additionalFeeDisplayObj, ...productHandlingFee]
}

/**
 * Show fee to display in product detail page, and single products
 * @param {Array|Object} feeToDisplay - The fees to display
 * @param {String} language - The language for the urls.
 * @returns a list of fees to display
 */
export const getFeeToDisplay = (feeToDisplay, language = ENGLISH) => {
  if (!feeToDisplay) {
    return []
  }
  const feeTypeData = [
    { text: 'noneLabel', url: '' },
    {
      text: 'ecoFeesLabel',
      url: `${HOST_URL}/Search/FeeFaqPage.aspx`,
    },
    {
      text: 'productHandlingFeesLabel',
      url: `${HOST_URL}/${language}/Shopping/DeliveryHandling/GetDeliveryHandlingFaq`,
    },
    { text: 'transacFeesLabel', url: '' },
    { text: 'assemblyFeesLabel', url: '' },
    { text: 'deliveryAndInstallFeesLabel', url: '' },
    { text: 'deliveryFeesLabel', url: '' },
    { text: 'remoteLocationFeesLabel', url: '' },
    { text: 'deliveryAreaSurchargeFeesLabel', url: '' },
    { text: 'tariffFeesLabel', url: '' },
  ]
  const feeToDisplayObj = []

  feeToDisplay?.forEach((data) => {
    if (feeTypeData[data.FeeType]) {
      const feeTypeInNo = feeTypeData[data.FeeType].text || ''
      data.FeeTypeText = messages[language][feeTypeInNo]
      data.link = feeTypeData[data.FeeType].url || ''
      feeToDisplayObj.push(data)
    }
  })
  return feeToDisplayObj
}
