import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useRef, useState } from 'react'
import Button from 'shared/Button'
import Icon from 'shared/Icon'
import Text from 'shared/Text'
import CollapseWrapper from '~/components/shared/CollapseWrapper/CollapseWrapper'
import { ENGLISH } from '~/redux/constants'
import CloseButton from '../../CloseButton'
import { defaultOptions } from '../AlertNotificationUtils/AlertNotificationConstants'
import styles from './AlertNotification.module.scss'
import messages from './messages'

/**
 * Name: AlertNotification
 * Desc: Render Alert Notification
 * @param {string} text - Can be anything even a component
 * @param {string} iconPosition
 * @param {string} prefix
 * @param {string} type
 * @param {func} closeAlertHandler
 * @param {string} maxWidth
 * @param {array} secondaryMessages
 * @param {number} index
 * @param {bool} showCloseButton
 */

const AlertNotification = ({
  text,
  iconPosition,
  prefix,
  type,
  closeAlertHandler,
  id,
  maxWidth,
  secondaryMessages,
  showCloseButton,
  index,
  language = ENGLISH,
}) => {
  const buttonRef = useRef(null)

  const [secondaryMessagesOpened, secondaryMessagesOpenedToggle] =
    useState(false)

  const toggleHandler = () => {
    secondaryMessagesOpenedToggle(!secondaryMessagesOpened)
  }

  const iconStyles = classNames(styles.icon, {
    [`${styles[iconPosition]}`]: iconPosition,
  })
  const classNameWrapper = classNames(
    `${styles.alert} ${styles[type]} ${styles[maxWidth]}`
  )

  return (
    <div
      className={styles.alertWrapper}
      data-alert-id={id}
      data-customwidth="customWidth"
    >
      <div className={classNameWrapper}>
        <div className={styles.alertFlex}>
          <Icon
            className={iconStyles}
            variant={prefix}
            cursor="default"
            data-test="alert-notification"
          />
          <div className={styles.alertContent} data-text="message-text">
            <Text
              role="alert"
              text={text}
              className={styles.alertText}
              data-test="alert-notification-text-message"
            />
            {!!secondaryMessages.length && (
              <Button
                role="button"
                className={styles.toggler}
                variant="link"
                icon={secondaryMessagesOpened ? 'arrowUp' : 'arrowDown'}
                aria-expanded={secondaryMessagesOpened ? 'true' : 'false'}
                aria-label={messages[language].message}
                onClick={toggleHandler}
                data-test="message-link-secondary-message"
                aria-describedby={
                  secondaryMessagesOpened ? `secondaryMessage-${id}` : null
                }
                ref={buttonRef}
              />
            )}
          </div>
          {showCloseButton ? (
            <CloseButton
              className={styles.buttonClose}
              data-test="alert-close-button"
              onClick={() => closeAlertHandler(index)}
              icon="close"
              aria-label={messages[language].closeMessage}
              variant="link"
              tab-index="0"
              ref={!secondaryMessages.length ? buttonRef : null}
              role="button"
            />
          ) : null}
        </div>
        <AlertSecondaryMessagesList
          items={secondaryMessages}
          isOpen={secondaryMessagesOpened}
          id={`secondaryMessage-${id}`}
        />
      </div>
    </div>
  )
}

// Default Props
AlertNotification.defaultProps = defaultOptions

// Props Validation
AlertNotification.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]),
  /**
   * **By default the value is setted to be top** \
   * you can center the icon using "center"
   */
  iconPosition: PropTypes.string,
  prefix: PropTypes.string,
  type: PropTypes.string,
  closeAlertHandler: PropTypes.func,
  /** controls the width size of different types of AlertNotifications \
   * **By default the value is "lg"** \
   *
   * Other values can be
   * - md
   * - auto (takes the width off the content)
   */
  maxWidth: PropTypes.string,
  secondaryMessages: PropTypes.array,
  showCloseButton: PropTypes.bool,
  index: PropTypes.number,
  language: PropTypes.string,
}

export default AlertNotification

function AlertSecondaryMessagesList({ items, isOpen, id }) {
  return items.length ? (
    <CollapseWrapper isOpened={isOpen}>
      <ul className={styles.alertList}>
        {items.map((text, index) => (
          <AlertSecondaryMessageItem
            key={index}
            index={index}
            text={text}
            id={id}
          />
        ))}
      </ul>
    </CollapseWrapper>
  ) : null
}

// Props Validation
AlertSecondaryMessagesList.propTypes = {
  items: PropTypes.array,
  isOpen: PropTypes.bool,
  id: PropTypes.string,
}

AlertSecondaryMessagesList.defaultProps = {
  items: [],
}

function AlertSecondaryMessageItem({ text, id }) {
  return (
    <li data-test="secondary-message">
      <Text role="alert" text={text} id={id} color="secondary" />
    </li>
  )
}

AlertSecondaryMessageItem.propTypes = {
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]),
  id: PropTypes.string,
}
