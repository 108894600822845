import { ENGLISH } from '@redux/constants'
import {
  getApiUrl,
  getKheopsAppIdFromEnv,
  getOldApiUrl,
} from 'services/utils/configUtils'
import { GROUP_ORDERS_ROUTE } from 'api/groupOrders/groupOrdersConstants'
import { isUnderMinimumOrderAmount } from '~/components/shared/Utils/userUtils'
import { getOldEwayFullLocale } from '~/services/utils/languageUtils'
import { checkArgsEmpty, sendRequest } from './apiHelper'
import { addAuthToken, withAuth } from 'api/apiHelperUtils'

import { buildAndFetchClient } from './client'
import {
  MAX_NUMBER_OF_ACCOUNTS,
  MAX_NUMBER_OF_FAVORITE_ACCOUNTS,
} from './constants'
import { buildAndFetchSettings } from './settings'
import { logError } from 'services/telemetaryData/loggerService'

export const API_URL = getApiUrl()
const OLD_API_URL = getOldApiUrl()
const KHEOPS_APPLICATION_ID = getKheopsAppIdFromEnv()

const buildAndFetchOrder = async (props) => {
  const [buyerId, sessionId] = props
  if (!buyerId || !sessionId) {
    logError(
      new Error('buyerId or sessionId is missing for buildAndFetchOrder')
    )
    return {}
  }
  const url = `${API_URL}/buyers/${buyerId}/sessions/${sessionId}/order?includes=orderProducts`
  const result = await sendRequest(url)
  const formattedOrder = formatOrder(result)
  const finalResult = {
    ...formattedOrder,
    isUnderMinimumOrderAmount: isUnderMinimumOrderAmount(result),
  }

  return Promise.resolve(finalResult)
}

function formatOrder(order = {}) {
  return {
    ...order,
    ...(order.orderId && {
      isUnderMinimumOrderAmount: isUnderMinimumOrderAmount(order),
    }),
  }
}

const buildAndFetchGroupOrder = async (props) => {
  const [orderId] = props
  const url = `${API_URL}/generic/groupOrders/orderId/${orderId}?filters=orderId`
  return sendRequest(url)
}
const buildAndFetchGroupOrderNo = async (props) => {
  const [orderId] = props
  const url = `${GROUP_ORDERS_ROUTE}/by-order/${orderId}`
  return sendRequest(url)
}

const buildAndFetchSession = async (props) => {
  const [buyerId, sessionId, tokenHeader] = props
  const url = `${API_URL}/buyers/${buyerId}/sessions/${sessionId}?includes=shipToClient`
  const options = {
    headers: { APIAUTH: tokenHeader },
  }
  const result = await sendRequest(url, options)
  return result || {}
}

const buildAndFetchAccountsList = async (props) => {
  const [buyerId, sessionId, options = {}] = props
  if (!buyerId || !sessionId) {
    logError(
      new Error('buyerId or sessionId is missing for buildAndFetchAccountsList')
    )
    return []
  }
  const { includeMax = true } = options
  const maxParameters = includeMax
    ? `?maxElements=${MAX_NUMBER_OF_ACCOUNTS}&maxFavorites=${MAX_NUMBER_OF_FAVORITE_ACCOUNTS}`
    : ''
  const url =
    `${API_URL}/buyers/${buyerId}/sessions/${sessionId}/shiptos${maxParameters}`.trim()
  const data = await sendRequest(url)
  return data.hasError ? [] : data
}

const buildAndFetchLogonName = async (props) => {
  const [logonName] = props
  const url = `${API_URL}/generic/buyers/logonName/${logonName}`
  const data = await sendRequest(url)
  return data.hasError ? [] : data
}

const buildAndUpdateClient = async (props) => {
  const [clientNo, currentClientNo] = props
  // TODO: This needs to become an UPDATE method, to change in KHEOPS api
  const options = {
    method: 'GET',
  }
  const url = `${OLD_API_URL}/api/client/True/${clientNo}/${currentClientNo}`
  return fetch(url, addAuthToken(options))
}

const buildAndUpdateBuyerLanguage = async (props) => {
  const [buyerId, language] = props
  const url = `${API_URL}/buyers/${buyerId}`
  const options = {
    method: 'PATCH',
    body: JSON.stringify([
      {
        op: 'add',
        path: '/CultureCode',
        value: getOldEwayFullLocale(language),
      },
    ]),
    headers: {
      'Content-Type': 'application/json-patch+json',
    },
    retry: {
      as: 'POST',
      addPreviousMethodToUrl: true,
    },
  }

  return sendRequest(url, options)
}

export const buildAndFetchCategoryMenu = async (args) => {
  // When you're not connected, we send an empty guid. (hack) to get the category menu.
  const [language = ENGLISH, showRestrictions = 2, searchFilter = ''] = args

  const searchFilterString = searchFilter && `/${searchFilter}`
  const url = `${OLD_API_URL}/api/menu/category/${showRestrictions}${searchFilterString}`
  const options = {
    method: 'GET',
    headers: {
      EwayApplicationId: KHEOPS_APPLICATION_ID,
      'Accept-Language': `${language}-CA`,
    },
  }
  const result = await sendRequest(url, options)
  return result
}

const buildAndFetchBuyerAndApprovers = async (args) => {
  const [buyerId, tokenHeader] = args
  const buyerUrl = `${API_URL}/buyers/${buyerId}`
  const approversUrl = `${API_URL}/generic/approvers/${buyerId}`
  const options = {
    headers: { APIAUTH: tokenHeader },
  }
  const buyerPromise = sendRequest(buyerUrl, options)
  const approversPromise = sendRequest(approversUrl, options)

  const result = await Promise.all([buyerPromise, approversPromise])
  const [buyer, approvers = []] = result
  const isApprover = !!approvers.length

  return { ...buyer, isApprover }
}

export const getOrder = checkArgsEmpty(buildAndFetchOrder)
export const getGroupOrder = checkArgsEmpty(buildAndFetchGroupOrder)
export const getGroupOrderNo = checkArgsEmpty(buildAndFetchGroupOrderNo)
export const getSession = checkArgsEmpty(buildAndFetchSession)
export const getClient = checkArgsEmpty(buildAndFetchClient)
export const getClientWithAuth = withAuth(getClient)

export const getBuyerAndApprovers = checkArgsEmpty(
  buildAndFetchBuyerAndApprovers
)
export const getBuyerAndApproversWithAuth = withAuth(getBuyerAndApprovers)
export const getSettings = checkArgsEmpty(buildAndFetchSettings)
export const getSettingsWithAuth = withAuth(getSettings)
export const getCategoryMenu = checkArgsEmpty(buildAndFetchCategoryMenu)
export const getAccounts = checkArgsEmpty(buildAndFetchAccountsList)
export const getLogonName = checkArgsEmpty(buildAndFetchLogonName)
export const updateAccount = checkArgsEmpty(buildAndUpdateClient)
export const updateBuyerLanguage = checkArgsEmpty(buildAndUpdateBuyerLanguage)
