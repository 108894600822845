export const DEFAULT_VALUE = 'default'
export const HOME_LOCAL_ROUTE = 'http://localhost:3000'
export const LOGIN_ROUTE = 'login'
export const ADDRESS_VALIDATION_ROUTE = 'address-validation'
export const RETURNS_ROUTE = 'returns'
export const HOME_ROUTE = 'home'
export const CONFIRMATION_PAGE_ROUTE = 'confirmation'
export const SHOPPING_CART_ROUTE = 'shopping-cart'
export const SHOPPING_LIST_ROUTE = 'shopping-list'
export const SHOPPING_LIST_EDIT_ROUTE = 'shopping-list-edit'
export const CONTACT_US_ROUTE = 'contact-us'
export const PUBLICATIONS_ROUTE = 'publications'
export const CREATE_NEW_GROUP_ORDER_ROUTE = 'create-new-group-order'
export const UPDATE_QUICK_GROUP_ORDER_ROUTE = 'update-quick-group-order'
export const UPDATE_GROUP_ORDER_ROUTE = 'update-group-order'
export const QUICK_GROUP_ORDER_ROUTE = 'quick-group-order'
export const GROUP_ORDER_ROUTE = 'group-order'
export const ACCOUNT_ACTIVATION_ROUTE = 'account-activation'
export const CREATE_NEW_ACCOUNT_ROUTE = 'create_new_account'
export const PRODUCT_DETAIL_ROUTE = 'product-detail'
export const CHECKOUT_ROUTE = 'checkout'
export const NOT_FOUND = '404'
export const TURN_TO_USER_TOKEN_ROUTE = 'turn-to-auth' //create token for turnto review & ratings
export const COMPARE_PRODUCTS_ROUTE = 'compare-products'
export const EDIT_PROFILE_ROUTE = 'edit-profile'
export const PRINT_VIEW_ROUTER = 'print-view'
export const SPECIAL_ORDERS_ROUTER = 'special-orders'
export const ACCOUNT_MANAGEMENT_CENTRE_ROUTE = 'account-management-centre'
export const ADD_EDIT_USER_ROUTER = 'add-edit-user'
export const MANAGE_USERS_ROUTE = 'manage-users'
export const TEMPLATES_SUMMARY_ROUTE = 'templates-summary'
export const MANAGE_GROUP_ROUTE = 'manage-group'
export const VENDOR_INTEGRATION_AGREEMENT_ROUTE = 'vendor-integration-agreement'
export const CUSTOM_PROMPTS_ROUTE = 'custom-prompts'
export const MANAGE_MY_GROUPS = 'groups'
export const MANAGE_GROUP_ORDERS = 'manage-group-orders'
export const ORDER_DETAIL_TEMPLATE_ROUTE = 'order-detail-template'
export const SUBMITTED_ORDER_DETAILS = 'submitted-order-details'
export const ORDER_DETAILS = 'order-details'
export const MODIFY_ORDER = 'modify-order'
export const APPROVAL_ORDER_DETAILS = 'approval-order-details'
export const CONFIRM_USER_ADDRESS_ROUTE = 'confirm-user-address'
export const MODIFY_ORDER_APPROVAL = 'modify-order-approval'
export const CHANGE_PASSWORD_ROUTE = 'change-password'
export const APPROVAL_DETAILS_MODIFY_APPROVER =
  'approval-details-modify-approver'
export const GROUP_ORDERS = 'group-orders'
export const SUBMITTED_ORDERS_ROUTE = 'submitted-orders'
export const APPROVAL_ORDERS_ROUTE = 'approval-orders'
export const RECENT_APPROVAL_ORDERS_ROUTE = 'recent-approval-orders'
export const ORDER_AWAITING_APPROVAL = 'orders-awaiting-approval'
export const ORDER_SUMMARIES_ROUTE = 'order-summaries'
export const REBATES_OFFERS = 'rebates-offers'
export const SAFETY_DATA_SHEET = 'safety-data-sheet'
export const SHOPPING_LIST_SAVE_ROUTE = 'shopping-list-save'
export const REBATES_OFFERS_ROUTE = 'rebates-offers'
export const ORDER_TYPE_PAGE = 'orders/summaries/[orderType]'
export const FLYERS_ROUTE = 'flyers'
export const SHOPPING_LIST_DETAILS_ROUTE = 'shopping-list-details'
export const FLYERS_DETAILS_ROUTE = 'flyers/[flyerId]'
export const RECALL_INFORMATION = 'recall-information'
export const TERMS_CONDITIONS = 'terms-conditions'
export const HELP_CENTRE = 'help-centre'
export const PRIVACY_NOTICE = 'privacy-notice'
export const INK_AND_TONER = 'ink-and-toner'
export const UPDATE_CREDENTIALS_WEB_FORM = 'update-credentials-web-form'
export const CONFIRM_SHIPPING_BILLING_ROUTE =
  'confirm-shipping-billing-information'
export const BROWSE_PRODUCTS_CATEGORY_ROUTE = 'browse-products-category'
export const BROWSE_PRODUCTS_KEYWORD_ROUTE = 'browse-products-keyword'
export const BROWSE_PRODUCTS_PROMOTION_ROUTE = 'browse-products-promotion'
export const FIND_YOUR_ORDER = 'find-your-order'
export const CONFIRM_USER_ADDRESS_INFORMATION =
  'confirm-user-address-information'
export const START_GROUP_ORDER = 'start-group-order'
export const MANAGE_MY_GROUP = 'groups'
export const GROUP_ORDER_QUICK_EDIT = 'group-order-quick-edit'
export const QUICK_GROUP_ORDER = 'quick-group-order'
export const ADVANCED_GROUP_ORDER = 'advanced-group-order'
export const CREATE_GROUP_ORDER = 'create-group-order'
export const MAPPING = {
  [HOME_ROUTE]: ['/', ''],
  [HOME_LOCAL_ROUTE]: ['http://localhost:3000', ''],
  [LOGIN_ROUTE]: ['/api/login', '/api/buyer/login'],
  [SHOPPING_CART_ROUTE]: ['/shopping-cart', '/orders/cart/details'],
  [SHOPPING_LIST_ROUTE]: [
    '/shopping-lists',
    '/ShoppingList/ShoppingList/GetShoppingListList',
  ],
  [SHOPPING_LIST_EDIT_ROUTE]: [
    '/shopping-lists/edit',
    '/ShoppingList/ShoppingList/Edit',
  ],
  [CONTACT_US_ROUTE]: ['/contact-us', ''],
  [PUBLICATIONS_ROUTE]: ['/publications', '/Landing/Publications_EN'],
  [CREATE_NEW_ACCOUNT_ROUTE]: ['/create_new_account', ''],
  [ACCOUNT_ACTIVATION_ROUTE]: [
    '/account-activation',
    '/AccountManagement/Profile/AccountActivation',
  ],
  [GROUP_ORDER_ROUTE]: ['/group-order', ''],
  [CREATE_NEW_GROUP_ORDER_ROUTE]: ['/create-new-group-order', ''],
  [RETURNS_ROUTE]: ['/returns', '/Orders/Submitted/Returns'],
  [PRODUCT_DETAIL_ROUTE]: ['/product-detail/product', '/Shopping/Product'],
  [CHECKOUT_ROUTE]: ['/checkout', '/orders/cart/checkout'],
  [NOT_FOUND]: ['', '/errors/Custom404.aspx'],
  [TURN_TO_USER_TOKEN_ROUTE]: ['/api/turnToAuth', '/api/buyer/turnToAuth'],
  [COMPARE_PRODUCTS_ROUTE]: ['/compare-products', ''],
  [QUICK_GROUP_ORDER_ROUTE]: [
    '/quick-group-order',
    '/GroupOrder/GroupOrderMenu.aspx',
  ],
  [SPECIAL_ORDERS_ROUTER]: ['/special-orders', '/shopping/specialOrders/Form'],
  [EDIT_PROFILE_ROUTE]: ['/edit-profile', '/AccountManagement/Profile/Edit'],
  [PRINT_VIEW_ROUTER]: ['/print-view', ''],
  [MANAGE_USERS_ROUTE]: ['/manage-users', ''],
  [ACCOUNT_MANAGEMENT_CENTRE_ROUTE]: ['/account-management-centre', ''],
  [ADD_EDIT_USER_ROUTER]: ['/add-edit-user', ''],
  [MANAGE_GROUP_ROUTE]: ['/manage-group', ''],
  [GROUP_ORDERS]: ['/group-orders', '/GroupOrder/GroupOrderMenu.aspx'],
  [TEMPLATES_SUMMARY_ROUTE]: [
    '/templates-summary',
    '/Orders/Orders/OrderSummaries/6',
  ],
  [VENDOR_INTEGRATION_AGREEMENT_ROUTE]: ['/vendor-integration-agreement', ''],
  [CUSTOM_PROMPTS_ROUTE]: ['/custom-prompts', ''],

  [ORDER_DETAIL_TEMPLATE_ROUTE]: [
    '/order-detail-template',
    '/Orders/templates/details',
  ],
  [SUBMITTED_ORDER_DETAILS]: ['/orders/submitted', '/Orders/Submitted/Details'],
  [ORDER_DETAILS]: ['/order-details', ''],
  [APPROVAL_ORDER_DETAILS]: ['/approval-order-details', ''],
  [MODIFY_ORDER]: ['/modify-order/', '/Shop/OrderModification.aspx?ObjectId='],
  [CONFIRM_USER_ADDRESS_ROUTE]: ['/confirm-user-address', ''],
  [MODIFY_ORDER_APPROVAL]: ['/modify-order-approval', ''],
  [APPROVAL_DETAILS_MODIFY_APPROVER]: ['/approval-details-modify-approver', ''],
  [ORDER_AWAITING_APPROVAL]: ['/orders-awaiting-approval', ''],
  [ADDRESS_VALIDATION_ROUTE]: [
    '/api/addressValidation',
    '/api/buyer/addressValidation',
  ],
  [SUBMITTED_ORDERS_ROUTE]: [
    '/orders/summaries/1',
    '/Orders/Orders/OrderSummaries/1',
  ],
  [APPROVAL_ORDERS_ROUTE]: [
    '/orders/summaries/2',
    '/Orders/Orders/OrderSummaries/2',
  ],
  [RECENT_APPROVAL_ORDERS_ROUTE]: [
    '/orders/summaries/3',
    '/Orders/Orders/OrderSummaries/3',
  ],
  [CHANGE_PASSWORD_ROUTE]: ['api/changePassword', '/api/buyer/changePassword'],
  [ORDER_SUMMARIES_ROUTE]: [
    '/templates-summary',
    '/Orders/Orders/OrderSummaries',
  ],
  [REBATES_OFFERS]: ['/rebates-offers', ''],
  [SAFETY_DATA_SHEET]: ['/safety-data-sheet', ''],
  [SHOPPING_LIST_SAVE_ROUTE]: [
    '/shopping-lists/create',
    '/ShoppingList/ShoppingList/create',
  ],
  [REBATES_OFFERS_ROUTE]: ['/rebates-offers', '/shopping/offers/rebates'],
  [FLYERS_ROUTE]: ['/flyers', '/shopping/offers/Publications'],
  [FLYERS_DETAILS_ROUTE]: [
    '/flyers/{flyerId}',
    '/shopping/offers/Publications/{flyerId}',
  ],
  [SHOPPING_LIST_DETAILS_ROUTE]: [
    '/browse-products/ShoppingList?q={shoppingListId}',
    '/ShoppingList/ShoppingList/GetShoppingListDetails/{shoppingListId}/{shoppingListType}',
  ],
  [RECALL_INFORMATION]: ['/recall-information', ''],
  [TERMS_CONDITIONS]: ['/terms-conditions', '/Info/Help/TermsAndConditions'],
  [HELP_CENTRE]: ['/help-centre', '/Info/Help/HelpCenter'],
  [PRIVACY_NOTICE]: ['/privacy-notice', '/Info/Help/PrivacyNotice'],
  [INK_AND_TONER]: ['/ink-and-toner', '/Shopping/InkAndToner/Find'],
  [CONFIRMATION_PAGE_ROUTE]: [
    '/confirmation',
    '/Shopping/Checkout/Confirmation',
  ],
  [CONFIRM_SHIPPING_BILLING_ROUTE]: [
    '/confirm-shipping-billing-information',
    '/VendorIntegrations/VendorFirstStep.aspx',
  ],
  [BROWSE_PRODUCTS_CATEGORY_ROUTE]: [
    '/browse-products/category',
    '/Shopping/Search/Category',
  ],
  [BROWSE_PRODUCTS_KEYWORD_ROUTE]: [
    '/browse-products/keyword',
    '/Shopping/Search/SearchByKeyword',
  ],
  [BROWSE_PRODUCTS_PROMOTION_ROUTE]: [
    '/browse-products/Promotion?q=',
    '/Shopping/Search/SearchByPromotion/',
  ],
  [FIND_YOUR_ORDER]: ['/find-your-order', '/Eway/FindYourOrder'],
  [CONFIRM_USER_ADDRESS_INFORMATION]: [
    '/confirm-user-address-information',
    '/VendorIntegrations/VendorFirstStep',
  ],
  [GROUP_ORDERS]: ['/group-orders', '/Orders/GroupOrder/GroupOrderMenu'],
  [START_GROUP_ORDER]: [
    '/group-orders/start-group-order',
    '/GroupOrder/GroupOrderChoice.aspx',
  ],
  [MANAGE_GROUP_ORDERS]: [
    '/group-orders/manage-group-orders',
    '/Orders/GroupOrder/GroupOrderList',
  ],

  [QUICK_GROUP_ORDER]: [
    '/group-orders/quick-group-order',
    '/Orders/GroupOrder/QuickGroupOrder',
  ],
  [ADVANCED_GROUP_ORDER]: [
    '/group-orders/advanced-group-order',
    // using vintage url because advanced group order is not ready yet
    '/GroupOrder/GroupOrderEdit.aspx',
    //"/Orders/GroupOrder/AdvancedGroupOrder"
  ],

  [MANAGE_MY_GROUPS]: [
    '/group-orders/manage-groups',
    '/Orders/GroupOrder/ManageGroups',
  ],
  [GROUP_ORDER_QUICK_EDIT]: [
    '/GroupOrder/QuickGroupOrderEdit.aspx',
    '/GroupOrder/QuickGroupOrderEdit.aspx',
  ],
  [CREATE_GROUP_ORDER]: [
    '/GroupOrder/GroupOrderEditGroup.aspx',
    '/GroupOrder/GroupOrderEditGroup.aspx',
  ],
}
