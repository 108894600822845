import { eventManager } from 'services/eventsManager'
import { isBrowser } from 'services/utils/runtimeUtils'
import {
  defaultOptions,
  REPLACE_NOTIFICATION,
  SHOW_NOTIFICATION,
  HIDE_NOTIFICATION,
  HIDE_NOTIFICATION_ALL,
  ALERT_TYPES,
  GLOBAL_CONTAINER_ID,
} from './AlertNotificationConstants'

/**
 * Allows you to debug the notification alerts in browser
 * open dev tools and
 * ```
 * showNotification('Hello!')
 * ```
 * To debug, set to true.
 * Exposes the API in the browser
 */
const debugAlertInBrowser = false

/**
 *
 * @param {string} The message of your choosing
 * @param {Object} The options
 * @param options.showCloseButton Makes the box closable or not
 * @param options.type Can be 'primary, success, warn, danger'
 * @param option.prefix Changes the icon, you normally shouldn't have to change that.
 * @param options.closeAlertHandler This is the action that happens when the box is closed
 *
 */
export const showNotification = (content, options) => {
  // nextTick needed to prevent async rendering
  nextTick(() => dispatchNotification(content, options))
}

export const hideNotification = (id, { containerId } = {}) => {
  // nextTick needed to prevent async rendering
  nextTick(() => closeNotification(id, containerId))
}

export const replaceNotification = (content, userOptions) => {
  const options = mergeWithDefaults(userOptions)
  options.id &&
    eventManager.emit(
      `${REPLACE_NOTIFICATION}-${options.containerId}`,
      content,
      options
    )
}

function closeNotification(id, containerId) {
  const finalContainer = !containerId ? defaultOptions.containerId : containerId
  const options = mergeWithDefaults({ id, containerId: finalContainer })
  notificationExists(id) &&
    eventManager.emit(`${HIDE_NOTIFICATION}-${options.containerId}`, options)
}

export const closeAllNotification = () => {
  eventManager.emit(HIDE_NOTIFICATION_ALL)
}
/**
 * If the id exists, replace the notification, if not, display a new one
 * @param {*} content
 * @param {*} options
 * @param {*} callback
 */

function dispatchNotification(content, userOptions) {
  const options = mergeWithDefaults(userOptions)
  const { fallbackToGlobalContainer, containerId: containerFromOptions } =
    options
  const containerExists = !!Array.from(
    document.querySelectorAll(`[data-container-id='${containerFromOptions}']`)
  ).length

  const containerId =
    fallbackToGlobalContainer && !containerExists
      ? GLOBAL_CONTAINER_ID
      : containerFromOptions

  const alreadyExists = notificationExists(options.id)
  const event = `${SHOW_NOTIFICATION}-${containerId}`
  alreadyExists
    ? replaceNotification(content, options)
    : eventManager.emit(event, content, {
        ...options,
        containerId,
      })
}

function mergeWithDefaults(options) {
  return {
    ...defaultOptions,
    ...options,
  }
}

export function addAlertMessagesType(alertMessage, index) {
  return {
    ...alertMessage,
    ...ALERT_TYPES[alertMessage.MessageStatus],
    index,
  }
}
/**
 * Methods that check in the browser that the wanted id exists
 * @param {string} id
 */
export const notificationExists = (id) => {
  // function should only be ran in browser
  if (!isBrowser()) {
    return
  }
  const domAlerts = Array.from(document.querySelectorAll('[data-alert-id]'))
  const alertIds = domAlerts.map((e) => e.dataset.alertId)
  return alertIds.includes(id)
}

export const removeAlertById = (alerts, index) =>
  alerts.filter((a) => a.id !== index)

/**
 * Generates an error message à la "old eway"
 * @param {*} messages
 * @param {*} language
 */

export const createErrorMessage = (message) => {
  return [
    {
      PrimaryMessage: message,
      SecondaryMessages: [],
      MessageType: 2, // Needs to be constant, but not tested
      MessageStatus: 2, // Needs to be constant but not tested
      MessagePosition: 0,
      StartDate: '2019-11-07T00:00:00-05:00',
      IsCartMessage: true,
    },
  ]
}

/**
 * This returns all types from alerts types as a unique array
 * ['primary', 'success' ... etc]
 */
const types = Array.from(
  new Set(
    ...[
      Object.entries(ALERT_TYPES).map((type) => {
        const [, second] = type
        return second.type
      }),
    ]
  )
)

/**
 * Creates a shortcut methods based on types.
 * showNotification.warning, showNotification.success
 */
for (const type in types) {
  showNotification[types[type]] = (message, options) => {
    const mergedOptions = {
      ...options,
      type: types[type],
    }
    // nextTick needed to prevent async rendering
    nextTick(() => dispatchNotification(message, mergedOptions))
  }
}

/**
 * Create a shortHand methods
 */
showNotification.warn = showNotification.warning
showNotification.error = showNotification.danger

/**
 * Exposes the showNotification method in browser
 * @param {Boolean} isDebugTruthy
 */
if (process.browser && debugAlertInBrowser) {
  window.showNotification = showNotification
  window.hideNotification = hideNotification
}

/**
 * To understand why this exists
 * https://stackoverflow.com/questions/779379/why-is-settimeoutfn-0-sometimes-useful
 * @param {*} callback
 */
function nextTick(callback) {
  setTimeout(() => {
    callback()
  }, 0)
}
