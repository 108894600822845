export const KEYWORD_PATH = '/keyword'
export const CATEGORY_PATH = '/category'
export const SHOPPING_LIST_PATH = '/shoppinglist'
export const BLOOMREACH = 'Bloomreach'
export const ENDECA = 'Endeca'
export const BROWSE_RESULTS = 'Browse Results / '

export const PRE_LOGIN_BANNERS = {
  events: `event1`,
  eVar2: 'Pre-Login Homepage',
  prop5: 'Pre-Login Homepage',
  prop12: 'Search Results',
  eVar14: 'Non-Browse',
  eVar15: 'Non-Browse',
  eVar36: 'Other Finding Method',
}

export const POST_LOGIN_BANNERS = {
  ...PRE_LOGIN_BANNERS,
  eVar3: 'Search',
}

export const PRE_LOGIN_SEARCH_BAR = {
  ...PRE_LOGIN_BANNERS,
}

export const BREADCRUMB = {
  prop12: 'Search Results',
  eVar36: 'Other Finding Method',
}

export const SEARCH_RESULTS = 'Search Results / '

export const PROMO_BANNER_ANALYTICS_VALUES = {
  Channel: 'Promotion Banner Landing Page',
  eVar1: 'Non-Search',
  eVar3: 'Promotion Banner',
  eVar14: 'Non-Browse',
  eVar15: 'Non-Browse',
  pageName: 'Promotion Banner Landing Page',
  prop3: 'Promotion Banner Landing Page',
  prop12: 'Shopping List Details',
}

export const PRE_LOGIN_CATEGORY = {
  eVar1: 'Non-Search',
  prop3: 'Master Category',
  eVar3: 'Browse',
}
export const BOX_FINDER_PAGE_VALUES = {
  pageName: 'Box Finder',
  eVar3: 'Not_assigned ',
  prop6: 'Search Results',
  eVar14: 'Non-Browse ',
  eVar15: 'Non-Browse ',
  eVar36: 'Other Finding Method',
  events: `event4`,
  prop26: 'Submitted Order Details',
}
export const INK_AND_TONER_FINDER_VALUES = {
  pageName: 'Ink and Toner Finder Tool',
  prop4: 'Search Results /1',
  prop5: 'Ink, Ribbon & Toner',
  prop6: 'Search Results',
  prop26: 'Ink, Ribbon & Toner',
  eVar36: 'Other Finding Method',
  events: `event4`,
}
export const SHOPPING_LIST = 'Shopping List'
export const VIEW_TYPE_BOX = 'Box'
