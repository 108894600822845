import { checkArgsEmpty } from '~/api/apiHelper'
import { getCartOrderSummary } from 'shared/OrderSummary/OrderSummaryDetail/OrderSummaryDetailsUtils'
import { ENGLISH } from '~/redux/constants'
import { addProductId } from '../utils'
import { getCartDetails } from './cartServiceApiUtils'

/**
 * Name: Fetch Cart Details
 * Desc: Get Cart data
 * @param {string} language
 */
const buildFetchShoppingCartData = async (args) => {
  const [
    lang,
    enableContractProductTotalBreakdown = false,
    shippingPostalCode,
  ] = args
  const language = lang || ENGLISH
  const cartOrderData = await getCartDetails(args)

  // Extract Needed Data
  const result = formatData(
    cartOrderData,
    language,
    enableContractProductTotalBreakdown,
    shippingPostalCode
  )

  return { ...result, isSuccess: cartOrderData?.status === 200 }
}

export const getCartOrderData = checkArgsEmpty(buildFetchShoppingCartData)

function formatData(
  cartOrderData = {},
  language = ENGLISH,
  enableContractProductTotalBreakdown = false,
  shippingPostalCode
) {
  const {
    CartInfo = {},
    CustomPromptsInfo = {},
    OrderId = '',
    OrderDeli = {},
    Messages = [],
    CustomPrompts = [],
    DeliveryAddress,
    BillingAddress,
    ApprovalAction,
    CreditCard,
    BloomreachAnalytics,
    Analytics,
  } = cartOrderData

  const orderSummary = getCartOrderSummary({
    language,
    cartDetails: cartOrderData,
    approvalAction: ApprovalAction,
    showContractProductTotal: enableContractProductTotalBreakdown,
    shippingPostalCode,
  })

  // Fix the orderId from CustomPromptInfo (should probably be done API wise)
  if (CustomPromptsInfo) {
    CustomPromptsInfo.OrderId = OrderId
  }

  // Map values
  return {
    cartProductList: CartInfo?.Products?.map(addProductId) || [],
    cartSummary: CartInfo?.CartSummary || {},
    customPromptsInfo: CustomPromptsInfo,
    orderDeliveryInfo: OrderDeli,
    customPrompts: CustomPrompts || [],
    orderSummary,
    messages: Messages,
    approvalAction: ApprovalAction,
    deliveryAddress: DeliveryAddress,
    billingAddress: BillingAddress,
    orderId: OrderId,
    creditCard: CreditCard,
    bloomreachAnalytics: BloomreachAnalytics,
    adobeAnalytics: Analytics,
    cartInfo: CartInfo || {},
  }
}
