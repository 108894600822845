import { applyMiddleware, combineReducers, createStore } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import stateReducer, {
  initialState as stateReducerInitial,
} from './stateReducer'

const reducers = combineReducers({
  state: stateReducer,
})
let store = null
export function initializeStore(session) {
  const initialState = {
    ...stateReducerInitial,
    session,
  }
  store = createStore(
    reducers,
    {
      state: { ...initialState },
    },
    composeWithDevTools(applyMiddleware(thunk))
  )

  return store
}

export { store }
