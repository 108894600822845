export const DEFAULT_STATE = {
  orderNo: '********',
  orderProducts: [],
  orderProductGroups: [],
  AwaitingApproversList: [],
  OrderApproversHistory: [],
  orderStatus: null,
  summaryInfo: {},
  isSpecialDelivery: false,
  postedComments: [],
  OrderId: '',
  buttonsStateInfo: {},
  is404: false,
  isValidOrder: false,
  userRole: '',
}
export const ORDER_ACTIONS = {
  APPROVE: 1,
  REJECT: 2,
  CANCEL: 3,
  ORDER_NOTIFICATION: 4,
  CHECKOUT: 5,
  MODIFY: 6,
  UPDATE_ORDER: 7,
}

export const PREVIOUS_PAGE_NO = {
  IN_APPROVAL: 1,
  ORDER_DETAILS: 2,
}

export const ORDER_TYPES = {
  AWAITING: 'AWAITING',
  REFUSED: 'REFUSED',
  OPEN: 'OPEN',
  CANCELED: 'CANCELED',
  SHIPPED: 'SHIPPED',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
  ARCHIVED: 'ARCHIVED',
}
export const APPROVAL_TYPES_INFO = [
  { key: 0, type: 'AWAITING' },
  { key: 2, type: 'REFUSED' },
  { key: 1, type: 'OPEN' },
  { key: 3, type: 'CANCELED' },
]

export const APPROVAL_TYPES = {
  AWAITING: 'AWAITING',
  REJECTED: 'REFUSED',
  APPROVED: 'OPEN',
}

export const SENT_ORDER_NOTIFICATION_ID = 'sendOrderNotificationId'
export const SPECIAL_DELIVERY_DETAILS_MODAL_ID = 'specialDeliveryModalId'
export const SPECIAL_DELIVERY_NOTIFICATION_ID = 'special-delivery-message'

export const ORDER_PRODUCT_GROUP_TYPE = {
  PENDING: 0,
  SHIPPED: 1,
  CANCELLED: 2,
}

export const ORDER_PENDING_COLOR = 'blue'
export const ORDER_CANCELED_COLOR = 'primary'

export const HEADER = 'header'
export const FOOTER = 'footer'
export const ORDER_TOTAL = 'orderTotal'
export const USER_ROLE = {
  ANONYMOUS: 'Anonymus',
  AUTHORIZED_NON_ACCESSIBLE: 'AuthorizedButCanNotSeeThePrice',
  AUTHORIZED_ACCESSIBLE: 'AuthorizedAndCanSeeThePrice',
  AUTHORIZED_PRICE_ACCESSIBLE: 'AuthorizedToSeeThePriceButCanNotModifyOrder',
}
export const ACCOUNT_NO_HEADING = 'Acct #'
export const EDIT_MODE = 'edit'
