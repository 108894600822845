import { shortenLanguage } from '~/services/utils/languageUtils'
import { COOKIES } from 'services/constants'
/**
 * Return formatted cookies for store to consume them
 */
export const formatCookiesFromContext = (cookies) => {
  const language = shortenLanguage(cookies?.[COOKIES.LANGUAGE])
  const buyerId = cookies?.[COOKIES.BUYER_ID]
  const sessionId = cookies?.[COOKIES.SESSION_ID]
  const tokenHeader = cookies?.[COOKIES.APIAUTH]

  return { language, buyerId, sessionId, tokenHeader }
}
