import Text from 'shared/Text'
import { SUCCESS, DANGER, MESSAGE_TYPE } from './AlertNotificationConstants'
import {
  formatServerMessages,
  formatApprovalMessages,
  formatPDPWarningMessages,
  formatPromotionalMessages,
  formatRestrictedMessages,
} from './AlertNotificationFilteringUtils'
import { showNotification } from './AlertNotificationUtils'
import { ENGLISH } from '~/redux/constants'
const defaultMessages = {
  en: 'Something went wrong please try again later.',
  fr: `Quelque chose c'est mal passé. Merci d'essayer plus tard.`,
}

export function showApprovalAlerts(MessagesCenter = [], options) {
  showMessageAlert(formatApprovalMessages(MessagesCenter), options)
}

export function showPromotionalAlerts(MessagesCenter = [], optionProps) {
  const options = {
    ...optionProps,
    id: 'promotional-alert-messages',
  }
  showMessageAlert(formatPromotionalMessages(MessagesCenter), options)
}

function showMessageAlert(messages = [], options = {}) {
  messages.forEach((message) => {
    const { mainMessage, secondaryMessages } = message
    showNotification.warn(
      <Text variant="xsText" dataTest="message-text">
        {mainMessage}
      </Text>,
      {
        id: options.id || 'messaging-center-messages',
        showCloseButton: true,
        secondaryMessages,
        ...options,
      }
    )
  })
}
export function showPDPWarningNotification(MessagesCenter = [], optionProps) {
  const options = {
    ...optionProps,
    id: 'pdp-messages-warning',
  }
  showMessageAlert(formatPDPWarningMessages(MessagesCenter), options)
}
export function showRestrictedMessage(MessagesCenter = [], optionProps) {
  const options = {
    ...optionProps,
    id: 'restricted-messages-error',
  }
  showMessageAlert(formatRestrictedMessages(MessagesCenter), options)
}
export function showSuccessErrorCheckout(MessagesCenter = [], optionProps) {
  const options = {
    ...optionProps,
  }
  showApiNotifications(formatServerMessages(MessagesCenter), options)
}
/**
 * Function that allows that smooth scroll up
 */
export const smoothScrollTop = () => {
  process.browser && window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
}

export function showApiNotifications(messages = [], options) {
  if (!messages.length) {
    return
  }
  messages.forEach((item, index) => {
    const { messageType, type, message, secondaryMessages } = item
    let msgType = ''
    type === SUCCESS ? (msgType = type) : (msgType = `${type}-${index}`)
    if (
      messageType === MESSAGE_TYPE.ADD_TO_CART &&
      (type === SUCCESS || type === DANGER)
    ) {
      showNotification[type](message, {
        id: type,
        toast: true,
        autoDismiss: 5000,
      })
    } else {
      showNotification[type](<Text variant="xsText">{message}</Text>, {
        id: `add-to-cart-${msgType}`,
        showCloseButton: true,
        secondaryMessages,
        ...options,
      })
      smoothScrollTop()
    }
  })
}

export const somethingWentWrong = (
  language = ENGLISH,
  options = {},
  passedMessage = ''
) => {
  const message = passedMessage || defaultMessages[language]
  showNotification.error(message, options)
}
