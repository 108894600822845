export const CUSTOM_PROMPT_TYPE = {
  HEADER: 1,
  PRODUCT: 2,
}

export const CUSTOM_PROMPT_MAX_LENGTH = 120

export function getDefaultPromptValue(
  selectedValue,
  customPromptData = {},
  customPrompt = {}
) {
  const options = getPromptValuesBySequence(customPromptData, customPrompt)
  const hasMatch = options?.find((option = {}) => {
    const selectedValueWithoutZeros = removeLeadingZeros(selectedValue)
    const customizationWithoutZeros = removeLeadingZeros(
      option.CustomizationValue
    )
    return selectedValueWithoutZeros === customizationWithoutZeros
  })
  if (isNaN(selectedValue)) {
    return hasMatch?.CustomizationValue || selectedValue
  }
  return hasMatch?.CustomizationValue || removeLeadingZeros(selectedValue)
}

function getPromptValuesBySequence(customPromptData, customPrompt) {
  return customPromptData.CustomPromptElements?.find(
    (x) => x.Sequence === customPrompt.Sequence
  )?.CustomPromptCustomizations
}

function removeLeadingZeros(value) {
  // isNaN returns false for an empty string but NaN for parseInt
  if (isNaN(value)) {
    return value
  }
  const valueWithoutLeadingZeros = parseInt(value, 10)
  const finalValue =
    valueWithoutLeadingZeros > Number.MAX_SAFE_INTEGER
      ? value
      : valueWithoutLeadingZeros
  return Number.isNaN(valueWithoutLeadingZeros) ? value : finalValue
}

export const filterPromptValue = (value) => {
  return value?.replace(/\r?\n/gi, '')
}

export const getCustomElement = (
  sequence,
  customPromptData = {},
  enableCustomPromptDropDown,
  customPromptType
) => {
  const customPromptList = []
  const { CustomPromptElements = [] } = customPromptData
  if (!CustomPromptElements.length || !enableCustomPromptDropDown) {
    return customPromptList
  }
  const returnData = CustomPromptElements?.filter(
    (x) => sequence === x.Sequence && x.Type === customPromptType
  )
  if (
    !(
      returnData.length &&
      returnData[0] &&
      returnData[0]?.CustomPromptCustomizations &&
      returnData[0]?.CustomPromptCustomizations?.length
    )
  ) {
    return customPromptList
  }

  if (returnData[0]?.CustomPromptCustomizations.length > 0) {
    let countAlias = 0
    returnData[0]?.CustomPromptCustomizations?.forEach((item = {}) => {
      item.Alias?.length === 0 ? countAlias : countAlias++
    })
    returnData[0]?.CustomPromptCustomizations?.forEach((item, index) => {
      if (countAlias > 0) {
        item.Alias &&
          customPromptList.push({
            value: item.Alias,
            name: item.CustomizationValue,
          })
      } else {
        item.CustomizationValue &&
          index !== 0 &&
          customPromptList.push({
            value: item.CustomizationValue,
            name: item.CustomizationValue,
          })
      }
    })
  }
  return customPromptList
}

export const getFormFieldProps = ({
  sequence,
  customPromptData,
  enableCustomPromptDropDown,
  value,
  promptLength,
  prefix,
  customPromptType,
  passDefaultValue = false, // TODO will be removed when custom prompts will be refactored
}) => {
  const customElement = getCustomElement(
    sequence,
    customPromptData,
    enableCustomPromptDropDown,
    customPromptType
  )

  const finalValue = prefix && !isNaN(value) ? value.toString() : value

  const formFieldProps = getFormFieldPropsField(
    customElement,
    finalValue,
    promptLength,
    passDefaultValue
  )
  return formFieldProps
}

function getFormFieldPropsField(
  customElement,
  value,
  promptLength,
  passDefaultValue = false
) {
  return customElement.length >= 1
    ? {
        type: 'select',
        options: customElement,
        selectedValue: value,
      }
    : {
        type: 'text',
        placeholder: '',
        ...(passDefaultValue ? { defaultValue: value } : { value }),
        maxLength: promptLength || CUSTOM_PROMPT_MAX_LENGTH,
      }
}

// TODO : Need to remove when custom Prompts refactor will be done.
export const getServerAugmentedErrors = (formState = {}, fieldName) => {
  const { formServerErrors, errors } = formState
  const newErrors = { ...errors }
  if (formServerErrors?.[fieldName]) {
    newErrors[fieldName] = formServerErrors[fieldName]
  }
  return newErrors
}

// TODO : Need to remove when custom Prompts refactor will be done.
export const getServerAugmentedDirties = (formState = {}, fieldName) => {
  const { formServerErrors, dirties } = formState
  const newDirties = { ...dirties }
  if (formServerErrors?.[fieldName]) {
    newDirties[fieldName] = true
  }
  return newDirties
}
