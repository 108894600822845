import { sendRequest } from './apiHelper'
import {
  CREATE_PASSWORD_STEP_ID,
  PROFILE_INFO_STEP_ID,
  SHIP_TO_LOCATIONS_STEP_ID,
  ACCOUNT_ACTIVATION_COMPLETED,
} from 'components/containers/AccountActivation/AccountActivationConstants'
import { getApiUrl, getOldApiUrl } from 'services/utils/configUtils'
import { fromEntries } from 'services/utils'
const API_URL = getApiUrl()
const OLD_API_URL = getOldApiUrl()
export const buildAndFetchSettings = async (props) => {
  const [currentShipToAccount, tokenHeader] = props
  const url = `${API_URL}/clients/${currentShipToAccount}/settings`
  const options = {
    headers: {
      APIAUTH: tokenHeader,
    },
  }
  const settingsPromise = currentShipToAccount ? sendRequest(url, options) : {}
  const accountActivationPromise = fetchAccountActivationSteps(props)
  const [settingsResult, activationStepsResult = {}] = await Promise.all([
    settingsPromise,
    accountActivationPromise,
  ])
  const settings = formatSettings(activationStepsResult, settingsResult)

  return settings
}

function formatSettings(activationStepsResult, settingsResult = {}) {
  const { status } = activationStepsResult
  const accountActivationSettings = transformActivationStepsToSettings(
    activationStepsResult
  )
  const settings = {
    recommendationsAreEnabled: recommendationsIsEnabled(settingsResult),
    ...settingsResult,
    ...(status === 200 && accountActivationSettings),
  }
  return settings
}

function recommendationsIsEnabled({
  enableRecommendationProduct,
  enableRecentlyViewedFooter,
}) {
  return enableRecommendationProduct || enableRecentlyViewedFooter
}

function fetchAccountActivationSteps(props) {
  const [, tokenHeader] = props
  const options = {
    headers: {
      APIAUTH: tokenHeader,
    },
  }
  const accountActivationURL = `${OLD_API_URL}/api/buyer/accountActivationSteps`
  return sendRequest(accountActivationURL, options)
}

function transformActivationStepsToSettings(result) {
  const { activationSteps, activeStep } = result
  const activationSettings = [
    {
      id: 0,
      step: ACCOUNT_ACTIVATION_COMPLETED,
    },
    {
      id: 1,
      key: 'accountActivationCreatePassword',
      step: CREATE_PASSWORD_STEP_ID,
    },
    {
      id: 2,
      key: 'accountActivationConfirmProfile',
      step: PROFILE_INFO_STEP_ID,
    },
    {
      id: 3,
      key: 'accountActivationCreditCard',
      step: PROFILE_INFO_STEP_ID,
    },
    {
      id: 4,
      key: 'accountActivationCreateShipTos',
      step: SHIP_TO_LOCATIONS_STEP_ID,
    },
  ]
  const allKeys = activationSettings.map(({ key, id }) => {
    return activationSteps?.includes(id) ? [key, true] : [key, false]
  })
  const currentStep = activationSettings.find(({ id }) => id === activeStep)
  return {
    ...fromEntries(allKeys),
    accountActivationStep: currentStep?.step || activeStep,
  }
}
