import PropTypes from 'prop-types'
import { memo, useEffect, useState } from 'react'
import { eventManager } from 'services/eventsManager'
import AlertNotificationsList from '../AlertNotificationsList'
import { removeAlertById } from '../AlertNotificationUtils'
import {
  GLOBAL_CONTAINER_ID,
  HIDE_NOTIFICATION,
  HIDE_NOTIFICATION_ALL,
  REPLACE_NOTIFICATION,
  SHOW_NOTIFICATION,
} from '../AlertNotificationUtils/AlertNotificationConstants'
import {
  handleHideNotification,
  handleReplaceNotification,
  handleShowNotification,
} from './AlertNotificationContainerUtils'

/**
 *Name: AlertNotificationContainer
 *Desc: Container for the AlertNotifications in the app
 * @param {array} alertMessages
 * @param {string} maxWidth
 * @param {string} isAutoScrollTop
 */

const AlertNotificationContainer = ({
  maxWidth = 'lg',
  alertMessages = [],
  isAutoScrollTop = false,
  containerId = GLOBAL_CONTAINER_ID,
  ...rest
}) => {
  const { alerts, setAlerts } = useAlertMessages(alertMessages, containerId)
  const handleCloseAlert = (index) => {
    index && setAlerts((updatedAlerts) => removeAlertById(updatedAlerts, index))
  }

  return (
    <AlertNotificationsList
      alerts={alerts}
      maxWidth={maxWidth}
      isAutoScrollTop={isAutoScrollTop}
      onCloseAlert={handleCloseAlert}
      id={containerId}
      {...rest}
    />
  )
}

// PropType Validation
AlertNotificationContainer.propTypes = {
  alertMessages: PropTypes.array,
  containerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxWidth: PropTypes.string,
  isAutoScrollTop: PropTypes.bool,
}
/**
 *  Custom hooks used by AlertContainers
 * @param {Array} alertMessages
 * @param {string} containerId
 */

function useAlertMessages(alertMessages, containerId) {
  const [alerts, setAlerts] = useState(alertMessages)
  useEffect(() => {
    // Handles adding a notification
    eventManager.on(
      `${SHOW_NOTIFICATION}-${containerId}`,
      handleShowNotification(containerId, alerts.length, setAlerts)
    )

    // Handles replacing a notification (updating its content)
    eventManager.on(
      `${REPLACE_NOTIFICATION}-${containerId}`,
      handleReplaceNotification(setAlerts)
    )

    // Handles hiding a notification
    eventManager.on(
      `${HIDE_NOTIFICATION}-${containerId}`,
      handleHideNotification(containerId, alerts, setAlerts)
    )

    // close all notification
    eventManager.on(HIDE_NOTIFICATION_ALL, () => {
      setAlerts([])
    })

    return () => {
      eventManager.off(`${SHOW_NOTIFICATION}-${containerId}`)
      eventManager.off(`${REPLACE_NOTIFICATION}-${containerId}`)
      eventManager.off(`${HIDE_NOTIFICATION}-${containerId}`)
      eventManager.off(`${HIDE_NOTIFICATION_ALL}`)
    }
  }, [alerts, alerts.length, containerId])
  return { alerts, setAlerts }
}

export default memo(AlertNotificationContainer)
