import { getCardBudgetCalculatorData } from './HomeApiUtils'
import messages from 'components/containers/Home/MembershipCardPanel/messages'
const CUSTOM_PERIOD_MESSAGE = 'Budget:'

export async function fetchCardBudgetCalculatorData(language, showCustomDates) {
  const result = await getCardBudgetCalculatorData()
  const label = result.TimePeriodMessage?.replace(/:$/, '')
  const translations = messages[language]
  const data = {
    totalBudget: result.TotalBudget,
    beginDate: result.BeginDate,
    endDate: result.EndDate,
    availableBudget: result.AvailableBudget,
    showCustomDates,
    showBudgetCalculator: result.ShowBudgetCalculator,
    timePeriodMessage: label,
    showTimePeriodDates: result.TimePeriodMessage === CUSTOM_PERIOD_MESSAGE,
  }
  const mergedData = {
    language,
    budgetLabel: label,
    heading: translations.budgetTileHeadingLabel,
    budgetTimePeriodLabel: translations.budgetTimePeriodLabel,
    budgetAvailableLabel: translations.budgetAvailableLabel,
    toLabel: translations.toLabel,
    ...data,
  }
  return mergedData
}

// API response manipulation
export async function cardBudgetData(language, showCustomDates) {
  const data = await fetchCardBudgetCalculatorData(language, showCustomDates)
  return data
}
