export const ORDER_FEE_TYPES = {
  ENVIRONMENTAL: 'environmental',
  DELIVERY: 'delivery',
  TRANSAC: 'transac',
  ASSEMBLY: 'assembly',
  DELIVERY_AND_INSTALL: 'deliveryAndInstall',
  PRODUCT_HANDLING: 'delivery', // API should returning "Handling" but called "Delivery"
  TARIFF: 'tariff',
  FREIGHT_FEE: 'remoteLocation',
}
