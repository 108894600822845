import { format } from 'date-fns'
import { isEnglish } from '~/services/utils/languageUtils'
import { enCA, frCA } from 'date-fns/locale'

export const isWeekday = (date) => {
  const day = new Date(date).getDay()
  return day !== 0 && day !== 6
}

export const getFormattedDate = (date) => {
  if (!date) {
    return ''
  }
  const deliveryDate = createSlicedDateString(date)
  return format(deliveryDate, 'yyyy-MM-dd')
}

export const createSlicedDateString = (value = '') => {
  if (!value) {
    return ''
  }
  const isoStringDate = new Date(value).toISOString().slice(0, -5)
  const deliveryDate = new Date(isoStringDate)
  return deliveryDate
}
export const getAfterTodayDate = (days) => {
  const date = new Date()
  date.setDate(date.getDate() + days)
  return date
}

/**
 *Name: getFutureBusinessDate
 *Desc: Return upcoming date from the current date, Here counting only thoese dates  for business day (Excluding Sat and Sun).
 * @param {number} noOfDaysToAdd
 */

export const getFutureBusinessDate = (noOfDaysToAdd) => {
  const todayDate = getFormattedDate(new Date()).toString()
  const startDate = new Date(todayDate.replace(/-/g, '/'))
  let endDate = '',
    count = 0
  while (count < noOfDaysToAdd) {
    endDate = new Date(startDate.setDate(startDate.getDate() + 1))
    if (endDate.getDay() !== 0 && endDate.getDay() !== 6) {
      count++
    }
  }
  return endDate
}

/**
 *Name: getFutureMonth
 *Desc: Return upcoming month date from the current date.
 * @param {number} noOfMonthsToAdd
 */
export const getFutureMonth = (noOfMonthsToAdd) => {
  return new Date(new Date().setMonth(new Date().getMonth() + noOfMonthsToAdd))
}

export function formatDateForServer(date) {
  const yMd = format(date, 'yyyy-MM-dd')
  const hMs = format(date, 'HH:mm:dd')
  const finalValue = `${yMd}T${hMs}`
  return finalValue
}

/**
 * Desc: format date in required format for according to provided format
 * @param {date} date
 * @param {language} string
 */
export function formateDateInLocale(date, language, requiredDateFormat = '') {
  if (!date) {
    return ''
  }
  const dateFormat =
    requiredDateFormat ||
    (isEnglish(language) ? 'MMMM dd, yyyy' : 'dd MMMM yyyy')
  const locale = isEnglish(language) ? { locale: enCA } : { locale: frCA }
  return format(new Date(date), dateFormat, locale)
}

export const getTermsConditionDate = (date) => {
  if (!date) {
    return ''
  }
  const deliveryDate = createSlicedDateString(date)
  return format(deliveryDate, 'dd / MM / yyyy')
}

export const isInvalidDate = (deliveryDate) => {
  return deliveryDate === '1900-01-01'
}

export function getCurrentTimeStamp() {
  return new Date().getTime()
}
/**
 *Name: getFutureYear
 *Desc: Return upcoming year date from the current date.
 * @param {number} noOfYearsToAdd
 */
export const getFutureYear = (noOfYearsToAdd) => {
  return new Date(new Date().setYear(new Date().getFullYear() + noOfYearsToAdd))
}

export const MAX_DATE_VALUE = 8640000000000000
