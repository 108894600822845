export default {
  en: {
    productFamilyLabel: 'Product Family',
    regularLabel: 'Regular',
    saleLabel: 'Sale',
    saveLabel: 'Save',
    addToCart: 'Add to cart',
    noneLabel: 'None',
    ecoFeesLabel: 'Eco Fees',
    deliveryFeesLabel: 'Delivery Fee',
    transacFeesLabel: 'Transac',
    assemblyFeesLabel: 'Assembly Fee',
    deliveryAndInstallFeesLabel: 'Delivery and Install Fee',
    productHandlingFeesLabel: 'Product Handling',
    remoteLocationFeesLabel: 'Remote Location Fee',
    deliveryAreaSurchargeFeesLabel: 'Delivery Area Surcharge',
    tariffFeesLabel: 'Tariff Fees',
    costBreakDownLabel: 'Cost breakdown',
    alternateLabel: 'Alternate ordering formats',
    addToShoppingListLabel: 'Add to shopping list',
    /* keys for product icons start */
    promotionalItemLabel: 'Promotional Item',
    shipsFromATrustedStaplesLabel: 'Ships from a trusted Staples partner',
    specialDeliveryItemLabel: 'Special Delivery Item',
    sourcebookItemLabel: 'Sourcebook Item',
    flyerItemLabel: 'Flyer Item',
    restrictedProductLabel: 'Restricted Product',
    contractPriceLabel: 'Contract Price',
    marketPriceLabel: 'Market Price',
    /* keys for product icons end */
    offLabel: 'off',
    regularPriceText: 'Reg.',
    reviews: 'Reviews',
    beforeLoginPricingText: 'Login for pricing and availability information.',
  },
  fr: {
    productFamilyLabel: 'Gamme de produits',
    regularLabel: 'Régulier',
    saleLabel: 'Spécial',
    saveLabel: 'Économisez',
    addToCart: 'Ajouter au panier',
    noneLabel: 'None',
    ecoFeesLabel: 'Écofrais',
    deliveryFeesLabel: 'Frais de livraison',
    transacFeesLabel: 'Transac',
    assemblyFeesLabel: "Frais d'assemblage",
    deliveryAndInstallFeesLabel: "Frais de livraison et d'assemblage",
    productHandlingFeesLabel: 'Manutention',
    remoteLocationFeesLabel: 'Frais de région éloignée',
    deliveryAreaSurchargeFeesLabel: 'Surcharge de zone de livraison',
    tariffFeesLabel: 'Frais de douane',
    costBreakDownLabel: 'Répartition des coûts',
    alternateLabel: 'Autres formats de commande',
    addToShoppingListLabel: "Ajouter à la liste d'achat",
    /* keys for product icons start */
    promotionalItemLabel: 'Article promotionnel',
    shipsFromATrustedStaplesLabel:
      'Expédié par un partenaire de confiance Staples',
    specialDeliveryItemLabel: 'Article en livraison spéciale',
    sourcebookItemLabel: 'Article en catalogue',
    flyerItemLabel: 'Article en circulaire',
    restrictedProductLabel: 'Produit restreint',
    contractPriceLabel: 'Prix contrat',
    marketPriceLabel: 'Prix marché',
    /* keys for product icons end */
    offLabel: 'de rabais',
    regularPriceText: 'Rég. ',
    reviews: 'Avis',
    beforeLoginPricingText:
      'Connectez-vous pour connaître les prix et la disponibilité.',
  },
}
