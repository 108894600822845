import isEmpty from 'lodash/isEmpty'
import { setCookie } from 'cookies-next'
import { sessionDependentCookies } from '~/components/shared/Layout/Header/Logout/LogoutUtils'
import { WELCOME_TO_EWAY_MODAL_SHOWN } from '~/components/shared/WelcomeToEwayModal/WelcomeToEwayModalConstants'
import { COOKIES } from '~/services/constants'
import { setApiAuthCookie } from '~/services/cookieManager'
import { logErrorToConsole } from '~/services/logger'
import { fetchAutoLoginCookies } from './autoLoginServiceApiUtils'
import { DEFAULT_OPTIONS, REQUIRED_KEYS } from './autoLoginServiceConstants'
import {
  getAutoLoginEntries,
  getAutoLoginValues,
} from './autoLoginServiceUtils'

/**
 * Sets the all the cookies in the auto login function before returning the context to the browser
 * @param {Object} ctx
 */
export async function setAutoLoginCookies(ctx) {
  try {
    const values = getAutoLoginValues(ctx.query)
    // If values are empty don't do anything
    if (isEmpty(values)) {
      return resolveDone
    }
    const result = (await fetchAutoLoginCookies(values, ctx.req, ctx.res)) || {}
    const { data: allCookies = [] } = result || {}

    const { req, res } = ctx
    allCookies.forEach((cookieValue) => {
      const { name, value, ...rest } = cookieValue
      const options = {
        req,
        encode: (val) => val,
        res,
        ...DEFAULT_OPTIONS,
        ...rest,
      }
      setCookie(name, value, options)
    })

    if (allCookies.length) {
      clearSessionDependentCookies(req, res, ctx?.req?.cookies)
    }

    const apiauth = allCookies.find((e) => e.name === COOKIES.APIAUTH)?.value
    setApiAuthCookie(apiauth)
    return Promise.resolve({ cookies: [...allCookies], ...DONE })
  } catch (error) {
    logErrorToConsole(error)
    throw new Error(error)
  }
}

const DONE = { done: true }
const resolveDone = Promise.resolve(DONE)

function clearSessionDependentCookies(req, res, reqCookies = {}) {
  // Prevents issue for infinite postal code entry shown
  const cookiesToClear =
    reqCookies[WELCOME_TO_EWAY_MODAL_SHOWN] === 'true'
      ? sessionDependentCookies.filter(({ name = '' } = {}) => {
          return name !== WELCOME_TO_EWAY_MODAL_SHOWN
        })
      : sessionDependentCookies
  cookiesToClear.forEach(({ name, defaultValue }) => {
    const options = {
      req,
      res,
      encode: (val) => val,
      ...DEFAULT_OPTIONS,
      expires: null,
    }
    return setCookie(name, defaultValue, options)
  })
}

/**
 * Detects if buyerId, orderId, and sessionId are part of the query params
 * If so, we do an automatic login just like we do for impersonification / punchout login
 * @param {Object} queryParams
 * @returns {boolean} - Should return a boolean saying if we should do the login by id
 */
export function shouldDoLoginByIds(queryParams = {}) {
  const keys = getAutoLoginEntries(queryParams).map(([key]) => key)
  const hasAllRequiredKeys = REQUIRED_KEYS.every((required) =>
    keys.includes(required)
  )
  return hasAllRequiredKeys
}
