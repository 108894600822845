import classNames from 'classnames'
import isBoolean from 'lodash/isBoolean'
import PropTypes from 'prop-types'
import { forwardRef, memo } from 'react'
import styles from './Text.module.scss'
import { isProduction } from '~/config/env/environmentUtils'

const SIMILAR_COLORS_NAMES_MAP = {
  [styles.red]: styles.primary,
}

/**
 * Name: Text
 * Desc: Render text
 * @param {string} text
 * @param {string} color
 * @param {string} fontWeight
 * @param {bool} lineThrough
 * @param {string} variant
 */

const Text = forwardRef((props, ref) => {
  const {
    text = '',
    variant = 'xsText',
    size = '',
    color,
    fontWeight,
    lineThrough,
    children,
    className,
    dataTest = null,
    textDecoration,
    cursor,
    fontFamily,
    whiteSpace,
    textAlign,
    as: Component = 'span',
    ...rest
  } = props
  const finalColor = SIMILAR_COLORS_NAMES_MAP[color] || color
  const colorClass = styles[finalColor] || styles.lightGray
  const textDecorationClass = isBoolean(textDecoration)
    ? styles.textDecoration
    : styles[textDecoration]
  const sizeClass = styles[size] || styles[variant]

  const classes = classNames(
    sizeClass,
    colorClass,
    className,
    textDecorationClass,
    {
      [styles.lineThrough]: !!lineThrough,
      [styles[fontWeight]]: fontWeight,
      [styles.fontFamilyMedium]: fontFamily === 'md',
      [styles.noWrap]: whiteSpace === 'nowrap',
      [styles.pre]: whiteSpace === 'pre',
      [styles.textAlignCenter]: textAlign === 'center',
      [styles['cursorPointer']]: cursor === 'pointer',
    }
  )
  const finalText = children || text

  return (
    <Component data-test={dataTest} ref={ref} className={classes} {...rest}>
      {finalText}
    </Component>
  )
})

// PropTypes Validation
Text.propTypes = {
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.element,
    PropTypes.array,
  ]),
  /** Controls the color of the text \
   * **By default text has a value of #666666"** \
   *
   * Other values can be
   *
   * - color="primary" -> red
   * - color="secondary" -> dark-grey
   * - color="tertiary" -> medium-grey
   * - color="black" -> black
   * - color="success"
   * - color="white"
   */
  color: PropTypes.string,
  as: PropTypes.string,
  fontWeight: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
  lineThrough: PropTypes.bool,
  textAlign: PropTypes.string,
  // one of text or boolean
  textDecoration: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  whiteSpace: PropTypes.string,
  cursor: PropTypes.string,
  dataTest: PropTypes.string,

  /** The font family, defaults to "MotivasanRegular" use "md" \
   * To use the alternate "MotivasanMedium" font.
   * The alternate font family is bolder.
   * You have have to use it to match
   * what is seen on mocks
   */
  fontFamily: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
}
if (!isProduction()) {
  Text.displayName = 'Text'
}
export default memo(Text)
