import classNames from 'classnames'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import Box from '../Box'
import styles from './Icon.module.scss'
import Svg from './Svg'

/**
 * Name: Icon
 * Desc: Renders an Icon
 * Usage:
 * ```
 * <Icon variant="close" />
 * ```
 * @param {string} variant
 * @param {string} title
 */

const Icon = forwardRef((props, ref) => {
  const { variant, title, cursor, as, ...rest } = props
  const classes = classNames(
    styles.iconStyling,
    {
      [styles[variant]]: variant,
      [styles.cursorDefault]: cursor === 'default',
    },
    rest.className
  )
  const component =
    as === 'svg' ? (
      <Svg {...{ ...rest, variant }} />
    ) : (
      <Box as="span" title={title} {...rest} className={classes} ref={ref} />
    )
  return component
})

// Default Props
Icon.defaultProps = {
  variant: 'primary',
  title: null,
  as: 'span',
}

Icon.displayName = 'Icon'

// Props Validation
Icon.propTypes = {
  cursor: PropTypes.string,
  as: PropTypes.string,
  title: PropTypes.string,
  variant: PropTypes.string,
}

export default Icon
