export const sortDefault = () => (
  <svg
    width="16px"
    height="16px"
    viewBox="0 0 16 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>sortDefault</title>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M12.7518012,10.704617 C12.6263453,10.5930104 12.4776312,10.5372533 12.3057976,10.5372533 L3.42554105,10.5372533 C3.25363802,10.5372533 3.10502799,10.5930104 2.97946796,10.704617 C2.85390793,10.816347 2.79117999,10.9484421 2.79117999,11.1011491 C2.79117999,11.2538253 2.85390793,11.3859204 2.97946796,11.4975578 L7.41961357,15.4442729 C7.54531246,15.5558796 7.69392249,15.61176 7.86568667,15.61176 C8.03745084,15.61176 8.18619973,15.5558796 8.31165562,15.4442729 L12.7518012,11.497527 C12.8772224,11.3859204 12.9401933,11.2538253 12.9401933,11.1011183 C12.9401933,10.9484421 12.8772224,10.816347 12.7518012,10.704617 Z"
        fill="#444444"
        fillRule="nonzero"
      ></path>
      <path
        d="M12.7518012,0.555603638 C12.6263453,0.443997013 12.4776312,0.388239985 12.3057976,0.388239985 L3.42554105,0.388239985 C3.25363802,0.388239985 3.10502799,0.443997013 2.97946796,0.555603638 C2.85390793,0.667333687 2.79117999,0.799428782 2.79117999,0.952135773 C2.79117999,1.10481191 2.85390793,1.236907 2.97946796,1.34854448 L7.41961357,5.29525958 C7.54531246,5.40686621 7.69392249,5.46274666 7.86568667,5.46274666 C8.03745084,5.46274666 8.18619973,5.40686621 8.31165562,5.29525958 L12.7518012,1.34851363 C12.8772224,1.236907 12.9401933,1.10481191 12.9401933,0.952104916 C12.9401933,0.799428782 12.8772224,0.667333687 12.7518012,0.555603638 Z"
        fill="#444444"
        fillRule="nonzero"
        transform="translate(7.865687, 2.925493) scale(1, -1) translate(-7.865687, -2.925493) "
      ></path>
    </g>
  </svg>
)
export const sortAscending = (primaryTitle, secondaryTitle) => (
  <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <title>{primaryTitle}</title>
      <path
        d="M12.7518012,10.704617 C12.6263453,10.5930104 12.4776312,10.5372533 12.3057976,10.5372533 L3.42554105,10.5372533 C3.25363802,10.5372533 3.10502799,10.5930104 2.97946796,10.704617 C2.85390793,10.816347 2.79117999,10.9484421 2.79117999,11.1011491 C2.79117999,11.2538253 2.85390793,11.3859204 2.97946796,11.4975578 L7.41961357,15.4442729 C7.54531246,15.5558796 7.69392249,15.61176 7.86568667,15.61176 C8.03745084,15.61176 8.18619973,15.5558796 8.31165562,15.4442729 L12.7518012,11.497527 C12.8772224,11.3859204 12.9401933,11.2538253 12.9401933,11.1011183 C12.9401933,10.9484421 12.8772224,10.816347 12.7518012,10.704617 Z"
        id="arrowUp"
        fill="#444444"
        fillRule="nonzero"
      ></path>
      <g
        transform="translate(7.925493, 2.462747) scale(1, -1) translate(-7.925493, -2.462747) translate(2.425493, -0.537253)"
        fill="#CCCCCC"
        fillRule="nonzero"
      >
        <title>{secondaryTitle}</title>
        <path d="M10.3263079,0.167363653 C10.200852,0.0557570281 10.0521378,0 9.88030424,0 L1.00004773,0 C0.8281447,0 0.679534668,0.0557570281 0.553974639,0.167363653 C0.428414609,0.279093702 0.365686665,0.411188797 0.365686665,0.563895787 C0.365686665,0.716571922 0.428414609,0.848667017 0.553974639,0.960304498 L4.99412025,4.9070196 C5.11981913,5.01862622 5.26842917,5.07450668 5.44019334,5.07450668 C5.61195752,5.07450668 5.76070641,5.01862622 5.88616229,4.9070196 L10.3263079,0.960273642 C10.4517291,0.848667017 10.5147,0.716571922 10.5147,0.563864931 C10.5147,0.411188797 10.4517291,0.279093702 10.3263079,0.167363653 Z"></path>
      </g>
    </g>
  </svg>
)
export const sortDescending = (primaryTitle, secondaryTitle) => (
  <svg
    width="16px"
    height="16px"
    viewBox="0 0 16 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{primaryTitle}</title>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M12.7518012,10.704617 C12.6263453,10.5930104 12.4776312,10.5372533 12.3057976,10.5372533 L3.42554105,10.5372533 C3.25363802,10.5372533 3.10502799,10.5930104 2.97946796,10.704617 C2.85390793,10.816347 2.79117999,10.9484421 2.79117999,11.1011491 C2.79117999,11.2538253 2.85390793,11.3859204 2.97946796,11.4975578 L7.41961357,15.4442729 C7.54531246,15.5558796 7.69392249,15.61176 7.86568667,15.61176 C8.03745084,15.61176 8.18619973,15.5558796 8.31165562,15.4442729 L12.7518012,11.497527 C12.8772224,11.3859204 12.9401933,11.2538253 12.9401933,11.1011183 C12.9401933,10.9484421 12.8772224,10.816347 12.7518012,10.704617 Z"
        fill="#CCCCCC"
        fillRule="nonzero"
      ></path>
      <g
        transform="translate(7.925493, 2.462747) scale(1, -1) translate(-7.925493, -2.462747) translate(2.425493, -0.537253)"
        fill="#444444"
        fillRule="nonzero"
      >
        <title>{secondaryTitle}</title>
        <path d="M10.3263079,0.167363653 C10.200852,0.0557570281 10.0521378,0 9.88030424,0 L1.00004773,0 C0.8281447,0 0.679534668,0.0557570281 0.553974639,0.167363653 C0.428414609,0.279093702 0.365686665,0.411188797 0.365686665,0.563895787 C0.365686665,0.716571922 0.428414609,0.848667017 0.553974639,0.960304498 L4.99412025,4.9070196 C5.11981913,5.01862622 5.26842917,5.07450668 5.44019334,5.07450668 C5.61195752,5.07450668 5.76070641,5.01862622 5.88616229,4.9070196 L10.3263079,0.960273642 C10.4517291,0.848667017 10.5147,0.716571922 10.5147,0.563864931 C10.5147,0.411188797 10.4517291,0.279093702 10.3263079,0.167363653 Z"></path>
      </g>
    </g>
  </svg>
)
export const arrowUpFilled = (primaryTitle) => (
  <svg
    fill="currentColor"
    viewBox="5.3 0.4 5.1 5.1"
    color="#666666"
    focusable="false"
    height="5.07px"
    width="10.15px"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
  >
    <title>{primaryTitle}</title>
    <path
      d="M12.7518012,0.555603638 C12.6263453,0.443997013 12.4776312,0.388239985 12.3057976,0.388239985 L3.42554105,0.388239985 C3.25363802,0.388239985 3.10502799,0.443997013 2.97946796,0.555603638 C2.85390793,0.667333687 2.79117999,0.799428782 2.79117999,0.952135773 C2.79117999,1.10481191 2.85390793,1.236907 2.97946796,1.34854448 L7.41961357,5.29525958 C7.54531246,5.40686621 7.69392249,5.46274666 7.86568667,5.46274666 C8.03745084,5.46274666 8.18619973,5.40686621 8.31165562,5.29525958 L12.7518012,1.34851363 C12.8772224,1.236907 12.9401933,1.10481191 12.9401933,0.952104916 C12.9401933,0.799428782 12.8772224,0.667333687 12.7518012,0.555603638 Z"
      fillRule="nonzero"
      transform="translate(7.865687, 2.925493) scale(1, -1) translate(-7.865687, -2.925493) "
    ></path>
  </svg>
)
export const arrowDownFilled = (secondaryTitle) => (
  <svg width="10.15px" height="5.07px" viewBox="5.3 10.4 5.1 5.1">
    <title>{secondaryTitle}</title>
    <path
      d="M12.7518012,10.704617 C12.6263453,10.5930104 12.4776312,10.5372533 12.3057976,10.5372533 L3.42554105,10.5372533 C3.25363802,10.5372533 3.10502799,10.5930104 2.97946796,10.704617 C2.85390793,10.816347 2.79117999,10.9484421 2.79117999,11.1011491 C2.79117999,11.2538253 2.85390793,11.3859204 2.97946796,11.4975578 L7.41961357,15.4442729 C7.54531246,15.5558796 7.69392249,15.61176 7.86568667,15.61176 C8.03745084,15.61176 8.18619973,15.5558796 8.31165562,15.4442729 L12.7518012,11.497527 C12.8772224,11.3859204 12.9401933,11.2538253 12.9401933,11.1011183 C12.9401933,10.9484421 12.8772224,10.816347 12.7518012,10.704617 Z"
      fillRule="nonzero"
    ></path>
  </svg>
)
