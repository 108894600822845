/**
 * Validate postal code
 * @param {string} postalCode
 */
export const isValidPostalCode = (postalCode = '') => {
  if (!postalCode.length) {
    return false
  }
  const pattern = new RegExp(postalCodeRegex)
  return pattern.test(postalCode)
}
export const isValidEmailAddress = (emailAddress = '') => {
  const pattern = new RegExp(emailRegex)
  return pattern.test(emailAddress)
}

// eslint-disable-next-line no-useless-escape
export const emailRegex = /^[\w\.'-]+@[\w\.-]+(\.\w{2,4})$/

export const postalCodeRegex =
  /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ ]?\d[ABCEGHJ-NPRSTV-Z]\d$/i
export const priceRegex = /^\d*[,.]?[0-9]?$/
export const priceNonEmpty =
  /^[1-9]+[0-9]+[.,]?[\d]*?$|^[1-9]$|^[0-9][,.][0-9]+|^[0][0-9]+/

const ewayGUID =
  /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i
/**
 * Tests that the string passed is a GUID
 * @param {string} value - The passed guid
 * @returns {bool} - Boolean that returns true or false
 */
export const isEwayGuid = (value) => {
  if (!value) {
    return false
  }
  const finalValue = value.replace('{', '').replace('}', '')
  return !!ewayGUID.test(finalValue)
}
