// Inspired from
// https://www.npmjs.com/package/react-toastify
export const eventManager = {
  list: new Map(),

  on(event, callback) {
    this.list.has(event) || this.list.set(event, [])
    this.list.get(event).push(callback)
    return this
  },

  off(event) {
    this.list.delete(event)
    return this
  },

  removeListener(event, callback) {
    const callbackList = this.list.get(event)
    if (callbackList.length === 1) {
      this.off(event)
      return
    }
    callbackList.find((fn, index) => {
      if (fn === callback) {
        callbackList.splice(index, 1)
      }
    })
  },

  /**
   * Enqueue the event at the end of the call stack
   * Doing so let the user call toast as follow:
   * toast('1')
   * toast('2')
   * toast('3')
   * Without setTimemout the code above will not work
   */
  emit(event, ...args) {
    this.list.has(event) &&
      this.list.get(event).forEach((callback) => {
        if (args[0] && args[0].isSynchEvent) {
          callback(...args)
          return
        }
        setTimeout(() => {
          callback(...args)
        }, 0)
      })
  },
}
