import { getLanguageFromUrl } from '~/components/shared/EwayRouter/EwayRouterUtils'
import { COOKIES } from './constants'
import { wrapWithCurlyBrace } from './utils'
import { shortenLanguage } from './utils/languageUtils'

export const getAllHeaders = (req = {}) => {
  const { headers = {} } = req
  const headerBuyerId = headers[COOKIES.BUYER_ID.toLowerCase()]
  const headerSessionId = headers[COOKIES.SESSION_ID.toLowerCase()]
  const tokenHeader = headers['apiauth']
  const languageFromUrl = getLanguageFromUrl(headers.absoluteuri)
  const buyerId = wrapWithCurlyBrace(headerBuyerId)
  const sessionId = wrapWithCurlyBrace(headerSessionId)
  const languageFromHeaders = headers[COOKIES.LANGUAGE.toLowerCase()]
  const language = languageFromUrl || shortenLanguage(languageFromHeaders)
  return { buyerId, sessionId, tokenHeader, language }
}
