export default {
  en: {
    label: 'Tariff Fees',
    dynamicText: '*Includes Applicable {link} ',
  },
  fr: {
    label: 'Frais De Douanes',
    dynamicText: '{link} Applicables Inclus',
  },
}
