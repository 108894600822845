/**
 * Inspired from
 * https://docs.adobe.com/content/help/en/analytics/implementation/javascript-implementation/appmeasure-mjs-pagecode.html
 */
import { getAnalyticsAccountFromEnv } from 'services/utils/configUtils'
// Represents the global settings for the analytics.
export const globalAnalyticsSettings = () => {
  return {
    account: getAnalyticsAccountFromEnv(),
    trackingServer: 'staples.122.2o7.net',
    // Link Tracking Config
    visitorNamespace: 'staples',
    trackDownloadLinks: true,
    trackExternalLinks: true,

    // Conversion Config
    currencyCode: 'CAD',
    trackInlineStats: true,
    linkDownloadFileTypes:
      'exe,zip,wav,mp3,mov,mpg,avi,wmv,pdf,doc,docx,xls,xlsx,ppt,pptx',
    linkInternalFilters:
      'javascript:,eway.ca,cexp.ca,staplesams.com,stg-eway-release.savana.staples.com,stg-eway-maintenance.savana.staples.com,stg-eway-previous.savana.staples.com,dev-eway-release.savana.staples.com',
    linkLeaveQueryString: false,
    linkTrackVars: 'None',
    linkTrackEvents: 'None',
    usePlugins: true,
  }
}
