export const NONE = 0

export const CREDIT_CARD_MANDATORY_TYPE = {
  // NotAuthorized = 0
  // Means user can only use - Place this order on account. Payment will be made according to the previously established Terms.
  NOT_AUTHORIZED: 0, // NotAuthorized = 0 Cannot use any other option than OnAccount (1)

  /* Optional = 1
   * Means user has access to any option in payment options -
   * Allows user to have options:
   * "Use the Credit Card loaded in My Profile"
   * "Use the Credit Card provided"
   */
  OPTIONAL: 1,

  /* Mandatory = 2
   * Means user can use anything expect the "On Account"
   * Essentially user has to enter a credit card.
   * Allows user to have options "Use the Credit Card loaded in My Profile" and "Use the Credit Card provided"
   */
  MANDATORY: 2, // Mandatory = 2 Anything but OnAccount(1)
}

export const SUCCESS_ADD = 'CCSUCCESSFULLINSERT'
export const SERVICE_NOT_AVAILABLE = 'CCENTRYPOWERROR'

export const IFRAME_URL_ERROR = 'iframe-not-loading-error'

export const CREDIT_CARD_TYPE = {
  0: 'Other',
  3: 'Amex',
  4: 'Visa',
  5: 'MasterCard',
  8: 'DinersClub',
}
