import DynamicTranslation from 'components/shared/DynamicTranslation/DynamicTranslation'
import FeeLink from 'components/shared/OrderSummary/OrderSummaryDetail/OrderSummaryLine/FeeLink/index.js'
import PropTypes from 'prop-types'
import { Fragment } from 'react'
import FeeMessage from 'shared/OrderSummary/OrderSummaryDetail/OrderSummaryLine/FeeMessage'
import { ENGLISH } from '~/redux/constants'
import messages from './messages'

export default function EcoFeeMessage({ language, short }) {
  const { label, dynamicText } = messages[language]
  const linkComponent = (
    <FeeLink href="/Search/FeeFaqPage.aspx" language={language}>
      {label}
    </FeeLink>
  )
  const component = short ? (
    linkComponent
  ) : (
    <DynamicTranslation
      text={dynamicText}
      replace="{link}"
      with={linkComponent}
    />
  )

  return (
    <FeeMessage
      language={language}
      text={component}
      as={short ? Fragment : undefined}
    />
  )
}

// PropTypes
EcoFeeMessage.propTypes = {
  language: PropTypes.string,
  short: PropTypes.bool,
}

// Default Props
EcoFeeMessage.defaultProps = {
  language: ENGLISH,
}
