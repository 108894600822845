import { useEffect, useState } from 'react'
/**
 * Checks the component is mounted
 * Change the approach of the ref way to the state way
 * https://simbathesailor.dev/useismounted-ref-vs-state-approach%20
 */
const useIsMounted = () => {
  const [isMounted, setIsMounted] = useState(false)
  useEffect(() => {
    setIsMounted(true)
    return () => setIsMounted(false)
  }, [])

  return isMounted
}

export default useIsMounted
