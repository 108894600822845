import { isSuspended } from '~/components/shared/Utils/userUtils'
import { ENGLISH } from '~/redux/constants'
import { fetchClientInformation } from './clientApiUtils'
import { fetchPunchOut } from '../punchOut'
import {
  fetchCustomSiteContent,
  fetchCustomSiteContentDetails,
  fetchCustomSiteContentResources,
} from '../siteContent'

export async function buildAndFetchClient(props) {
  let [, , , language] = props
  const [
    currentShipToAccountId,
    punchOutModuleId,
    contentNumber,
    ,
    tokenHeader,
  ] = props
  const siteContentNo = contentNumber || 1
  language = language || ENGLISH
  const cultureCode = `${language}-CA`
  const options = {
    headers: { APIAUTH: tokenHeader },
  }
  const siteContentArgs = [siteContentNo, options]
  const promises = [
    fetchClientInformation(currentShipToAccountId, options),
    fetchPunchOut(punchOutModuleId, options),
    fetchCustomSiteContent(...siteContentArgs),
    fetchCustomSiteContentDetails(...siteContentArgs),
    fetchCustomSiteContentResources(...siteContentArgs),
  ].filter((x) => x !== undefined)
  const promiseResult = await Promise.all(promises)
  const [
    client,
    punchout,
    customContent,
    customContentDetails,
    customContentResources,
  ] = promiseResult

  const punchOutSettings = punchout?.find?.(firstOrDefault) || {}

  const { logoFilename: customLogo } =
    customContent?.find?.(firstOrDefault) || {}
  const {
    copyrightContent: customCopyrightContent,
    brandingShortName: customBrandingShortName,
  } =
    customContentDetails?.find?.(firstOrDefaultByCultureCode(cultureCode)) || {}
  const { valueEn: customContactUsEmailEn, valueFr: customContactUsEmailFr } =
    customContentResources?.find?.(
      (x) => x !== undefined && x.dataName === 'HeaderLblEmailAssistance'
    ) || {}

  const customContactUsEmail =
    language === 'fr' ? customContactUsEmailFr : customContactUsEmailEn

  const result = {
    ...client,
    customLogo,
    isSuspended: isSuspended(client) || false,
    customBrandingShortName,
    customContactUsEmail,
    customCopyrightContent,
    isUsingInternalCheckout: !!punchOutSettings.isUsingInternalCheckout,
    canUseB2bCartResponse: !!punchOutSettings.useB2bcartResponse,
  }

  return result
}

const firstOrDefault = () => (x) => x !== undefined
const firstOrDefaultByCultureCode = (cultureCode) => (x) =>
  x !== undefined && x.cultureCode === cultureCode
