import { getFeesSummary } from '~/services/fees/order'
import { camelCaseKeys } from 'services/utils/utils'
import { ENGLISH } from '~/redux/constants'

/**
 * Name: getCartOrderSummary
 * Desc: Fetch cart order summary data
 * @param {*} language
 * @param {*} cartDetails
 * @param {*} approvalAction
 * @param {*} shippingPostalCode
 */
export const getCartOrderSummary = ({
  language = ENGLISH,
  cartDetails = {},
  approvalAction,
  showContractProductTotal = false,
  shippingPostalCode = '',
}) => {
  const { cartInfo: cartInformation = {}, orderDeli } =
    camelCaseKeys(cartDetails)
  const { products = [], cartSummary = {} } = cartInformation
  const cartInfo = {
    products,
    cartSummary,
    orderDeli: {
      ...orderDeli,
      shippingPostalCode,
    },
  }
  const orderTotal = getCartSummaryTotal(cartSummary)
  const summaryDetails = getFeesSummary(
    cartInfo,
    language,
    showContractProductTotal
  )
  const orderSummary = {
    approvalAction,
    orderSummaryHeader: {
      orderSummaryTotalPrice: orderTotal,
    },
    orderSummaryDetails: orderTotal ? summaryDetails : [],
  }

  return orderSummary
}

/**
 *
 * @param {object} cartSummary
 */

function getCartSummaryTotal(cartSummary = {}) {
  // Not sure that this logic needs to be applied, to confirm with backend team
  return cartSummary?.priceDetails?.promoDiscount
    ? cartSummary?.priceDetails?.total -
        cartSummary?.priceDetails?.promoDiscount
    : cartSummary?.priceDetails?.total || 0
}
