export default {
  en: {
    monthlyBudget: 'Monthly Budget',
    expenseAllowance: 'Expense Allowance: ',
    remainingBalance: 'Remaining Balance: ',
    available: 'Available',
    timePeriod: 'Time Period',
    printView: 'Print View',
    saveAsTemplate: 'Save As Template',
    clearCart: 'Clear cart',
    toLabel: 'to',
    addToListLabel: 'Add to list',
    addToShoppingListLabel: 'Add to shopping list',
    selectShoppingList: 'Select Shopping List',
    listsTileHeadingLabel: 'Lists',
    createListLabel: 'Create a list',
    viewAllLabel: 'View all',
    itemLabel: 'item in the list',
    moreItemsLabel: 'items in the list',
    itemAddedToShoppingList:
      'The items in your cart have been successfully added to the shopping list',
  },
  fr: {
    monthlyBudget: 'Allocation mensuelle',
    expenseAllowance: 'Allocation de dépenses: ',
    remainingBalance: 'Solde restant: ',
    available: 'Disponible',
    timePeriod: 'Période',
    printView: "Vue de l'impression",
    saveAsTemplate: 'Sauvegarder en brouillon',
    clearCart: 'Vider panier',
    toLabel: 'à',
    addToListLabel: 'Ajouter à la liste',
    addToShoppingListLabel: "Ajouter à la liste d'achat",
    selectShoppingList: "Sélectionnez la liste d'achats",
    listsTileHeadingLabel: 'Listes',
    createListLabel: 'Créer une liste',
    viewAllLabel: 'Voir tout',
    itemLabel: 'article dans cette liste',
    moreItemsLabel: 'articles dans cette liste',
    itemAddedToShoppingList:
      "Les articles dans votre panier ont été ajoutés avec succès à la liste d'achats",
  },
}
