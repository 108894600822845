export default {
  en: {
    preAssemblyNotAvailable: `Pre-assembly of Special Delivery products is not available in the area where this Ship To Account is located. The Assemble Option has been automatically removed from the Special Delivery products on this order.`,
    installationUnavailable: `Installation of Special Delivery products is not available in the area where this Ship To Account is located. The Service Option for this order has been automatically changed from "Deliver & Install" to "Deliver Only".`,
  },
  fr: {
    preAssemblyNotAvailable: `Le pré-assemblage de produits en livraison spéciale n'est pas disponible là où se trouve le sous-compte. L'option d'assemblage a été automatiquement retirée des produits en livraison spéciale sur cette commande.`,
    installationUnavailable: `L'installation de produits en livraison spéciale n'est pas disponible là où se trouve ce sous-compte. L'option de service pour cette commande a été automatiquement changée de "Livrer et installer" à "Livraison seulement".`,
  },
}
