import { ENGLISH } from '@redux/constants'
import { generateHeadersWithLanguage, sendRequest } from '../apiHelper'

export async function searchApi(args) {
  const [url, reqParams, language = ENGLISH] = args
  const options = {
    method: 'POST',
    body: JSON.stringify(reqParams),
    headers: { ...generateHeadersWithLanguage(language) },
  }
  return await sendRequest(url, options)
}
