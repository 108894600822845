import DynamicTranslation from 'components/shared/DynamicTranslation/DynamicTranslation'
import PropTypes from 'prop-types'
import EcoFeeMessage from 'shared/OrderSummary/OrderSummaryDetail/OrderSummaryLine/EcoFeeMessage'
import FeeMessage from 'shared/OrderSummary/OrderSummaryDetail/OrderSummaryLine/FeeMessage'
import TariffFeeMessage from 'shared/OrderSummary/OrderSummaryDetail/OrderSummaryLine/TarifFeeMessage'
import { ENGLISH } from '~/redux/constants'
import styles from './EcoAndTariffFeeMessage.module.scss'
import messages from './messages'

export default function EcoAndTarrifFeeMessage({ language }) {
  const text = messages[language]
  const mapping = {
    '{ecoFees}': <EcoFeeMessage language={language} short={true} />,
    '{tariffFees}': <TariffFeeMessage language={language} short={true} />,
  }

  return (
    <FeeMessage className={styles.ecoAndTariffFee}>
      <DynamicTranslation text={text} replaceMap={mapping} />
    </FeeMessage>
  )
}

// PropTypes
EcoAndTarrifFeeMessage.propTypes = {
  language: PropTypes.string,
}

// Default Props
EcoAndTarrifFeeMessage.defaultProps = {
  language: ENGLISH,
}
