import pickBy from 'lodash/pickBy'
import identity from 'lodash/identity'
import {
  getCallingLocationFromUrl,
  getCategories,
  getCidCampaignFromUrl,
  getIcidCampaignFromUrl,
} from '../../AdobeAnalyticsQueryParamUtils'
import { getFullUrl, safeShow } from '../../AdobeAnalyticsUtils'
import * as CONSTANTS from './AdobeAnalyticsSearchConstants'
/**
 *
 * @param {string} relativePath - Represents the relative path created by nextjs framework
 * @param {string} pagination - The the pagination number that's sent in the url when you search
 * @param {object} req - the nextjs request object that comes from express
 * @param {bool} isLoggedIn - checks if the user if logged in
 * @param {string} query - the word the user / buyer searched for
 */
export function handleBrowseProductAnalytics(
  relativePath,
  pagination,
  req,
  isLoggedIn,
  query
) {
  const fullUrl = getFullUrl(req)
  const { isKeywordSearch, isCategorySearch, isShoppingListView } =
    getAnalyticsType(relativePath)
  const categories = getCategories(fullUrl)
  if (isKeywordSearch || isCategorySearch) {
    const eVar36 = 'Other Finding Method'
    const values = getKeywordOrCategorySearchAnalytics(
      fullUrl,
      pagination,
      isLoggedIn,
      categories
    )
    const finalQuery = query || '<Empty>'
    const keywordSearchValues = {
      ...values,
      prop1: finalQuery,
      eVar1: finalQuery,
    }
    const categorySearchValues = {
      eVar1: isCategorySearch ? 'Non-Search' : null,
      eVar3: 'Browse',
      prop3: 'Master Category',
      ...values,
      eVar36,
    }
    const searchValues = isKeywordSearch
      ? keywordSearchValues
      : categorySearchValues

    return searchValues
  }

  if (isShoppingListView) {
    return getShoppingListSearchAnalytics(pagination)
  }

  return {}
}

function getAnalyticsType(relativePath) {
  const isKeywordSearch = relativePath.includes(CONSTANTS.KEYWORD_PATH)
  const isCategorySearch = relativePath.includes(CONSTANTS.CATEGORY_PATH)
  const isShoppingListView = relativePath.includes(CONSTANTS.SHOPPING_LIST_PATH)
  return { isKeywordSearch, isCategorySearch, isShoppingListView }
}

export function getKeywordOrCategorySearchAnalytics(
  url,
  pageNumber,
  isLoggedIn,
  categories
) {
  const campaign = getCidCampaignFromUrl(url)
  const eVar4 = getIcidCampaignFromUrl(url)
  const campaignAndSearchResults = removeFalsy({
    campaign,
    eVar4,
  })

  const commonValues = {
    ...campaignAndSearchResults,
    eVar68: '+1',
  }
  const values = generateAnalyticsKeywordOrCategory(
    url,
    pageNumber,
    isLoggedIn,
    categories
  )
  return { ...values, ...commonValues }
}

const generateAnalyticsKeywordOrCategory = (
  url,
  pageNumber,
  isLoggedIn,
  categories
) => {
  // ⬇️ "campaign" is translated to eVar0 in the tracking pixel values
  // when sent using "sendTrackingInformation"

  const prop4 = getProp4(pageNumber)

  const {
    calledFromBanners,
    calledFromBreadCrumbs,
    calledFromSearchBar,
    calledFromNavBar,
    calledFromPromotion,
  } = getCallingLocationFromUrl(url)

  if (calledFromBanners) {
    const preLoginBannersValues = {
      ...CONSTANTS.PRE_LOGIN_BANNERS,
      prop4,
    }
    const postLoginBannersValues = {
      ...CONSTANTS.POST_LOGIN_BANNERS,
      prop4,
    }
    const finalValues = isLoggedIn
      ? postLoginBannersValues
      : preLoginBannersValues
    return { ...finalValues }
  }

  if (calledFromBreadCrumbs) {
    const finalValues = !isLoggedIn ? { ...CONSTANTS.BREADCRUMB } : {}
    return { ...finalValues }
  }

  if (calledFromSearchBar) {
    const preLoginSearchBarValues = {
      ...CONSTANTS.PRE_LOGIN_SEARCH_BAR,
      prop4,
    }
    const finalValues = !isLoggedIn ? preLoginSearchBarValues : {}
    return { ...finalValues }
  }

  if (calledFromPromotion) {
    return {
      ...CONSTANTS.PROMO_BANNER_ANALYTICS_VALUES,
    }
  }

  if (calledFromNavBar) {
    const preLoginCategoryValues = {
      ...CONSTANTS.PRE_LOGIN_CATEGORY,
      eVar9: safeShow`${CONSTANTS.BROWSE_RESULTS}${pageNumber}`,
      prop7: '',
      prop8: '',
      prop3: (categories[0] && decodeURIComponent(categories[0])) || null,
      prop4: (categories[1] && decodeURIComponent(categories[1])) || null,
      prop5: (categories[2] && decodeURIComponent(categories[2])) || null,
      prop6: (categories[3] && decodeURIComponent(categories[3])) || null,
      eVar11: (categories[3] && decodeURIComponent(categories[3])) || null,
    }
    const finalValues = !isLoggedIn ? preLoginCategoryValues : {}
    return {
      ...finalValues,
    }
  }
  return {}
}

export function getProp4(pageNumber) {
  return safeShow`${CONSTANTS.SEARCH_RESULTS}${pageNumber}`
}

export function getShoppingListSearchAnalytics(pageNumber) {
  const value = safeShow`${CONSTANTS.SEARCH_RESULTS}${pageNumber}`
  return {
    eVar3: CONSTANTS.SHOPPING_LIST,
    eVar19: value,
    prop4: value,
  }
}

export function removeFalsy(obj) {
  return pickBy(obj, identity)
}
