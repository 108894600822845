import messagesOrderSummary from 'components/containers/SubmittedOrderDetails/SubmittedOrderDetailsSummary/messages.js'
import { CREDIT_CARD_MANDATORY_TYPE } from '~/components/shared/PaymentOptions/PaymentOptionConstants'
import { ENGLISH } from '~/redux/constants'
import { filterAndMapTruthyValues } from '~/services/utils'
import {
  ACCOUNT_CREDIT,
  PLACE_ON_ACCOUNT,
  PAYMENT_OPTIONS,
  PROFILE,
  USE_PREVIOUSLY_ENTERED,
  USE_PROVIDED,
} from '../PaymentOptionItem/PaymentOptionItemConstants'
const { OPTIONAL, MANDATORY, NOT_AUTHORIZED } = CREDIT_CARD_MANDATORY_TYPE

export function getPaymentOptions({
  creditCardMandatoryType: type,
  ...rest
} = {}) {
  // First apply the settings that are truthy
  // return first array that can contain ['profileCredit', 'useProvided', 'accountCredit' ...]
  // based on buyer and details and more
  const defaultOptions = applyUserPaymentSettings(rest)

  // Then apply account settings that supersede it (and restrict some of them)
  const paymentOptions = applyPaymentAccountSettings(type, defaultOptions)

  // transform to boolean object
  const values = Object.fromEntries(paymentOptions.map((e) => [e, true]))
  // return as array, and object for UI usage
  return { options: paymentOptions, values }
}

/**
 * Function that applies all of settings to display Payment Options
 * @param {Object} Object -
 * @param {Boolean} canHaveProfileCreditCardFlag - canHaveProfileCreditCardFlag from api buyer
 * @param {Boolean} canHaveCheckoutCreditCardFlag - canHaveCheckoutCreditCardFlag from api buyer
 * @param {Boolean} creditCardOnShipToFlag - creditCardOnShipToFlag from api details
 * @param {Boolean} showPreviouslyEntered - showPreviouslyEntered - used to show the previously entered credit card
 * @returns
 */
function applyUserPaymentSettings({
  canHaveProfileCreditCardFlag = false,
  canHaveCheckoutCreditCardFlag = false,
  creditCardOnShipToFlag = false,
  showPreviouslyEntered = false,
} = {}) {
  // settings that come from the buyer
  const buyerMap = {
    // allows buyer to add credit card from profile in Checkout page
    [PROFILE]: canHaveProfileCreditCardFlag,
    // allows buyer to add credit card in Iframe in Checkout Page
    [USE_PROVIDED]: canHaveCheckoutCreditCardFlag,
  }
  // setting that come from the details object
  const detailMap = {
    // allows buyer to select hardcoded credit card. Use the Credit Card preset by my Program Administrator"
    [ACCOUNT_CREDIT]: creditCardOnShipToFlag,
  }
  // settings that are added conditionally through app logic
  const otherSettings = {
    // allows buyer to select previously selected credit card from order
    [USE_PREVIOUSLY_ENTERED]: showPreviouslyEntered,
  }
  const mergedMap = {
    ...otherSettings,
    ...buyerMap,
    ...detailMap,
  }
  return filterAndMapTruthyValues(mergedMap)
}
/**
 * Apply settings for payment options based on account
 * @param {*} type - creditCardMandatoryType from the details object
 * @param {*} defaultOptions - options generated from "applyUserPaymentSettings"
 * @returns An array of values
 */
function applyPaymentAccountSettings(type, defaultOptions = []) {
  // NOT_AUTHORIZED means not authorized to use credit card.
  // Only allow to order on account.
  // Buyer should ONLY have ['placeOnAccount']
  if (type === NOT_AUTHORIZED) {
    return [PLACE_ON_ACCOUNT]
  }

  // OPTIONAL buyer can order on account and have credit card
  // Buyer can have 4 or 5 options
  // ['placeOnAccount', 'profile', 'useProvided', 'accountCredit', 'creditCardPreviouslyEntered']
  if (type === OPTIONAL) {
    return [PLACE_ON_ACCOUNT, ...defaultOptions]
  }

  const { length } = defaultOptions

  const defaultValue = !length ? [USE_PROVIDED] : defaultOptions
  // MANDATORY buyer is obligated to order with credit card
  // Buyer can have 3 option ['profile', 'useProvided', 'accountCredit']
  if (type === MANDATORY) {
    return defaultValue
  }
  return defaultValue
}

export const getBillingOptionText = (billingOptionType, language = ENGLISH) => {
  const m = messagesOrderSummary[language]
  const MAP = {
    [PLACE_ON_ACCOUNT]: m.billingOptionOnAccount,
    [ACCOUNT_CREDIT]: m.billingOptionAccountCreditCard,
    [PROFILE]: m.billingOptionProfileCreditCard,
    [USE_PROVIDED]: m.billingOptionCheckoutCreditCard,
  }
  return MAP[billingOptionType] || m.billingOptionNone
}

/**
 * Transform the billing option type (number) to a billing option name like 'useProvided'
 * For easier internal use.
 * @param {String|Number} billingOptionType
 * @returns {String} such as 'useProvided', 'profile' ...
 */
export function getBillingOptionName(billingOptionType) {
  return (
    PAYMENT_OPTIONS.find(({ value }) => value === billingOptionType)?.id || ''
  )
}
/**
 * Transform the billing option name 'useProvided' to a billing number for the API and payloads
 * For easier internal use.
 * @param {*} buyer
 * @returns {String} such as 'useProvided', 'add'
 */
export function getBillingOptionValue(preferedBillingOptionName) {
  return (
    PAYMENT_OPTIONS.find(({ id }) => id === preferedBillingOptionName)?.value ||
    ''
  )
}
