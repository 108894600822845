export const TEMPLATE_NAME = 'Template Name'
export const DELETE_TEMPLATE = 'delete-template'
export const ADD_TEMPLATE_NAME = 'TEMPLATE_NAME'
export const SET_DELETE_MODAL = 'SET_DELETE_MODAL'
export const SET_PRINT_MODAL = 'SET_PRINT_MODAL'
export const TOGGLE_TEMPLATE_FIELD = 'TOGGLE_TEMPLATE_FIELD'
export const SET_CONFIRMATION_MODAL = 'SET_CONFIRMATION_MODAL'
export const MOVE_TEMPLATE = 'move-template'

export const PRINT_VIEW = 'PRINT_VIEW'
export const ICON_COPY = 'ICON_COPY'
export const ICON_DELETE = 'ICON_DELETE'
export const MOVE_TO_CART = 'MOVE_TO_CART'
export const ORDER_DETAIL_TEMPLATE_ID = 'orderDetailTemplate'
export const TEMPLATE_SEGMENT_LOCATION = 'templates'
