import { ENGLISH } from '@redux/constants'
import isEmpty from 'lodash/isEmpty'
import { getOldApiUrl, getApiUrl } from 'services/utils/configUtils'
import {
  PRODUCT_TRANSACTION_FEE,
  PRODUCT_ASSEMBLY_FEE,
  PRODUCT_DELIVERY_INSTALLATION_FEE,
  PRODUCT_ENVIRONMENTAL_FEE,
  PRODUCT_TARIF_FEE,
} from '~/services/fees/products/feeProductTypes'

import {
  checkArgsEmpty,
  generateHeadersWithLanguage,
  sendRequest,
} from '../apiHelper'
import { checkPromoInfo } from 'shared/ProductCard/ProductCardUtils/Utils'
import { getProductPrices } from '~/components/shared/ProductCard/ProductCardUtils'
import { camelCaseKeys, isEmptyArray } from 'services/utils'
import {
  MIN_PRODUCT_QTY,
  FREE_GIVE_AWAY,
  PROMOTION_QUALIFIER,
} from '~/components/shared/ProductCard/ProductCardConstants'
import { getTextFromHtml } from '~/components/shared/ProductCard/ProductCardBody/ProductHeading/ProductHeadingUtils'
import { PRODUCT_MINIMUM_QUANTITY } from '~/components/shared/AddToCartButton/AddToCartButtonConstants'
import { getDeliveryMessage } from './productOperationsUtils'
import { getFeeToDisplay } from '~/services/fees/products/feeProductsAdditionalFees'
import { searchApi } from './searchWrapper'

export const OLD_API_URL = getOldApiUrl()
const API_URL = getApiUrl()
/**
 * Name: fetchRecentAndRecommendationProducts
 * Desc: get product list for recent product
 * @param {*} language
 * @param {*} type // expected values Cart = 0, FiftyGreen = 1, HomePage = 2, MiniPDP = 3, Product = 4, Search = 5, ShoppingList = 6, ShoppingListProduct = 7
 */
const buildFetchRecentAndRecommendationProducts = async (args) => {
  const [language = ENGLISH, type, reqParams] = args

  const url = `${OLD_API_URL}/api/recommendationsRail/${type}`
  const options = {
    method: 'POST',
    body: JSON.stringify(reqParams),
    headers: {
      ...generateHeadersWithLanguage(language),
    },
  }
  return sendRequest(url, options)
}

/**
 * Export methods
 */
export const getRecentAndRecommendationProducts = checkArgsEmpty(
  buildFetchRecentAndRecommendationProducts
)

/**
 * Name: fetchProductDetails
 * Desc: get product detail for PDP
 * @param {*} language
 * @param {*} productCode
 */
const buildFetchProductDetails = async (args) => {
  const [language = ENGLISH, productCode] = args
  const url = `${OLD_API_URL}/api/products/productdetails/${productCode}/0`
  const options = {
    headers: {
      'Content-Type': 'application/json',
      'Accept-Language': `${language}-CA`,
    },
  }
  const result = await sendRequest(url, options)
  return formatPdpProductDetail(result)
}

/**
 * Export methods
 */
export const getProductDetails = checkArgsEmpty(buildFetchProductDetails)

/**
 * Name: fetchProductsBySearchKeywordOrCategory
 * Desc: get product detail for PDP
 * @param {*} language
 * @param {*} productCode
 */
const fetchProductsBySearchKeywordOrCategory = async (args) => {
  const [, , language = ENGLISH] = args
  const data = (await searchApi(args)) || {}
  const { Records = [], ...rest } = data
  const products = formatProductDetail(Records, language)
  return { ...rest, Records: products }
}

/**
 * Export method
 */
export const getSearchOrCategoryProducts = checkArgsEmpty(
  fetchProductsBySearchKeywordOrCategory
)

/**
 * Name: fetchProductsBySearchProductList
 * Desc: get product list for Ink & Tonner Search
 * @param {*} language
 */
const fetchProductsBySearchProductList = async (args) => {
  const [
    language = ENGLISH,
    pageNumber,
    optionsFilter,
    sortBy,
    guestLocation,
    reqParams,
  ] = args
  let url = `${OLD_API_URL}/api/searchByProductList/${pageNumber}/${optionsFilter}`
  if (sortBy) {
    url += `/${sortBy}`
  }
  if (guestLocation) {
    url += `/${guestLocation}`
  }

  const options = {
    method: 'POST',
    body: JSON.stringify(reqParams),
    headers: {
      'Content-Type': 'application/json',
      'Accept-Language': `${language}-CA`,
    },
  }

  return sendRequest(url, options)
}

/**
 * Export method
 */
export const getSearchByProductList = checkArgsEmpty(
  fetchProductsBySearchProductList
)

/**
 * Name: buildFetchProductByShoppingId
 * Desc: get product detail for browse promotion
 * @param {*} language
 * @param {*} shoppingId
 * @param {*} pageNumber
 * @param {*} optionsFilter
 * @param {*} sortBy
 */
const buildFetchProductByShoppingId = async (args) => {
  const [language = ENGLISH, shoppingId, pageNumber, , sortBy = ''] = args
  const url = `${OLD_API_URL}/api/shoppinglists/GetShoppingListDetails/${shoppingId}/${pageNumber}/${sortBy}`
  const { Records, ...restResult } = await sendRequest(url, {
    headers: {
      ...generateHeadersWithLanguage(language),
    },
  })
  const productList = formatProductDetail(Records, language)
  return { Records: productList, ...restResult }
}

/**
 * Export method
 */
export const getShoppingProductDetails = checkArgsEmpty(
  buildFetchProductByShoppingId
)

/**
 * Name: updateShoppingListProductQty
 * Desc: update shopping list product qty
 * @param {string} language
 * @param {string} shoppingListId
 * @pram {array} reqParams
 */
const buildUpdateShoppingListProductQty = async (args) => {
  const [language = ENGLISH, shoppingListId, reqParams] = args
  const url = `${API_URL}/shoppingLists/${shoppingListId}/products`
  const options = {
    method: 'POST',
    body: JSON.stringify([reqParams]),
    headers: {
      ...generateHeadersWithLanguage(language),
    },
  }
  return sendRequest(url, options)
}

/**
 * Export method
 */
export const updateShoppingListProductQty = checkArgsEmpty(
  buildUpdateShoppingListProductQty
)

export const formatProductDetail = (productList = [], language) => {
  const products = productList?.map((item) => {
    const {
      PromoInfo = {},
      CombinedBasePrice,
      Saving,
      IsFlyer,
      CombinedSellPrice,
      QualifierType,
      CombinedLinePrice,
      Quantity = MIN_PRODUCT_QTY,
      FinalSellPrice,
      BasePrice,
      Description,
      ImageUrl,
      MediaImages,
      UnitSellPrice,
      MinimumQuantity,
      QuantityBO,
      IsPriceReallyWeeFeeCombined,
      IsPriceTariffFeeCombined,
      ShowAlphaCommentLine3,
      AlphaCommentLine3,
    } = item

    const deliveryMessage = getDeliveryMessage({
      isBackOrdered: item.IsBackOrdered,
      showAlphaCommentLine2: item.ShowAlphaCommentLine2,
      alphaCommentLine2: item.AlphaCommentLine2,
      serviceDeliveryMessage: item.MessServDeli,
    })

    const displayFee =
      item.FeetoDisplay || Object.values(item?.OrderProductFees || {})

    const feetToDisplayObj = getFeeToDisplay(displayFee, language)

    const fees = feetToDisplayObj.filter(
      ({ FeeType } = {}) =>
        FeeType !== PRODUCT_TRANSACTION_FEE &&
        FeeType !== PRODUCT_ASSEMBLY_FEE &&
        FeeType !== PRODUCT_DELIVERY_INSTALLATION_FEE
    )

    const { price, saving, productPrice } = getProductPrices(
      camelCaseKeys({
        CombinedBasePrice,
        containsPromotion: PromoInfo.ContainPromo,
        Saving,
        IsFlyer,
        CombinedSellPrice,
      })
    )

    const ecoFeeIndex = fees
      ? fees.findIndex((item) => item.FeeType === PRODUCT_ENVIRONMENTAL_FEE)
      : -1
    const ecoFeeObj = ecoFeeIndex !== -1 ? fees[ecoFeeIndex] : {}

    const tariffFeeIndex = fees
      ? fees.findIndex((item) => item.FeeType === PRODUCT_TARIF_FEE)
      : -1
    const tariffFeeObj = tariffFeeIndex !== -1 ? fees[tariffFeeIndex] : {}
    const handlingFees = fees
      ? fees.filter(
          (item) =>
            item.FeeType !== PRODUCT_ENVIRONMENTAL_FEE &&
            item.FeeType !== PRODUCT_TARIF_FEE
        )
      : []

    const savingsAsPercentage =
      saving !== 0 ? `${Math.round((saving / price) * 100)}%` : '0'

    const calculatedSavings = CombinedLinePrice - saving * Quantity

    const handlingFee =
      handlingFees && handlingFees.length > 0 ? handlingFees[0].FeeAmount : 0
    const tarifFee = tariffFeeObj?.FeeAmount ? tariffFeeObj?.FeeAmount : 0
    const ecoFee = ecoFeeObj?.FeeAmount ? ecoFeeObj?.FeeAmount : 0
    const calculatedTotalPrice =
      FinalSellPrice || BasePrice - saving + ecoFee + tarifFee + handlingFee
    const purifiedHtmlString = getTextFromHtml(Description)
    const imageUrl = ImageUrl || MediaImages?.[0]
    const isGiveAway = QualifierType === FREE_GIVE_AWAY
    const isPromotional = checkPromoInfo(PromoInfo, QualifierType)
    const isFreeGiveAway = QualifierType === FREE_GIVE_AWAY
    const isPromotion = QualifierType === PROMOTION_QUALIFIER
    const sellPrice = isPromotion ? UnitSellPrice : CombinedBasePrice
    const minimumQuantity = MinimumQuantity || PRODUCT_MINIMUM_QUANTITY
    const backOrderQuantity = QuantityBO || 0
    const quantity = Quantity || MIN_PRODUCT_QTY
    const showSubTotal = IsPriceReallyWeeFeeCombined || IsPriceTariffFeeCombined
    const leadTimeMessage = ShowAlphaCommentLine3 ? AlphaCommentLine3 : ''
    return {
      ...item,
      deliveryMessage,
      feetToDisplayObj,
      stockMessage: item.ShowAlphaCommentLine1 ? item.AlphaCommentLine1 : '',
      price,
      saving,
      productPrice,
      fees,
      ecoFeeIndex,
      tariffFeeIndex,
      handlingFees,
      isPromotional,
      savingsAsPercentage,
      calculatedSavings,
      calculatedTotalPrice,
      purifiedHtmlString,
      imageUrl,
      isGiveAway,
      isFreeGiveAway,
      sellPrice,
      minimumQuantity,
      backOrderQuantity,
      quantity,
      showSubTotal,
      leadTimeMessage,
      productTitle: Description,
    }
  })
  return products
}

export const formatPdpProductDetail = (product = {}) => {
  if (isEmpty(product)) {
    return {}
  }
  const {
    FeetoDisplay: FeeToDisplay = [],
    FinalSellPrice,
    BasePrice,
    Saving,
    CombinedBasePrice,
    CombinedSellPrice,
  } = product
  const handlingFees = !isEmptyArray(FeeToDisplay)
    ? FeeToDisplay.filter(
        (item) =>
          item.FeeType !== PRODUCT_ENVIRONMENTAL_FEE &&
          item.FeeType !== PRODUCT_TARIF_FEE
      )
    : []
  const ecoFeeIndex = !isEmptyArray(FeeToDisplay)
    ? FeeToDisplay.findIndex(
        (item) => item.FeeType === PRODUCT_ENVIRONMENTAL_FEE
      )
    : -1
  const tariffFeeIndex = !isEmptyArray(FeeToDisplay)
    ? FeeToDisplay.findIndex((item) => item.FeeType === PRODUCT_TARIF_FEE)
    : -1

  const ecoFeeObj = ecoFeeIndex !== -1 ? FeeToDisplay[ecoFeeIndex] : {}
  const tariffFeeObj = tariffFeeIndex !== -1 ? FeeToDisplay[tariffFeeIndex] : {}
  const calculatedTotalPrice =
    FinalSellPrice ||
    BasePrice -
      Saving +
      (ecoFeeObj?.FeeAmount ? ecoFeeObj?.FeeAmount : 0) +
      (tariffFeeObj?.FeeAmount ? tariffFeeObj?.FeeAmount : 0) +
      (handlingFees && handlingFees.length > 0 ? handlingFees[0].FeeAmount : 0)

  const priceDiff =
    CombinedBasePrice !== 0 ? CombinedBasePrice - CombinedSellPrice : 0

  return {
    ...product,
    handlingFees,
    ecoFeeIndex,
    tariffFeeIndex,
    ecoFeeObj,
    tariffFeeObj,
    calculatedTotalPrice,
    priceDiff,
  }
}
