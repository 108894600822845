const { DEVELOPMENT, PRODUCTION, TEST } = require('./environmentConstants')

exports.isDev = () => {
  return (process.env.NODE_ENV || DEVELOPMENT) === DEVELOPMENT
}
exports.isTest = () => {
  return process.env.NODE_ENV === TEST
}
exports.isProduction = () => {
  return process.env.NODE_ENV === PRODUCTION
}
