export const SEARCH_TYPES = {
  DEFAULT_PRE_SEARCH: 2,
  DEFAULT_RESTRICTION: 2,
  DEFAULT_FILTER: 'CF_ALL',
  CATEGORY: 'Category',
  KEYWORD: 'KeyWord',
  SHOPPING: 'ShoppingList',
  PROMO: 'Promotion',
  INK_AND_TONER: 'InkAndToner',
  SEARCH_CAT_KEY: 1, // Means search by keyword and browse category
  SEARCH_PROMO: 2, // Means search by promotion
  SEARCH_SHOPPING_ID: 3, // Means your looking at the shopping list detail page
  INK_AND_TONER_AS_INT: 4, //Means search by ink & toner
  BROWSE_SEARCH_HYBRID: 'browse',
}

export const RES_TRACKING_ID = 'RES_TRACKINGID'
export const VIEW_TYPE = 'viewType'
export const BOX_QUERY = {
  KEY: VIEW_TYPE,
  VALUE: 'Box',
}
export const INK_AND_TONER_QUERY = {
  KEY: VIEW_TYPE,
  VALUE: 'inkAndToner',
}

export const BOX_FILTER = {
  BOX_WIDTH: 'width',
  BOX_HEIGHT: 'height',
  BOX_LENGTH: 'length',
}

export const SHOW_RESTRICTED_ITEMS_VIEW = {
  HIDE: 0,
  SHOW: 1,
}

export const FILTER_TYPE = {
  BOX: 'Box',
}

export const BOX_FINDER = 'box finder'
export const INK_AND_TONER_EN_FINDER = 'ink and toner finder'
export const INK_AND_TONER_FR_FINDER = 'encre et toner'

export const FINDER_TYPE = {
  BOX: 'Box',
  INK_AND_TONER: 'InkAndToner',
}

export const SEARCH_API_TYPE = {
  KEYWORD: 1,
  NOT_KEYWORD: 0,
}

export const QUALIFIER_TYPES = {
  ORDER: 'order',
  PRODUCT: 'product',
}
