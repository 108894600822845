import * as api from 'api'
import { getAllCookies } from 'services/cookieManager'
import { buildOrderWithAuth } from 'services/orderService'
import { generateAdobeAnalyticsValues } from '~/components/shared/AdobeAnalytics/AdobeAnalyticsValuesGenerator/AdobeAnalyticsValuesGenerator'
import { accountHasCvv } from '~/components/shared/CustomHooks/useCreditCardIframeUrl/useCreditCardIframeUrlUtils'
import { getLanguageFromUrl } from '~/components/shared/EwayRouter/EwayRouterUtils'
import { LOGOUT_COOKIES } from '~/components/shared/Layout/Header/Logout/LogoutConstants'
import { getBillingOptionName } from '~/components/shared/PaymentOptions/PaymentOptionsList/PaymentOptionsListUtils'
import { formatCookiesFromContext } from '~/lib/reduxStoreUtils'
import { getAllHeaders } from '~/services/headersManager'
import { getOrderNumberFull } from '~/services/utils/orderUtils'
import { isBrowser } from '~/services/utils/runtimeUtils'
import { wrapWithCurlyBrace } from '../services/utils/utils'
import { getAutoLoginValues } from './autoLoginService/autoLoginServiceUtils'
import { ENGLISH } from './constants'
import { formatBuyer } from '../api/buyer/buyerApiFormatUtils'
import { setCookie } from 'cookies-next'

/**
 *  This method should only be called once backend wise
 *  It gets the initial payload of the page for eway
 * @param {*} req
 * @param {*} query
 */
export async function initialize(ctx) {
  const { req, query } = ctx
  const oldEwayValues = getInitialValuesFromOldEway(req, query)
  const defaultLanguage = {
    language: oldEwayValues.language || ENGLISH,
  }

  const sessionExists = userHasASession(oldEwayValues.session)
  const { language } = defaultLanguage
  const adobeAnalytics = sessionExists
    ? {}
    : await generateAdobeAnalyticsValues({
        buyer: {},
        client: {},
        settings: {},
        ctx,
        language,
      })

  const result = sessionExists
    ? await buildInitialPayload(oldEwayValues, ctx)
    : { language, adobeAnalytics }

  return result
}

export async function buildInitialPayload(oldEwayValues = {}, ctx = {}) {
  const {
    session: sessionFromVintage = {},
    tokenHeader,
    language,
    hideRestrictions,
  } = oldEwayValues
  const { buyerId, sessionId } = sessionFromVintage
  const {
    shipToClientId,
    isUnauthorized,
    deliveryAddressId: selectedDeliveryAddressId,
    punchOutPostBackUrl,
  } = await api.getSession([buyerId, sessionId], tokenHeader)

  const { req = {} } = ctx
  const { cookies = {} } = req || {}
  const hasLogoutCookieInRequest = Object.keys(cookies).some((cookie) =>
    LOGOUT_COOKIES.includes(cookie)
  )
  // Remove all logout cookies from browser if user is not authorized in initial payload.
  if (isUnauthorized && hasLogoutCookieInRequest) {
    LOGOUT_COOKIES.forEach((name) => {
      // expire  cookie
      setCookie(name, '', {
        req: ctx.req,
        encode: (val) => val,
        res: ctx.res,
        path: '/',
        expires: new Date('Thu, 01 Jan 1970 00:00:00 GMT'),
      })
    })
  }

  const buyer = formatBuyer(
    await api.getBuyerAndApproversWithAuth(buyerId, tokenHeader, isUnauthorized)
  )
  const settings = await api.getSettingsWithAuth(
    shipToClientId,
    tokenHeader,
    isUnauthorized
  )
  const { enableWebAnalyticsInstrumentation = true } = settings
  const client = await api.getClientWithAuth(
    shipToClientId,
    settings.punchOutModuleId,
    settings.siteContentNo,
    language,
    tokenHeader,
    isUnauthorized
  )

  const order = await buildOrderWithAuth({ buyerId, sessionId }, isUnauthorized)
  // Don't generate analytics if account does not allow it
  const adobeAnalytics = enableWebAnalyticsInstrumentation
    ? await generateAdobeAnalyticsValues({
        buyer,
        client,
        order,
        settings,
        ctx,
        language,
      })
    : {}

  const initialPayload = {
    ...(buyer && { buyer }),
    ...(settings && { settings }),
    details: formatDetails(client),
    sessionId,
    order: formatOrder(order, buyer),
    hideRestrictions,
    adobeAnalytics,
    language: getLanguage(buyer, language),
    tokenHeader,
    selectedDeliveryAddressId,
    punchOutPostBackUrl,
  }

  return initialPayload
}

const formatOrder = (order, buyer) => {
  return {
    ...order,
    ...(order.billingOptionType && {
      billingOptionName: getBillingOptionName(order.billingOptionType),
    }),
    ewayOrderNumber: getOrderNumberFull(order, buyer),
  }
}

export const getInitialValuesFromOldEway = (req = {}, query) => {
  const cookies = getAllAndFormatCookies()
  const headers = getAllHeaders(req)
  const autoLogin = getAutoLoginValues(query)
  const buyerId = autoLogin.buyerId || headers.buyerId || cookies.buyerId
  const languageFromUrl = getLanguageFromUrl(req?.headers?.absoluteuri)
  const sessionId =
    autoLogin.sessionId || headers.sessionId || cookies.sessionId
  const language = languageFromUrl || cookies.language || headers.language
  const tokenHeader =
    autoLogin.apiauth || headers.tokenHeader || cookies.tokenHeader
  const hideRestrictionsResult = getHideRestrictionsValue(req)
  const initialValues = {
    session: {
      buyerId,
      sessionId,
    },
    hideRestrictions: hideRestrictionsResult,
    language,
    tokenHeader,
  }
  return initialValues
}

export const getHideRestrictionsValue = (req = {}) => {
  let hideRestrictions
  const { headers = {} } = req
  if (headers['userinfo']) {
    const indexOfShowHideRestriction = headers['userinfo'].indexOf(
      'ShowHideRestrictions'
    )
    const indexOfRestrictionsValue =
      indexOfShowHideRestriction + 1 + 'ShowHideRestrictions'.length
    hideRestrictions = headers['userinfo'].substring(
      indexOfRestrictionsValue,
      indexOfRestrictionsValue + 1
    )
  }
  return hideRestrictions || '0'
}

function formatDetails(client) {
  return {
    accountHasCvv: accountHasCvv(client.creditCardMandatoryType),
    creditCardMandatoryType: 0,
    ...client,
  }
}

function getAllAndFormatCookies() {
  const cookies = getAllCookies()
  const formattedCookies = isBrowser()
    ? formatCookiesFromContext(cookies)
    : cookies
  const tokenHeader = cookies.APIAUTH
  const buyerId = wrapWithCurlyBrace(formattedCookies.buyerId)
  const sessionId = wrapWithCurlyBrace(formattedCookies.sessionId)
  const language = formattedCookies.language
  return { buyerId, sessionId, language, tokenHeader }
}

function userHasASession(sessionValues) {
  return !!toValues(sessionValues).filter(Boolean).length
}

function toValues(session = {}) {
  return [...Object.values(session)]
}

const getLanguage = ({ buyer = {} }, language) => {
  return (language || buyer.language || ENGLISH).substring(0, 2)
}
