import omit from 'lodash/omit'
import { isHostedInOldEway } from '~/components/shared/EwayRouter'
import {
  authenticate,
  fetchWebFormCookies,
  getApiAuth,
} from '~/pages/api/login/loginUtils'
import { COOKIES } from '~/services/constants'
import { QUERY_STRING_POSTAL_CODE } from './autoLoginServiceConstants'
import { generateDaysFromNow } from './autoLoginServiceUtils'

/**
 * Function that fetches the login cookies
 * @param {Object} allValues Fetches all the cookies based on the buyerId, sessionId and orderId
 * @returns {Promise} - Returns promise containing the data
 */
export async function fetchAutoLoginCookies(values = {}) {
  try {
    const valuesWithoutPostalCode = omit(values, QUERY_STRING_POSTAL_CODE)
    await authenticate(valuesWithoutPostalCode)
    // fetch the WebForm cookies only if the app has vintage eway running
    // making sure logged we're logged in on WebFormPages
    const response =
      isHostedInOldEway() &&
      (await fetchWebFormCookies(valuesWithoutPostalCode))
    const { data = [] } = response

    // fetch the API auth for the react version of the site.
    const { apiauth } = await getApiAuth(values.buyerId, values.sessionId)
    const expires = generateDaysFromNow(30)

    // Manually prepare some of the cookies
    const manualCookies = [
      {
        name: COOKIES.AUTO_LOGIN_INITIATED,
        value: true,
        httpOnly: false,
        path: '/',
      },
      {
        name: COOKIES.BUYER_ID,
        value: values.buyerId,
        expires,
        httpOnly: false,
        path: '/',
      },
      {
        name: COOKIES.SESSION_ID,
        value: values.sessionId,
        expires,
        httpOnly: false,
        path: '/',
      },
      {
        ...(values?.postalCode && {
          name: QUERY_STRING_POSTAL_CODE,
          value: values?.postalCode.toUpperCase(),
          expires,
          httpOnly: false,
          path: '/',
        }),
      },

      // TODO: Fix APIAUTH security issue
      // Makes the APIAUTH available in the browser and it shouldn't be
      {
        name: COOKIES.APIAUTH,
        value: apiauth,
        httpOnly: false,
        path: '/',
        ...(isHostedInOldEway() && { sameSite: 'none' }),
      },
    ].filter(Boolean)
    const allCookies = [...data, ...manualCookies]
    return Promise.resolve({ data: allCookies, error: {} })
  } catch (error) {
    return Promise.reject(error)
  }
}
