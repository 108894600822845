import { getBudgetData } from '~/services/shoppingCartService'
import { cardBudgetData } from '~/components/containers/Home/HomeUtils'

// API response manipulation for shopping cart
export async function cardBudgetDataForShoppingCart(language, showCustomDates) {
  const data = await cardBudgetData(language, showCustomDates)
  const budgetData = getBudgetData(data, language, showCustomDates)
  return budgetData
}

// Check budget Calculator invalid dates
export const budgetCalculatorDateIsInvalid = ({ beginDate, endDate }) => {
  return (
    beginDate === '1901-01-01T00:00:00' && endDate === '1901-01-01T00:00:00'
  )
}
