//breakpoint
export const BREAK_POINTS = {
  xxs: 475, //small mobile
  xs: 600, //mobile
  sm: 820, //small tablets
  md: 1024, // tablets
  lg: 1280, // laptop
  xl: 1440, //desktops
}

export const PAGE_NAMES = {
  HOME: 'HOME',
  SEARCH: 'SEARCH',
  PDP: 'PDP',
  CONTACT_US: 'CONTACT_US',
  SHOPPING_LIST: 'SHOPPING_LIST',
  CATEGORY: 'CATEGORY',
  PROMOTION_BANNER: 'PROMOTION_BANNER',
  SHOPPING_CART: 'shopping-cart',
  CHECKOUT: 'CHECKOUT',
  BECOME_A_CUSTOMER: 'become-a-customer',
  SHOPPING_LIST_SAVE: 'shopping-list-save',
  MANAGE_USERS: 'manage-users',
  TEMPLATES_SUMMARY: 'templates-summary',
  PRINT_VIEW: 'print-view',
  MANAGE_ORDER_APPROVALS: 'manage-order-approvals',
  ORDER_DETAIL_TEMPLATE: 'order-detail-template',
  ACCOUNT_MANAGEMENT_CENTRE: 'account-management-centre',
  EDIT_GROUP: 'edit-group',
  CONFIRM_USER_ADDRESS_INFORMATION: 'confirm-user-address-information',
  EDIT_PROFILE: 'edit-profile',
  GROUP_ORDER: 'group-orders',
  CHANGE_PASSWORD: 'change-password',
  MODIFY_ORDER_APPROVAL: 'modify-order-approval',
  APPROVAL_DETAILS_MODIFY_APPROVER: 'approval-details-modify-approver',
  ORDER_AWAITING_APPROVAL: 'orders-awaiting-approval',
  SUBMITTED_ORDER_DETAILS: 'submitted-order-details',
  ORDER_DETAILS: 'order-details',
  MODIFY_ORDER: 'modify-order',
  APPROVAL_ORDER_DETAILS: 'approval-order-details',
  SUBMITTED_ORDERS: 'submitted-orders',
  ACCOUNT_ACTIVATION: 'account-activation',
  REBATES_OFFERS: 'rebates-offers',
  ORDER_TYPE_PAGE: 'order-type',
  FLYERS: 'flyers',
  FLYERS_DETAIL: 'flyers-details',
  RECALL_INFORMATION: 'recall-information',
  TERMS_AND_CONDITIONS: 'terms-conditions',
  RETURNS: 'returns',
  LANDING_PAGE: 'landing-pages',
  HELP_CENTRE: 'help-Centre',
  PRIVACY_NOTICE: 'privacy-notice',
  INK_AND_TONER: 'ink-and-toner',
  FIND_YOUR_ORDER: 'find-your-order',
  CREATE_NEW_ACCOUNT: 'create-new-account',
  ADVANCED_GROUP_ORDER: 'advance-group-order',
  QUICK_GROUP_ORDER: 'quick-group-order',
  MANAGE_GROUPS: 'manage-groups',
}
export const SELECT = 'SELECT'
export const CHECKBOX = 'checkbox'
export const DATE_PICKER = 'DATE_PICKER'

export const ICON_NAMES = {
  LOCK_ICON: 'lock',
  UNLOCK_ICON: 'UnlockOutline',
}
