import orderBy from 'lodash/orderBy'
import uniqueId from 'lodash/uniqueId'
import { getLanguageSuffix, purifyHtmlString } from 'services/utils'
import { getApiUrl, getCdnUrl, getHostUrl } from '~/services/utils/configUtils'
import { sendRequest } from '../apiHelper'
import { getTermsConditionDate } from '~/services/utils/dateTimeUtils'
import { isEnglish } from '~/services/utils/languageUtils'
import { getUrl } from 'shared/EwayRouter'
import { FLYERS_ROUTE } from 'shared/EwayRouter/EwayRouterConstants'
import { ENGLISH } from '@redux/constants'

const API_URL = getApiUrl()
const HOST_URL = getHostUrl()
const CDN_URL = getCdnUrl()

export function fetchCustomSiteContent(siteContentNo, options) {
  return hasCustomSiteContent(siteContentNo)
    ? sendRequest(`${API_URL}/generic/siteContent/${siteContentNo}`, options)
    : undefined
}

const hasCustomSiteContent = (siteContentNo) => {
  const DEFAULT_SITE_CONTENT = 1
  return DEFAULT_SITE_CONTENT !== siteContentNo
}

export function fetchCustomSiteContentDetails(siteContentNo, options) {
  return hasCustomSiteContent(siteContentNo)
    ? sendRequest(
        `${API_URL}/generic/siteContentDetails/${siteContentNo}`,
        options
      )
    : undefined
}

export function fetchCustomSiteContentResources(siteContentNo, options) {
  return hasCustomSiteContent(siteContentNo)
    ? sendRequest(
        `${API_URL}/generic/siteContentResources/${siteContentNo}`,
        options
      )
    : undefined
}

export async function getReviewAndConfirmCustomMessage({
  language,
  siteContentNo,
}) {
  const languageSuffix = getLanguageSuffix(language)
  const url = `${HOST_URL}/api/amon/site-content/${siteContentNo}/details?cultureCode=${languageSuffix}`

  try {
    const { isSuccess, status, ...result } = await sendRequest(url, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    const { reviewAndConfirmPage = '' } = result
    const finalResult = isSuccess ? reviewAndConfirmPage : ''
    return { isSuccess, status, result: finalResult }
  } catch (e) {
    return { isSuccess: false, status: 400, message: e }
  }
}

export async function getTermsConditions({ language, siteContentNo }) {
  const languageSuffix = getLanguageSuffix(language)
  const url = `${HOST_URL}/api/amon/site-content/details/${siteContentNo}/terms-conditions?cultureCode=${languageSuffix}`
  const options = {
    headers: {
      'Content-Type': 'application/json',
      'Accept-Language': `${language}-CA`,
    },
  }
  try {
    const { isSuccess, status, ...result } = await sendRequest(url, options)
    const finalResult = isSuccess ? result[0].termAndConditionContent : ''

    return { isSuccess, status, result: finalResult }
  } catch (e) {
    return { isSuccess: false, status: 400, message: e }
  }
}

export async function getPrivacyNotice({ language, siteContentNo }) {
  const languageSuffix = getLanguageSuffix(language)
  const url = `${HOST_URL}/api/amon/site-content/details/${siteContentNo}/privacy?cultureCode=${languageSuffix}`
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  }
  try {
    const { isSuccess, status, ...result } = await sendRequest(url, options)
    const finalResult = isSuccess ? result[0].privacyContent : ''

    return { isSuccess, status, result: finalResult }
  } catch (e) {
    return { isSuccess: false, status: 400, message: e }
  }
}

export async function getRecallInformation({ language }) {
  const url = `${HOST_URL}/api/amon/site-content/recall-information`
  const options = {
    headers: {
      'Content-Type': 'application/json',
      'Accept-Language': `${language}-CA`,
    },
  }
  try {
    const result = await sendRequest(url, options)
    const { isSuccess, status } = result
    const finalResult = isSuccess
      ? formatDataForRecallInfo(language, result)
      : result

    return { isSuccess, status, result: finalResult }
  } catch (e) {
    return { isSuccess: false, status: 400, message: e }
  }
}

const formatDataForRecallInfo = (language, response = []) => {
  const prefix = isEnglish(language) ? 'Eng' : 'Fra'
  const finalData = response.map((element) => {
    return {
      weight: element.weight,
      recallId: element.recallId,
      startDate: getTermsConditionDate(element.startDate),
      endDate: getTermsConditionDate(element.endDate),
      description: purifyHtmlString(element[`description${prefix}`]),
      title: purifyHtmlString(element[`title${prefix}`]),
      urls: getUrls(element[`pdf${prefix}`], element[`url${prefix}`]),
    }
  })
  return finalData
}

function getUrls(pdf, url) {
  const urls = pdf ?? url ? pdf ?? url : ''
  return urls
}

export async function getFlyersList(
  language,
  wareHouseNo = null,
  divisionCode = null
) {
  try {
    const url = `${API_URL}/site-content/publications?warehouseNo=${wareHouseNo}&divisionCode=${divisionCode}`
    const { isSuccess, status, ...result } = await sendRequest(url)
    const data = formatData(result, language)
    return {
      status,
      isSuccess,
      items: data,
    }
  } catch (error) {
    return {
      isSuccess: false,
      items: [],
    }
  }
}

const formatData = (data, language) => {
  const formattedData = orderBy(data, 'weight', 'desc').map((e) => {
    const shoppingListUrl = getShoppingListUrl(
      e.shoppingListId,
      e.shoppingListType,
      language
    )
    const flyerDetailUrl = getFlyerUrl(e)
    const prefix = language === 'en' ? 'Eng' : 'Fra'
    const title = e[`title${prefix}`]
    const imageName = e[`image${prefix}`]
    const cdnUrl = `${CDN_URL}/images/publications/${language}/${imageName}`
    const imgSrc = cdnUrl
    const itemDescription = e[`description${prefix}`]
    return {
      id: uniqueId(),
      shoppingListUrl,
      flyerDetailUrl,
      imgSrc,
      imgAltText: title,
      itemHeading: title,
      itemDescription,
    }
  })
  return formattedData
}

function getFlyerUrl(e) {
  const { number } = e
  const flyerDetailUrl = `${getUrl(FLYERS_ROUTE)}/${encodeURIComponent(number)}`
  return flyerDetailUrl
}

function getShoppingListUrl(id, type, language) {
  return id
    ? `${HOST_URL}/${language}/ShoppingList/ShoppingList/GetShoppingListDetails/${id}/${type}`
    : ''
}

export async function getFlyerDetails({
  flyerNumber,
  divisionCode,
  warehouseNo,
  language,
}) {
  const encodedFlyerNumber = encodeURIComponent(flyerNumber)
  const url = `${API_URL}/site-content/publications?number=${encodedFlyerNumber}&warehouseNo=${warehouseNo}&divisionCode=${divisionCode}`
  const [flyerData = {}] = await sendRequest(url)
  const flyerTitle =
    language === ENGLISH ? flyerData?.titleEng : flyerData?.titleFra

  return { flyerTitle, flyerData }
}
