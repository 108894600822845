import isBoolean from 'lodash/isBoolean'
import isEmpty from 'lodash/isEmpty'
import Router from 'next/router'
import { removeRestrictedProducts } from 'shared/CheckoutButton/CheckoutButtonUtils'
import { isDev } from '~/config/env/environmentUtils'
import { COOKIES } from '~/services/constants'
import { getCookie } from '~/services/cookieManager'
import { isBrowser } from '~/services/utils/runtimeUtils'
import {
  getLanguageFromUrl,
  wrapWithHostUrl,
  throwErrorIf,
  isCheckout,
  isPageBasedOnMapping,
} from './EwayRouterUtils'
import useIsMounted from '../CustomHooks/useIsMounted'
import {
  CHECKOUT_ROUTE,
  DEFAULT_VALUE,
  HOME_ROUTE,
  MAPPING,
  NOT_FOUND,
  SHOPPING_CART_ROUTE,
} from './EwayRouterConstants'
import { getHostUrl } from '~/services/utils/configUtils'
import getConfig from 'next/config'
const HOST_URL = getHostUrl()
const configs = getConfig().publicRuntimeConfig

/**
 * @param {bool} forceRunningInVintageEway
 *
 * Exists for debug purposes:
 * Instead of having the new eway routes
 * The router would generate the vintage eway routes
 * instead of rendering
 *
 * "/rebates"
 * the router would generate the corresponding vintage route
 *
 * "/shopping/rebates/List"
 *
 * All values are defined in
 * EwayRouterConstants.js file.
 **/

// FOR DEBUG PURPOSES ONLY ⬇️
const DEBUG_RUNNING_IN_VINTAGE_EWAY = false

/**
 *  Behaves a bit like the nextjs router,
 *  Except that's it's able to detect if you're running in the old eway environnement
 *  This router is called to disappear once we eliminate all old eway pages.
 */
export const push = (props = {}) => {
  if (isHostedInOldEway()) {
    // Use window.location
    goToPath(props)
  } else {
    const { pathname = '', search = '' } = props
    // Use the next router
    process.browser &&
      Router.push({
        pathname,
        search,
      })
  }
  return
}
// [newEwayRoute,oldEwayRoute]
export const getUrl = (
  route,
  { noLanguage = false, specificLanguage = '', forceVintageUrl = false } = {}
) => {
  const [newRoute, oldRoute] = getRoutes(route)
  const callback = noLanguage ? wrapWithHostUrl : wrapWithHostUrlAndLanguage
  const finalOldRoute = callback(oldRoute, specificLanguage)
  if (forceVintageUrl) {
    return finalOldRoute
  }
  const result = isHostedInOldEway() ? finalOldRoute : newRoute
  return result
}

export const getRelativeUrl = (route) => {
  const [newRoute, oldRoute] = getRoutes(route)
  return isHostedInOldEway() ? oldRoute : newRoute
}

function getRoutes(route) {
  return findSubRoutes(route) || MAPPING[route] || MAPPING[NOT_FOUND]
}

const findSubRoutes = (route) => {
  return route?.includes('/product-detail') && route?.includes('/product/')
    ? [route, `/Shopping/Product/${getProductId(route)}`]
    : null
}

function getProductId(route) {
  return route.slice(route.lastIndexOf('/'), route.length).replace('/', '')
}

/**
 * Used in conjunction with getUrl, which basically returns
 * An url according with the host url and browser language
 * @param {string} url
 */
const wrapWithHostUrlAndLanguage = (url, specificLanguage = '') => {
  const language = isBrowser()
    ? getLanguageFromUrl(window.location.href) || getRouterLanguage()
    : getRouterLanguage()
  const finalLanguage = specificLanguage || language
  const finalUrl = `${HOST_URL}${
    finalLanguage ? `/${finalLanguage}` : ''
  }${url}`
  return finalUrl
}

export { wrapWithHostUrl }
/**
 * Does a 302 redirect from a server side perspective
 * Then pushes also the proper route to the given path
 */
export const redirect = (path, ctx) => {
  if (ctx.req) {
    ctx.res.writeHead(302, { Location: path })
    ctx.res.end()
    return
  }
  push(path)
}

const goToPath = (pathname, search) => {
  const pathNameWithQueryParams = buildOldEwayPath(pathname, search)
  window.location.assign(pathNameWithQueryParams)
}

/**
 * Represents the different routes existing in the old way
 * That matches to the new eway
 */
const routes = {
  [DEFAULT_VALUE]: `${process.env.HOST_URL}/${getCookie(COOKIES.LANGUAGE)}`,
  confirmation: '/Shopping/Checkout/Confirmation',
  returns: '/Orders/Submitted/Returns',
  'product-detail': '/Shopping/Product',
  home: `${process.env.HOST_URL}/${getCookie(COOKIES.LANGUAGE)}`,
}

/**
 *
 * @param {*} nextJsInternalPath
 *
 * Converts an old eway route, to an internal nextjs route
 *
 * If you pass a nextjs internal route like : 'https://dev-eway-release.savana.staples.com'
 * It should return the new eway's corresponding url.
 * i.e /home
 */
export const convertToNewEwayRoute = (oldEwayPath) => {
  return flipRoutes(routes)[oldEwayPath]
}

/**
 *
 * @param {*} path
 *
 * Converts an old eway route, to a new one
 *
 * If you pass in '/home'
 * It should return the old eway's current release.
 * i.e https://dev-eway-release.savana.staples.com
 */
export const convertToOldEwayRoute = (value) => {
  const oldEwayPath = `${routes[value || DEFAULT_VALUE]}`
  return oldEwayPath
}

const flipRoutes = (routes) => objectFlip(routes)

const objectFlip = (obj) => {
  const ret = {}
  Object.keys(obj).forEach((key) => {
    ret[obj[key]] = key
  })
  return ret
}

export function buildOldEwayPath({ pathname, search }) {
  if (isHostedInOldEway()) {
    const HOST_URL = getHostUrl()
    const language = getLanguageFromUrl(window.location.href)
    const pathName = convertToOldEwayRoute(pathname)
    return `${HOST_URL}${language ? `/${language}` : ''}${pathName}/${search}`
  }
  return null
}

export const redirectToCheckout = async (language) => {
  await removeRestrictedProducts(language)

  // Only redirect if not already on page
  if (process.browser && !isCheckout()) {
    window.location.assign(getUrl(CHECKOUT_ROUTE))
  }
}

export const redirectToShoppingCart = (hasValidateCustomPrompt) => {
  const values = MAPPING[SHOPPING_CART_ROUTE]
  const isShoppingCart = isPageBasedOnMapping(values)

  // Only redirect if not already on page
  if (process.browser && !isShoppingCart) {
    const shoppingCartUrl = getUrl(SHOPPING_CART_ROUTE)

    window.location.assign(
      `${shoppingCartUrl}${
        hasValidateCustomPrompt
          ? `?validateCustomPrompts=${hasValidateCustomPrompt}`
          : ''
      }`
    )
  }
}

export const redirectToHomePage = () => {
  if (process.browser) {
    window.location.assign(getUrl(HOME_ROUTE))
  }
}

export const routesMappingForMvcPages = routes

/**
 * isRunningBehindVintageEway is a private module variable
 * That defines the way the router behaves
 * If the value is true: It'll return the actual vintage eway values for paths
 * If the value is false: It'll return localhost urls
 * By default the app should return only localhost:3000/return urls.
 */
let isRunningBehindVintageEway = false
export const getIsRunningInVintageEway = function () {
  return isRunningBehindVintageEway
}

/**
 *
 * @param {bool} value Expects a boolean value
 */
export const setIsRunningInVintageEway = function (value = false) {
  throwErrorIf(
    !isBoolean(value),
    `Expected a boolean type but got ${typeof value}`
  )
  if (DEBUG_RUNNING_IN_VINTAGE_EWAY) {
    isRunningBehindVintageEway = true
    return
  }
  isRunningBehindVintageEway = value
}
/**
 * Hooks that checks if the router is running behind the vintage eway website
 * Hook will become obsolete when this application becomes the new front-end
 * This hooks sets the router language properly generates urls from the server to the front-end
 * @param {string} language
 */
export function useConfigureEwayRouter(language) {
  const languages = ['en', 'fr']
  const isMounted = useIsMounted()
  // configures the router's language ⬇️
  const isMountedBrowser = isMounted && isBrowser()
  throwErrorIf(
    isEmpty(language) || !languages.includes(language),
    `Expected a string value of either 'fr' or 'en' but got '${language}'`
  )
  if (isMountedBrowser) {
    setRouterLanguage(language)
  }

  // configures links on the site ⬇️
  const isLocalHost =
    isMountedBrowser && window.location.href.includes(`localhost:3000`)
  const isRunningInVintage =
    (isMountedBrowser && !isLocalHost) || !!configs.useVintageUrls

  if (isDev() && DEBUG_RUNNING_IN_VINTAGE_EWAY) {
    setIsRunningInVintageEway(true)
    return
  }

  isBrowser() && setIsRunningInVintageEway(isRunningInVintage)
}
/**
 * routerLanguage is a module variable that sets the language of the router
 * Some vintage eway have the language in the route. .ie /en/some-url
 * In order to make sure the URLS are properly rendered server side and client side
 * This variables exists.
 *
 * When this application becomes the new front-end, the need for this variable will be obsolete
 */
let routerLanguage
export function setRouterLanguage(language) {
  const languages = ['en', 'fr']
  throwErrorIf(
    isEmpty(language) || !languages.includes(language),
    `Expected a string value of either 'fr' or 'en' but got '${language}'`
  )
  routerLanguage = language
}

export function getRouterLanguage() {
  return routerLanguage
}

export const isHostedInOldEway = getIsRunningInVintageEway

if (isDev() && process.browser) {
  window.isHostedInOldEway = isHostedInOldEway
}
