/* eslint-disable no-console */
import { isTest } from '~/config/env/environmentUtils'
const DEBUG_API_CALL_URL = false
/**
 * Utility that allows using a mock server for tests
 */
let isUsingMockServer
export const setIsUsingMockServer = (value) => {
  isUsingMockServer = value
}
export const getIsUsingMockServer = () => {
  return isUsingMockServer
}

export function MOCK_TESTS_WARNING(url) {
  if (isTest()) {
    if (DEBUG_API_CALL_URL) {
      console.log(url)
    }
    if (isUsingMockServer) {
      return
    }
    // eslint-disable-next-line no-console
    console.error(new Error(`You need to mock API call for ${url}`).stack)
  }
}
