import classNames from 'classnames'
import omit from 'lodash/omit'
import { isProduction } from '~/config/env/environmentUtils'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import Icon from 'shared/Icon'
import Loader from '../Loader'
import styles from './Button.module.scss'

/**
 *Name: Button
 *Desc: Render button
 * @param {*} text
 * @param {*} icon
 * @param {*} onClick
 * @param {*} primary
 * @param {*} secondary
 * @param {*} tertiary
 * @param {*} link
 * @param {*} light
 * @param {*} variant
 * @param {*} buttonIcon
 * @param {*}  isFullWidth
 */

const Button = forwardRef((props, ref) => {
  const {
    className,
    variant,
    iconDataTest,
    size,
    keepOpacity,
    children,
    textDecoration,
    fontFamily,
    icon,
    text,
    isLoading,
    color,
    loadingText,
    as: Component,
    isFullWidth,
    borderRadiusRightType = '',
    whiteSpace = '',
    cursor,
    focus,
    ...rest
  } = props
  const classes = classNames(
    styles.btn,
    { [styles[variant]]: variant },
    { [styles.keepOpacityOnDisabled]: keepOpacity },
    { [styles.maxHeightNone]: rest['max-height'] === 'none' },
    { [styles[size]]: size },
    { [`${styles.inlineFlexAndCenter} ${styles.notAllowedCursor}`]: isLoading },
    { [styles.cursorDefault]: cursor === 'default' },
    { [styles.rg]: fontFamily === 'rg' },
    { [styles.redColor]: color === 'red' },
    { [styles.textDecoration]: textDecoration },
    { [styles.fullWidth]: isFullWidth },
    {
      [styles.borderRadiusRightTypeStraight]:
        borderRadiusRightType === 'straight',
    },
    {
      [styles.focusStylePrimary]: focus,
    },
    { [styles.noWrap]: whiteSpace === 'nowrap' },
    { [className]: className }
  )

  let iconProps = {}
  if (icon && !text) {
    iconProps = omit({ ...rest }, 'id')
    delete iconProps.onClick
  }

  // When loading set to disabled.
  const loadingProps = isLoading
    ? { 'aria-disabled': true, disabled: true }
    : {}

  const iconComponent = icon ? (
    <Icon {...iconProps} variant={icon} data-test={iconDataTest} />
  ) : null

  const omittedRest = omit(rest, 'max-height')
  return (
    <Component ref={ref} {...omittedRest} {...loadingProps} className={classes}>
      <>
        {isLoading ? (
          <>
            <Loader mr="0.5rem" />
            {iconComponent}
            {loadingText}
          </>
        ) : (
          <>
            {iconComponent}
            {text || children}
          </>
        )}
      </>
    </Component>
  )
})

if (!isProduction()) {
  Button.displayName = 'Button'
}

Button.defaultProps = {
  text: '',
  fontFamily: 'md',
  textDecoration: false,
  as: 'button',
  isFullWidth: false,
}

// Props Validation
Button.propTypes = {
  text: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.number,
  ]),
  loadingText: PropTypes.string,
  className: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  iconDataTest: PropTypes.string,
  children: PropTypes.node,
  color: PropTypes.string,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  isLoading: PropTypes.bool,
  link: PropTypes.bool,
  light: PropTypes.bool,
  focus: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  size: PropTypes.string,
  fontFamily: PropTypes.string,
  buttonIcon: PropTypes.bool,
  /** Controls the look of the button
   * By default the value should be primary
   *
   * Other values can be
   * - secondary
   * - tertiary
   * - quaternary
   *
   * You can can also display with outline (transparent) i.e
   * ```
   * <Button variant="outline-primary" />
   * ```
   */
  variant: PropTypes.string,
  keepOpacity: PropTypes.bool,
  textDecoration: PropTypes.bool,
  borderRadiusRightType: PropTypes.string,
  cursor: PropTypes.string,
  whiteSpace: PropTypes.string,
  as: PropTypes.string,
  isFullWidth: PropTypes.bool,
}

export default Button
