import { updateAccount } from 'api'
import { handleErrorWithLogging } from 'api/apiHelper'
import {
  INSTALLATION_UNAVAILABLE,
  PRE_ASSEMBLY_NOT_AVAILABLE,
} from '~/components/shared/Alerts/AlertNotificationUtils/AlertNotificationConstants'
import { byRemovedErrorMessages } from '~/components/shared/Alerts/AlertNotificationUtils/AlertNotificationFilteringUtils'
import { REMOVE_ITEM_MESSAGE } from '~/services/constants'
import { setCookie } from '~/services/cookieManager'
import { windowReload } from './utils/windowUtils'
export const updateUserAccountAndReload = async (
  clientNo,
  currentClientNo,
  orderProducts,
  options = { reload: true }
) => {
  let response
  try {
    response = await updateAccount(clientNo, currentClientNo)
    if (response.status === 200) {
      const result = await response.json()
      setCookieForErrorMessage(result)
      handleShipToMessageOnReload(result)
      setCartProducts(orderProducts)
      if (options.reload) {
        windowReload()
      }
    } else {
      handleErrorWithLogging(response)
    }
  } catch (error) {
    return handleErrorWithLogging(response, error)
  }
}

const setCookieForErrorMessage = (result = {}) => {
  const errorMessages = result?.Messages?.filter(byRemovedErrorMessages).map(
    withPrimaryMessage
  )
  const errorMessagesStringified = JSON.stringify(errorMessages)
  errorMessagesStringified &&
    setCookie(REMOVE_ITEM_MESSAGE, errorMessagesStringified)
}
const withPrimaryMessage = (message) => {
  return message.PrimaryMessage
}

const setCartProducts = (products) => {
  return localStorage.setItem('cartProducts', JSON.stringify(products))
}

function handleShipToMessageOnReload(result = {}) {
  const [firstMessage = {}] = result.Messages || []
  if (firstMessage.Type === PRE_ASSEMBLY_NOT_AVAILABLE) {
    setCookie(PRE_ASSEMBLY_NOT_AVAILABLE, true)
  }
  if (firstMessage.Type === INSTALLATION_UNAVAILABLE) {
    setCookie(INSTALLATION_UNAVAILABLE, true)
  }
}
