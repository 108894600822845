import { isBrowser } from 'services/utils/runtimeUtils'
/**
 * set LocalStorage for given key and value
 * @param {*} key
 * @param {*} value
 */
export const setLocalStorage = (key, value) => {
  window.localStorage.setItem(key, value)
}

/**
 * Return the LocalStorage for given key
 * @param {*} key
 */
export const getLocalStorage = (key) => {
  if (isBrowser()) {
    const value = window.localStorage.getItem(key)
    return value
  }
  return null
}

/**
 * Return removed item
 */
export const removeLocalStorage = (key) => {
  if (isBrowser()) {
    const value = window.localStorage.getItem(key)
    window.localStorage.removeItem(key)
    return value
  }
  return null
}

/**
 * Session Methods for getter setter and remover
 */
/**
 * set SessionStorage for given key and value
 * @param {*} key
 * @param {*} value
 */
export const setSessionStorage = (key, value) => {
  window.sessionStorage.setItem(key, value)
}
/**
 * Return the SessionStorage for given key
 * @param {*} key
 */
export const getSessionStorage = (key) => {
  if (isBrowser()) {
    const value = window.sessionStorage.getItem(key)
    return value
  }
  return null
}

/**
 * Return removed item
 */
export const removeSessionStorage = (key) => {
  if (isBrowser()) {
    const value = window.sessionStorage.getItem(key)
    window.sessionStorage.removeItem(key)
    return value
  }
  return null
}
