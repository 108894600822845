import { isDev } from '~/config/env/environmentUtils'
import { queryParamsToProps } from '../../Utils/pageUtils'
import { isLoggedIn } from '../../Utils/userUtils'
import { getQueryFromFullUrl } from '../AdobeAnalyticsQueryParamUtils'
import { getFullUrl, getParamFromUrl } from '../AdobeAnalyticsUtils'
import {
  CLIENT_TYPES,
  USER_GROUPS,
} from './AdobeAnalyticsValuesGeneratorConstants'

const buyerTypes = {
  [USER_GROUPS.TEST]: 'Demo Buyer',
  [USER_GROUPS.BUYER]: 'Buyer',
  [USER_GROUPS.ADMIN]: 'Administrator',
  [USER_GROUPS.APPROVER]: 'Approver',
  [USER_GROUPS.GROUP_ORDER_BUYER]: 'Group Order Buyer',
}
export function getUserGroup(props) {
  return !isLoggedIn(props.buyer)
    ? USER_GROUPS.ANONYMOUS
    : buyerTypes[getUserGroupType(props.buyer, props.settings)]
}

export function getClientType(props) {
  const types = {
    [CLIENT_TYPES.REGULAR]: 'Regular',
    [CLIENT_TYPES.PUNCH_OUT]: 'Punchout',
    [CLIENT_TYPES.PUNCH_OUT_WITH_INTERNAL_CHECKOUT]: 'SSO',
  }
  const type = getClientByType(props)
  return types[type]
}

export function getClientByType({ settings = {} }) {
  const { isPunchOutEnable, isUsingInternalCheckout } = settings
  const type = isPunchOutEnable
    ? isUsingInternalCheckout
      ? CLIENT_TYPES.PUNCH_OUT_WITH_INTERNAL_CHECKOUT
      : CLIENT_TYPES.PUNCH_OUT
    : CLIENT_TYPES.REGULAR
  return type
}

function getUserGroupType(buyer = {}, settings = {}) {
  const defaultValue = USER_GROUPS.BUYER
  const { inviteeState, isAdminFlag, isApprover } = buyer
  if (settings.isTestAccountFlag) {
    return USER_GROUPS.TEST
  }
  const type = isGroupOrderBuyer(inviteeState)
    ? USER_GROUPS.GROUP_ORDER_BUYER
    : isApprover
    ? USER_GROUPS.APPROVER
    : isAdminFlag
    ? USER_GROUPS.ADMIN
    : defaultValue
  return type
}
export const ACTIVE_GROUP_ORDER_BUYER = 1
function isGroupOrderBuyer(inviteeState) {
  return inviteeState === ACTIVE_GROUP_ORDER_BUYER
}

//#   ---------------------------
//#   RETURNS page Analytics
//#   TODO: extract to its own place
//#   ---------------------------
export function getCustomEventsFromPageType(ctx = {}, pageValues) {
  const { query } = ctx
  const { type } = queryParamsToProps(query)
  const { events } = isPageTypeReturn(type)
    ? getNewEventValues(pageValues.events, '90', '91')
    : pageValues
  return splitEventsToArray(events)
}

function isPageTypeReturn(type) {
  return type === 'return'
}

export function splitEventsToArray(events = '') {
  if (typeof events !== 'string') {
    showDevConsoleMessage(
      `You should be passing in a string for that method to convert it to an array of events, please correct`
    )
    return events
  }
  return events?.split(',').filter(Boolean)
}

function showDevConsoleMessage(message) {
  if (isDev()) {
    // eslint-disable-next-line no-console
    console.warn(message)
  }
}

export const getNewEventValues = (events, oldValue, newValue) => {
  return { events: events?.replace(oldValue, newValue) }
}

export function getQueryAndPageNumber(ctx) {
  const fullUrl = getFullUrl(ctx.req)
  if (!fullUrl) {
    return { pageNUmber: '', query: '' }
  }
  const query = getQueryFromFullUrl(fullUrl)
  const pageNumber = getParamFromUrl(fullUrl, 'page')
  return { pageNumber, query }
}
