export default {
  en: {
    message: 'Message',
    closeMessage: 'Close Message',
  },
  fr: {
    message: 'Message',
    closeMessage: 'Fermer le message',
  },
}
