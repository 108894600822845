export default {
  en: {
    checkoutLabel: 'Checkout',
    restrictedMessage:
      '{{ITEM_CODE}} item automatically removed from the Shopping cart due to restriction on quantity.',
    cobWarningMsg:
      'You can continue to add items to your cart, however you may not submit another order until your first order has been processed. If you have any questions, please do not hesitate to contact Customer Care at 1-877-272-2121.',
  },
  fr: {
    checkoutLabel: 'Commander',
    restrictedMessage:
      "{{ITEM_CODE}} l'article est automatiquement supprimé du panier en raison d'une restriction de quantité.",
    cobWarningMsg:
      "Vous pouvez continuer à ajouter des articles dans votre panier, cependant, vous ne pouvez pas soumettre de commandes, jusqu'à ce que votre première commande soit traitée. N'hésitez pas à nous contacter au 1-877-272-2121 si vous avez des questions.",
  },
}
